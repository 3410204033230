
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { IMPERSONATION_SELECTION_MODES } from '@/utilities/constants'
import { containsString, sortAlphabetically, sortAlphabeticallyByKey } from '@/utilities/functions'
import cloneDeep from 'lodash/cloneDeep'

const { ORGANISATION, USER } = IMPERSONATION_SELECTION_MODES

const SEARCH_STRINGS = (): Record<string, string> => ({ organisation: '', user: '' })

@Component
export default class ImpersonationSelectionModal extends Vue {
  // props // ***
  @Prop({ required: true, type: Array })
  impersonatableUsers!: Record<string, any>[]
  @Prop({ type: Boolean })
  value!: boolean

  // data // ***
  searchStrings: Record<string, string> = SEARCH_STRINGS()
  selectedOrganisation: string | null = null
  organisations: string[] = []
  filteredOrganisations: string[] = []

  // computed // ***
  get mode(): number {
    return this.selectedOrganisation ? USER : ORGANISATION
  }
  get modes() {
    return { ORGANISATION, USER }
  }
  get searchProps() {
    return { label: this.$t('common.search') }
  }
  get title(): string {
    const { mode } = this
    switch (mode) {
      case ORGANISATION:
        return String(this.$t('ui.pleaseSelectAnX', { x: this.$t('common.organisation') }))
      case USER:
        return String(this.$t('cmac.pleaseSelectAXToImpersonate', { x: this.$t('common.user') }))
      default:
        return ''
    }
  }
  get users(): Record<string, any>[] {
    const { impersonatableUsers: iu, searchStrings, selectedOrganisation: so } = this
    const { user: ss } = searchStrings
    if (!so) return []
    const users = cloneDeep(iu)
      .filter((u: Record<string, any>) => u.organisationName === so)
      .filter((u: Record<string, any>) => !ss || containsString(ss, u.userName))
    return sortAlphabeticallyByKey(users, 'userName')
  }

  // watch // ***
  @Watch('value')
  onValueChanged(v: boolean) {
    if (!v) this.reset()
  }

  created() {
    const { impersonatableUsers } = this
    cloneDeep(impersonatableUsers).forEach((u: Record<string, any>) => {
      let name = u.organisationName
      if (name && !this.organisations.includes(name)) this.organisations.push(name)
    })
    sortAlphabetically(this.organisations)
  }
  // methods // ***
  onBack() {
    this.reset()
  }
  searchOrganisations() {
    const { searchStrings } = this
    const { organisation: ss } = searchStrings

    if (ss.length < 3) return

    if (ss) {
      this.filteredOrganisations = this.organisations.filter((o: string) => containsString(ss, o))
    }
  }
  onClose() {
    this.reset()
    this.$emit('input', false)
  }
  onOrganisationSelected(e: string) {
    this.selectedOrganisation = e || null
  }
  onUserSelected(e: Record<string, any>) {
    if (e) this.$emit('user-selected', e)
    this.reset()
    this.$emit('input', false)
  }
  reset() {
    this.selectedOrganisation = null
    this.searchStrings = SEARCH_STRINGS()
    this.filteredOrganisations = []
  }
}
