export default `
<h5>'Company' refers to C-Mac Partnership Limited
'Supplier' refers to taxi, coach & hotel operators</h5>
<dl><dt>1. Confidentiality</dt>
<dd>Each party shall at all times use its best endeavours to keep confidential (and to procure that its employees and agents shall keep confidential) any confidential information which it or they may acquire in relation to the business, customers and affairs of the other party to this agreement and shall not use or disclose such information except with the consent of that other party or in accordance with the order of a court of competent jurisdiction.

The obligations of each of the parties shall continue without limit in point of time but shall cease to apply to any information coming into the public domain otherwise than by breach by any such party of its obligations contained in this agreement provided that nothing shall prevent any party from disclosing any such information to the extent required in or in connection with legal proceedings arising out of this agreement.</dd>
<dt>2. Data Protection</dt>
<dd>This clause shall be construed alongside the General Data Protection Regulation ('GDPR'). In the event the Company passes data to the Supplier the Company shall be the data controller and the Supplier shall be the data processor. The Supplier shall use the data for the reasons outlined by the Company only, ensuring Supplier obtains a commitment of confidentiality for personal data processed. Supplier must be Article 32 compliant and have secure processing. The Supplier shall ensure data is not retained for longer than absolutely necessary and shall assist the Company at all times when allowing customers to exercise their rights. Supplier must alert the Company immediately if there are any data breaches and must assist with any Audits the Company may require. Supplier will be in direct breach of the GDPR if they use the data provided for direct marketing purposes and therefore shall never use the information to contact the customer for any other reason than as directed.

If you require any assistance to ensure you do not breach GDPR in relation to these T&amp;C's please contact us for guidance.
</dd>
<dt>3. Intellectual Property</dt>
<dd>Neither party shall own or assert any interest in the other Party’s existing or future intellectual property rights. Any intellectual property rights created in the term of the business relationship between both Party’s shall remain with the Party who created them unless agreed otherwise before creation.</dd></dl>`
