
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class KeyValueTable extends Vue {
  // props // ***
  @Prop({ type: String })
  keyClasses!: string
  @Prop({ type: String })
  nullValue!: string
  @Prop({ type: String })
  rowClasses!: string
  @Prop({ type: String })
  valueClasses!: string
  @Prop({ required: true, type: Object })
  values!: Record<string, any>

  // computed // ***
  get pairs() {
    const { nullValue, values } = this
    const keys = Object.keys(values)
    return keys.map((key: string): Record<string, any> => {
      const v = values[key]
      const value = v === null || typeof v === 'undefined' || v === '' ? nullValue : v
      return { key, value }
    })
  }
}
