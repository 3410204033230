
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class WarningPrompt extends Vue {
  // props // ***
  @Prop({ default: 'error', type: String })
  cancelButtonColour!: string
  @Prop({ default: 'primary', type: String })
  confirmButtonColour!: string
  @Prop({ type: String })
  cancelButtonText!: string
  @Prop({ type: String })
  confirmButtonText!: string
  @Prop({ default: 'Are you sure you want to do that?', type: String })
  message!: string
  @Prop({ default: 'Are you sure?', type: String })
  title!: string
  @Prop({ type: Boolean })
  value!: boolean

  // methods // ***
  onCancel() {
    this.$emit('cancel')
  }
  onConfirm() {
    this.$emit('confirm')
  }
}
