
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PageHeading extends Vue {
  // props // ***
  @Prop()
  heading!: string
  @Prop()
  subheading!: string
  @Prop()
  text!: string
}
