var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tableCellWrapper"},[(_vm.fieldIsEditable)?_c('c-edit-dialog',_vm._b({attrs:{"close-on-click":!_vm.persistent},on:{"cancel":_vm.onEditInputCancel,"close":_vm.onEditInputClose,"open":_vm.onEditInputOpen,"save":_vm.onEditInputSave},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-form',{ref:"editInputForm",on:{"submit":function($event){$event.preventDefault();return _vm.onEditInputSave.apply(null, arguments)}}},[_c(_vm.editInputComponent,_vm._b({tag:"component",attrs:{"isValid":_vm.isValid},on:{"cancel":_vm.onEditInputCancel,"save":_vm.onEditInputSave},model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}},'component',_vm.editInput,false))],1)]},proxy:true}],null,false,3546450750),model:{value:(_vm.editDialogOpen),callback:function ($$v) {_vm.editDialogOpen=$$v},expression:"editDialogOpen"}},'c-edit-dialog',_vm.editDialog,false),[(_vm.header.vueComponent)?_c(_vm.header.vueComponent.name,_vm._b({tag:"component",attrs:{"value":_vm.tableCellValue,"header":_vm.header,"item":_vm.item},on:{"click":_vm.handleVueComponentFieldClicked}},'component',{
        ..._vm.header.vueComponent.props,
        ...(_vm.itemSpecificConfig.vueComponent.props || {}),
      },false)):(_vm.header.raw)?_c('div',{class:_vm.tableCellEditableClass,domProps:{"innerHTML":_vm._s(_vm.tableCellValue)}}):_c('div',{class:_vm.tableCellEditableClass},[_vm._v(" "+_vm._s(_vm.tableCellValue)+" ")])],1):(_vm.header.vueComponent)?_c(_vm.header.vueComponent.name,_vm._b({tag:"component",attrs:{"value":_vm.tableCellValue,"header":_vm.header,"item":_vm.item},on:{"click":_vm.handleVueComponentFieldClicked,"save:cell":function($event){return _vm.$emit('save:cell', $event)}}},'component',{
      ..._vm.header.vueComponent.props,
      ...(_vm.itemSpecificConfig.vueComponent.props || {}),
    },false)):(_vm.header.raw)?_c('div',{class:_vm.tableCellClass,domProps:{"innerHTML":_vm._s(_vm.tableCellValue)}}):_c('div',{class:_vm.tableCellClass},[_vm._v(_vm._s(_vm.tableCellValue))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }