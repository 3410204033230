import { makeDefaultGetters, makeDefaultMutations, setState } from '@/utilities/store'
import { send, sendAndSet } from '@/utilities/store'
import { STORE_DEFAULTS } from '@/utilities/constants'
import cloneDeep from 'lodash/cloneDeep'
import api from '@/utilities/api'
import Vue from 'vue'
import { sortAlphabeticallyByKey } from '@/utilities/functions'
import authClient from '@/utilities/auth'

const SUPPLIERS = cloneDeep(STORE_DEFAULTS.SUPPLIERS)

const {
  ACTIVE_SUPPLIER,
  ACTIVE_SUPPLIERS,
  BULK_UPLOADS,
  COVERAGE_AREAS,
  CURRENT_JOURNEYS,
  DISPUTES,
  DOCUMENTS,
  DOCUMENT_TYPES,
  DOWNLOAD_RESULTS,
  FARE_PRICE_CALCULATIONS,
  FIXED_PRICES,
  INVOICES,
  JOURNEYS,
  RATE_CARDS,
  VEHICLES,
  VEHICLETYPES,
  PSVARSTATUSES,
} = SUPPLIERS

const defaultState = (): Record<string, any> => ({
  activeSupplier: ACTIVE_SUPPLIER,
  activeSuppliers: ACTIVE_SUPPLIERS,
  bulkUploads: BULK_UPLOADS,
  coverageAreas: COVERAGE_AREAS,
  currentJourneys: CURRENT_JOURNEYS,
  disputes: DISPUTES,
  documents: DOCUMENTS,
  documentTypes: DOCUMENT_TYPES,
  downloadResults: DOWNLOAD_RESULTS,
  farePriceCalculations: FARE_PRICE_CALCULATIONS,
  fixedPrices: FIXED_PRICES,
  invoices: INVOICES,
  journeys: JOURNEYS,
  rateCards: {
    holidayRateCardEntries: RATE_CARDS.HOLIDAY_RATE_CARD_ENTRIES,
    hotelRateCards: RATE_CARDS.HOTEL_RATE_CARDS,
    vehicleRateCardEntries: RATE_CARDS.VEHICLE_RATE_CARD_ENTRIES,
  },
  vehicles: VEHICLES,
  vehicleTypes: VEHICLETYPES,
  psvarStatuses: PSVARSTATUSES,
})

const properties = (): string[] => Object.keys(defaultState())

const defaultGetters = makeDefaultGetters(properties())

const defaultMutations = makeDefaultMutations(properties(), defaultState())

const state = defaultState()

const getters = {
  ...defaultGetters,
  selectedSupplier: (
    state: Record<string, any>,
    getters: Record<string, any>
  ): Record<string, any> | undefined => getters.suppliers[state.activeSupplier],
  suppliers: (): Record<string, any>[] => {
    const user = authClient.activeUser || {}
    const { organisation, organisations } = user
    if (organisations?.length) return sortAlphabeticallyByKey(organisations, 'name')
    else if (organisation) return [organisation]
    else return []
  },
}

const actions = {
  async fetchBulkUploads(context: Record<string, any>, p: Record<string, any> = {}): Promise<void> {
    const { dispatch } = context
    dispatch('sendAndSet', { mutation: 'setBulkUploads', payload: { ...p, method: 'GET' } })
  },
  async fetchCoverageAreas(
    context: Record<string, any>,
    p: Record<string, any> = {}
  ): Promise<Record<string, any> | undefined> {
    const { commit } = context
    const res = (await api(p.url, undefined, undefined)) || {}
    if (res.data) commit('setCoverageAreas', res.data)
    return res
  },
  async fetchCurrentJourneys(
    context: Record<string, any>,
    p: Record<string, any> = {}
  ): Promise<void> {
    const { commit } = context
    const res = (await api(p.url, { ...p, json: true }, undefined)) || {}
    commit('setCurrentJourneys', res.data || defaultState().journeys)
  },
  async fetchDisputes(context: Record<string, any>, p: Record<string, any> = {}): Promise<void> {
    const { dispatch } = context
    dispatch('sendAndSet', { mutation: 'setDisputes', payload: { ...p, method: 'GET' } })
  },
  async fetchDocuments(context: Record<string, any>, p: Record<string, any> = {}): Promise<void> {
    const { commit } = context
    const res = (await api(p.url, { ...p }, undefined)) || {}
    commit('setDocuments', res.data || defaultState().documents)
  },
  async fetchDocumentTypes(
    context: Record<string, any>,
    p: Record<string, any> = {}
  ): Promise<void> {
    const { commit } = context
    const res = (await api(p.url, { ...p }, undefined)) || {}
    commit('setDocumentTypes', res.data || defaultState().documentTypes)
  },
  async fetchVehicles(context: Record<string, any>, p: Record<string, any> = {}): Promise<void> {
    const { commit } = context
    const res = (await api(p.url, { ...p }, undefined)) || {}
    commit('setVehicles', res.data || defaultState().vehicles)
  },
  async fetchVehicleTypes(
    context: Record<string, any>,
    p: Record<string, any> = {}
  ): Promise<void> {
    const { commit } = context
    const res = (await api(p.url, { ...p }, undefined)) || {}
    commit('setVehicleTypes', res.data || defaultState().vehicleTypes)
  },
  async fetchPsvarStatuses(
    context: Record<string, any>,
    p: Record<string, any> = {}
  ): Promise<void> {
    const { commit } = context
    const res = (await api(p.url, { ...p }, undefined)) || {}
    commit('setPsvarStatuses', res.data || defaultState().psvarStatuses)
  },
  async fetchFarePriceCalculation(
    context: Record<string, any>,
    p: Record<string, any> = {}
  ): Promise<void> {
    const { commit } = context
    const res = (await api(p.url, undefined, undefined)) || {}
    commit('setFarePriceCalculation', { id: p.id, value: res.data })
  },
  async fetchFixedPrices(context: Record<string, any>, p: Record<string, any> = {}): Promise<void> {
    const { dispatch } = context
    dispatch('sendAndSet', { mutation: 'setFixedPrices', payload: { ...p, method: 'GET' } })
  },
  async fetchInvoices(context: Record<string, any>, p: Record<string, any> = {}): Promise<void> {
    const { commit } = context
    const res = (await api(p.url, { ...p }, undefined)) || {}
    commit('setInvoices', res.data || defaultState().documents)
  },
  async fetchJourneys(context: Record<string, any>, p: Record<string, any> = {}): Promise<void> {
    const { commit } = context
    const res = (await api(p.url, { ...p, json: true }, undefined)) || {}
    commit('setJourneys', res.data || defaultState().journeys)
  },
  async fetchRateCards(context: Record<string, any>, p: Record<string, any> = {}): Promise<void> {
    const { dispatch } = context
    dispatch('sendAndSet', {
      mutation: 'setRateCards',
      payload: { method: 'GET', json: true, ...p },
    })
  },

  send,
  sendAndSet,
}

const mutations = {
  ...defaultMutations,
  resetState: (state: Record<string, any>): void => setState(state, defaultState(), true),
  setCurrentJourney: (state: Record<string, any>, data: Record<string, any>): void => {
    const { item, values } = data
    const id = item?.id
    if (!id) return
    const { journeys } = state.currentJourneys
    const index = journeys.findIndex((j: Record<string, any>) => j.id === id)
    const journey = journeys[index]
    if (!journey) return
    const driver = {
      name: values.name,
      phoneNumber: values.phoneNumber,
      vehicleReg: values.vehicleReg,
    }
    const replacement = { ...journey, driver }
    Vue.set(journeys, index, replacement)
  },
  setFarePriceCalculation(state: Record<string, any>, payload: Record<string, any>): void {
    Vue.set(state.farePriceCalculations, payload.id, payload.value)
  },
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
}
