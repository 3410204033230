import router from '@/plugins/router'
import { getIndexableRecord } from './typescript'

export function goTo(url: string, route: Record<string, any>): void {
  if (url && notCurrentRoute(url, route)) router.push(url)
}

export function notCurrentRoute(url: string, route: Record<string, any>): boolean {
  if (route) return route.path !== url
  return !getIndexableRecord(router)
    .options.routes.filter((r: any) => r.path === url || r.redirect === url)
    .map((r: any) => r.redirect || r.path)
    .find((r: any) => r === router.currentRoute?.path)
}

export function routeHasChanged(to: Record<string, any>, from: Record<string, any>): boolean {
  if (!to) return false
  else if (!from) return true
  else return to.path !== from.path
}
