
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class StepperContentLayout extends Vue {
  // props // ***
  @Prop({ default: 'Back', type: String })
  backText!: string
  @Prop({ default: 'Cancel', type: String })
  cancelText!: string
  @Prop({ default: 'Continue', type: String })
  continueText!: string
  @Prop({ type: Boolean })
  disableBack!: boolean
  @Prop({ type: Boolean })
  disableCancel!: boolean
  @Prop({ type: Boolean })
  disableContinue!: boolean
  @Prop({ type: Boolean })
  disableSkip!: boolean
  @Prop({ type: Boolean })
  loading!: boolean
  @Prop({ default: 'Skip', type: String })
  skipText!: string
  @Prop({ type: Boolean })
  showBack!: boolean
  @Prop({ type: Boolean })
  showCancel!: boolean
  @Prop({ type: Boolean })
  showContinue!: boolean
  @Prop({ type: Boolean })
  showSkip!: boolean
}
