import axios from 'axios'
import mapboxgl from 'mapbox-gl'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueMask from 'v-mask'
import { VuePlausible } from 'vue-plausible'
import VueRouter from 'vue-router'
import VueScrollTo from 'vue-scrollto'
import Vuex from 'vuex'
import App from '@/App.vue'
import components from '@/components'
import filters from '@/utilities/filters'
import i18n from '@/plugins/i18n'
import router from '@/plugins/router'
import store from '@/plugins/vuex'
import { Cropper } from 'vue-advanced-cropper'
import vuetify from '@/plugins/vuetify'
import Env from '@/plugins/env'
import Cookies from '@/plugins/cookies'
import Auth from '@/plugins/auth'
import VueTelInputVuetify from 'vue-tel-input-vuetify'
import '@/utilities/registerServiceWorker'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import 'vue-advanced-cropper/dist/style.css'

Vue.use(Env)

Vue.use(Auth)
axios.defaults.withCredentials = true

Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(VueTelInputVuetify, { vuetify })
Vue.use(VueI18n)

Object.keys(filters).forEach(f => Vue.filter(f, filters[f]))

const { NODE_ENV } = process.env

if (NODE_ENV !== 'production') console.debug('NODE_ENV = ' + NODE_ENV)

Vue.use(Cookies)
Vue.use(VueScrollTo)
Vue.use(VueMask)

// TODO: make mapbox a plugin or class similar to the authClient
mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_API_KEY

Vue.use(VuePlausible, { domain: process.env.VUE_APP_PLAUSIBLE_DOMAIN })
Vue.$plausible.enableAutoPageviews()

// import other components
Object.keys(components).forEach((k: string) => Vue.component(k, components[k]))
Vue.component('v-cropper', Cropper)

Vue.config.productionTip = false

export default new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
