
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class FareCalculationCard extends Vue {
  // props // ***
  @Prop({ type: [Number, String] })
  elevation!: number | string
  @Prop({ required: true })
  farePriceCalculation!: Record<string, any>
  @Prop({ type: Boolean })
  loading!: boolean
}
