import { title } from '@/utilities/filters'
import i18n from '@/plugins/i18n'

const permits = [
  {
    component: (): any => import(/* webpackChunkName: "Permits" */ '@/pages/permits/Permits.vue'),
    meta: { title: title(String(i18n.t('cmac.permits'))), public: false },
    name: 'Permits',
    path: '/permits',
  },
  {
    component: (): any =>
      import(/* webpackChunkName: "ViewPermits" */ '@/pages/permits/ViewPermits.vue'),
    meta: { title: title(String(i18n.t('cmac.permits'))), public: false },
    name: 'ViewPermits',
    path: '/permits/view',
  },
  {
    component: (): any =>
      import(
        /* webpackChunkName: "PermitAmendmentScreen" */ '@/pages/permits/forms/amendments/PermitAmendmentScreen.vue'
      ),
    meta: { title: title(String(i18n.t('cmac.amend'))), public: false },
    name: 'PermitAmendmentScreen',
    path: '/permits/amend/:id',
  },
  {
    component: (): any =>
      import(
        /* webpackChunkName: "PermitApplicationScreen" */ '@/pages/permits/forms/applications/PermitApplicationScreen.vue'
      ),
    meta: { title: title(String(i18n.t('cmac.apply'))), public: false },
    name: 'PermitApplicationScreen',
    path: '/permits/apply',
  },
]

export default permits
