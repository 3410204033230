
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ListItem extends Vue {
  // props // ***
  @Prop({ type: Boolean })
  clickable!: boolean
  @Prop()
  item!: Record<string, any>
  @Prop()
  nullText!: number | string
  @Prop({ type: Boolean })
  readonly!: boolean
  @Prop()
  subtitle!: number | string
  @Prop()
  text!: number | string
  @Prop()
  textColor!: string
  @Prop({ type: Boolean })
  threeLine!: boolean
  @Prop()
  title!: number | string
  @Prop()
  titleColor!: string
  @Prop({ type: Boolean })
  twoLine!: boolean

  // computed // ***
  get getText() {
    return this.text || this.nullText
  }
  get isNulled() {
    if (this.nullText && !this.text) return true
    else return false
  }

  // methods // ***
  clicked(e: any) {
    if (this.clickable && !this.readonly) this.$emit('clicked', e)
  }
}
