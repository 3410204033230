
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Snackbar extends Vue {
  // computed // ***
  get buttonColor() {
    return this.snackbar?.buttonColor
  }
  get message() {
    return this.snackbar?.message
  }
  get snackbar() {
    return this.$store.getters['core/snackbar']
  }
  get snackbarProps() {
    const snackbarProps: Record<string, any> = {}
    const propNames = ['buttonColor', 'color', 'right', 'timeout', 'top', 'value', 'vertical']
    propNames.forEach((p: string) => {
      snackbarProps[p] = this.snackbar[p]
    })
    if (!snackbarProps.top) snackbarProps.bottom = true
    if (!snackbarProps.right) snackbarProps.left = true
    return snackbarProps
  }

  // methods // ***
  close() {
    this.$store.dispatch('core/closeSnackbar')
  }
}
