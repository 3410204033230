
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ImpersonationNavMenu extends Vue {
  // data // ***
  menuOpen = false

  // computed // ***
  get impersonatedUser() {
    return this.$auth.impersonatedUser
  }
  get impersonating() {
    return this.$auth.impersonating
  }

  // methods // ***
  endImpersonation() {
    this.menuOpen = false
    this.$auth.endImpersonation()
  }
}
