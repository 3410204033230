
import { Component, Prop, Vue } from 'vue-property-decorator'
import NavLinks from '@/components/nav/NavLinks'
import UserDetails from '@/components/nav/UserDetails'
import { getFilterGradient } from '@/utilities/theme'

@Component({ components: { 'c-user-details': UserDetails, 'nav-links': NavLinks } })
export default class NavDrawer extends Vue {
  // props // ***
  @Prop()
  backLink!: Record<string, any>
  @Prop({ default: () => [] })
  links!: Record<string, any>[]
  @Prop({ type: Boolean })
  value!: boolean

  // data // ***
  loggingOut = false

  // computed // ***
  get activeUser() {
    return this.$auth.activeUser
  }
  get navDrawerGradient() {
    return getFilterGradient('#ffffff')
    // return getFilterGradient(String(this.$vuetify.theme.themes.light.primary))
  }
  get navImageSrc() {
    const url = 'https://picsum.photos/1920/1080?random&navDrawer'
    return this.$env.SHOW_NAV_DRAWER_IMAGE === 'true' ? url : null
  }
  get showDividers() {
    return this.links.length || this.backLink
  }
  get visible() {
    return this.$auth.impersonating || this.$auth.userHasSignedLatestTermsAndConditions
  }

  // methods // ***
  logOut() {
    this.loggingOut = true
    this.$store.dispatch('core/logOut').catch(e => {
      console.error(e)
    })
  }
  onLinkClicked() {
    this.$emit('input', false)
  }
}
