import i18n, { loadLanguageAsync } from '@/plugins/i18n'
import cloneDeep from 'lodash/cloneDeep'
import router from '@/plugins/router'
import Vue from 'vue'
import {
  getErrorMessage,
  makeDefaultGetters,
  makeDefaultMutations,
  setState,
} from '@/utilities/store'
import { APP_NAME, HTTP, STORE_DEFAULTS, DEFAULT_LOCALE } from '@/utilities/constants'
import authClient from '@/utilities/auth'
import api from '@/utilities/api'
import NAV_LINKS, { processLinksForTranslation } from '@/layouts/navLinks'

const { ERRORS, NON_ERRORS, NON_GLOBAL_ERRORS } = HTTP.STATUSES

const CORE = cloneDeep(STORE_DEFAULTS.CORE)

const {
  API_ENDPOINTS_LOADING,
  CSRF_TOKEN,
  KEEP_ALIVE,
  LAYOUT,
  ROUTER_VIEWPORT_OFFSET_TOP,
  SHOW_SAVE_CARD_SPACER,
  SNACKBAR,
  STARTUP_COMPLETED,
  TABLES,
  UPLOAD_PROGRESSES,
} = CORE

const defaultState = (): Record<string, any> => ({
  apiEndpointsLoading: API_ENDPOINTS_LOADING,
  csrfToken: CSRF_TOKEN,
  errors: CORE.ERRORS,
  keepAlive: KEEP_ALIVE,
  layout: LAYOUT,
  navLinks: NAV_LINKS,
  routerViewportOffsetTop: ROUTER_VIEWPORT_OFFSET_TOP,
  showSaveCardSpacer: SHOW_SAVE_CARD_SPACER,
  snackbar: {
    buttonColor: SNACKBAR.BUTTON_COLOR,
    closeButton: SNACKBAR.CLOSE_BUTTON,
    color: SNACKBAR.COLOR,
    message: SNACKBAR.MESSAGE,
    right: SNACKBAR.RIGHT,
    timeout: SNACKBAR.TIMEOUT,
    top: SNACKBAR.TOP,
    vertical: SNACKBAR.VERTICAL,
    value: SNACKBAR.VALUE,
  },
  startupCompleted: STARTUP_COMPLETED,
  tables: TABLES,
  uploadProgresses: UPLOAD_PROGRESSES,
  language: null,
})

const properties = (): string[] => Object.keys(defaultState())

const defaultGetters: Record<string, any> = makeDefaultGetters(properties())

const defaultMutations: Record<string, any> = makeDefaultMutations(properties(), defaultState())

const state: Record<string, any> = defaultState()

const getters: Record<string, any> = {
  ...defaultGetters,
  apiEndpointIsLoading:
    (state: Record<string, any>) =>
    (opts: Record<string, any>): boolean => {
      const { method, url } = opts
      return !!state.apiEndpointsLoading[method]?.[url]
    },
  featureDisabled:
    () =>
    (s: string): boolean => {
      const features = authClient?.features || {}
      if (!features[s]) return false
      else return !features[s].active
    },
  impersonating: (): boolean => !!authClient?.impersonating,
  impersonatableUsers: (): Record<string, any>[] => authClient?.impersonatableUsers || [],
  globalErrors: (state: Record<string, any>): any[] => {
    const errors = cloneDeep(state.errors)
    return Object.keys(errors)
      .map((n: any) => Number(n))
      .filter((s: any) => ERRORS.filter((e: any) => !NON_GLOBAL_ERRORS.includes(e)).includes(s))
      .map((e: any) => String(e) + ': ' + (errors[e]?.message || i18n.t('common.error')))
  },
  navLinks: (state: Record<string, any>, getters: Record<string, any>): Record<string, any>[] => {
    const { featureDisabled } = getters
    const links = cloneDeep(state.navLinks)
    const removeLink = (key: string) => {
      const index = links.findIndex((i: Record<string, any>) => i?.key === key)
      links.splice(index, 1)
    }

    if (authClient.isOnBoardingUser) {
      removeLink('bulkUpload')
      removeLink('costConfirmation')
      removeLink('disputes')
      removeLink('documents')
      removeLink('invoices')
      removeLink('journeys')
      removeLink('permits')
      removeLink('invoiceuploads')
      //removeLink('policies')
      removeLink('pricingInformation')
      //removeLink('profile')
      removeLink('compliance')
      removeLink('vehicles')
      removeLink('hotelRates')
      removeLink('availability')
    } else {
      if (featureDisabled('bulkUpload')) removeLink('bulkUpload')
      if (featureDisabled('costConfirmation')) removeLink('costConfirmation')
      if (featureDisabled('disputes')) removeLink('disputes')
      if (featureDisabled('documentManagement')) removeLink('documents')
      if (featureDisabled('invoiceManagement')) removeLink('invoices')
      if (featureDisabled('journeys')) removeLink('journeys')
      if (featureDisabled('permits') || !authClient?.activeUserIsPermitUser) removeLink('permits')
      if (featureDisabled('policies')) removeLink('policies')
      if (featureDisabled('pricingInformation')) removeLink('pricingInformation')
      if (featureDisabled('profile')) removeLink('profile')
      if (featureDisabled('compliance')) removeLink('compliance')
      if (featureDisabled('vehicleManagement')) removeLink('vehicles')
      if (featureDisabled('hotelRates')) removeLink('hotelRates')
      if (featureDisabled('availability')) removeLink('availability')
      if (featureDisabled('invoiceUploads')) removeLink('invoiceuploads')
    }

    return processLinksForTranslation(links)
  },
  organisation: (): Record<string, any> => authClient?.activeUser?.organisation || {},
  uploadProgressByUrl:
    (state: Record<string, any>) =>
    (url: string): any =>
      state.uploadProgresses.find((up: Record<string, any>) => up.url === url)?.progress,
  validationError:
    (state: Record<string, any>) =>
    (error: string): any =>
      state.errors[422]?.errors?.[error],
  validationErrors: (state: Record<string, any>): any => state.errors[422]?.errors,
  smartLinkErrors: (state: Record<string, any>): any => state.errors[400],
  language: (state: Record<string, any>): any =>
    state.language || authClient?.activeUser?.config?.preferences?.language || DEFAULT_LOCALE.LANG,
}

const actions = {
  activateSnackbar(context: Record<string, any>, params: Record<string, any>): void {
    const { commit } = context
    const sb = defaultState().snackbar
    commit('setSnackbar', { ...sb, ...params, value: true })
  },
  async acceptTermsAndConditions(context: Record<string, any>, agreementId: number): Promise<any> {
    const user = authClient?.activeUser
    const OrganisationId = user?.organisation?.id
    const OrganisationAgreementId = agreementId
    const Signatory = user?.id
    const data = { OrganisationId, OrganisationAgreementId, Signatory }
    const params = { data, json: true, method: 'POST' }
    return api('supplier/acceptOrganisationAgreement', params, undefined).then(() => {
      console.log('agreementadded')
    })
  },
  closeSnackbar(context: Record<string, any>): void {
    const { commit } = context
    commit('resetSnackbar')
  },
  async fetchActiveUser(): Promise<any> {
    return authClient?.fetchActiveUser()
  },
  forceLogout(context: Record<string, any>): void {
    const { dispatch } = context
    dispatch('redirect', '/login')
  },
  launchSnackbar(context: Record<string, any>, params: Record<string, any>): void {
    const { dispatch } = context
    dispatch('activateSnackbar', params)
  },
  async setLanguage(context: Record<string, any>, lang: string) {
    const { commit } = context
    commit('setLanguage', lang)
    await loadLanguageAsync(lang)
    return api(`supplier/setlanguage?language=${lang}`, { method: 'PUT' }, {})
  },
  async logIn(context: Record<string, any>): Promise<void> {
    const { dispatch } = context
    if (process.env.VUE_APP_SSO_ENABLED === 'true') await authClient.logIn()
    else {
      const res = (await api('/auth-creds', undefined, undefined)) || {}
      if (!res?.data) {
        return
      } else {
        dispatch('redirect', '/dashboard')
      }
    }
  },
  async logOut(context: Record<string, any>): Promise<void> {
    const { dispatch } = context
    if (process.env.VUE_APP_SSO_ENABLED === 'true') await authClient.logOut()
    else dispatch('redirect', '/login')
  },
  onApiError(context: Record<string, any>, e: Record<string, any> = {}): void {
    const { commit, dispatch } = context
    const { data = {}, status } = e
    if (!status || NON_ERRORS.includes(status)) return
    const { error, errors, message = e.data ? e.data : getErrorMessage(status) } = data
    // if not authenticated, force logout
    if (status === 401 || status === 419) dispatch('forceLogout')
    commit('setErrors', { error, errors, message, status })
  },
  onRouteChange(context: Record<string, any>, opts: Record<string, any>): void {
    const { commit } = context
    const { to, from } = opts
    document.title = APP_NAME
    // document.title = to?.meta?.title || APP_NAME
    const l = to?.meta?.layout || 'default-layout'
    const old = from?.meta?.layout
    if (authClient?.activeUserIsDisabled) commit('setLayout', 'disabled-layout')
    else if (l !== old) commit('setLayout', l)
    commit('setShowSaveCardSpacer', false)
  },
  redirect(context: Record<string, any>, url: string): void {
    const { commit } = context
    commit('resetErrors')
    router.push(url)
  },
}

const mutations = {
  ...defaultMutations,
  resetState: (state: Record<string, any>): void => setState(state, defaultState(), true),
  setApiEndpointsLoading(state: Record<string, any>, opts: Record<string, any>): void {
    const { method, url, value } = opts
    const target = state.apiEndpointsLoading
    if (!target[method]) Vue.set(target, method, {})
    Vue.set(target[method], url, value)
  },
  setErrors(state: Record<string, any>, opts: Record<string, any>): void {
    const { status, message, errors } = opts
    Vue.set(state.errors, status, { errors, message })
  },
  setUploadProgress(state: Record<string, any>, opts: Record<string, any>): void {
    const { url, progress } = opts
    const target = state.uploadProgresses.find((v: any) => v.url === url)
    if (target) target.progress = progress
    else state.uploadProgresses.push({ url, progress })
  },
  setLanguage(state: Record<string, any>, lang: string) {
    state.language = lang
  },
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
}
