export default {
  navbar: {
    userGuide: 'Aiuto & Guide',
  },
  settings: {
    emailTypes: {
      generalManager: 'Direttore Generale',
      reception: "Reception/Banca d'Accoglienza",
      sales: 'Entrate/Vendite',
      email24: 'Email 24 Ore',
      notification: 'Notifica',
      other: 'Altro',
    },
  },
  auth: {
    emailAndPasswordDoNotMatchOurRecords:
      'L’e-mail e/o la password non corrispondono a quelle presenti nel nostro sistema',
    forgottenYourPassword: 'Hai dimenticato la password?',
    forgottenYourUsername: 'Hai dimenticato il nome utente?',
    helpUsFindYourUsername:
      'Ti preghiamo di fornirci le informazioni richieste nel modulo sottostante, così da poterci aiutare a individuare il tuo nome utente.',
    helpUsResetYourPassword:
      'Ti preghiamo di fornirci il tuo nome utente così possiamo aiutarti a reimpostare la password.',
    login: 'Accesso',
    logIn: 'Accedi',
    logout: 'Termine sessione',
    logOut: 'Termina sessione',
    password: 'Password',
    passwordReset: 'Ripristino password',
    pleaseEnterThePrimaryEmailAddress:
      'Ti preghiamo di inserire l’indirizzo e-mail primario connesso al tuo profilo.',
    pleaseEnterYourUsername: 'Inserisci nome utente...',
    pleaseEnterYourPassword: 'Inserisci password...',
    primaryEmailAddress: 'Indirizzo e-mail primario',
    unfortunatelyTheSystemCouldNotAuthenticateYou:
      'Purtroppo il sistema non è stato in grado di identificarti. Spesso ciò accade perché la sessione è scaduta. Per continuare, ti preghiamo di effettuare un nuovo accesso.',
    username: 'Nome utente',
    usernameReminder: 'Promemoria nome utente',
    youAreBeingLoggedOut: 'Ti stiamo disconnettendo.',
    yourSessionHasExpired:
      'La tua sessione è scaduta.  Ti preghiamo di effettuare un nuovo accesso.',
  },
  availability: {
    availability: 'Disponibilità',
    addAvailability: 'Aggiungi disponibilità',
    addingAvailability: 'Stiamo aggiungendo la disponibilità',
    accessible: 'Accessibile per disabili',
    double: 'Doppia',
    family: 'Familiare',
    single: 'Singola',
    twin: 'Con due letti singoli',
    room: 'Camera',
    rooms: 'Camere',
    numberAvailable: 'Num. disponibile',
    noCmacRate: 'Nessuna tariffa CMAC',
    rate: 'Tariffa',
    useCmacAgreedRates: 'Utilizza le tariffe CMAC convenzionate',
    fullyBooked: 'Tutto prenotato',
    helpText1: 'Per inserire la disponibilità, selezionare una data o un intervallo di date.',
    helpText2: 'Per selezionare una data, fare clic sulla casella relativa a quella data.',
    helpText3: 'Per selezionare un intervallo, fare clic su una seconda, differente casella.',
    helpText4:
      'Le date selezionate appariranno in rosso. Per cancellare la data selezionata, fare clic nuovamente sulla casella relativa a quella data.',
    helpText5:
      'Fai clic sul bottone “Aggiungi disponibilità” e inserisci il numero di camere disponibili. Qualora in precedenza tu abbia già presentato delle tariffe, utilizzeremo le tariffe CMAC concordate. Altrimenti, ti preghiamo di inserire la tariffa di solo pernottamento per la disponibilità.',
    helpText6:
      'Qualora tu abbia camere disponibili a tariffe diverse, puoi inserire più voci per la stessa data.',
    publicHeader: 'Gruppo CMAC - Richiesta disponibilità - ',
    publicInstruction1:
      'Ti preghiamo di inserire il numero per ogni tipo disponibile e poi fare clic su “Invia”',
    publicInstruction2:
      'Puoi sempre utilizzare il presente link per tornare più tardi ad aggiornare queste informazioni. Esso scadrà alle',
    ratesIncludeTax: 'Per favore assicurati che la tariffa sia COMPRENSIVA d’IVA.',
    submittedBy: 'Inviato da ',
    updatedBy: 'Aggiornato da ',
    specificallyFor: 'Specificamente per ',
    parkFlyRoomOnly: 'Solo pernottamento e volo',
    parkFly4Bundle: 'Camera Sleep & Fly + Parcheggio per 4 giorni',
    parkFly8Bundle: 'Camera Sleep & Fly + Parcheggio per 8 giorni',
    parkFly15Bundle: 'Camera Sleep & Fly + 15 giorni di parcheggio',
    parkFlyToCloseOut:
      'Per chiudere una data, disattiva gli elementi pertinenti. Per riattivare la prenotazione, dovrai caricare nuovamente il foglio di calcolo dei prezzi.',
  },
  cmac: {
    actionedBy: 'Attivato da',
    addAddress: 'Aggiungi Indirizzo',
    addEmailAddress: 'Aggiungi Indirizzo Email',
    addTelephoneNumber: 'Aggiungi Numero di Telefono',
    addAComment: 'Inserisci un commento',
    addComment: 'Inserisci commento',
    addExpense: 'Aggiungi Spesa',
    addingAComment:
      'Se inserirai un commento, questa corsa verrà collocata in stato di contestazione. Ti preghiamo di utilizzare questa funzione solo per quelle differenze che non appaiono sulla riga tragitto. Ti preghiamo di includere i dettagli completi, ad esempio il chilometraggio e il motivo della richiesta.',
    agreed: 'D’accordo',
    allOpen: 'Tutto aperto',
    amend: 'Modifica',
    applies: 'Applica',
    apply: 'Applica',
    assignedOrganisation: 'Organizzazione incaricata',
    availability: 'Disponibilità',
    awaitingResponseFromSupplier: 'In attesa di una risposta da parte del fornitore',
    billingAddress: 'Indirizzo di Fatturazione',
    bulkUpload: 'Caricamento in blocco',
    bulkUploadHistory: 'Cronologia caricamenti in blocco',
    cancelled: 'Cancellato',
    closed: 'Bloccato',
    cmac: 'CMAC',
    cmacReference: 'Cod. riferimento CMAC',
    companyDetails: 'Dati dell’azienda',
    companyFinancialDetails: 'Dati finanziari',
    companyOnBoardingDocuments: 'Documenti di implementazione dell’azienda',
    companyNumber: 'Numero registro imprese',
    compliance: 'Conformità',
    costConfirmation: 'Conferma del prezzo',
    costVatBreakDown: 'Prezzo descrizione dettagliata IVA',
    coverage: 'Copertura',
    coverageAreas: 'Zone di copertura',
    currentlyImpersonating: 'Al momento impersonate',
    customDates: 'Date personalizzate',
    customerName: 'Nome cliente',
    customerOrganisation: 'Organizzazione clienti',
    departureDate: 'Data di partenza',
    depotAddress: 'Indirizzo del Deposito',
    dispute: 'Contestazione',
    disputed: 'Contestata',
    disputeDetails: 'Dettagli della contestazione',
    disputeEntry: 'Voce contestazione',
    disputeEntries: 'Voci contestazione',
    disputeId: 'ID contestazione',
    disputes: 'Contestazioni',
    disputesAwaitingAction: 'Controversie In Attesa di Azione',
    documentType: 'Tipo di documento',
    driver: 'Guidatore',
    driverDetails: 'Estremi del guidatore',
    editAddress: 'Modifica Indirizzo',
    editEmailAddress: 'Modifica Indirizzo Email',
    editJourney: 'Modifica viaggio',
    editTelephoneNumber: 'Modifica Numero di Telefono',
    editOpenJourneys: 'Modifica corse attive',
    endImpersonation: 'Termina impersonation',
    endTime: 'Orario di termine',
    expenseItem: {
      meetAndGreet: 'Incontro e saluto',
      parking: 'Parcheggio',
      toll: 'Pedaggio',
      greenFee: 'Tassa ecologica',
      misc: 'Varie',
      amount: 'Importo',
    },
    expenses: 'Costi e spese',
    expired: 'Scaduto',
    expiryDate: 'Data scadenza',
    expiryDateRequired: 'Data scadenza obbligatoria',
    extras: 'Extra',
    fare: 'Tariffa corsa',
    farePriceCalculation: 'Calcolo tariffa corsa',
    financialDetails: {
      accountName: 'Nome del Conto',
      bankAddress: 'Indirizzo della Banca',
      ibanNumber: 'Numero IBAN',
      currency: 'Valuta',
    },
    fixedPrices: 'Tariffe bloccate',
    greenFee: 'Eco-tassa',
    holidayRates: 'Tariffe festività',
    holidays: 'Festività',
    holidayUplift: 'Sollievo per le vacanze',
    holidayUpliftDescription:
      "Queste date personalizzate si applicano a TUTTE le voci del tariffario, a meno che non vengano sostituite da una data personalizzata specifica per l'ingresso",
    hotelMarkupRates: 'Tariffe maggiorazione hotel',
    hotelProfile: 'Profilo hotel',
    hotelRatesTitle: 'Tariffe hotel',
    ifYouNeedToChangeHowWeKnowYouInOurSystemPleaseCallTheSupplierRelationsTeamOn:
      'Se desideri modificare il nominativo con cui sei identificato nel nostro sistema, ti preghiamo di contattare la squadra Relazioni Fornitori al',
    impersonating: 'Impersonating',
    inProgress: 'In lavorazione',
    invoiceId: 'ID fattura',
    invoices: 'Fatture',
    job: 'Incarico | Incarichi',
    jobEntryId: 'ID registrazione incarico',
    jobTypes: 'Tipi di incarico',
    journey: 'Corsa',
    journeys: 'Corse',
    journeyChargeMethod: 'Modalità addebito della corsa',
    lastUpdated: 'Ultimo aggiornamento',
    leadMileage: 'Chilometri a vuoto',
    mileage: 'Chilometraggio',
    mileageRates: 'Tariffe chilometraggio',
    minimumPrice: 'Prezzo minimo',
    noCustomDates: 'Nessuna data personalizzata',
    noOfPax: 'N° passeg.',
    open: 'Aperto',
    orAlternativelyEmail: 'O, in alternativa, un indirizzo e-mail',
    otherOrganisations: 'Altre organizzazioni',
    passenger: 'Passeggero',
    parking: 'Parcheggio',
    parkingExpenses: 'Spese di parcheggio',
    pax: 'Pax',
    percentageOfOriginalCostPrice: 'Percentuale del prezzo di costo originario',
    permits: {
      currentHeading: 'PERMESSI VALIDI',
      openHeading: 'APERTO ALLA PRESENTAZIONE DI DOMANDE',
      title: 'Permessi',
      claimed: 'Rivendicato',
      status: 'Stato',
      expires: 'Scade',
      apply: 'Fai domanda ora',
      outstandingAmendment: 'C’è una richiesta di modifica in sospeso per questo permesso.',
      noClaimedPermits: 'Nessun permesso rivendicato.',
      noOpenApplications: 'Nessuna applicazione aperta.',
      download: 'Scarica',
      print: 'Stampa il permesso',
      amend: 'Modifica',
    },
    pickupDateTime: 'Data/Ora ritiro',
    pleaseAddAComment: 'Per favore aggiungi un commento',
    pleaseContactXForAssistance: 'Ti preghiamo di contattare {x} per richiedere assistenza',
    pleaseSelectAXToImpersonate: 'Ti preghiamo di selezionare {x} per impersonate',
    policiesAndTerms: 'Polizze, termini e condizioni',
    pricingInformation: 'Condizioni tariffarie',
    processing: 'In elaborazione',
    profile: 'Profilo',
    psvarStatus: 'Stato Psvar',
    rate: 'Tariffa',
    rateCards: 'Tariffari',
    referredToOperations: "Si fa riferimento all'operatività",
    referredToSupplierRelations: 'Si fa riferimento alle relazioni coi fornitori',
    referredToPricingManager: 'Si fa riferimento al responsabile dei prezzi',
    regions: 'Aree territoriali',
    registration: 'Targa',
    responseReceivedFromSupplier: 'Risposta ricevuta dal fornitore',
    selectDocumentType: 'Seleziona il tipo di documento',
    selectUserToImpersonate: 'Seleziona l’utente da impersonate',
    standDownChargeMethod: 'Modalità di addebito per no-show',
    standDownPrice: 'Tariffa per no-show',
    startTime: 'Orario d’inizio',
    statementId: 'ID comunicato',
    stoodDown: 'Cancellato',
    stoodDownWithinXMinutesOfDepartureDate: 'No-show entro {x} minuti dalla data di partenza',
    summary: 'Riepilogo',
    supplierName: 'Nome fornitore',
    supplierRef: 'Ref. fornitore',
    termsAndConditions: 'Termini e condizioni grgenerali',
    thankYouForLoggingInToTheSupplierPortal:
      'Grazie per aver effettuato l’accesso al portale fornitori',
    tierType: 'Tipo di categoria',
    updateFailed: 'Aggiornamento non riuscito',
    updateSuccessful: 'Aggiornamento andato a buon fine',
    userDetails: 'Dati utente',
    hotelDetails: 'Dati hotel',
    vatNumber: 'Numero IVA',
    vatTreatment: 'Trattamento IVA',
    vehicle: 'Veicolo',
    vehicleRegistration: 'Registrazione del Veicolo',
    vehicleRates: 'Tariffe veicolo',
    vias: 'Vie',
    viewAll: 'Visualizza tutto',
    waypoint: 'Tappa intermedia | Tappe intermedie',
    waitingTime: 'Tempo di attesa',
    waitingTimeCost: 'Costo del tempo di attesa',
    waitingTimeFree: 'Tempo di attesa non addebitato',
    waitingTimeMinutes: 'Minuti tempo di attesa',
    waitingTimeRate: 'Tariffa tempo di attesa',
    withinXMinutes: 'Entro {x} minuti',
    vehicles: 'Veicoli',
    vehicleType: 'Tipo di veicolo',
    yourAccountIsCurrentlyInactive: 'Il tuo account è attualmente inattivo',
    yourReference: 'Tuo codice riferimento',
    total: 'Totale',
    invoiceuploads: 'Caricamenti fatture',
    hotelRates: {
      validBetween: 'Valido tra',
      addNewRate: 'Aggiungi tariffe',
      addNewRateText: 'C’è una nuova richiesta di tariffa per questa organizzazione.',
      noRatesTextPrepend:
        'Attualmente non ci sono tariffe attive per la tua organizzazione. Ti preghiamo di contattarci al',
      noRatesTextAppend: 'per predisporre l’invio delle tue tariffe.',
      hasRatesText:
        'Di seguito trovi le attuali tariffe presenti nel nostro sistema.  Se desideri apportare qualsiasi modifica, ti preghiamo di contattarci al ',
      profile: 'Profilo',
      profileStarRating: 'Valutazione in stelle',
      profileUnkown: 'Imprecisato',
      profileYes: 'Sì',
      profileNo: 'No',
      profileYesFree: 'Sì, gratuito',
      profileNumberOfRooms: 'Numero di camere',
      profileAccessibleRooms: 'Camere con accesso per disabili',
      profileFamilyRooms: 'Camere familiari',
      profileHotelBus: 'Navetta dell’hotel',
      profileParking: 'Parcheggio',
      profileSpa: 'Spa',
      profileSwimmingPool: 'Piscina',
      profileGym: 'Palestra',
      profileRestaurant: 'Ristorante',
      commissionRate: 'Tariffe di commissione CMAC precedentemente concordate',
      commissionRooms: 'Camere',
      commissionFood: 'Pasti',
      commissionDynamic: 'Dinamico',
      editText: 'Tariffario hotel',
      editProfileText: 'Modifica dati hotel',
      ratesValidDatesText: 'Le tariffe sono applicabili in questo intervallo di tempo',
      ratesValidFrom: 'Valida da',
      ratesValidTo: 'Valida fino',
      rates: 'Tariffe',
      ratesType: 'Tipologia',
      ratesRoomText: 'Tariffe a camera per passeggeri vittime di ritardi e personale di volo',
      ratesRoomType: 'Tipologia di camera',
      ratesMidweekText: 'Infrasettimanale solo pernottamento (dom-giov)',
      ratesDayLet: 'Utilizzo giornaliero',
      ratesWeekendText: 'Fine settimana solo pernottamento (ven-sab)',
      ratesRoomTwinDouble: 'Letti singoli / Doppia',
      ratesRoomSingle: 'Camera singola',
      ratesRoomTriple: 'Tripla',
      ratesRoomFamily: 'Familiare',
      ratesWeekdayRate: 'Tariffa infrasettimanale',
      ratesWeekdayDayRate: 'Tariffa giornaliera infrasettimanale',
      ratesWeekendRate: 'Tariffa fine settimana',
      ratesWeekendDayRate: 'Tariffa giornaliera fine settimana',
      ratesFoodText: 'Cibi e bevande (inc. tè, caffè, liquori) Prezzo a persona',
      ratesContinentalBreakfast: 'Colazione continentale',
      ratesFullBreakfast: 'Colazione completa',
      ratesLunch: 'Pranzo',
      ratesDinner: 'Cena',
      ratesAgreementText:
        'L’immissione delle presenti tariffe stipulerà un contratto vincolante tra il Gruppo CMAC e la tua struttura, quindi dovresti assicurarti che solamente un individuo autorizzato riempia il modulo. L’hotel si riterrà responsabile dell’individuo che ha riempito il modulo, e qualora sia un individuo non autorizzato a riempire il presente modulo, ciò non pregiudicherà il Gruppo CMAC né la sua facoltà di applicare le presenti tariffe.',
      ratesTermsConditionsText:
        'Si prega di contrassegnare a conferma dell’avvenuta presa visione dei nostri',
      ratesTermsConditionsLinkText: 'termini e condizioni generali',
      submittedHeading: 'Presentato da',
      viewRatesTermsConditionsText: 'Clicca qui per visionare il nostro',
      outstandingRateRequest: 'Richiesta di tariffa in sospeso',
      submittedByName: 'Nome',
      submittedByEmail: 'Email',
      submittedByNameRequired: 'Inserisci il tuo nome.',
      submittedByEmailRequired: 'Inserisci il tuo indirizzo email.',
      submittedSuccess:
        "Grazie per aver inviato le tue tariffe. Non vediamo l'ora di lavorare con te.",
      errorSaving:
        'Si è verificato un errore durante il salvataggio delle tue tariffe. Si prega di contattare hotelrelations@cmacgroup.com per assistenza.',
    },
    cmacRef: 'Cod. Rif. CMAC',
    yourRef: 'Tuo cod. rif.',
    leadMileageCost: 'Costo chilometri di punta',
    noNotificationEmailAddress: 'Non hai un indirizzo email di notifica',
    leadMiles: 'Miglia Extra',
    fareNet: 'Tariffa (Netto)',
    leadMilesCost: 'Costo per miglia extra (Netto)',
    totalCost: 'Costo totale (Netto)',
    appliesOnDays: 'Si applica nei giorni',
    noVehicleRates: 'Nessuna tariffa per i veicoli',
    markup: 'Markup',
    addedPrice: 'Prezzo Aggiunto',
    noHolidayRates: 'Nessuna tariffa per le vacanze',
    standDownRates: 'Ridurre le tariffe',
    noStandDownRates: 'Nessuna tariffa di stand-down',
    stoodDownWithin: 'Mi sono fermato dentro',
    minutesOfDepartureDate: 'verbale della data di partenza',
    ofOriginalCostPrice: 'del prezzo di costo originario',
    ofOriginalCostPriceWhicheverIsHigher:
      'del prezzo di costo originale, a seconda di quale sia maggiore',
    waitingTimeRates: 'Tariffe dei tempi di attesa',
    noWaitingTimeRates: 'Nessuna tariffa per i tempi di attesa',
    minutesFree: 'minuti gratuiti',
    minutes: 'minuti',
  },
  common: {
    about: 'Informazioni',
    accepted: 'Accettato',
    add: 'Aggiungi',
    addComment: 'Agg. commento',
    addVehicle: 'Aggiungi veicolo',
    actions: 'Azioni',
    addXtoY: 'Aggiungi {x} a {y}',
    agreed: 'D’accordo',
    agreementType: 'Tipologia di accordo',
    all: 'Tutto',
    back: 'Indietro',
    backToTop: 'Torna a inizio pagina',
    cancel: 'Cancella',
    close: 'Chiudi',
    comment: 'Commenta',
    comments: 'Commenti',
    completed: 'Completato',
    confirm: 'Conferma',
    continue: 'Continua',
    cost: 'Costo',
    create: 'Crea',
    created: 'Creato',
    createdAt: 'Creato alle',
    customer: 'Cliente',
    date: 'Data',
    dateRange: 'Intervallo date',
    delete: 'Elimina',
    departureDateRange: 'Intervallo data di partenza',
    description: 'Descrizione',
    details: 'Dettagli',
    disputed: 'Contestata',
    distance: 'Distanza',
    documents: 'Documenti',
    download: 'Scarica',
    edit: 'Modifica',
    edited: 'Modificato',
    editVehicle: 'Mod. veicolo',
    email: 'E-mail',
    enabled: 'abilitato',
    error: 'Errore',
    example: 'Esempio',
    expired: 'Scaduto',
    expires: 'Scade',
    failed: 'Non riuscito',
    file: 'File',
    filename: 'Nome del file',
    filters: 'Filtri',
    from: 'Da',
    full: 'Completa',
    half: 'Mezza',
    home: 'Home',
    hide: 'Nascondi',
    id: 'Id',
    info: 'Informazioni',
    important: 'Importanti',
    jobNo: 'Incarico n.',
    loading: 'Caricamento in corso',
    map: 'Mappa',
    messages: 'Messaggi',
    miles: 'Chilometri',
    minutes: 'Minuti',
    mode: 'Modalità',
    name: 'Nome',
    new: 'Nuovo',
    no: 'No',
    none: 'Nessuno',
    notes: 'Annotazioni',
    null: 'Invalido',
    ok: 'OK',
    open: 'Aperto',
    organisation: 'Azienda',
    organisations: 'Aziende',
    original: 'Originale',
    others: 'Altri',
    password: 'Password',
    pending: 'In sospeso',
    price: 'Prezzo',
    previouslyUploaded: 'Caricato in precedenza',
    processed: 'Evaso',
    processing: 'In elaborazione',
    protection: 'Protezione',
    quantity: 'Quantità',
    raw: 'Non processato',
    readonly: 'Sola lettura',
    reference: 'Riferimento',
    refresh: 'Aggiorna',
    reset: 'Riazzera',
    roles: 'Ruoli',
    save: 'Salva',
    search: 'Cerca',
    settings: 'Impostazioni',
    show: 'Mostra',
    signedBy: 'Firmato da',
    signedOn: 'Firmato il',
    sortBy: 'Ordina per',
    status: 'Stato',
    submit: 'Invia',
    surcharge: 'Applica sovrapprezzo',
    test: 'Verifica',
    time: 'Durata',
    title: 'Titolo',
    to: 'A',
    total: 'Totale',
    type: 'Tipologia',
    unknown: 'Imprecisato',
    update: 'Aggiorna',
    updated: 'Aggiornato',
    updatedAt: 'Aggiornato alle',
    upload: 'Carica',
    uploaded: 'Caricato',
    user: 'Utente',
    username: 'Nome utente',
    users: 'Utenti',
    website: 'Sito internet',
    weight: 'Peso',
    yes: 'Sì',
    next: 'Avanti',
    hour: 'ora',
    mile: 'miglio',
    or: 'o',
  },
  currencies: {
    gbp: '£',
    eur: '€',
  },
  datetime: {
    monday: 'Lunedì',
    tuesday: 'Martedì',
    wednesday: 'Mercoledì',
    thursday: 'Giovedì',
    friday: 'Venerdì',
    saturday: 'Sabato',
    sunday: 'Domenica',
    january: 'Gennaio',
    february: 'Febbraio',
    march: 'Marzo',
    april: 'Aprile',
    may: 'Maggio',
    june: 'Giugno',
    july: 'Luglio',
    august: 'Agosto',
    september: 'Settembre',
    october: 'Ottobre',
    november: 'Novembre',
    december: 'Dicembre',
    today: 'Oggi',
  },
  docs: {
    documentation: 'Documentazione',
    faq: 'FAQ',
    frequentlyAskedQuestions: 'Domande frequenti',
    tutorials: 'Esercitazioni e guide',
  },
  errors: {
    301: 'Non sei autorizzato/a ad accedere a questa risorsa.',
    400: 'Siamo spiacenti, la richiesta non è andata a buon fine.',
    404: 'Siamo spiacenti, non siamo riusciti a localizzare la risorsa che stavi cercando.',
    500: 'Il server non è stato in grado di evadere la richiesta.',
    error: 'Errore',
    nError: '{n} errori',
    noResponseFromServer: 'Nessuna risposta dal server',
    noUrl: 'Nessun url',
    sorrySomethingWentWrong: 'Siamo spiacenti, qualcosa è andato storto',
    uploadFailed: 'Caricamento non riuscito',
    uploadFailedWithError: 'Caricamento non riuscito, con errore',
  },
  fields: {
    address: 'Indirizzo',
    addresses: 'Indirizzi',
    addressLine1: 'Indirizzo riga 1',
    addressLine2: 'Indirizzo riga 2',
    addressLine3: 'Indirizzo riga 3',
    addressLookup: 'Ricerca Indirizzo',
    city: 'Città',
    country: 'Paese',
    county: 'Regione',
    description: 'Descrizione',
    driverName: 'Nome del conducente',
    driverPhone: 'Numero di telefono del conducente',
    driverRegistration: 'Registrazione del conducente',
    email: 'E-mail',
    emailAddress: 'Indirizzo e-mail',
    emailAddresses: 'Indirizzi e-mail',
    home: 'Home',
    mobile: 'Cellulare',
    name: 'Nome',
    number: 'Numero',
    phoneNumber: 'Numero di telefono',
    postCode: 'CAP',
    region: 'Regione',
    registrationNumber: 'Numero di targa',
    telephoneNumber: 'Numero di telefono',
    telephoneNumbers: 'Numeri di telefono',
    title: 'Titolo',
    titles: {
      dr: 'Dott.',
      lady: 'Lady',
      lord: 'Lord',
      mr: 'Sig.',
      miss: 'Sig.na',
      mrs: 'Signora',
      ms: 'Sig.na',
      mx: 'Non binario',
      prof: 'Prof.',
      sir: 'Sir',
    },
    town: 'Città',
    work: 'Lavoro',
  },
  onboarding: {
    documents: {
      public_insurance: 'Certificato di assicurazione di responsabilità pubblica',
      liability: 'Certificato di assicurazione per la responsabilità civile del datore di lavoro',
      motor_insurance: 'Certificato di assicurazione automobilistica',
      gdpr: 'Politica GDPR',
      small_operator_license: 'Patente di operatore - veicoli di piccole dimensioni',
      large_operator_license: 'Patente di operatore - veicoli di grandi dimensioni',
      upload: 'Carica documento',
      download: 'Scarica documento',
      file: 'Nome file',
      document: 'Tipo documento',
      created: 'Creato il',
      expires: 'Scade il',
    },
    agreement: 'Ho letto e accetto i termini e le condizioni sopra stampati.',
    mustAgree: 'È necessario accettare i termini e le condizioni per utilizzare l`app.',
    welcomeHeader: 'Benvenuto/a alla registrazione fornitori CMAC',
    country: 'Nome del paese',
    legalCompanyName: 'Nome dell’azienda',
    tradingName: 'Operante come',
    companyRegistrationNumber: 'Numero iscrizione registro imprese',
    registeredAddress: 'Sede legale/sociale',
    operatingAddress: 'Sede operativa',
    currency: 'Termine sessione',
    logOut: 'Termina sessione',
    smallVehicle:
      'Monovolume (taxi, autoveicoli medi, veicoli con accesso per disabili, taxi londinesi)',
    largeVehicle: 'Veicolo di grandi dimensioni (pulmino, autobus)',
    fleetSize: 'Capienza persone',
    emailAddress: 'Indirizzo e-mail',
    mainContactNumber: 'Inserisci la password...',
    primaryEmailAddress: 'Indirizzo e-mail primario',
    successResult:
      'L’account è stato creato correttamente; ti preghiamo di controllare la tua casella di posta elettronica',
    failResult:
      'Al momento non siamo in grado di creare il tuo account; già esiste un account con le informazioni che ci hai fornite. Ti preghiamo di inviare un’e-mail a supplier.relations@cmacgroup.com per maggiori informazioni.',

    progressHeader: 'Grazie per averci inviato i tuoi dati per diventare un Fornitore CMAC.',
    progressMainText:
      'Per far avanzare la tua domanda, sincerati di aver riempito tutte le parti delle sezioni Dati Finanziari e Documentazione Implementazione Nuova Azienda qui di seguito.',
    progressSubText:
      'Una volta ricevute tali informazioni, saremo in grado di far avanzare la tua domanda alla fase successiva.',
    progressBarText: 'Processo di implementazione azienda',
    progressCompleted:
      'Grazie per aver inviato tutti i tuoi documenti. Uno dei nostri membri del team li esaminerà ora e ti contatterà a breve per procedere al passo successivo.',
    rejected:
      'Sfortunatamente, la tua richiesta non è stata accettata. Contatta il nostro team delle relazioni con i fornitori per ulteriori informazioni.',
  },
  plurals: {
    comments: 'Commento | Commenti',
    journeys: 'Corsa| Corse',
    results: 'Risultato | Risultati',
    resultsFound: 'Risultato trovato | Risultati trovati',
    rows: 'Riga | Righe',
    suppliers: 'Fornitore | Fornitori',
  },
  ui: {
    anNewVersionOfTheApplicationIsAvailable: 'È disponibile una versione dell’applicazione',
    appName: 'Portale fornitore',
    approve: 'Approva',
    approveAllSelectedRows: 'Approva tutte le righe selezionate',
    changeProfilePicture: 'Modifica immagine profilo',
    chooseX: 'Scegli {x}',
    clear: 'Azzera',
    clearFilters: 'Azzera i filtri',
    clickToCopyToClipboard: 'Fai clic per copiare negli appunti',
    clickToSortByX: 'Fai clic per ordinare per {x}',
    closeDrawer: 'Chiudi cassetto',
    currentlyUnavailable: 'Momentaneamente non disponibile',
    dashboard: 'Pannello di controllo',
    document: 'Documento',
    dragYourXHereToUploadIt: 'Trascina qui il tuo {x} per caricarlo',
    export: 'Esporta',
    exportToCsv: 'Esporta CSV',
    file: 'file',
    files: 'File',
    fileSizeTooBig: 'Dimensioni file troppo grandi',
    footer: 'Piè di pagina',
    home: 'Home',
    homePage: 'Pagina Iniziale',
    itemsPerPage: 'Elementi per pagina',
    navigation: 'Navigazione',
    noDetailsToDisplay: 'Nessun dettaglio da visualizzare',
    noFileSelected: 'Nessun file selezionato',
    noResultsToShow: 'Nessun risultato da mostrare',
    noXProvided: 'Nessun {x} fornito',
    orClickToBrowse: 'Oppure fai clic per navigare',
    page: 'Pagina',
    pageSize: 'Dimensione pagina',
    pleaseSeletFromTheFollowing: 'Seleziona tra le seguenti opzioni',
    pleaseSelectAnX: 'Ti preghiamo di selezionare un {x}',
    pleaseSelectAX: 'Ti preghiamo di selezionare un {x}',
    pleaseSelectNXOnly: 'Ti preghiamo di selezionare solo {n} {x}',
    profilePicture: 'Immagine profilo',
    resultsPerPage: 'Risultati per pagina',
    saveChanges: 'Salva modifiche',
    selectAll: 'Seleziona tutto',
    selectX: 'Seleziona {x}',
    selectADifferentX: 'Seleziona un {x} diverso',
    spreadsheet: 'Foglio di calcolo',
    theXHasBeenDeleted: '{x} è stato eliminato',
    thisWillPutXInDispute: 'Ciò metterà {x} in contestazione',
    updateX: 'Aggiorna {x}',
    uploadBeingProcessed: 'Caricamento in fase di elaborazione',
    uploadStatus: 'Stato caricamento',
    uploading: 'In caricamento',
    uploadNewX: 'Carica un nuovo {x}',
    uploadX: 'Carica {x}',
    userProfile: 'Profilo utente',
    version: 'versione',
    xMissing: '{x} mancante',
    policySignedX: 'Grazie per aver firmato {x}',
  },
  validations: {
    canOnlyContainLettersAndNumbers: 'Può contenere solo lettere e numeri',
    canOnlyContainLettersNumbersAndTheFollowingCharactersX:
      'Può contenere solo lettere, numeri e i seguenti caratteri: {x}',
    canOnlyContainNumbers: 'Può contenere solo numeri',
    checkboxConfirm:
      'Ti preghiamo di spuntare la casella per confermare di aver accettato i presenti termini e condizioni generali',
    maxNCharacters: 'Massimo {n} caratteri',
    minNCharacters: 'Minimo {n} caratteri',
    mustBeAnInteger: 'Deve essere un numero senza decimali',
    mustBeAFloat: 'Deve essere un numero a virgola mobile',
    mustBeAValidEmailAddress: 'Deve essere un indirizzo e-mail valido',
    mustBeAValidTelephoneNumber: 'Deve essere un numero di telefono valido',
    mustBeAWholeNumber: 'Deve essere un numero intero',
    mustBeExactlyNCharacters: 'Deve essere composto di {n} caratteri esatti',
    mustBeGreaterThanN: 'Deve essere maggiore di {n}',
    mustBeGreaterThanOrEqualToN: 'Deve essere maggiore o uguale a {n}',
    mustBeLessThanN: 'Deve essere minore di {n}',
    mustBeLessThanOrEqualToN: 'Deve essere minore o uguale a {n}',
    mustBeRoundedToExactlyNDecimalPlaces: 'Deve essere arrotondato a {n} cifre decimali esatte',
    mustBeRoundedToNDecimalPlaces: 'Deve essere arrotondato a {n} cifre decimali',
    mustHaveASingleDecimalPoint: 'Deve avere un solo punto decimale',
    requiresBooleanValue: 'Richiedere un valore booleano',
    thisDateMustBeInTheFuture: 'Deve essere una data futura',
    thisDateMustBeInThePast: 'Deve essere una data passata',
    thisExceedsTheLeadMileageLimit: 'Eccede il limite massimo di chilometri a vuoto',
    thisFieldIsRequired: 'Questo campo è obbligatorio',
    thisFieldMustBeAValidDate: 'Questo campo deve essere una data valida',
    thisFieldMustContainAX: 'Questo campo deve contenere un {x}',
    thisFieldMustContainAnX: 'Questo campo deve contenere un {x}',
    mustBeUkBankSortCode: 'Il codice di ordinamento bancario deve essere nel formato 00-00-00',
    mustBeUkBankAccountNumber:
      'Il numero di conto bancario del Regno Unito deve essere composto da 8 cifre',
    mustBeAtLeastOneChecked: 'Seleziona almeno uno dei valori',
  },
  invoiceUpload: {
    invoiceNo: 'Fattura n.',
    reference: 'Riferimento',
    uploadDate: 'Data di caricamento',
    invoiceDate: 'Data fattura',
    dueDate: 'Data di scadenza',
    total: 'Totale',
    currency: 'Valuta',
    status: 'Stato',
    comments: 'Commenti',
    file: 'File',
    selectJourneys: 'Seleziona corse',
    uploadInvoice: 'Carica fattura',
    confirm: 'Conferma',
    uploadingAs: 'Al momento, stai caricando tramite il profilo di',
    atLeastOneJourney: 'Devi selezionare almeno una corsa',
    errorUploadingFile: 'Si è verificato un errore nel caricamento e analisi di questo file.',
    uploadInFormat: 'Ti preghiamo di caricare la fattura in formato PDF.',
    selectFile: 'Ti preghiamo di selezionare un file da caricare',
    uploadingAnalyzing: 'In fase di caricamento e analisi',
    invoiceReference: 'Cod. riferimento fattura',
    invoice: 'Fattura',
    cmacReference: 'Cod. riferimento CMAC',
    yourReference: 'Tuo codice riferimento',
    journeysSelectionJourney: 'corsa| corse',
    journeySelectionSelected: 'selezionato',
    net: 'Netto',
    tax: 'Tasse',
    gross: 'Lordo',
    expenses: 'Costi e spese',
    details: 'Dettagli',
    journey: 'Corsa',
    departureDate: 'Data di partenza',
    cmacRef: 'Cod. riferimento CMAC',
    yourRef: 'Tuo cod. rif.',
    invoiceAccepted: 'Fattura verificata e approvata',
    invoiceDoesNotMatch: 'Si prega di rivedere e inviare nuovamente',
    stillUpload:
      'Puoi comunque caricare questa fattura, ma essa dovrà comunque essere analizzata. ',
    paymentDelayed: 'Il pagamento sarà sospeso finché questo processo non sarà completato.',
    totalNetDoesNotMatch:
      'L`importo netto totale sulla fattura non corrisponde al prezzo netto totale per tutti i viaggi selezionati.',
    expected: 'Previsto',
    totalGross: 'Lordo totale',
    totalNet: 'Netto totale',
    totalTax: 'Totale tasse',
    notFound: 'Nessun risultato',
    invoiced: 'Fatturato',
    underDispute: 'In stato di contestazione',
    notUnique: 'Un file con questo nome esiste già. Scegliere un nome file diverso.',
    invalidCharacters:
      'Il nome del file contiene caratteri non validi. Non sono ammessi i seguenti.',
    invalidFileExtension:
      'Questo tipo di file non è valido. Sono consentiti i seguenti tipi di file.',
    cancelled: 'Annullato',
    journeyCost: 'Costo del viaggio (Netto)',
    leadMilesCost: 'Costo per miglia extra (Netto)',
    waitingTimeCost: 'Costo per attesa (Netto)',
    expensesCost: 'Costo delle spese (Netto)',
    greenFeeCost: 'Costo tassa ecologica (Netto)',
    totalCost: 'Costo totale (Netto)',
    downloadJourneyData: 'Clicca qui per scaricare i dati dei viaggi selezionati.',
    paid: 'Pagato',
    excludedJourneys: 'Questo processo esclude i viaggi attualmente in discussione.',
    rejectedStatus: 'Respinto',
    createPdfInvoice: 'Crea Fattura PDF',
    createPdf: {
      title: 'Crea Fattura PDF per i viaggi selezionati',
      invoiceNumber: 'Numero Fattura',
      invoiceDate: 'Data Fattura',
      chooseAddress: 'Si prega di scegliere un indirizzo',
      registerDetails: {
        title: 'Dettagli Registrazione',
        heading: 'Per creare la tua fattura, abbiamo bisogno di alcune informazioni aggiuntive.',
        financial: {
          heading: 'Segui i passaggi sottostanti per aggiungere i tuoi dettagli finanziari:',
          step1:
            'Seleziona l’icona del menu a hamburger {x} situata in alto a destra del tuo schermo',
          step2: "Quindi seleziona 'Profilo'.",
          step3:
            "Nella sezione 'Dettagli Finanziari', clicca sul pulsante di modifica {x} ed inserisci i tuoi dettagli.",
        },
        address: {
          heading: 'Segui i passaggi sottostanti per aggiungere l’indirizzo della tua azienda:',
          step1:
            'Seleziona l’icona del menu a hamburger {x} situata in alto a destra del tuo schermo.',
          step2: "Quindi seleziona 'Profilo'.",
          step3:
            "Nella sezione 'Indirizzi', clicca sul pulsante più {x} ed inserisci i tuoi dettagli.",
        },
      },
    },
    separateInvoicesRequired:
      'I viaggi con veicoli con più di 10 posti devono essere fatturati separatamente dai viaggi con veicoli con meno di 10 posti.',
    invoiceNumberFieldNameLabel: 'Nome campo numero fattura',
    totalNetAmountFieldNameLabel: 'Nome campo Importo netto totale',
  },
  compliance: {
    introduction:
      'Di seguito troverai un riassunto dei principali documenti che devono essere predisposti e registrati presso di noi. Se hai domande riguardo a uno di questi documenti, ti preghiamo di contattarci al 03333 207 100',
    noRulesFound: 'Nessuna regola di conformità trovata',
  },
  contactItem: {
    generalManager: 'Direttore generale',
    reception24Hour: 'Reception 24 ore',
    revenueSales: 'Entrate/vendite',
    twentyFourHourEmail: 'E-mail 24 ore su 24',
    notification: 'Notifica',
    other: 'Altro',
  },
}
