
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { COUNTRIES } from '@/utilities/constants'
import debounce from 'lodash/debounce'
import { addressSearch, addressSelect } from '@/utilities/org'

const DEFAULT_SOURCES = Object.freeze(['custom', 'google', 'idealpostcode', 'what3words'])

@Component
export default class AddressAutocomplete extends Vue {
  // props // ***
  @Prop({ type: Boolean, default: false, required: false })
  useApiAuth!: boolean
  @Prop({ type: Boolean })
  clearable!: boolean
  @Prop({ default: 'GB', type: String })
  country!: string
  @Prop({ default: () => [], type: Array })
  errorMessages!: string[]
  @Prop({ type: Boolean })
  showCountrySelector!: boolean
  @Prop({ type: Boolean })
  hideDetails!: boolean
  @Prop({ type: String })
  label!: string
  @Prop({ default: 'Enter an address...', type: String })
  placeholder!: string
  @Prop({ type: Array, default: () => DEFAULT_SOURCES })
  sources!: string[]
  @Prop({ type: Array, default: () => [], required: false })
  rules!: Record<string, any>[]

  // data // ***
  searchTerm = ''
  searching = false
  searchResults: Record<string, any>[] = []
  internalValue: Record<string, any> = {}
  selectedCountry = ''
  watchSearchCriteria: Record<string, any> | null = null
  value: Record<string, any> = {}

  // computed // ***
  get countries(): readonly string[][] {
    return COUNTRIES
  }
  get groupedSearchResults(): Record<string, any>[] {
    if (!this.searchResults) return []
    let w3r = this.searchResults.filter((x: Record<string, any>) => x.source === 'what3words')
    let or = this.searchResults.filter((x: Record<string, any>) => x.source !== 'what3words')
    if (w3r.length > 0 && or.length > 0) w3r.push({ divider: true })
    if (w3r.length > 0) return [...w3r, ...or]
    return this.searchResults
  }
  get organisationId(): string {
    return this.useApiAuth ? '00000000-0000-0000-0000-000000000000' : String(this.supplier?.id)
  }
  get supplier(): Record<string, any> {
    return this.$store.getters['suppliers/selectedSupplier']
  }

  // watch // ***
  @Watch('searchTerm')
  onSearchTermChanged(v: string, oldV: string) {
    if (v === null && oldV === '') return
    this.handleSearchTermChange(v)
  }
  handleSearchTermChange = debounce(v => {
    const iv = this.internalValue
    if (!v) {
      this.resetAddress()
      this.emitInternalValue()
      return
    } else if (v.length < 3 || (iv && iv.description === v)) return
    this.addressSearch(v)
  }, 400)
  @Watch('value')
  onValueChanged(v: Record<string, any>) {
    this.setAddress(v, null, false)
  }

  @Watch('country')
  onCountryChanged(v: string) {
    this.refreshCountry()
  }

  // methods // ***
  mounted() {
    this.selectedCountry = this.country
    this.$nextTick(() => this.setAddress(this.value, null, false))
  }

  refreshCountry() {
    this.selectedCountry = this.country
    this.$nextTick(() => this.setAddress(this.value, null, false))
  }
  // methods // ***
  addressSearch(searchTerm = '') {
    this.searching = true
    addressSearch(
      this.selectedCountry,
      {
        customerOrganisationId: this.organisationId,
        term: searchTerm,
        sources: this.sources,
        countries: [this.selectedCountry],
      },
      this.useApiAuth
    )
      .then((response: Record<string, any> | void) => {
        const res: Record<string, any> = response || {}
        this.searchResults = res?.data || []
      })
      .finally(() => (this.searching = false))
  }
  selectAddress(v: Record<string, any> = {}) {
    if (!v) return
    const { key, source, value } = v
    if (!(key || source)) return
    else addressSelect(key, source, value).then(response => this.setAddress(response || {}, key))
  }
  setAddress(address: Record<string, any>, key: string | null | undefined, emit = true) {
    this.internalValue = Object.assign({}, address, {
      description: this.getAddressDescription(address),
    })
    if (this.internalValue.description) {
      this.searchTerm = this.internalValue.description
      this.searchResults = [{ key: key, description: this.internalValue.description }]
    }
    if (emit) this.emitInternalValue()
  }
  emitInternalValue() {
    this.$nextTick(() => {
      this.$emit('input', this.internalValue)
      this.$emit('change', this.internalValue)
    })
  }
  getAddressDescription(address: Record<string, any>) {
    if (!address.address1 && !address.postCode) return
    let description = address.address1
    if (address.address2) description += `, ${address.address2}`
    if (address.town) description += `, ${address.town}`
    return `${description}, ${address.postCode}`
  }
  resetAddress() {
    this.internalValue = {}
    this.searchResults = []
  }
}
