
import { Component, Watch, Vue } from 'vue-property-decorator'
import layouts from '@/layouts'
import { routeHasChanged } from '@/utilities/router'
import { onStartup } from '@/utilities/boot'
@Component({ components: { ...layouts } })
export default class App extends Vue {
  // computed // ***
  get layout() {
    if (!this.startupCompleted) return 'loading-layout'
    // check for api root env variable - if missing, disable app by loading error layout
    else if (!this.$env?.API_ROOT_URL) return 'error-layout'
    else return this.$store.getters['core/layout'] || 'default-layout'
  }
  get startupCompleted(): boolean {
    return this.$store.getters['core/startupCompleted']
  }
  get activeUser(): boolean {
    return this.$auth.activeUser
  }

  // watch // ***
  @Watch('$route')
  onRouteChanged(to: any, from: any) {
    if (routeHasChanged(to, from)) this.$store.dispatch('core/onRouteChange', { from, to })
  }

  // created // ***
  created() {
    this.onStartup()
  }

  // methods // ***
  async onStartup() {
    await onStartup(this)
  }
}
