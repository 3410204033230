
import { Component, Prop, Vue } from 'vue-property-decorator'
import Navbar from '@/components/nav/Navbar'
import NavDrawer from '@/components/nav/NavDrawer'
import CmsNotice from '@/components/shared/CmsNotice'
import { sortAlphabeticallyByKey } from '@/utilities/functions'
import axios from 'axios'

@Component({
  components: { 'c-navbar': Navbar, 'c-nav-drawer': NavDrawer, 'c-cms-notice': CmsNotice },
})
export default class DefaultLayout extends Vue {
  // props // ***
  @Prop()
  backLink!: Record<string, any>
  @Prop()
  icons!: string[]
  @Prop({ default: () => [] })
  keepAlive!: string[]
  @Prop({ type: Boolean })
  loading!: boolean

  // data // ***
  navDrawerOpen = false
  html = ''
  bannerUrl = process.env.VUE_APP_BANNER_URL

  // computed // ***
  get navLinks() {
    const links = this.$store.getters['core/navLinks']
    return sortAlphabeticallyByKey(links, 'text')
  }
  get showSaveCardSpacer(): boolean {
    return this.$store.getters['core/showSaveCardSpacer']
  }

  // lifecycle hooks
  mounted() {
    axios(this.bannerUrl)
      .then(result => {
        this.html = result.data
      })
      .catch(error => {
        console.error('Error fetching banner:', error)
      })
  }
}
