
import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  endImpersonation,
  getInitialsFromName,
  getNameFromEmailAddress,
  onImpersonatedUserSelected,
} from '@/utilities/org'

@Component
export default class UserDetails extends Vue {
  // props // ***
  @Prop({ type: Boolean })
  loggingOut!: boolean

  // data // ***
  dense = true
  showImpersonationSelectionModal = false

  // computed // ***
  get activeUserIsSuperAdmin(): boolean {
    return this.$auth.activeUserIsSuperAdmin
  }
  get impersonatableUsers() {
    return this.$auth.impersonatableUsers || []
  }
  get impersonatedUserEmail(): string {
    return this.impersonatedUser?.id || ''
  }
  get impersonatedUserName(): string {
    return getNameFromEmailAddress(this.impersonatedUserEmail) || ''
  }
  get impersonatedUser() {
    return this.$auth.impersonatedUser
  }
  get impersonating() {
    return this.$auth.impersonating
  }
  get profileEnabled() {
    return this.$auth.features?.profile?.active === true
  }
  get user(): Record<string, any> | undefined {
    return this.$auth.activeUser
  }
  get userEmail(): string {
    return this.user?.id || ''
  }
  get userIcon(): string {
    return this.impersonating ? 'people' : 'person'
  }
  get userInitials(): string {
    return getInitialsFromName(this.userName)
  }
  get userMenuOptions(): Record<string, any>[] {
    const options: Record<string, any>[] = []
    const gtupOption = { id: 'goToUserProfile', title: 'View User Profile', url: '/profile' }
    const impersonateOption = { id: 'impersonate', title: 'Impersonate' }
    const endImpersonationOption = { id: 'endImpersonation', title: 'End Impersonation' }
    if (this.impersonating) options.push(endImpersonationOption)
    else options.push(impersonateOption)
    options.push(gtupOption)
    return options
  }
  get userName(): string {
    return getNameFromEmailAddress(this.userEmail)
  }
  get userTo(): string | undefined {
    if (!this.activeUserIsSuperAdmin || !this.profileEnabled) return undefined
    else return '/profile'
  }

  // methods // ***
  endImpersonation() {
    endImpersonation(this)
  }
  impersonate() {
    this.showImpersonationSelectionModal = true
  }
  onUserMenuOptionClicked(option: Record<string, any>): void {
    const { id, url } = option
    if (url && this.$route.path !== url) this.$router.push(url)
    else if (id === 'endImpersonation') this.endImpersonation()
    else if (id === 'impersonate') this.impersonate()
  }
  onUserSelected(e: Record<string, any> = {}) {
    onImpersonatedUserSelected(this, e)
  }
  profileClicked(e: Record<string, any> = {}) {
    if (this.profileEnabled) this.$emit('link-clicked', e)
  }
}
