import i18n from '@/plugins/i18n'
import { sortAlphabeticallyByKey } from '@/utilities/functions'
import { title } from '@/utilities/filters'

const pricingInformationItems: Record<string, any>[] = [
  { text: 'cmac.coverage', url: '/coverage' },
  { text: 'cmac.rateCards', url: '/rate-cards' },
  { text: 'cmac.fixedPrices', url: '/fixed-prices' },
]

const navLinks = [
  { key: 'bulkUpload', text: 'cmac.bulkUpload', url: '/bulk-upload' },
  {
    key: 'costConfirmation',
    text: 'cmac.costConfirmation',
    url: '/cost-confirmation',
  },
  { key: 'disputes', text: 'cmac.disputes', url: '/disputes' },
  { key: 'documents', text: 'common.documents', url: '/documents' },
  { key: 'invoices', text: 'cmac.invoices', url: '/invoices' },
  {
    key: 'invoiceuploads',
    text: 'cmac.invoiceuploads',
    url: '/invoice-uploads',
  },
  { key: 'journeys', text: 'cmac.journeys', url: '/journeys' },
  { key: 'permits', text: 'cmac.permits.title', url: '/permits' },
  {
    key: 'policiesAndTerms',
    text: 'cmac.policiesAndTerms',
    url: '/policies-and-terms',
  },
  {
    // TODO: make expanded dependent on route, e.g., true if child route active, else false
    key: 'pricingInformation',
    expanded: false,
    items: sortAlphabeticallyByKey(pricingInformationItems, 'text'),
    text: 'cmac.pricingInformation',
  },
  { key: 'profile', text: 'cmac.profile', url: '/profile' },
  { key: 'compliance', text: 'cmac.compliance', url: '/compliance' },
  { key: 'vehicles', text: 'cmac.vehicles', url: '/vehicles' },
  { key: 'hotelRates', text: 'cmac.hotelRatesTitle', url: '/hotel-rates' },
  { key: 'availability', text: 'cmac.availability', url: '/availability' },
]

export function processLinksForTranslation(links: any[]): any[] {
  return links.map((link: any) => {
    if (link.items) {
      link.items = link.items.map((item: any) => ({
        ...item,
        text: title(String(i18n.t(item.text))),
      }))
    }
    return {
      ...link,
      text: title(String(i18n.t(link.text))),
    }
  })
}

export default sortAlphabeticallyByKey(navLinks, 'text')
