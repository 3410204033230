import { title } from '@/utilities/filters'
import i18n from '@/plugins/i18n'
import { RouteConfig } from 'vue-router'
import permits from '@/routes/authenticated/permits'

const routes = [
  ...permits,
  {
    component: (): any =>
      import(/* webpackChunkName: "CostConfirmation" */ '@/pages/bulkUpload/BulkUpload.vue'),
    meta: { title: title(String(i18n.t('cmac.bulkUpload'))), public: false },
    name: 'BulkUpload',
    path: '/bulk-upload',
  },
  {
    component: (): any =>
      import(
        /* webpackChunkName: "CostConfirmation" */ '@/pages/costConfirmation/CostConfirmation.vue'
      ),
    meta: { title: title(String(i18n.t('cmac.costConfirmation'))), public: false },
    name: 'CostConfirmation',
    path: '/cost-confirmation',
  },
  {
    component: (): any =>
      import(
        /* webpackChunkName: "CostConfirmation" */ '@/pages/costConfirmation/LeadMilesDisplayMap.vue'
      ),
    meta: { title: title(String(i18n.t('cmac.costConfirmation'))), public: false },
    name: 'LeadMilesMap',
    path: '/cost-confirmation/lead-miles',
  },
  {
    component: (): any =>
      import(/* webpackChunkName: "Coverage" */ '@/pages/coverage/Coverage.vue'),
    meta: { title: title(String(i18n.t('cmac.coverage'))), public: false },
    name: 'Coverage',
    path: '/coverage',
  },
  {
    component: (): any =>
      import(/* webpackChunkName: "Disputes" */ '@/pages/costConfirmation/disputes/Disputes.vue'),
    meta: { title: title(String(i18n.t('cmac.disputes'))), public: false },
    name: 'Disputes',
    path: '/disputes',
  },
  {
    component: (): any =>
      import(/* webpackChunkName: "Documents" */ '@/pages/documents/Documents.vue'),
    meta: { title: title(String(i18n.t('common.documents'))), public: false },
    name: 'Documents',
    path: '/documents',
  },
  {
    component: (): any =>
      import(/* webpackChunkName: "FixedPrices" */ '@/pages/fixedPrices/FixedPrices.vue'),
    meta: { title: title(String(i18n.t('cmac.fixedPrices'))), public: false },
    name: 'FixedPrices',
    path: '/fixed-prices',
  },
  {
    component: (): any =>
      import(/* webpackChunkName: "Invoices" */ '@/pages/documents/Invoices.vue'),
    meta: { title: title(String(i18n.t('cmac.invoices'))), public: false },
    name: 'Invoices',
    path: '/invoices',
  },
  {
    component: (): any =>
      import(/* webpackChunkName: "Invoices" */ '@/pages/documents/InvoicesUploads.vue'),
    meta: { title: title(String(i18n.t('cmac.invoices'))), public: false },
    name: 'InvoiceUploads',
    path: '/invoice-uploads',
  },
  {
    component: (): any =>
      import(/* webpackChunkName: "Invoices" */ '@/pages/documents/InvoiceUpload.vue'),
    meta: { title: title(String(i18n.t('cmac.invoices'))), public: false },
    name: 'InvoiceUpload',
    path: '/invoice-uploads/upload',
  },
  {
    component: (): any =>
      import(/* webpackChunkName: "Journeys" */ '@/pages/journeys/Journeys.vue'),
    meta: { title: title(String(i18n.t('cmac.journeys'))), public: false },
    name: 'Journeys',
    path: '/journeys',
  },
  {
    component: (): any =>
      import(/* webpackChunkName: "public" */ '@/pages/policies/PoliciesAndTerms.vue'),
    meta: { title: title(String(i18n.t('cmac.policiesAndTerms'))), public: false },
    name: 'PoliciesAndTerms',
    path: '/policies-and-terms',
  },
  {
    component: (): any => import(/* webpackChunkName: "Profile" */ '@/pages/profile/Profile.vue'),
    meta: { title: title(String(i18n.t('ui.userProfile'))), public: false },
    name: 'Profile',
    path: '/profile',
  },
  {
    component: (): any =>
      import(/* webpackChunkName: "Coverage" */ '@/pages/rateCards/RateCards.vue'),
    meta: { title: title(String(i18n.t('cmac.rateCards'))), public: false },
    name: 'RateCards',
    path: '/rate-cards',
  },
  {
    component: (): any =>
      import(/* webpackChunkName: "HotelRates" */ '@/pages/hotelRates/HotelRates.vue'),
    meta: { title: title(String(i18n.t('cmac.rateCards'))), public: false },
    name: 'HotelRates',
    path: '/hotel-rates',
  },
  {
    component: (): any =>
      import(/* webpackChunkName: "Compliance" */ '@/pages/compliance/Index.vue'),
    meta: { title: title(String(i18n.t('cmac.compliance'))), public: false },
    name: 'Compliance',
    path: '/compliance',
  },
  {
    component: (): any =>
      import(/* webpackChunkName: "Vehicles" */ '@/pages/vehicles/Vehicles.vue'),
    meta: { title: title(String(i18n.t('cmac.vehicles'))), public: false },
    name: 'Vehicles',
    path: '/vehicles',
  },
  {
    component: (): any => import(/* webpackChunkName: "Summary" */ '@/pages/documents/Summary.vue'),
    meta: { title: title(String(i18n.t('cmac.summary'))), public: false },
    name: 'summary',
    path: '/summary',
  },
  {
    component: (): any =>
      import(/* webpackChunkName: "Availability" */ '@/pages/availability/Availability.vue'),
    meta: { title: title(String(i18n.t('cmac.availability'))), public: false },
    name: 'Availability',
    path: '/availability',
  },
  {
    component: async () => await import('@/pages/userGuide/index.vue'),
    path: '/userguide',
  },
]

export default routes.map(
  (r: RouteConfig): RouteConfig => ({
    ...r,
    meta: { ...r.meta, authenticated: true },
  })
)
