
import { Component, Prop, Vue } from 'vue-property-decorator'
import { copyToClipboard, fakeClick } from '@/utilities/functions'

@Component
export default class CopyToClipboard extends Vue {
  // refs // ***
  $refs!: { tooltipWrapper: HTMLFormElement }

  // props // ***
  @Prop()
  copyValue!: string
  @Prop({ type: Boolean })
  hideIcon!: boolean
  @Prop({ type: Boolean })
  hideMessage!: boolean
  @Prop({ type: Boolean })
  hideTooltip!: boolean
  @Prop()
  icon!: string
  @Prop()
  message!: string
  @Prop({ type: Boolean })
  raw!: boolean
  @Prop()
  value!: string

  // methods // ***
  onClick() {
    copyToClipboard(this.copyValue || this.value)
    this.rippleTooltip()
  }
  rippleTooltip() {
    fakeClick(this.$refs.tooltipWrapper.$el)
  }
}
