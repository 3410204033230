
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class ContactItemModal extends Vue {
  // props // ***
  @Prop({ required: true, type: Array })
  fields!: Record<string, any>[]
  @Prop({ required: true, type: Object })
  item!: Record<string, any>
  @Prop()
  loading!: boolean
  @Prop({ default: '600px' })
  maxWidth!: string | number
  @Prop()
  title!: string
  @Prop()
  value!: boolean

  // data // ***
  values: Record<string, any> = {}

  // created // ***
  created() {
    this.setInitialValues()
    this.resetValidation()
  }

  // watch // ***
  @Watch('value')
  onValueChanged(e: boolean) {
    if (e) this.setInitialValues()
    this.resetValidation()
  }
  @Watch('item')
  onItemChanged() {
    this.setInitialValues()
    this.resetValidation()
  }

  // methods // ***
  close() {
    this.$emit('input', false)
  }
  getComponent(f: Record<string, any>) {
    return f?.component || 'v-text-field'
  }
  getErrorMessages(f: Record<string, any>) {
    return this.$store.getters['core/validationError'](f?.name)
  }
  onCancel() {
    this.close()
  }
  onInput(e: boolean) {
    this.$emit('input', e)
  }
  onFieldChange(f: Record<string, any>, e: any) {
    if (f.component === 'v-checkbox') this.setValue(f.name, e)
    this.$emit('input:field', { field: f, value: e })
  }
  onFieldInput(f: Record<string, any>, e: any) {
    this.setValue(f.name, e)
    this.$emit('input:field', { field: f, value: e })
  }
  async onSubmit() {
    const valid = await this.validateForm()
    if (!valid) return
    else this.$emit('submit', this.values)
  }
  resetValidation() {
    this.$store.commit('core/resetErrors')
    const refs: Record<string, any> = this.$refs
    refs.form?.resetValidation()
  }
  setInitialValues() {
    Vue.set(this, 'values', {})
    this.fields.forEach((f: Record<string, any>) => {
      const value = this.item[f.name]
      this.setValue(f.name, value)
    })
    this.resetValidation()
  }
  setValue(n: string, v: any) {
    Vue.set(this.values, n, v)
  }
  async validateForm(): Promise<boolean> {
    const refs: Record<string, any> = this.$refs
    return await refs.form.validate()
  }
}
