
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'

@Component
export default class TableCell extends Vue {
  // props // ***
  @Prop({ required: true })
  header!: Record<string, any>
  @Prop({ required: true })
  item!: Record<string, any>

  // data // ***
  localValue: any = ''
  editDialogOpen = false

  // created // ***
  created() {
    this.resetLocalValue()
  }

  // watch // ***
  @Watch('item')
  onValueChanged() {
    this.resetLocalValue()
  }

  // computed // ***
  get editDialog() {
    return {
      // add default settings here
      ...(this.header?.editDialog || {}),
    }
  }
  get editInput() {
    const { editInput } = this.header
    const config = this.itemSpecificConfig
    const rules = [...(editInput?.rules || []), ...(config?.rules || [])]
    return {
      // add default settings here
      autofocus: true,
      lazyValidation: true,
      ...(editInput || {}),
      rules,
    }
  }
  get editInputComponent() {
    return this.header?.editInput?.component || 'c-confirmation-input'
  }
  get fieldIsEditable(): boolean {
    const { header } = this
    const config: Record<string, any> = this.itemSpecificConfig || {}
    if (Object.prototype.hasOwnProperty.call(header, 'editable')) return !!header.editable
    if (Object.prototype.hasOwnProperty.call(config, 'editable')) return !!config.editable
    const { editable, editInput, editDialog } = this.header
    const isEditable = !!(editable || editInput || editDialog)
    return isEditable
  }
  get isValid() {
    const rules = this.editInput?.rules || []
    return rules.reduce((a: any, c: any) => {
      if (!a) return false
      else return c(this.localValue) === true
    }, true)
  }
  get persistent(): boolean {
    if (Object.prototype.hasOwnProperty.call(this.header, 'persistent'))
      return this.header.persistent
    else return true
  }
  get itemSpecificConfig(): Record<string, any> {
    return this.item.itemSpecificConfig?.[this.header.value] || { vueComponent: { props: {} } }
  }
  get tableCellClass() {
    return this.item?.itemSpecificConfig?.class || ''
  }
  get tableCellEditableClass() {
    const textColor = this.tableCellTextColor
    const classes = [this.tableCellClass, textColor]
    return classes.join(' ')
  }
  get tableCellTextColor() {
    const { nullTextColor, value } = this.header
    if (this.isNullValue(this.item[value])) return nullTextColor || 'grey--text'
    else return 'accent--text'
  }
  get tableCellValue() {
    const { header, item } = this
    const { renderFunction, value } = header
    const v = typeof renderFunction === 'function' ? renderFunction(item[value]) : item[value]
    if (this.isNullValue(v)) return this.getNullValue(v)
    else return v
  }

  // methods // ***
  closeEditDialog(): void {
    this.editDialogOpen = false
  }
  getNullValue(value: any): any {
    const { header } = this
    const { nullText, getNullValue } = header || {}
    if (typeof getNullValue === 'function') return getNullValue(header, value)
    else if (nullText) return nullText
    else return value
  }
  handleVueComponentFieldClicked(event: any): void {
    const { header, item } = this
    this.$emit('field-clicked', { event, header, item })
  }
  isNullValue(v: any): boolean {
    return v === null || v == undefined || v === ''
  }
  onEditInputCancel(e: any): void {
    const { header, item } = this
    this.resetLocalValue()
    this.resetValidation()
    this.closeEditDialog()
    this.$emit('cancel:cell', { event: e, item, header })
  }
  onEditInputClose(e: any): void {
    const { header, item } = this
    this.closeEditDialog()
    this.$emit('close:cell', { event: e, item, header })
  }
  onEditInputOpen(e: any): void {
    const { header, item } = this
    this.$emit('open:cell', { event: e, item, header })
  }
  onEditInputSave(e: any): void {
    const { header, item, localValue } = this
    const { parseReturnValue } = header
    const form: any = this.$refs.editInputForm
    form.validate()
    if (this.isValid) {
      this.$emit('save:cell', {
        event: e,
        item,
        header,
        value: parseReturnValue ? parseReturnValue(localValue) : localValue,
      })
      this.resetLocalValue()
      this.resetValidation()
      this.closeEditDialog()
    }
  }
  resetLocalValue(): void {
    this.localValue = this.item[this.header.value]
  }
  resetValidation(): void {
    const form: any = this.$refs.editInputForm
    form.resetValidation()
  }
}
