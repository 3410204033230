
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class List extends Vue {
  // props // ***
  @Prop({ type: Boolean })
  clickable!: boolean
  @Prop({ type: Boolean })
  dense!: boolean
  @Prop({ required: true })
  items!: Record<string, any>[]
  @Prop()
  maxWidth!: number | string
  @Prop()
  nullText!: string
}
