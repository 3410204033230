import AddressAutocomplete from '@/components/forms/AddressAutocomplete'
import AddressCard from '@/components/pages/AddressCard'
import Button from '@/components/pages/Button'
import ClickableDiv from '@/components/pages/ClickableDiv'
import ClickableSpan from '@/components/pages/ClickableSpan'
import ConfirmationInput from '@/components/forms/ConfirmationInput'
import ConfirmationTextarea from '@/components/forms/ConfirmationTextarea'
import CopyToClipboard from '@/components/pages/CopyToClipboard'
import CountrySelector from '@/components/forms/CountrySelector'
import CoverageMap from '@/components/pages/coverage/CoverageMap'
import CsvExporter from '@/components/pages/CsvExporter'
import DatePicker from '@/components/forms/DatePicker'
import DateRangePicker from '@/components/forms/DateRangePicker'
import EditDialog from '@/components/pages/EditDialog'
import EditModal from '@/components/pages/EditModal'
import Errors from '@/components/pages/Errors'
import ExpenseInput from '@/components/forms/ExpenseInput'
import FareCalculationCard from '@/components/pages/FareCalculationCard'
import FareCalculationPopup from '@/components/pages/FareCalculationPopup'
import Form from '@/components/forms/Form'
import FullScreenDialog from '@/components/pages/FullScreenDialog'
import IconWithTooltip from '@/components/pages/IconWithTooltip'
import ImpersonationSelectionModal from '@/components/pages/ImpersonationSelectionModal'
import KeyValueTable from '@/components/tables/KeyValueTable'
import List from '@/components/pages/List'
import ListItem from '@/components/pages/ListItem'
import LoadingSpinner from '@/components/pages/LoadingSpinner'
import LoadingScreen from '@/components/pages/LoadingScreen'
import Logo from '@/components/images/Logo'
import MaskInput from '@/components/forms/MaskInput'
import Modal from '@/components/pages/Modal'
import MultipleSupplierSelector from '@/components/forms/MultipleSupplierSelector'
import MultiSelect from '@/components/forms/MultiSelect'
import Page from '@/components/pages/Page'
import PageHeading from '@/components/pages/PageHeading'
import Paginator from '@/components/tables/Paginator'
import PhotoCropperFileInput from '@/components/forms/PhotoCropperFileInput'
import SaveCard from '@/components/pages/SaveCard'
import Scorecard from '@/components/pages/Scorecard'
import SearchRow from '@/components/pages/SearchRow'
import Snackbar from '@/components/pages/Snackbar'
import StepperContentLayout from '@/components/pages/StepperContentLayout'
import SupplierSelector from '@/components/forms/SupplierSelector'
import Table from '@/components/tables/Table'
import TableActionButton from '@/components/tables/TableActionButton'
import TableActionIcon from '@/components/tables/TableActionIcon'
import TableActions from '@/components/tables/TableActions'
import TableCell from '@/components/tables/TableCell'
import TermsAndConditionsModal from '@/components/pages/termsAndConditions/TermsAndConditionsModal'
import UploadDialog from '@/components/pages/UploadDialog'
import VersionTracker from '@/components/pages/VersionTracker'
import WarningPrompt from '@/components/pages/WarningPrompt'
import Waypoints from '@/components/pages/Waypoints'
import LeadMileage from '@/components/pages/LeadMileage'

const register: Record<string, any> = {
  'c-address-autocomplete': AddressAutocomplete,
  'c-address-card': AddressCard,
  'c-btn': Button,
  'c-clickable-div': ClickableDiv,
  'c-clickable-span': ClickableSpan,
  'c-confirmation-input': ConfirmationInput,
  'c-confirmation-textarea': ConfirmationTextarea,
  'c-copy-to-clipboard': CopyToClipboard,
  'c-country-selector': CountrySelector,
  'c-coverage-map': CoverageMap,
  'c-csv-exporter': CsvExporter,
  'c-date-picker': DatePicker,
  'c-date-range-picker': DateRangePicker,
  'c-edit-dialog': EditDialog,
  'c-edit-modal': EditModal,
  'c-errors': Errors,
  'c-expense-input': ExpenseInput,
  'c-fare-calculation-card': FareCalculationCard,
  'c-fare-calculation-popup': FareCalculationPopup,
  'c-form': Form,
  'c-full-screen-dialog': FullScreenDialog,
  'c-icon-with-tooltip': IconWithTooltip,
  'c-impersonation-selection-modal': ImpersonationSelectionModal,
  'c-key-value-table': KeyValueTable,
  'c-list': List,
  'c-list-item': ListItem,
  'c-loading-spinner': LoadingSpinner,
  'c-loading-screen': LoadingScreen,
  'c-logo': Logo,
  'c-mask-input': MaskInput,
  'c-modal': Modal,
  'c-multiple-supplier-selector': MultipleSupplierSelector,
  'c-multi-select': MultiSelect,
  'c-page': Page,
  'c-page-heading': PageHeading,
  'c-paginator': Paginator,
  'c-photo-cropper-file-input': PhotoCropperFileInput,
  'c-save-card': SaveCard,
  'c-scorecard': Scorecard,
  'c-search-row': SearchRow,
  'c-snackbar': Snackbar,
  'c-stepper-content-layout': StepperContentLayout,
  'c-supplier-selector': SupplierSelector,
  'c-table': Table,
  'c-table-action-button': TableActionButton,
  'c-table-action-icon': TableActionIcon,
  'c-table-actions': TableActions,
  'c-table-cell': TableCell,
  'c-terms-and-conditions-modal': TermsAndConditionsModal,
  'c-upload-dialog': UploadDialog,
  'c-version-tracker': VersionTracker,
  'c-warning-prompt': WarningPrompt,
  'c-waypoints': Waypoints,
  'c-leadmileage': LeadMileage,
}

export default register
