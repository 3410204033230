import { DateTime } from 'luxon'
import { STORE_DEFAULTS } from '@/utilities/constants'
import { title } from '@/utilities/filters'
import { config as env } from '@/plugins/env'
import api from '@/utilities/api'

const { VUE_APP_CORE_API_URL } = env
const { ACTIVE_SUPPLIER, ACTIVE_SUPPLIERS } = STORE_DEFAULTS.SUPPLIERS
const { fromISO, DATE_SHORT, DATETIME_SHORT, TIME_24_SIMPLE } = DateTime

export type AddressSearchFilters = {
  customerOrganisationId?: string
  term: string
  sources?: string[]
  countries?: string[]
}

export const addressSearch = async (
  selectedCountry = '',
  filters: AddressSearchFilters,
  useApiAuth = false
): Promise<Record<string, any> | void> => {
  if (selectedCountry.toLowerCase() !== 'gb')
    filters.sources = filters.sources?.filter(x => x != 'idealpostcode')
  return await api(
    'geo/address/search',
    {
      data: filters,
      json: true,
      method: 'POST',
      noPrefix: true,
      rootUrl: VUE_APP_CORE_API_URL,
      useApiAuth: useApiAuth,
    },
    undefined
  )
}

export const addressSelect = async (
  id: string,
  source: string,
  description?: string
): Promise<Record<string, any> | void> => {
  return await api(
    'geo/address/select',
    {
      data: {
        id,
        source,
        description,
      },
      json: true,
      method: 'POST',
      noPrefix: true,
      rootUrl: VUE_APP_CORE_API_URL,
    },
    undefined
  )
}

export const disableSorting = (headers: Record<string, any>[]): Record<string, any>[] =>
  headers.map(h => ({ ...h, sortable: false }))

export const endImpersonation = (context: Record<string, any>): void => {
  context.$auth.endImpersonation()
}

export const getCmacRef = (v: Record<string, any>): string => `${v?.jobId}:${v?.jobEntryId}`

export const getFormattedDate = (v: string): string => fromISO(v).toLocaleString(DATE_SHORT)

export const getFormattedDateTime = (v: string): string => fromISO(v).toLocaleString(DATETIME_SHORT)

export const getFormattedJourneyReference = (v: Record<string, any>): string =>
  `${v.jobNumber}:${v.journeyNumber}${v.externalId ? '<br/>' + v.externalId : ''}`

export const getFormattedTime = (v: string): string => fromISO(v).toLocaleString(TIME_24_SIMPLE)

export const getNameFromEmailAddress = (s = ''): string =>
  title(s.split('@')[0].split('.').join(' ')).trim() || ''

export const getInitialsFromName = (s = ''): string =>
  s
    .split(' ')
    ?.map((n: string) => n[0]?.toUpperCase() || '')
    ?.join('') || ''

export const onImpersonatedUserSelected = (
  context: Record<string, any>,
  e: Record<string, any>
): void => {
  context.$auth.fetchImpersonatedUser(e).then((user: any) => {
    context.$cookies.set('impersonatedUser', user)
    context.$store.commit('suppliers/setActiveSupplier', ACTIVE_SUPPLIER)
    context.$cookies.set('activeSupplier', ACTIVE_SUPPLIER)
    context.$store.commit('suppliers/setActiveSuppliers', ACTIVE_SUPPLIERS)
    context.$cookies.set('activeSuppliers', ACTIVE_SUPPLIERS)
  })
}
