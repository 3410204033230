import i18n from '@/plugins/i18n'
import { DateTime, Interval } from 'luxon'

const { fromISO, now } = DateTime
const { fromDateTimes } = Interval

export const endsInDecimalPoint = (v: string | number): boolean =>
  String(v).lastIndexOf('.') === String(v).length - 1

export const exactChar =
  (n: number) =>
  (v: string): boolean | string =>
    !v || String(v).length === n || String(i18n.t('validations.mustBeExactlyNCharacters', { n }))

export const isAlphaNumeric = (v: string | number): boolean | string => {
  const reg = /^[a-zA-Z0-9\s]*$/
  return reg.test(String(v)) || String(i18n.t('validations.canOnlyContainLettersAndNumbers'))
}

export const isUkBankSortCode = (v: string | number): boolean | string => {
  const reg = /^(?!(?:0{6}|00-00-00))(?:\d{6}|\d\d-\d\d-\d\d)$/
  return reg.test(String(v)) || String(i18n.t('validations.mustBeUkBankSortCode'))
}

export const isUkBankAccountNumber = (v: string | number): boolean | string => {
  const reg = /^(\d){8}$/
  return reg.test(String(v)) || String(i18n.t('validations.mustBeUkBankAccountNumber'))
}

// TODO: make this return a function that accepts an array of special character strings
// and make custom regex based on the additional characters
export const isAlphaNumericWithSpecials = (v: string | number): boolean | string => {
  const reg = /^[a-zA-Z0-9\s*_\-,'."#@!?()]*$/
  const x = `*_-,'."#@!?()`
  return (
    reg.test(String(v)) ||
    String(i18n.t('validations.canOnlyContainLettersNumbersAndTheFollowingCharactersX', { x }))
  )
}

export const isEmailAddress = (v: string): boolean | string =>
  !v || isEmailAddressCheck(v) || String(i18n.t('validations.mustBeAValidEmailAddress'))

/**
 * Returns true if a valid email address.
 *
 * @param {String} str string to be checked
 *
 * @returns {Boolean} true if string is a valid email address
 */
export function isEmailAddressCheck(str: string): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(str).toLowerCase())
}

export const isFloat = (v: string | number, strict: boolean): boolean | string =>
  strict
    ? Number(v) === v
    : Number(v) == v || endsInDecimalPoint(v) || String(i18n.t('validations.mustBeAFloat'))

export const isFuture = (v: string): boolean | string =>
  fromDateTimes(now(), fromISO(v)).isValid ||
  String(i18n.t('validations.thisDateMustBeInTheFuture'))

export const isGreaterThanN =
  (n: number): ((v: number) => boolean | string) =>
  (v: number) =>
    v > n || String(i18n.t('validations.mustBeGreaterThanN', { n }))

export const isGreaterThanOrEqualToN =
  (n: number): ((v: number) => boolean | string) =>
  (v: number) =>
    v >= n || String(i18n.t('validations.mustBeGreaterThanOrEqualToN', { n }))

export const isInteger = (v: string | number, strict = false): boolean | string =>
  Number.isInteger(strict ? v : Number(v)) || String(i18n.t('validations.mustBeAnInteger'))

export const isLessThanN =
  (n: number): ((v: number) => boolean | string) =>
  (v: number) =>
    v < n || String(i18n.t('validations.mustBeLessThanN', { n }))

export const isLessThanOrEqualToN =
  (n: number): ((v: number) => boolean | string) =>
  (v: number) =>
    v <= n || String(i18n.t('validations.mustBeLessThanOrEqualToN', { n }))

export const isNumeric = (v: string | number): boolean | string => {
  const reg = /^[0-9]*$/
  return reg.test(String(v)) || String(i18n.t('validations.canOnlyContainNumbers'))
}

export const isPast = (v: string): boolean | string =>
  fromDateTimes(fromISO(v), now()).isValid || String(i18n.t('validations.thisDateMustBeInThePast'))

export const isRequired = (v: string | number): boolean | string =>
  ((v || v === 0) && String(v).trim().length > 0) ||
  String(i18n.t('validations.thisFieldIsRequired'))

export const isRoundedToNDecimalPlaces = (
  v: string,
  n: number,
  exact = false
): boolean | string => {
  const exploded = String(v).split('.')
  const eLen = exploded.length
  if (eLen === 1) return true // number is whole
  if (eLen > 2) return String(i18n.t('validations.mustHaveASingleDecimalPoint')) // not a decimal
  const length = exploded[eLen - 1].length
  if (exact)
    return length === n || String(i18n.t('validations.mustBeRoundedToExactlyNDecimalPlaces', { n }))
  else return length <= n || String(i18n.t('validations.mustBeRoundedToNDecimalPlaces', { n }))
}

export const isTelephoneNumber = (v: string): boolean | string => {
  const isTel = (t: string | number) => {
    const reg = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g
    return reg.test(String(t))
  }
  return !v || isTel(v) || String(i18n.t('validations.mustBeAValidTelephoneNumber'))
}

export const isValidDate = (v: string): boolean | string =>
  DateTime.fromISO(v).isValid || String(i18n.t('validations.thisFieldMustBeAValidDate'))

export const isWholeNumber = (v: string | number | null): boolean | string => {
  if (isInteger(Number(v)) === true) return true
  else return String(i18n.t('validations.mustBeAWholeNumber'))
}

export const maxChar =
  (n: number) =>
  (v: string): boolean | string =>
    !v || String(v).length <= n || String(i18n.t('validations.maxNCharacters', { n }))

export const minChar =
  (n: number) =>
  (v: string): boolean | string =>
    !v || String(v).length >= n || String(i18n.t('validations.minNCharacters', { n }))

export const mustNotExceedLeadMileageLimit = (v: string | number, n: number): boolean | string => {
  if (isLessThanOrEqualToN(n)(Number(v)) === true) return true
  else return String(i18n.t('validations.thisExceedsTheLeadMileageLimit'))
}
