
import { Component, Prop, Vue } from 'vue-property-decorator'
import { getFormattedDate } from '@/utilities/org'
import { DateTime } from 'luxon'

@Component
export default class DatePicker extends Vue {
  // props // ***
  @Prop({ type: Function })
  allowedDates!: ((s: string) => boolean) | null | undefined
  @Prop({ type: Boolean })
  closeOnInput!: boolean
  @Prop({ type: Boolean })
  dense!: boolean
  @Prop()
  label!: string
  @Prop({ type: Boolean })
  normalLabel!: boolean
  @Prop()
  inputClass!: string
  @Prop({ default: '400px' })
  modalWidth!: string
  @Prop({ default: () => [], type: Array })
  rules!: ((value: any) => string | boolean)[]
  @Prop({ type: Boolean })
  showCloseButton!: boolean
  @Prop({ type: Boolean })
  showResetButton!: boolean
  @Prop({ default: () => [], type: Array })
  textFieldRules!: ((value: any) => string | boolean)[]
  @Prop({ type: Object })
  value!: DateTime

  // data // ***
  show = false

  // computed // ***
  get dateInputText() {
    const { isoDate: d } = this
    return d ? getFormattedDate(d) : ''
  }
  get isoDate() {
    return this.value?.toISODate()
  }
  get valid() {
    return this.rules.reduce((a, r) => {
      if (!a) return false
      else return r(this.value) === true
    }, true)
  }

  // methods // ***
  onInput(e: string) {
    this.$emit('input', DateTime.fromISO(e))
    if (this.closeOnInput) this.show = false
  }
}
