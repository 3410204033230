/* eslint-disable no-console */

import { register } from 'register-service-worker'
const { NODE_ENV } = process.env

if (NODE_ENV === 'production' || NODE_ENV === 'uat') {
  register(`/service-worker.js`, {
    ready() {
      console.debug('App is being served from cache by a service worker.')
    },
    registered(registration: Record<string, any>) {
      console.debug('Service worker has been registered.')
      setInterval(() => {
        registration.update()
      }, 5 * 60 * 1000) // every 5 minutes
    },
    cached() {
      console.debug('Content has been cached for offline use.')
    },
    updatefound() {
      console.debug('New content is downloading.')
    },
    updated(registration: Record<string, any>) {
      console.debug('New content is available; please refresh.')
      document.dispatchEvent(new CustomEvent('app-update-available', { detail: registration }))
    },
    offline() {
      console.debug('No internet connection found. App is running in offline mode.')
    },
    error(error: Record<string, any>) {
      console.error('Error during service worker registration:', error)
    },
  })
}
