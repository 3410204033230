
import { Component, Prop, Vue } from 'vue-property-decorator'
import NavLink from '@/components/nav/NavLink'

@Component({ components: { 'c-nav-link': NavLink } })
export default class NavLinkGroup extends Vue {
  // props // ***
  @Prop()
  expanded!: boolean
  @Prop({ required: true })
  items!: Record<string, any>[]
  @Prop({ required: true })
  text!: string

  // data // ***
  value = false

  // created // ***
  created() {
    if (this.expanded) this.value = true
  }
  // methods // ***
  onItemClicked(item: Record<string, any>) {
    this.$emit('item-clicked', item)
  }
}
