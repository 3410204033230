import { COOKIE_KEYS } from '@/utilities/constants'
import { pascalCase } from '@/utilities/filters'
import store from '@/plugins/vuex'

export const authoriseUser = async (context: Record<string, any>): Promise<void> => {
  const { $auth, $router } = context
  const fetchActiveUser = async (): Promise<void> => {
    return await $auth
      .fetchActiveUser()
      .then(async () => {
        if ($auth.activeUserIsSuperAdmin) return await $auth.fetchImpersonatableUsers()
      })
      .catch((e: Record<string, any>) => {
        store.dispatch('core/forceLogout', e)
      })
  }
  if (!$auth.authenticated) {
    if ($auth.authType === 'development') {
      return await fetchActiveUser()
    } else {
      return await $auth
        .fetchAuthUser()
        .then(async (authUser: any) => {
          if (!authUser?.access_token) {
            $router.push('/login')
          } else {
            return await fetchActiveUser()
          }
        })
        .catch((e: Record<string, any>) => {
          store.dispatch('core/forceLogout', e)
        })
    }
  } else if (!$auth.activeUser?.id) return await fetchActiveUser()
  else return
}

export const onStartup = async (context: Record<string, any>): Promise<void> => {
  loadCookieData(context)
  if (context.$auth.activeUserIsDisabled) context.$store.commit('core/setLayout', 'disabled-layout')
  context.$store.commit('core/setStartupCompleted', true)
}

const loadCookieData = ({ $auth, $cookies, $store }: Record<string, any>): void => {
  const authKeys = ['impersonatedUser', 'impersonatableUsers']
  const storeKeys = ['activeSupplier', 'activeSuppliers']
  const setAuthVal = (k: string, v: any) => $auth[`set${pascalCase(k)}`](v)
  const setStoreVal = (k: string, v: any) => $store.commit(`suppliers/set${pascalCase(k)}`, v)
  COOKIE_KEYS.forEach((k: string): void => {
    const v = $cookies.get(k)
    if (typeof v === 'undefined') return
    else if (authKeys.includes(k)) setAuthVal(k, v)
    else if (storeKeys.includes(k)) setStoreVal(k, v)
  })
}
