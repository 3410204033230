export default {
  navbar: {
    userGuide: 'Βοήθεια & Οδηγοί',
  },
  settings: {
    emailTypes: {
      generalManager: 'Γενικός Διευθυντής',
      reception: 'Υποδοχή/Προσφυγή',
      sales: 'Έσοδα/Πωλήσεις',
      email24: 'Ηλεκτρονικό Ταχυδρομείο 24 Ώρες',
      notification: 'Ειδοποίηση',
      other: 'Άλλο',
    },
  },
  auth: {
    emailAndPasswordDoNotMatchOurRecords: 'Το email και ο κωδικός δεν συμφωνούν με τα αρχεία μας',
    forgottenYourPassword: 'Ξεχάσατε τον κωδικό σας;',
    forgottenYourUsername: 'Ξεχάσατε το όνομα χρήστη σας;',
    helpUsFindYourUsername:
      'Συμπληρώστε τα στοιχεία που ζητούνται παρακάτω για να μας βοηθήσετε να βρούμε το όνομα χρήστη σας.',
    helpUsResetYourPassword:
      'Συμπληρώστε το όνομα χρήστη σας για να μας βοηθήσετε να επαναφέρουμε τον κωδικό σας.',
    login: 'Σύνδεση',
    logIn: 'Σύνδεση',
    logout: 'Αποσύνδεση',
    logOut: 'Αποσύνδεση',
    password: 'Κωδικός',
    passwordReset: 'Επαναφορά κωδικού',
    pleaseEnterThePrimaryEmailAddress:
      'Εισάγετε την κύρια διεύθυνση ηλεκτρονικού ταχυδρομείου για τον λογαριασμό σας.',
    pleaseEnterYourUsername: 'Εισάγετε το όνομα χρήστη σας...',
    pleaseEnterYourPassword: 'Εισάγετε τον κωδικό σας...',
    primaryEmailAddress: 'Κύρια διεύθυνση ηλεκτρονικού ταχυδρομείου',
    unfortunatelyTheSystemCouldNotAuthenticateYou:
      'Δυστυχώς το σύστημα δεν μπόρεσε να σας ταυτοποιήσει. Αυτό συμβαίνει συνήθως επειδή έληξε η σύνδεσή σας. Συνδεθείτε ξανά για να συνεχίσετε.',
    username: 'Όνομα χρήστη',
    usernameReminder: 'Υπενθύμιση ονόματος χρήστη',
    youAreBeingLoggedOut: 'Αποσυνδέεστε.',
    yourSessionHasExpired: 'Η σύνδεσή σας έληξε. Συνδεθείτε ξανά.',
  },
  availability: {
    availability: 'Διαθεσιμότητα',
    addAvailability: 'Προσθήκη διαθεσιμότητας',
    addingAvailability: 'Προσθήκη διαθεσιμότητας',
    accessible: 'Προσβάσιμο',
    double: 'Δίκλινο',
    family: 'Οικογενειακό',
    single: 'Μονόκλινο',
    twin: 'Δίκλινο',
    room: 'Δωμάτιο',
    rooms: 'Δωμάτια',
    numberAvailable: 'Διαθέσιμος αρ.',
    noCmacRate: 'Δεν υπάρχει Τιμή CMAC',
    rate: 'Τιμή',
    useCmacAgreedRates: 'Χρήση των Συμφωνημένων Τιμών CMAC',
    fullyBooked: 'Πλήρες',
    helpText1: 'Για να εισάγετε διαθεσιμότητα, επιλέξτε μια ημερομηνία ή μια χρονική περίοδο.',
    helpText2: 'Για να επιλέξετε μια ημερομηνία, επιλέξτε το κουτί για εκείνη την ημερομηνία.',
    helpText3: 'Για να επιλέξετε μια περίοδο, επιλέξτε ένα δεύτερο, διαφορετικό κουτί.',
    helpText4:
      'Οι επιλεγμένες ημερομηνίες θα εμφανιστούν ως κόκκινες. Για να σβήσετε μια ημερομηνία που έχετε επιλέξει, επιλέξτε το κουτί για εκείνη την ημερομηνία.',
    helpText5:
      'Επιλέξτε Προσθήκη Διαθεσιμότητας και εισάγετε τον συνολικό αριθμό διαθέσιμων δωματίων. Αν έχετε υποβάλει προηγουμένως τιμές, θα χρησιμοποιήσουμε τις Συμφωνημένες Τιμές CMAC. Διαφορετικά, εισάγετε την τιμή δωματίου για τη διαθεσιμότητα.',
    helpText6:
      'Μπορείτε να προσθέσετε πολλές καταχωρήσεις για την ίδια ημερομηνία αν έχετε διαθέσιμα δωμάτια σε διαφορετικές τιμές.',
    publicHeader: 'CMAC Group – Αίτημα Διαθεσιμότητας - ',
    publicInstruction1: 'Εισάγετε τον αριθμό κάθε διαθέσιμου τύπου και επιλέξτε Υποβολή',
    publicInstruction2:
      'Μπορείτε πάντα να χρησιμοποιείτε αυτόν τον σύνδεσμο για να επιστρέφετε αργότερα και να ενημερώνετε αυτές τις πληροφορίες. Θα λήξει στις',
    ratesIncludeTax: 'Βεβαιωθείτε ότι η τιμή ΠΕΡΙΛΑΜΒΑΝΕΙ φόρο / ΦΠΑ',
    submittedBy: 'Που υποβάλλονται από ',
    updatedBy: 'Ενημερώθηκε από ',
    specificallyFor: 'Συγκεκριμένα για ',
    parkFlyRoomOnly: 'Sleep & Fly Room Μόνο',
    parkFly4Bundle: 'Δωμάτιο Sleep & Fly + Πάρκινγκ 4 ημερών',
    parkFly8Bundle: 'Δωμάτιο Sleep & Fly + Πάρκινγκ 8 ημερών',
    parkFly15Bundle: 'Δωμάτιο ύπνου & πτήσης + 15ήμερο πάρκινγκ',
    parkFlyToCloseOut:
      'Για να κλείσετε μια ημερομηνία, απενεργοποιήστε τα σχετικά στοιχεία. Για να ενεργοποιήσετε ξανά την κράτηση, θα πρέπει να ανεβάσετε ξανά το υπολογιστικό φύλλο τιμολόγησης.',
  },
  cmac: {
    actionedBy: 'Εκτελέστηκε από',
    addAddress: 'Προσθήκη Διεύθυνσης',
    addEmailAddress: 'Προσθήκη Ηλεκτρονικής Διεύθυνσης',
    addTelephoneNumber: 'Προσθήκη Τηλεφωνικού Αριθμού',
    addAComment: 'Προσθήκη σχολίου',
    addComment: 'Προσθήκη σχολίου',
    addExpense: 'Προσθήκη Εξόδου',
    addingAComment:
      'Η προσθήκη σχολίου θα θέσει αυτήν τη διαδρομή υπό αμφισβήτηση. Χρησιμοποιήστε μόνο για διαφορές που δεν εμφανίζονται στη γραμμή της διαδρομής. Περιλάβετε πλήρη στοιχεία, π.χ., χιλιομετρική απόσταση και λόγο υποβολής ερωτήματος.',
    agreed: 'Συμφωνήθηκε',
    allOpen: 'Όλα ανοιχτά',
    amend: 'Τροποποίηση',
    applies: 'Ισχύει',
    apply: 'Εφαρμογή',
    assignedOrganisation: 'Υπεύθυνος οργανισμός',
    availability: 'Διαθεσιμότητα',
    awaitingResponseFromSupplier: 'Αναμονή απάντησης από προμηθευτή',
    billingAddress: 'Διεύθυνση Χρέωσης',
    bulkUpload: 'Μαζική μεταφόρτωση',
    bulkUploadHistory: 'Ιστορικό μαζικής μεταφόρτωσης',
    cancelled: 'Ακυρώθηκε',
    closed: 'Έκλεισε',
    cmac: 'CMAC',
    cmacReference: 'Αρ. CMAC',
    companyDetails: 'Στοιχεία εταιρείας',
    companyFinancialDetails: 'Οικονομικά στοιχεία',
    companyOnBoardingDocuments: 'Έγγραφα εγγραφής εταιρείας',
    companyNumber: 'Αριθμός εταιρείας',
    compliance: 'Συμμόρφωση',
    costConfirmation: 'Επιβεβαίωση κόστους',
    costVatBreakDown: 'Ανάλυση ΦΠΑ κόστους',
    coverage: 'Κάλυψη',
    coverageAreas: 'Περιοχές κάλυψης',
    currentlyImpersonating: 'Προς το παρόν πλαστοπροσωπία',
    customDates: 'Προσαρμοσμένες ημερομηνίες',
    customerName: 'Όνομα πελάτη',
    customerOrganisation: 'Οργανισμός πελάτη',
    departureDate: 'Ημερομηνία αναχώρησης',
    depotAddress: 'Διεύθυνση Αποθήκης',
    dispute: 'Αμφισβήτηση',
    disputed: 'Υπό αμφισβήτηση',
    disputeDetails: 'Λεπτομέρειες αμφισβήτησης',
    disputeEntry: 'Καταχώρηση αμφισβήτησης',
    disputeEntries: 'Καταχωρήσεις αμφισβήτησης',
    disputeId: 'Αρ. αμφισβήτησης',
    disputes: 'Αμφισβητήσεις',
    documentType: 'Τύπος εγγράφου',
    driver: 'Οδηγός',
    driverDetails: 'Στοιχεία οδηγού',
    editJourney: 'Επεξεργασία διαδρομής',
    editOpenJourneys: 'Επεξεργασία ανοιχτών διαδρομών',
    endImpersonation: 'End impersonation',
    endTime: 'Χρόνος λήξης',
    disputesAwaitingAction: 'Διαφορές Που Περιμένουν Ενέργεια',
    editAddress: 'Επεξεργασία Διεύθυνσης',
    editEmailAddress: 'Επεξεργασία Ηλεκτρονικής Διεύθυνσης',
    editTelephoneNumber: 'Επεξεργασία Τηλεφωνικού Αριθμού',
    expenseItem: {
      meetAndGreet: 'Συνάντηση και χαιρετισμός',
      parking: 'Πάρκινγκ',
      toll: 'Διόδια',
      greenFee: 'Οικολογική χρέωση',
      misc: 'Διάφορα',
      amount: 'Ποσό',
    },
    expenses: 'Δαπάνες',
    expired: 'Έληξε',
    expiryDate: 'Ημερομηνία λήξης',
    expiryDateRequired: 'Απαιτείται ημερομηνία λήξης',
    extras: 'Έξτρα',
    fare: 'Κόμιστρο',
    farePriceCalculation: 'Υπολογισμός κόμιστρου',
    financialDetails: {
      accountName: 'Όνομα Λογαριασμού',
      bankAddress: 'Διεύθυνση Τράπεζας',
      ibanNumber: 'Αριθμός IBAN',
      currency: 'Νόμισμα',
    },
    fixedPrices: 'Καθορισμένες τιμές',
    greenFee: 'Πράσινο τέλος',
    holidayRates: 'Τιμές αργιών',
    holidays: 'Αργίες',
    holidayUplift: 'Εορταστική ανάταση',
    holidayUpliftDescription:
      'Αυτές οι προσαρμοσμένες ημερομηνίες ισχύουν για ΟΛΕΣ τις καταχωρήσεις της κάρτας τιμής, εκτός εάν παρακάμπτονται από συγκεκριμένη προσαρμοσμένη ημερομηνία καταχώρισης',
    hotelMarkupRates: 'Τιμές προσαύξησης ξενοδοχείων',
    hotelProfile: 'Προφίλ Ξενοδοχείου',
    hotelRatesTitle: 'Τιμές Ξενοδοχείου',
    ifYouNeedToChangeHowWeKnowYouInOurSystemPleaseCallTheSupplierRelationsTeamOn:
      'Αν πρέπει να αλλάξετε τον τρόπο που σας γνωρίζουμε στο σύστημά μας, καλέστε την Ομάδα Σχέσεων με Προμηθευτές στο',
    impersonating: 'Impersonating',
    inProgress: 'Σε εξέλιξη',
    invoiceId: 'Αρ. τιμολογίου',
    invoices: 'Τιμολόγια',
    job: 'Διαδρομή | Διαδρομές',
    jobEntryId: 'Αρ. καταχώρησης διαδρομής',
    jobTypes: 'Τύποι διαδρομών',
    journey: 'Διαδρομή',
    journeys: 'Διαδρομές',
    journeyChargeMethod: 'Μέθοδος χρέωσης διαδρομής',
    lastUpdated: 'Τελευταία ενημέρωση',
    leadMileage: 'Επιπλέον χιλιόμετρα χωρίς επιβάτες',
    leadMileageCost: 'Κόστος επιπλέον χιλιομέτρων χωρίς επιβάτες',
    mileage: 'Χιλιομετρική απόσταση',
    mileageRates: 'Τιμές χιλιομετρικής απόστασης',
    minimumPrice: 'Ελάχιστη χρέωση',
    open: 'Ανοιχτό',
    noCustomDates: 'Δεν υπάρχουν προσαρμοσμένες ημερομηνίες',
    noOfPax: 'Αρ. επιβατών',
    orAlternativelyEmail: 'Ή εναλλακτικά email',
    otherOrganisations: 'Άλλοι οργανισμοί',
    passenger: 'Επιβάτης',
    parking: 'Στάθμευση',
    parkingExpenses: 'Δαπάνες στάθμευσης',
    pax: 'Επιβάτες',
    percentageOfOriginalCostPrice: 'Ποσοστό αρχικής τιμής κόστους',
    permits: {
      currentHeading: 'ΤΡΕΧΟΥΣΕΣ ΑΔΕΙΕΣ',
      openHeading: 'ΑΝΟΙΧΤΟ ΓΙΑ ΑΙΤΗΣΗ',
      title: 'Άδειες',
      claimed: 'Ανατέθηκε',
      status: 'Κατάσταση',
      expires: 'Λήγει',
      apply: 'Κάντε αίτηση τώρα',
      outstandingAmendment: 'Εκκρεμεί ένα αίτημα τροποποίησης για αυτήν την άδεια.',
      noClaimedPermits: 'Δεν υπάρχουν ανατεθειμένες άδειες.',
      noOpenApplications: 'Δεν υπάρχουν ανοιχτές αιτήσεις.',
      download: 'Λήψη',
      print: 'Εκτύπωση άδειας',
      amend: 'Τροποποίηση',
    },
    pickupDateTime: 'Ημ/Ώρα παραλαβής',
    pleaseAddAComment: 'Προσθέστε ένα σχόλιο',
    pleaseContactXForAssistance: 'Επικοινωνήστε με {x} για βοήθεια',
    pleaseSelectAXToImpersonate: 'Please select a {x} to impersonate',
    policiesAndTerms: 'Πολιτικές και όροι',
    pricingInformation: 'Πληροφορίες τιμολόγησης',
    processing: 'Επεξεργασία',
    profile: 'Προφίλ',
    psvarStatus: 'Psvar Κατάσταση',
    rate: 'Τιμή',
    rateCards: 'Κάρτες τιμών',
    referredToOperations: 'Παραπέμφθηκε στο τμήμα λειτουργιών',
    referredToSupplierRelations: 'Παραπέμφθηκε στο τμήμα σχέσεων με προμηθευτές',
    referredToPricingManager: 'Παραπέμφθηκε στον υπεύθυνο τιμολόγησης',
    regions: 'Περιοχές',
    registration: 'Άδεια κυκλοφορίας',
    responseReceivedFromSupplier: 'Λήφθηκε απάντηση από τον προμηθευτή',
    selectDocumentType: 'Επιλέξτε τύπο εγγράφου',
    selectUserToImpersonate: 'Select user to impersonate',
    standDownChargeMethod: 'Μέθοδος χρέωσης με έκπτωση λόγω μη εμφάνισης πελάτη',
    standDownPrice: 'Έκπτωση λόγω μη εμφάνισης πελάτη',
    startTime: 'Χρόνος έναρξης',
    statementId: 'Αρ. δήλωσης',
    stoodDown: 'Η εκτέλεση της διαδρομής ανεστάλη',
    stoodDownWithinXMinutesOfDepartureDate:
      'Η εκτέλεση της διαδρομής ανεστάλη εντός {x} λεπτών της ημερομηνίας αναχώρησης',
    summary: 'Σύνοψη',
    supplierName: 'Όνομα προμηθευτή',
    supplierRef: 'Αναφ. προμηθευτή',
    termsAndConditions: 'Όροι και προϋποθέσεις',
    thankYouForLoggingInToTheSupplierPortal: 'Ευχαριστούμε που συνδεθήκατε στην πύλη προμηθευτών',
    tierType: 'Τύπος κατηγορίας',
    updateFailed: 'Αποτυχία ενημέρωσης',
    updateSuccessful: 'Επιτυχής ενημέρωση',
    userDetails: 'Στοιχεία χρήστη',
    hotelDetails: 'Στοιχεία ξενοδοχείου',
    vatNumber: 'Αριθμός ΦΠΑ',
    vatTreatment: 'Επεξεργασία ΦΠΑ',
    vehicle: 'Όχημα',
    vehicleRates: 'Τιμές οχημάτων',
    vias: 'Μέσω',
    viewAll: 'Προβολή όλων',
    waypoint: 'Ενδιάμεσος σταθμός | Ενδιάμεσοι σταθμοί',
    waitingTime: 'Χρόνος αναμονής',
    waitingTimeCost: 'Κόστος χρόνου αναμονής',
    waitingTimeFree: 'Χρόνος αναμονής δωρεάν',
    waitingTimeMinutes: 'Λεπτά χρόνου αναμονής',
    waitingTimeRate: 'Τιμή χρόνου αναμονής',
    withinXMinutes: 'Εντός {x} λεπτών',
    vehicles: 'Οχήματα',
    vehicleRegistration: 'Εγγραφή οχήματος',
    vehicleType: 'Τύπος οχήματος',
    yourAccountIsCurrentlyInactive: 'Ο λογαριασμός σας είναι ανενεργός αυτήν τη στιγμή',
    yourReference: 'Ο Αρ. σας',
    total: 'Σύνολο',
    invoiceuploads: 'Μεταφορτώσεις Τιμολογίων',
    hotelRates: {
      validBetween: 'Ισχύει μεταξύ',
      addNewRate: 'Προσθήκη τιμών',
      addNewRateText: 'Εκκρεμεί ένα νέο αίτημα τιμής για αυτόν τον οργανισμό.',
      noRatesTextPrepend:
        'Δεν υπάρχουν τρέχουσες τιμές για τον οργανισμό σας. Επικοινωνήστε μαζί μας στο',
      noRatesTextAppend: 'για να προγραμματίσετε την υποβολή των τιμών σας.',
      hasRatesText:
        'Παρακάτω θα βρείτε τις τρέχουσες τιμές που έχουμε στο σύστημά μας. Αν πρέπει να κάνετε οποιαδήποτε αλλαγή, επικοινωνήστε μαζί μας στο ',
      profile: 'Προφίλ',
      profileStarRating: 'Αξιολόγηση με αστέρια',
      profileUnkown: 'Άγνωστο',
      profileYes: 'Ναι',
      profileNo: 'Όχι',
      profileYesFree: 'Ναι Δωρεάν',
      profileNumberOfRooms: 'Αριθμός δωματίων',
      profileAccessibleRooms: 'Προσβάσιμα δωμάτια',
      profileFamilyRooms: 'Οικογενειακά δωμάτια',
      profileHotelBus: 'Λεωφορείο ξενοδοχείου',
      profileParking: 'Χώρος στάθμευσης',
      profileSpa: 'Σπα',
      profileSwimmingPool: 'Πισίνα',
      profileGym: 'Γυμναστήριο',
      profileRestaurant: 'Εστιατόριο',
      commissionRate: 'Ποσοστά προμήθειας',
      commissionRooms: 'Δωμάτια',
      commissionFood: 'Φαγητό',
      commissionDynamic: 'Δυναμική',
      editText: 'Κάρτα τιμών ξενοδοχείου',
      editProfileText: 'Επεξεργασία στοιχείων ξενοδοχείου',
      ratesValidDatesText: 'Οι τιμές ισχύουν μεταξύ αυτών των ημερομηνιών',
      ratesValidFrom: 'Ισχύει από',
      ratesValidTo: 'Ισχύει έως',
      rates: 'Τιμές',
      ratesType: 'Τύπος',
      ratesRoomText:
        'Τιμές ανά δωμάτιο για επιβάτες και πλήρωμα αεροσκαφών που υποχρεώνονται σε αναμονή λόγω καθυστέρησης',
      ratesRoomType: 'Τύπος δωματίου',
      ratesMidweekText: 'Δωμάτιο εκτός σαββατοκύριακου (Κυρ-Πε)',
      ratesDayLet: 'Ημερήσια χρήση',
      ratesWeekendText: 'Δωμάτιο μόνο Σαββατοκύριακο (Παρ-Σα)',
      ratesRoomTwinDouble: 'Δίκλινο',
      ratesRoomSingle: 'Μονόκλινο',
      ratesRoomTriple: 'Τρίκλινο',
      ratesRoomFamily: 'Οικογενειακό',
      ratesWeekdayRate: 'Τιμή καθημερινής',
      ratesWeekdayDayRate: 'Τιμή καθημερινής',
      ratesWeekendRate: 'Τιμή Σαββατοκύριακου',
      ratesWeekendDayRate: 'Τιμή Σαββατοκύριακου',
      ratesFoodText: 'Φαγητό και ποτό (συμπ. τσάι, καφέ, λικέρ) Τιμή ανά άτομο',
      ratesContinentalBreakfast: 'Ευρωπαϊκό πρωινό',
      ratesFullBreakfast: 'Πλήρες πρωινό',
      ratesLunch: 'Μεσημεριανό',
      ratesDinner: 'Βραδινό',
      ratesAgreementText:
        'Η καταχώρηση αυτών των τιμών θα δημιουργήσει δεσμευτική σύμβαση μεταξύ του CMAC Group και του ξενοδοχείου σας, γι’ αυτό πρέπει να φροντίσετε να συμπληρωθούν μόνο εξουσιοδοτημένο άτομο. Το ξενοδοχείο θα είναι υπεύθυνο για το άτομο που συμπληρώνει τις τιμές, και αν οι τιμές συμπληρωθούν από μη εξουσιοδοτημένο άτομο, αυτό δεν θα επηρεάσει τον CMAC Group ή την ικανότητά του να εφαρμόσει αυτές τις τιμές.',
      ratesTermsConditionsText: 'Επιλέξτε για να επιβεβαιώσετε ότι διαβάσατε το ',
      ratesTermsConditionsLinkText: 'όροι και προϋποθέσεις',
      submittedHeading: 'Υποβλήθηκε από',
      viewRatesTermsConditionsText: 'Κάντε κλικ εδώ για να δείτε το δικό μας',
      outstandingRateRequest: 'Εκκρεμείς Αίτηση Τιμολογίου',
      submittedByName: 'Όνομα',
      submittedByEmail: 'Email',
      submittedByNameRequired: 'Παρακαλώ εισάγετε το όνομά σας.',
      submittedByEmailRequired: 'Παρακαλώ εισάγετε τη διεύθυνση email σας.',
      submittedSuccess:
        'Σας ευχαριστούμε που υποβάλατε τις τιμές σας. Ανυπομονούμε να συνεργαστούμε μαζί σας.',
      errorSaving:
        'Παρουσιάστηκε σφάλμα κατά την αποθήκευση των τιμών σας. Επικοινωνήστε με το hotelrelations@cmacgroup.com για βοήθεια.',
    },
    cmacRef: 'Αρ. CMAC',
    yourRef: 'Ο Αρ. σας',
    noNotificationEmailAddress: 'Δεν έχετε διεύθυνση email ειδοποίησης',
    leadMiles: 'Προηγούμενες Μίλιες',
    fareNet: 'Ναύλος (Καθαρό)',
    leadMilesCost: 'Κόστος προηγούμενων μιλίων (Καθαρό)',
    totalCost: 'Συνολικό κόστος (Καθαρό)',
    appliesOnDays: 'Ισχύει τις ημέρες',
    noVehicleRates: 'Χωρίς χρεώσεις οχημάτων',
    markup: 'Σήμανσης',
    addedPrice: 'Προστιθέμενη τιμή',
    noHolidayRates: 'Δεν υπάρχουν χρεώσεις διακοπών',
    standDownRates: 'Σταματήστε τα ποσοστά',
    noStandDownRates: 'Δεν υπάρχουν ποσοστά μείωσης',
    stoodDownWithin: 'Στάθηκε μέσα',
    minutesOfDepartureDate: 'λεπτά της ημερομηνίας αναχώρησης',
    ofOriginalCostPrice: 'της αρχικής τιμής κόστους',
    ofOriginalCostPriceWhicheverIsHigher: 'της αρχικής τιμής κόστους, όποιο είναι υψηλότερο',
    waitingTimeRates: 'Τιμές χρόνου αναμονής',
    noWaitingTimeRates: 'Χωρίς χρεώσεις χρόνου αναμονής',
    minutesFree: 'λεπτά δωρεάν',
    minutes: 'λεπτά',
  },
  common: {
    about: 'Σχετικά',
    accepted: 'Αποδεκτό',
    add: 'Προσθήκη',
    addComment: 'Προσθ. σχόλιο',
    addVehicle: 'Προσθήκη οχήματος',
    actions: 'Ενέργειες',
    addXtoY: 'Προσθήκη {x} στο {y}',
    agreed: 'Συμφωνήθηκε',
    agreementType: 'Τύπος συμφωνίας',
    all: 'Όλα',
    back: 'Πίσω',
    backToTop: 'Επιστροφή στην αρχή',
    cancel: 'Ακύρωση',
    close: 'Κλείσιμο',
    comment: 'Σχόλιο',
    comments: 'Σχόλια',
    completed: 'Ολοκληρώθηκε',
    confirm: 'Επιβεβαίωση',
    continue: 'Συνέχεια',
    cost: 'Κόστος',
    create: 'Δημιουργία',
    created: 'Δημιουργήθηκε',
    createdAt: 'Δημιουργήθηκε στις',
    customer: 'Πελάτης',
    date: 'Ημερομηνία',
    dateRange: 'Χρονική περίοδος',
    delete: 'Διαγραφή',
    departureDateRange: 'Χρονική περίοδος αναχώρησης',
    description: 'Περιγραφή',
    details: 'Στοιχεία',
    disputed: 'Αμφισβητήθηκε',
    distance: 'Απόσταση',
    documents: 'Έγγραφα',
    download: 'Λήψη',
    edit: 'Επεξεργασία',
    edited: 'Υποβλήθηκε σε επεξεργασία',
    editVehicle: 'Επεξ. οχήματος',
    email: 'Email',
    enabled: 'ενεργοποιήθηκε',
    error: 'Σφάλμα',
    example: 'Παράδειγμα',
    expired: 'Έληξε',
    expires: 'Λήγει',
    failed: 'Αποτυχία',
    file: 'Αρχείο',
    filename: 'Όνομα Αρχείου',
    filters: 'Φίλτρα',
    from: 'Από',
    full: 'Πλήρης διατροφή',
    half: 'Ημιδιατροφή',
    home: 'Αρχική σελίδα',
    hide: 'Απόκρυψη',
    id: 'Αρ.',
    info: 'Πληροφορίες',
    important: 'Σημαντικό',
    jobNo: 'Αρ. διαδρομής',
    loading: 'Φόρτωση',
    map: 'Χάρτης',
    messages: 'Μηνύματα',
    miles: 'Χιλιόμετρα',
    minutes: 'Λεπτά',
    mode: 'Μέθοδος',
    name: 'Όνομα',
    new: 'Νέο',
    no: 'Όχι',
    none: 'Κανένα',
    notes: 'Σημειώσεις',
    null: 'Μηδενικό',
    ok: 'OK',
    open: 'Άνοιγμα',
    organisation: 'Οργανισμός',
    organisations: 'Οργανισμοί',
    original: 'Αρχικό',
    others: 'Άλλοι',
    password: 'Κωδικός',
    pending: 'Εκκρεμεί',
    price: 'Τιμή',
    previouslyUploaded: 'Μεταφορτώθηκε ήδη',
    processed: 'Υποβλήθηκε σε επεξεργασία',
    processing: 'Επεξεργασία',
    protection: 'Προστασία',
    quantity: 'Ποσότητα',
    raw: 'Χωρίς επεξεργασία',
    readonly: 'Μόνο για ανάγνωση',
    reference: 'Αρ.',
    refresh: 'Ανανέωση',
    reset: 'Επαναφορά',
    roles: 'Ρόλοι',
    save: 'Αποθήκευση',
    search: 'Αναζήτηση',
    settings: 'Ρυθμίσεις',
    show: 'Εμφάνιση',
    signedBy: 'Υπογράφηκε από',
    signedOn: 'Υπογράφηκε στις',
    sortBy: 'Ταξινόμηση κατά',
    status: 'Κατάσταση',
    submit: 'Υποβολή',
    surcharge: 'Πρόσθετη χρέωση',
    test: 'Δοκιμή',
    time: 'Ώρα',
    title: 'Τίτλος',
    to: 'Έως',
    total: 'Σύνολο',
    type: 'Τύπος',
    unknown: 'Άγνωστο',
    update: 'Ενημέρωση',
    updated: 'Ενημερώθηκε',
    updatedAt: 'Ενημερώθηκε στις',
    upload: 'Μεταφόρτωση',
    uploaded: 'Μεταφορτώθηκε',
    user: 'Χρήστης',
    username: 'Όνομα χρήστη',
    users: 'Χρήστες',
    website: 'Ιστοσελίδα',
    weight: 'Βάρος',
    yes: 'Ναι',
    next: 'Επόμενο',
    hour: 'ώρα',
    mile: 'μίλι',
    or: 'ή',
  },
  currencies: {
    gbp: '£',
    eur: '€',
  },
  datetime: {
    monday: 'Δευτέρα',
    tuesday: 'Τρίτη',
    wednesday: 'Τετάρτη',
    thursday: 'Πέμπτη',
    friday: 'Παρασκευή',
    saturday: 'Σάββατο',
    sunday: 'Κυριακή',
    january: 'Ιανουάριος',
    february: 'Φεβρουάριος',
    march: 'Μάρτιος',
    april: 'Απρίλιος',
    may: 'Μάιος',
    june: 'Ιούνιος',
    july: 'Ιούλιος',
    august: 'Αύγουστος',
    september: 'Σεπτέμβριος',
    october: 'Οκτώβριος',
    november: 'Νοέμβριος',
    december: 'Δεκέμβριος',
    today: 'Σήμερα',
  },
  docs: {
    documentation: 'Έγγραφα',
    faq: 'Συχνές ερωτήσεις',
    frequentlyAskedQuestions: 'Συχνές ερωτήσεις',
    tutorials: 'Εκπαιδευτικό υλικό',
  },
  errors: {
    301: 'Δεν έχετε άδεια πρόσβασης σε αυτήν την πηγή.',
    400: 'Λυπούμαστε, το αίτημα ήταν ανεπιτυχές.',
    404: 'Λυπούμαστε, δεν μπορέσαμε να βρούμε την πηγή που αναζητούσατε.',
    500: 'Ο διακομιστής δεν μπόρεσε να επεξεργαστεί το αίτημα.',
    error: 'Σφάλμα',
    nError: '{n} σφάλμα',
    noResponseFromServer: 'Καμία απάντηση από τον διακομιστή',
    noUrl: 'Δεν υπάρχει url',
    sorrySomethingWentWrong: 'Λυπούμαστε, κάτι πήγε στραβά',
    uploadFailed: 'Αποτυχία μεταφόρτωσης',
    uploadFailedWithError: 'Αποτυχία μεταφόρτωσης, με σφάλμα',
  },
  fields: {
    address: 'Διεύθυνση',
    addresses: 'Διευθύνσεις',
    addressLine1: 'Γραμμή διεύθυνσης 1',
    addressLine2: 'Γραμμή διεύθυνσης 2',
    addressLine3: 'Γραμμή διεύθυνσης 3',
    addressLookup: 'Αναζήτηση Διεύθυνσης',
    city: 'Πόλη',
    country: 'Χώρα',
    county: 'Περιφέρεια',
    description: 'Περιγραφή',
    driverName: 'Όνομα οδηγού',
    driverPhone: 'Αριθμός τηλεφώνου οδηγού',
    driverRegistration: 'Εγγραφή οδηγού',
    email: 'Email',
    emailAddress: 'Διεύθυνση ηλεκτρονικού ταχυδρομείου',
    emailAddresses: 'Διευθύνσεις ηλεκτρονικού ταχυδρομείου',
    home: 'Αρχική σελίδα',
    mobile: 'Κινητό τηλέφωνο',
    name: 'Όνομα',
    number: 'Αριθμός',
    phoneNumber: 'Τηλέφωνο',
    postCode: 'Ταχυδρομικός κώδικας',
    region: 'Περιοχή',
    registrationNumber: 'Αριθμός άδειας κυκλοφορίας',
    telephoneNumber: 'Τηλέφωνο',
    telephoneNumbers: 'Τηλέφωνα',
    title: 'Τίτλος',
    titles: {
      dr: 'Δρ',
      lady: 'Lady',
      lord: 'Lord',
      mr: 'Κος',
      miss: 'Δεσποινίς',
      mrs: 'Κα',
      ms: 'Δις',
      mx: 'Mx',
      prof: 'Καθ.',
      sir: 'Sir',
    },
    town: 'Πόλη',
    work: 'Εργασία',
  },
  onboarding: {
    documents: {
      public_insurance: 'Πιστοποιητικό Ασφάλισης Δημόσιας Ευθύνης',
      liability: 'Πιστοποιητικό ασφάλισης αστικής ευθύνης εργοδοτών',
      motor_insurance: 'Πιστοποιητικό ασφάλισης αυτοκινήτων',
      gdpr: 'Πολιτική GDPR',
      small_operator_license: 'Άδεια χειριστή - μικρά οχήματα',
      large_operator_license: 'Άδεια χειριστή - μεγάλα οχήματα',
      upload: 'Carica documento',
      download: 'Scarica documento',
      file: 'Nome file',
      document: 'Tipo documento',
      created: 'Creato il',
      expires: 'Scade il',
    },
    agreement:
      'Έχω διαβάσει και αποδέχομαι τους όρους και τις προϋποθέσεις που εκτυπώνονται παραπάνω.',
    mustAgree:
      'Πρέπει να αποδεχτείτε τους όρους και τις προϋποθέσεις για να χρησιμοποιήσετε την εφαρμογή.',
    welcomeHeader: 'Καλώς ήρθατε στην εγγραφή προμηθευτή CMAC',
    country: 'Όνομα χώρας',
    legalCompanyName: 'Εταιρική επωνυμία',
    tradingName: 'Διακριτικός τίτλος',
    companyRegistrationNumber: 'Αριθμός εγγραφής εταιρείας',
    registeredAddress: 'Έδρα',
    operatingAddress: 'Διεύθυνση δραστηριοτήτων',
    currency: 'Αποσύνδεση',
    logOut: 'Αποσύνδεση',
    smallVehicle: 'Μικρό Όχημα (ταξί, βαν, αναπηρικό αυτοκίνητο)',
    largeVehicle: 'Μεγάλο Όχημα (μίνιμπας, πούλμαν)',
    fleetSize: 'Μέγεθος στόλου',
    emailAddress: 'Διεύθυνση ηλεκτρονικού ταχυδρομείου',
    mainContactNumber: 'Εισάγετε τον κωδικό σας...',
    primaryEmailAddress: 'Κύρια διεύθυνση ηλεκτρονικού ταχυδρομείου',
    successResult: 'Ο λογαριασμός δημιουργήθηκε με επιτυχία, ελέγξτε τα εισερχόμενα μηνύματά σας',
    failResult:
      'Δεν μπορούμε να δημιουργήσουμε τον λογαριασμό σας αυτήν τη στιγμή, υπάρχει ήδη λογαριασμός με τα στοιχεία που συμπληρώσατε, επικοινωνήστε με το supplier.relations@cmacgroup.com για περισσότερες πληροφορίες.',

    progressHeader: 'Ευχαριστούμε που υποβάλατε τα στοιχεία σας για να γίνεται Προμηθευτής CMAC.',
    progressMainText:
      'Για να προχωρήσετε με τις αιτήσεις σας, φροντίστε όλα τα τμήματα των Οικονομικών Στοιχείων και των Εγγράφων Εγγραφής Εταιρείας κατωτέρω να είναι συμπληρωμένα.',
    progressSubText:
      'Μόλις λάβουμε αυτά τα στοιχεία, θα μπορέσουμε να προχωρήσουμε την αίτησή σας στο επόμενο στάδιο.',
    progressBarText: 'Πρόοδος εγγραφής',
    progressCompleted:
      'Σας ευχαριστούμε για την υποβολή όλων των εγγράφων σας. Ένα από τα μέλη της ομάδας μας θα ελέγξει την υποβολή σας και θα επικοινωνήσει μαζί σας σύντομα για να προχωρήσετε στο επόμενο βήμα.',
    rejected:
      'Δυστυχώς, η αίτησή σας δεν έχει εγκριθεί. Επικοινωνήστε με την ομάδα μας για θέματα σχέσεων με τους προμηθευτές για περισσότερες πληροφορίες.',
  },
  plurals: {
    comments: 'Σχόλιο | Σχόλια',
    journeys: 'Διαδρομή | Διαδρομές',
    results: 'Αποτέλεσμα | Αποτελέσματα',
    resultsFound: 'Βρέθηκε αποτέλεσμα | Βρέθηκαν αποτελέσμαtα',
    rows: 'Σειρά | Σειρές',
    suppliers: 'Προμηθευτής | Προμηθευτές',
  },
  ui: {
    anNewVersionOfTheApplicationIsAvailable: 'Είναι διαθέσιμη μια νέα έκδοση της αίτησης',
    appName: 'Πύλη προμηθευτή',
    approve: 'Έγκριση',
    approveAllSelectedRows: 'Έγκριση όλων των επιλεγμένων σειρών',
    changeProfilePicture: 'Αλλαγή φωτογραφίας προφίλ',
    chooseX: 'Επιλογή {x}',
    clear: 'Εκκαθάριση',
    clearFilters: 'Εκκαθάριση φίλτρων',
    clickToCopyToClipboard: 'Κάντε κλικ για αντιγραφή στο πρόχειρο',
    clickToSortByX: 'Κάντε κλικ για ταξινόμηση κατά {x}',
    closeDrawer: 'Κλείσιμο συρταριού',
    currentlyUnavailable: 'Προσωρινά μη διαθέσιμο',
    dashboard: 'Πίνακας ελέγχου',
    document: 'Έγγραφο',
    dragYourXHereToUploadIt: 'Σύρετε το {x} σας εδώ για να το μεταφορτώσετε',
    export: 'Εξαγωγή',
    exportToCsv: 'Εξαγωγή σε CSV',
    file: 'αρχείο',
    fileSizeTooBig: 'Το αρχείο είναι πολύ μεγάλο',
    footer: 'Υποσέλιδο',
    home: 'Αρχική σελίδα',
    files: 'Αρχεία',
    homePage: 'Αρχική Σελίδα',
    itemsPerPage: 'Στοιχεία ανά σελίδα',
    navigation: 'Πλοήγηση',
    noDetailsToDisplay: 'Δεν υπάρχουν στοιχεία για προβολή',
    noFileSelected: 'Δεν επιλέχθηκε αρχείο',
    noResultsToShow: 'Δεν υπάρχουν αποτελέσματα για προβολή',
    noXProvided: 'Δεν παρασχέθηκε {x}',
    orClickToBrowse: 'Ή κάντε κλικ για περιήγηση',
    page: 'Σελίδα',
    pageSize: 'Μέγεθος σελίδας',
    pleaseSeletFromTheFollowing: 'Επιλέξτε από τα ακόλουθα',
    pleaseSelectAnX: 'Επιλέξτε ένα {x}',
    pleaseSelectAX: 'Επιλέξτε ένα {x}',
    pleaseSelectNXOnly: 'Επιλέξτε μόνο {n} {x}',
    profilePicture: 'Φωτογραφία προφίλ',
    resultsPerPage: 'Αποτελέσματα ανά σελίδα',
    saveChanges: 'Αποθήκευση αλλαγών',
    selectAll: 'Επιλογή όλων',
    selectX: 'Επιλογή {x}',
    selectADifferentX: 'Επιλογή διαφορετικού {x}',
    spreadsheet: 'Υπολογιστικό φύλλο',
    theXHasBeenDeleted: 'Το {x} διαγράφηκε',
    thisWillPutXInDispute: 'Αυτό θα θέσει το {x} υπό αμφισβήτηση',
    updateX: 'Ενημέρωση {x}',
    uploadBeingProcessed: 'Η μεταφόρτωση είναι υπό επεξεργασία',
    uploadStatus: 'Κατάσταση μεταφόρτωσης',
    uploading: 'Μεταφόρτωση',
    uploadNewX: 'Μεταφόρτωση νέου {x}',
    uploadX: 'Μεταφόρτωση {x}',
    userProfile: 'Προφίλ χρήστη',
    version: 'έκδοση',
    xMissing: 'λείπει το {x}',
    policySignedX: 'Ευχαριστούμε που υπογράψατε το {x}',
  },
  validations: {
    canOnlyContainLettersAndNumbers: 'Μπορεί να περιέχει μόνο γράμματα και αριθμούς',
    canOnlyContainLettersNumbersAndTheFollowingCharactersX:
      'Μπορεί να περιέχει μόνο γράμματα, αριθμούς και τους ακόλουθους χαρακτήρες: {x}',
    canOnlyContainNumbers: 'Μπορεί να περιέχει μόνο αριθμούς',
    checkboxConfirm:
      'Επιλέξτε το κουτί για να επιβεβαιώσετε ότι αποδέχεστε τους όρους και τις προϋποθέσεις',
    maxNCharacters: 'Μέγιστο {n} χαρακτήρες',
    minNCharacters: 'Ελάχιστο {n} χαρακτήρες',
    mustBeAnInteger: 'Πρέπει να είναι ακέραιος αριθμός',
    mustBeAFloat: 'Πρέπει να είναι δεκαδικός αριθμός',
    mustBeAValidEmailAddress: 'Πρέπει να είναι έγκυρη διεύθυνση ηλεκτρονικού ταχυδρομείου',
    mustBeAValidTelephoneNumber: 'Πρέπει να είναι έγκυρο τηλέφωνο',
    mustBeAWholeNumber: 'Πρέπει να είναι ακέραιος αριθμός',
    mustBeExactlyNCharacters: 'Πρέπει να είναι ακριβώς {n} χαρακτήρες',
    mustBeGreaterThanN: 'Πρέπει να είναι μεγαλύτερος από {n}',
    mustBeGreaterThanOrEqualToN: 'Πρέπει να είναι μεγαλύτερος ή ίσος με {n}',
    mustBeLessThanN: 'Πρέπει να είναι μικρότερος από {n}',
    mustBeLessThanOrEqualToN: 'Πρέπει να είναι μικρότερος ή ίσος με {n}',
    mustBeRoundedToExactlyNDecimalPlaces:
      'Πρέπει να στρογγυλοποιηθεί σε ακριβώς {n} δεκαδικά ψηφία',
    mustBeRoundedToNDecimalPlaces: 'Πρέπει να στρογγυλοποιηθεί σε {n} δεκαδικά ψηφία',
    mustHaveASingleDecimalPoint: 'Πρέπει να έχει μόνο ένα δεκαδικό ψηφίο',
    requiresBooleanValue: 'Απαιτεί τιμή Βoolean',
    thisDateMustBeInTheFuture: 'Αυτή η ημερομηνία πρέπει να είναι στο μέλλον',
    thisDateMustBeInThePast: 'Αυτή η ημερομηνία πρέπει να είναι στο παρελθόν',
    thisExceedsTheLeadMileageLimit: 'Υπερβαίνει το όριο επιπλέον χιλιομέτρων χωρίς επιβάτες',
    thisFieldIsRequired: 'Αναγκαίο πεδίο',
    thisFieldMustBeAValidDate: 'Αυτό το πεδίο πρέπει να είναι έγκυρη ημερομηνία',
    thisFieldMustContainAX: 'Αυτό το πεδίο πρέπει να περιέχει {x}',
    thisFieldMustContainAnX: 'Αυτό το πεδίο πρέπει να περιέχει {x}',
    mustBeUkBankSortCode: 'Ο κωδικός ταξινόμησης τράπεζας πρέπει να είναι στη μορφή 00-00-00',
    mustBeUkBankAccountNumber:
      'Ο αριθμός τραπεζικού λογαριασμού στο Ηνωμένο Βασίλειο πρέπει να έχει 8 ψηφία',
    mustBeAtLeastOneChecked: 'Επιλέξτε τουλάχιστον ένα από τα αντικείμενα',
  },
  invoiceUpload: {
    invoiceNo: 'Αρ. τιμολογίου',
    reference: 'Αρ.',
    uploadDate: 'Ημερομηνία μεταφόρτωσης',
    invoiceDate: 'Ημερομηνία τιμολογίου',
    dueDate: 'Ημερομηνία λήξης',
    total: 'Σύνολο',
    currency: 'Νόμισμα',
    status: 'Κατάσταση',
    comments: 'Σχόλια',
    file: 'Αρχείο',
    selectJourneys: 'Επιλογή διαδρομών',
    uploadInvoice: 'Μεταφόρτωση τιμολογίου',
    confirm: 'Επιβεβαίωση',
    uploadingAs: 'Αυτήν τη στιγμή μεταφορτώνετε ως',
    atLeastOneJourney: 'Πρέπει να επιλέξετε τουλάχιστον μία διαδρομή',
    errorUploadingFile: 'Προέκυψε σφάλμα κατά τη μεταφόρτωση και ανάλυση του αρχείου.',
    uploadInFormat: 'Μεταφορτώστε το τιμολόγιό σας σε μορφή PDF.',
    selectFile: 'Επιλέξτε ένα αρχείο για μεταφόρτωση',
    uploadingAnalyzing: 'Μεταφόρτωση και ανάλυση',
    invoiceReference: 'Αρ. τιμολογίου',
    invoice: 'Τιμολόγιο',
    cmacReference: 'Αρ. CMAC',
    yourReference: 'Ο Αρ. σας',
    journeysSelectionJourney: 'διαδρομή | διαδρομές',
    journeySelectionSelected: 'επιλέχθηκε',
    net: 'Καθαρό ποσό',
    tax: 'Φόρος',
    gross: 'Μεικτό ποσό',
    expenses: 'Δαπάνες',
    details: 'Λεπτομέρειες',
    journey: 'Διαδρομή',
    departureDate: 'Ημερομηνία αναχώρησης',
    cmacRef: 'Αρ. CMAC',
    yourRef: 'Ο Αρ. σας',
    invoiceAccepted: 'Το τιμολόγιο επιβεβαιώθηκε και έγινε αποδεκτό',
    invoiceDoesNotMatch: 'Ελέγξτε και υποβάλετε ξανά',
    stillUpload: 'Μπορείτε να μεταφορτώσετε αυτό το τιμολόγιο αλλά θα χρειαστεί έρευνα. ',
    paymentDelayed: 'Η εξόφληση θα καθυστερήσει μέχρι να ολοκληρωθεί αυτό.',
    totalNetDoesNotMatch:
      'Το συνολικό καθαρό ποσό στο τιμολόγιο δεν ταιριάζει με τη συνολική καθαρή τιμή για όλα τα επιλεγμένα ταξίδια.',
    expected: 'Αναμένεται',
    totalGross: 'Συνολικό μεικτό ποσό',
    totalNet: 'Συνολικό καθαρό ποσό',
    totalTax: 'Συνολικός φόρος',
    notFound: 'Δεν βρέθηκε',
    invoiced: 'Τιμολογήθηκε',
    underDispute: 'Υπό αμφισβήτηση',
    notUnique: 'Ένα αρχείο με αυτό το όνομα υπάρχει ήδη. Παρακαλώ επιλέξτε άλλο όνομα αρχείου.',
    invalidCharacters:
      'Το όνομα αρχείου περιέχει μη έγκυρους χαρακτήρες. Δεν επιτρέπονται τα παρακάτω.',
    invalidFileExtension:
      'Αυτός ο τύπος αρχείου δεν είναι έγκυρος. Επιτρέπονται οι ακόλουθοι τύποι αρχείων.',
    cancelled: 'Ακυρώθηκε',
    journeyCost: 'Κόστος ταξιδιού (Καθαρό)',
    leadMilesCost: 'Κόστος προηγούμενων μιλίων (Καθαρό)',
    waitingTimeCost: 'Κόστος αναμονής (Καθαρό)',
    expensesCost: 'Κόστος εξόδων (Καθαρό)',
    greenFeeCost: 'Κόστος περιβαλλοντικού τέλους (Καθαρό)',
    totalCost: 'Συνολικό κόστος (Καθαρό)',
    downloadJourneyData: 'Κάντε κλικ εδώ για να λάβετε τα δεδομένα των επιλεγμένων ταξιδιών.',
    paid: 'Πληρώθηκε',
    excludedJourneys:
      'Αυτή η διαδικασία εξαιρεί ταξίδια που είναι επί του παρόντος υπό αμφισβήτηση.',
    rejectedStatus: 'Απορρίφθηκε',
    createPdfInvoice: 'Δημιουργία Τιμολογίου PDF',
    createPdf: {
      title: 'Δημιουργία Τιμολογίου PDF για τα επιλεγμένα ταξίδια',
      invoiceNumber: 'Αριθμός Τιμολογίου',
      invoiceDate: 'Ημερομηνία Τιμολογίου',
      chooseAddress: 'Παρακαλώ επιλέξτε διεύθυνση',
      registerDetails: {
        title: 'Εγγραφή Λεπτομερειών',
        heading:
          'Για να δημιουργήσετε το τιμολόγιό σας, χρειαζόμαστε κάποιες επιπλέον πληροφορίες.',
        financial: {
          heading:
            'Ακολουθήστε τα παρακάτω βήματα για να προσθέσετε τις οικονομικές σας λεπτομέρειες:',
          step1:
            'Επιλέξτε το εικονίδιο μενού hamburger {x} που βρίσκεται στην επάνω δεξιά πλευρά της οθόνης σας',
          step2: "Στη συνέχεια επιλέξτε 'Προφίλ'.",
          step3:
            "Στην ενότητα 'Οικονομικές Λεπτομέρειες', κάντε κλικ στο κουμπί επεξεργασίας {x} και εισάγετε τα στοιχεία σας.",
        },
        address: {
          heading:
            'Ακολουθήστε τα παρακάτω βήματα για να προσθέσετε τη διεύθυνση της εταιρείας σας:',
          step1:
            'Επιλέξτε το εικονίδιο μενού hamburger {x} που βρίσκεται στην επάνω δεξιά πλευρά της οθόνης σας.',
          step2: "Στη συνέχεια επιλέξτε 'Προφίλ'.",
          step3:
            "Στην ενότητα 'Διευθύνσεις', κάντε κλικ στο κουμπί συν {x} και εισάγετε τα στοιχεία σας.",
        },
      },
    },
    separateInvoicesRequired:
      'Τα ταξίδια με οχήματα που έχουν περισσότερα από 10 καθίσματα πρέπει να τιμολογούνται χωριστά από τα ταξίδια με οχήματα που έχουν λιγότερα από 10 καθίσματα.',
    invoiceNumberFieldNameLabel: 'Αριθμός τιμολογίου Όνομα πεδίου',
    totalNetAmountFieldNameLabel: 'Όνομα πεδίου Συνολικού Καθαρού Ποσού',
  },
  compliance: {
    introduction:
      'Παρακάτω είναι μια περίληψη των κύριων εγγράφων που πρέπει να υπάρχουν και να είναι καταχωρημένα σε εμάς. Αν έχετε κάποια ερώτηση σχετικά με οποιοδήποτε από αυτά τα έγγραφα, παρακαλούμε επικοινωνήστε μαζί μας στο 03333 207 100',
    noRulesFound: 'Δεν βρέθηκαν κανόνες συμμόρφωσης',
  },
  contactItem: {
    generalManager: 'Γενικός διευθυντής',
    reception24Hour: 'Υποδοχή / Ρεσεψιόν',
    revenueSales: 'Έσοδα / Πωλήσεις',
    twentyFourHourEmail: '24ωρο email',
    notification: 'Γνωστοποίηση',
    other: 'Αλλα',
  },
}
