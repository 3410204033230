
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class TableActions extends Vue {
  // props // ***
  @Prop({ type: Array })
  actions!: Record<string, any>[]
  @Prop({ type: Object })
  item!: Record<string, any>

  // methods // ***
  onActionClicked(action: Record<string, any>) {
    if (action?.callback) action.callback(this.item)
  }
}
