export default {
  navbar: {
    userGuide: 'Ayuda & Guías',
  },
  settings: {
    emailTypes: {
      generalManager: 'Gerente General',
      reception: 'Recepción/Mesa de Entrada',
      sales: 'Ingresos/Ventas',
      email24: 'Correo Electrónico las 24 Horas',
      notification: 'Notificación',
      other: 'Otro',
    },
  },
  auth: {
    emailAndPasswordDoNotMatchOurRecords:
      'El correo electrónico y la contraseña no coinciden con nuestros registros',
    forgottenYourPassword: '¿Ha olvidado su contraseña?',
    forgottenYourUsername: '¿Ha olvidado su nombre de usuario?',
    helpUsFindYourUsername:
      'Proporcione los datos solicitados en el siguiente formulario para ayudarnos a encontrar su nombre de usuario.',
    helpUsResetYourPassword:
      'Indíquenos su nombre de usuario para ayudarnos a restablecer su contraseña.',
    login: 'Inicio de sesión',
    logIn: 'Iniciar sesión',
    logout: 'Cierre de sesión',
    logOut: 'Cerrar sesión',
    password: 'Contraseña',
    passwordReset: 'Restablecer contraseña',
    pleaseEnterThePrimaryEmailAddress:
      'Introduzca la dirección de correo electrónico principal de su cuenta.',
    pleaseEnterYourUsername: 'Introduzca su nombre de usuario...',
    pleaseEnterYourPassword: 'Introduzca su contraseña...',
    primaryEmailAddress: 'Dirección de correo electrónico principal',
    unfortunatelyTheSystemCouldNotAuthenticateYou:
      'lamentablemente, el sistema no ha podido autentificarle.  Esto suele deberse a que su sesión ha expirado.  Inicie sesión de nuevo para continuar.',
    username: 'Nombre de usuario',
    usernameReminder: 'Recordatorio de nombre de usuario',
    youAreBeingLoggedOut: 'Se está cerrando la sesión.',
    yourSessionHasExpired: 'Su sesión ha expirado.  Inicie sesión de nuevo.',
  },
  availability: {
    availability: 'Disponibilidad',
    addAvailability: 'Añada disponibilidad',
    addingAvailability: 'Añadir disponibilidad',
    accessible: 'Accesible',
    double: 'Doble',
    family: 'Familiar',
    single: 'Individual',
    twin: 'Dos camas',
    room: 'Habitación',
    rooms: 'Habitaciones',
    numberAvailable: 'No disponible',
    noCmacRate: 'Sin tarifa CMAC',
    rate: 'Tarifa',
    useCmacAgreedRates: 'utilizar las tarifas acordadas con CMAC',
    fullyBooked: 'Todo reservado',
    helpText1: 'Para introducir la disponibilidad, seleccione una fecha o un intervalo de fechas.',
    helpText2: 'Para seleccionar una fecha, haga clic en la casilla correspondiente.',
    helpText3: 'Para seleccionar un periodo, haga clic en una segunda casilla diferente.',
    helpText4:
      'las fechas seleccionadas aparecerán en rojo.  Para eliminar una fecha que haya seleccionado, haga clic en la casilla de esa fecha.',
    helpText5:
      'Haga clic en el botón Añadir disponibilidad e introduzca el número total de habitaciones disponibles.  Si ya ha enviado tarifas anteriormente, utilizaremos las tarifas acordadas con CMAC.  De lo contrario, introduzca la tarifa de solo habitación para conocer la disponibilidad.',
    helpText6:
      'Puede añadir varias entradas para la misma fecha si tiene habitaciones disponibles con tarifas diferentes.',
    publicHeader: 'Grupo CMAC - solicitud de disponibilidad -',
    publicInstruction1:
      'Introduzca el número de cada tipo que tiene disponible y haga clic en "Enviar"',
    publicInstruction2:
      'Siempre puede utilizar este enlace para volver más tarde y actualizar esta información. Expirará en',
    ratesIncludeTax: 'Asegúrese de que la tarifa INCLUYE impuestos/IVA.',
    submittedBy: 'Presentado por ',
    updatedBy: 'Actualizado por ',
    specificallyFor: 'Específicamente para ',
    parkFlyRoomOnly: 'Solo habitación para dormir y volar',
    parkFly4Bundle: 'Habitación Sleep & Fly + Estacionamiento para 4 días',
    parkFly8Bundle: 'Habitación Sleep & Fly + Estacionamiento para 8 días',
    parkFly15Bundle: 'Habitación Sleep & Fly + 15 días de estacionamiento',
    parkFlyToCloseOut:
      'Para cerrar una fecha, desactive los elementos relevantes. Para volver a habilitar la reserva, deberá volver a cargar su hoja de cálculo de precios.',
  },
  cmac: {
    actionedBy: 'Realizado por',
    addAddress: 'Agregar Dirección',
    addEmailAddress: 'Agregar Correo Electrónico',
    addTelephoneNumber: 'Agregar Número de Teléfono',
    addAComment: 'Añada un comentario',
    addComment: 'Añadir comentario',
    addExpense: 'Agregar Gasto',
    addingAComment:
      'Añadir un comentario pondrá este viaje en disputa.,  Utilícelo solo para diferencias que no aparezcan en la línea de viaje. Incluya todos los detalles, por ejemplo, kilometraje y motivo de la consulta.',
    agreed: 'Acordado',
    allOpen: 'Todo abierto',
    amend: 'Modificar',
    applies: 'Se aplica',
    apply: 'Aplicar',
    assignedOrganisation: 'Organización asignada',
    availability: 'Disponibilidad',
    awaitingResponseFromSupplier: 'Esperando respuesta del proveedor',
    billingAddress: 'Dirección de Facturación',
    bulkUpload: 'Carga masiva',
    bulkUploadHistory: 'Historial de cargas masivas',
    cancelled: 'Cancelado',
    closed: 'Cerrado',
    cmac: 'CMAC',
    cmacReference: 'Referencia de CMAC',
    companyDetails: 'Datos de la empresa',
    companyFinancialDetails: 'Datos financieros',
    companyOnBoardingDocuments: 'Documentos de incorporación a la empresa',
    companyNumber: 'Número de empresa',
    compliance: 'Cumplimiento',
    costConfirmation: 'Confirmación del coste',
    costVatBreakDown: 'Desglose del coste de IVA',
    coverage: 'Cobertura',
    coverageAreas: 'Zonas de cobertura',
    currentlyImpersonating: 'Actualmente impersonating',
    customDates: 'Fechas personalizadas',
    customerName: 'Nombre del cliente',
    customerOrganisation: 'Organización del clientes',
    departureDate: 'Fecha de salida',
    depotAddress: 'Dirección del Depósito',
    dispute: 'Disputa',
    disputed: 'Disputado',
    disputeDetails: 'Detalles de la disputa',
    disputeEntry: 'Anotación de la disputa',
    disputeEntries: 'Anotaciones de las disputas',
    disputeId: 'Identificación de la disputa',
    disputes: 'Disputas',
    documentType: 'Tipo de documento',
    driver: 'Conductor',
    driverDetails: 'Datos del conductor',
    editOpenJourneys: 'Editar viajes abiertos',
    endImpersonation: 'Terminar impersonation',
    endTime: 'Hora de finalización',
    disputesAwaitingAction: 'Disputas Esperando Acción',
    editAddress: 'Editar Dirección',
    editEmailAddress: 'Editar Correo Electrónico',
    editTelephoneNumber: 'Editar Número de Teléfono',
    editJourney: 'Editar viaje',
    expenseItem: {
      meetAndGreet: 'Encuentro y saludo',
      parking: 'Estacionamiento',
      toll: 'Peaje',
      greenFee: 'Tasa ecológica',
      misc: 'Varios',
      amount: 'Monto',
    },
    expenses: 'Gastos',
    expired: 'Vencido',
    expiryDate: 'Fecha de vencimiento',
    expiryDateRequired: 'Fecha de vencimiento requerida',
    extras: 'Extras',
    fare: 'Tarifa',
    farePriceCalculation: 'Cálculo del precio de la tarifa',
    financialDetails: {
      accountName: 'Nombre de la Cuenta',
      bankAddress: 'Dirección del Banco',
      ibanNumber: 'Número IBAN',
      currency: 'Moneda',
    },
    fixedPrices: 'Precios fijos',
    greenFee: 'Green fee',
    holidayRates: 'Tarifas de días festivos',
    holidays: 'Vacaciones',
    holidayUplift: 'Mejora de las vacaciones',
    holidayUpliftDescription:
      'Estas fechas personalizadas se aplican a TODAS las entradas de la tarjeta de tarifas, a menos que se anulen por una fecha personalizada específica de la entrada',
    hotelMarkupRates: 'Tarifas de margen hotelero',
    hotelProfile: 'Perfil del hotel',
    hotelRatesTitle: 'Tarifas del hotel',
    ifYouNeedToChangeHowWeKnowYouInOurSystemPleaseCallTheSupplierRelationsTeamOn:
      'Si necesita cambiar la forma en que le conocemos en nuestro sistema, llame al equipo de relaciones con los proveedores',
    impersonating: 'Impersonating',
    inProgress: 'En curso',
    invoiceId: 'Identificación de factura',
    invoices: 'Facturas',
    job: 'Trayecto | Trayectos',
    jobEntryId: 'Identificación de entrada de trayecto',
    jobTypes: 'Tipos de trayecto',
    journey: 'Viaje',
    journeys: 'Viajes',
    journeyChargeMethod: 'Método de cobro por viaje',
    lastUpdated: 'Última actualización',
    leadMileage: 'Kilometraje muerto',
    leadMileageCost: 'Coste del kilometraje muerto',
    mileage: 'Kilometraje',
    mileageRates: 'Tarifas de kilometraje',
    minimumPrice: 'Precio mínimo',
    noCustomDates: 'Sin fechas personalizadas',
    open: 'Abierto',
    noOfPax: 'Nº de pasaj.',
    orAlternativelyEmail: 'o correo electrónico alternativo',
    otherOrganisations: 'Otras organizaciones',
    passenger: 'Pasajero',
    parking: 'Aparcamiento',
    parkingExpenses: 'Gastos de aparcamiento',
    pax: 'Pasajeros',
    percentageOfOriginalCostPrice: 'Porcentaje del precio de coste original',
    permits: {
      currentHeading: 'PERMISOS ACTUALES',
      openHeading: 'ABIERTO A SOLICITUDES',
      title: 'Permisos',
      claimed: 'Solicitado',
      status: 'Estado',
      expires: 'Vence',
      apply: 'Solicitar ya',
      outstandingAmendment: 'Hay una solicitud de modificación pendiente para este permiso.',
      noClaimedPermits: 'No hay permisos solicitados.',
      noOpenApplications: 'No hay solicitudes abiertas.',
      download: 'Descargar',
      print: 'Imprimir permiso',
      amend: 'Modificar',
    },
    pickupDateTime: 'Fecha/Hora recogida',
    pleaseAddAComment: 'Añada un comentario',
    pleaseContactXForAssistance: 'Póngase en contacto con {x} para obtener ayuda',
    pleaseSelectAXToImpersonate: 'Seleccione un/una {x} para impersonate',
    policiesAndTerms: 'Políticas y condiciones',
    pricingInformation: 'Información sobre precios',
    processing: 'Procesando',
    profile: 'Perfil',
    psvarStatus: 'Estado Psvar',
    rate: 'Tarifa',
    rateCards: 'Fichas de tarifas',
    referredToOperations: 'Remitido a operaciones',
    referredToSupplierRelations: 'Remitido a relaciones con los proveedores',
    referredToPricingManager: 'Remitido al gerente de precios',
    regions: 'Regiones',
    registration: 'Registro',
    responseReceivedFromSupplier: 'Respuesta recibida del proveedor',
    selectDocumentType: 'Seleccionar tipo de documento',
    selectUserToImpersonate: 'Seleccionar usuario a impersonate',
    standDownChargeMethod: 'Método de cobro cuando cliente no aparece',
    standDownPrice: 'Precio cuando cliente no aparece',
    startTime: 'Hora de inicio',
    statementId: 'Identificación de declaración',
    stoodDown: 'No apareció',
    stoodDownWithinXMinutesOfDepartureDate: 'No apareció a {x} minutos de la fecha de salida',
    summary: 'Resumen',
    supplierName: 'Nombre del proveedor',
    supplierRef: 'Ref. proveedor',
    termsAndConditions: 'Condiciones generales',
    thankYouForLoggingInToTheSupplierPortal:
      'Gracias por iniciar sesión en el portal de proveedores',
    tierType: 'Tipo de nivel',
    updateFailed: 'Falló la actualización',
    updateSuccessful: 'Actualización tuvo éxito',
    userDetails: 'Datos del usuario',
    hotelDetails: 'Datos del hotel',
    vatNumber: 'Número de IVA',
    vatTreatment: 'Tratamiento de IVA',
    vehicle: 'Vehículo',
    vehicleRates: 'Tarifas de vehículos',
    vias: 'por',
    viewAll: 'Ver todo',
    waypoint: 'Parada | Paradas',
    waitingTime: 'Tiempo de espera',
    waitingTimeCost: 'Coste del tiempo de espera',
    waitingTimeFree: 'Tiempo de espera gratuito',
    waitingTimeMinutes: 'Minutos de tiempo de espera',
    waitingTimeRate: 'Tarifa de tiempo de espera',
    withinXMinutes: 'En {x} minutos',
    vehicles: 'Vehículos',
    vehicleRegistration: 'Registro de Vehículo',
    vehicleType: 'Tipo de vehículo',
    yourAccountIsCurrentlyInactive: 'Su cuenta está actualmente inactiva',
    yourReference: 'Su referencia',
    total: 'Total',
    invoiceuploads: 'Facturas subidas',
    hotelRates: {
      validBetween: 'Válido entre',
      addNewRate: 'Añadir tarifas',
      addNewRateText: 'Hay una nueva solicitud de tarifa pendiente para esta organización.',
      noRatesTextPrepend:
        'No hay tarifas vigentes para su organización. Póngase en contacto con nosotros en',
      noRatesTextAppend: 'para organizar el envío de sus tarifas.',
      hasRatesText:
        'A continuación figuran las tarifas actuales que tenemos en nuestro sistema.  Si necesita hacer algún cambio, póngase en contacto con nosotros en ',
      profile: 'Perfil',
      profileStarRating: 'Clasificación por estrellas',
      profileUnkown: 'Desconocido',
      profileYes: 'Sí',
      profileNo: 'No',
      profileYesFree: 'Sí, libre',
      profileNumberOfRooms: 'Número de habitaciones',
      profileAccessibleRooms: 'Habitaciones accesibles',
      profileFamilyRooms: 'Habitaciones familiares',
      profileHotelBus: 'Autobús del hotel',
      profileParking: 'Aparcamiento',
      profileSpa: 'Spa',
      profileSwimmingPool: 'Piscina',
      profileGym: 'Gimnasio',
      profileRestaurant: 'Restaurante',
      commissionRate: 'Tarifas de comisiones',
      commissionRooms: 'Habitaciones',
      commissionFood: 'Comida',
      commissionDynamic: 'Dinámica',
      editText: 'Ficha de tarifas del hotel',
      editProfileText: 'Editar datos del hotel',
      ratesValidDatesText: 'Las tarifas son aplicables entre estas fechas',
      ratesValidFrom: 'Válido desde',
      ratesValidTo: 'Válido hasta',
      rates: 'Tarifas',
      ratesType: 'Tipo',
      ratesRoomText:
        'Tarifas por habitación para pasajeros con retraso en el vuelo y tripulación de cabina',
      ratesRoomType: 'Tipo de habitación',
      ratesMidweekText: 'Habitación entre semana solamente (domingo-jueves)',
      ratesDayLet: 'Alquiler diurno',
      ratesWeekendText: 'Habitación para el fin de semana solamente (viernes-sábado)',
      ratesRoomTwinDouble: 'Doble con dos camas/doble',
      ratesRoomSingle: 'Habitación individual',
      ratesRoomTriple: 'Triple',
      ratesRoomFamily: 'Familiar',
      ratesWeekdayRate: 'Tarifa de día entre semana',
      ratesWeekdayDayRate: 'Tarifa por día entre semana',
      ratesWeekendRate: 'Tarifa de fin de semana',
      ratesWeekendDayRate: 'Tarifa por día del fin de semana',
      ratesFoodText: 'Precio de comida y bebidas (incluido té, café y refrescos) por persona',
      ratesContinentalBreakfast: 'Desayuno continental',
      ratesFullBreakfast: 'Desayuno completo',
      ratesLunch: 'Almuerzo',
      ratesDinner: 'Cena',
      ratesAgreementText:
        'Introducir estas tarifas creará un acuerdo vinculante entre el Grupo CMAC y su hotel, por lo que debe asegurarse de que solo una persona autorizada rellene el formulario. El hotel será responsable de la persona que rellene el formulario y, en caso de que lo rellene una persona no autorizada, ello no afectará al Grupo CMAC ni a su capacidad para aplicar estas tarifas.',
      ratesTermsConditionsText: 'Marque esta casilla para confirmar que ha leído nuestras ',
      ratesTermsConditionsLinkText: 'condiciones generales',
      submittedHeading: 'Enviado por',
      viewRatesTermsConditionsText: 'Haga clic aquí para ver nuestra',
      outstandingRateRequest: 'Solicitud de tarifa pendiente',
      submittedByName: 'Nombre',
      submittedByEmail: 'Correo electrónico',
      submittedByNameRequired: 'Por favor, ingrese su nombre.',
      submittedByEmailRequired: 'Por favor, ingrese su dirección de correo electrónico.',
      submittedSuccess: 'Gracias por enviar sus tarifas. Esperamos trabajar con usted.',
      errorSaving:
        'Hubo un error al guardar sus tarifas. Comuníquese con hotelrelations@cmacgroup.com para obtener asistencia.',
    },
    cmacRef: 'Ref. CMAC',
    yourRef: 'Su ref.',
    noNotificationEmailAddress: 'No tienes dirección de correo electrónico de notificación',
    leadMiles: 'Millas Adicionales',
    fareNet: 'Tarifa (Neto)',
    leadMilesCost: 'Costo de Millas Adicionales (Neto)',
    totalCost: 'Costo Total (Neto)',
    appliesOnDays: 'Aplica en días',
    noVehicleRates: 'Sin tarifas de vehículos',
    markup: 'Margen',
    addedPrice: 'Precio Añadido',
    noHolidayRates: 'Sin tarifas de vacaciones',
    standDownRates: 'Tarifas de retirada',
    noStandDownRates: 'Sin tarifas de retirada',
    stoodDownWithin: 'Se detuvo dentro',
    minutesOfDepartureDate: 'minutos de la fecha de salida',
    ofOriginalCostPrice: 'del precio de coste original',
    ofOriginalCostPriceWhicheverIsHigher: 'del precio de costo original, el que sea mayor',
    waitingTimeRates: 'Tarifas de tiempo de espera',
    noWaitingTimeRates: 'Tarifas sin tiempo de espera',
    minutesFree: 'minutos gratis',
    minutes: 'minutos',
  },
  common: {
    about: 'Acerca de',
    accepted: 'Aceptado',
    addComment: 'Añadir coment.',
    add: 'Agregar',
    addVehicle: 'Añadir vehículo',
    actions: 'Acciones',
    addXtoY: 'Añadir {x} a {y}',
    agreed: 'Acordado',
    agreementType: 'Tipo de acuerdo',
    all: 'Todos',
    back: 'Atrás',
    backToTop: 'Volver al principio',
    cancel: 'Cancelar',
    close: 'Cerrar',
    comment: 'Comentario',
    comments: 'Comentarios',
    completed: 'Completado',
    confirm: 'confirmar',
    continue: 'Continuar',
    cost: 'Coste',
    create: 'Crear',
    created: 'Creado',
    createdAt: 'Creado en',
    customer: 'Cliente',
    date: 'Fecha',
    dateRange: 'Intervalo de fechas',
    delete: 'Eliminar',
    departureDateRange: 'Intervalo de fechas de salida',
    description: 'Descripción',
    details: 'Detalles',
    disputed: 'Disputado',
    distance: 'Distancia',
    documents: 'Documentos',
    download: 'Descargar',
    edit: 'Editar',
    edited: 'Editado',
    editVehicle: 'Editar vehículo',
    email: 'Correo electrónico',
    enabled: 'habilitado',
    error: 'Error',
    example: 'Ejemplo',
    expired: 'Vencido',
    expires: 'Vence',
    failed: 'Hubo un fallo',
    file: 'Archivo',
    filename: 'Nombre del archivo',
    filters: 'Filtros',
    from: 'Desde',
    full: 'Completa',
    half: 'Media',
    home: 'Inicio',
    hide: 'Ocultar',
    id: 'Identificación',
    info: 'Información',
    important: 'Importante',
    jobNo: 'N.º de trayecto',
    loading: 'Cargando',
    map: 'Mapa',
    messages: 'Mensajes',
    miles: 'Millas',
    minutes: 'Minutos',
    mode: 'Modo',
    name: 'Nombre',
    new: 'Nuevo',
    no: 'No',
    none: 'Ninguno',
    notes: 'Notas',
    null: 'Nulo',
    ok: 'Aceptar',
    open: 'Abierto',
    organisation: 'Organización',
    organisations: 'Organizaciones',
    original: 'Original',
    others: 'Otros',
    password: 'Contraseña',
    pending: 'Pendiente',
    price: 'Precio',
    previouslyUploaded: 'Subido anteriormente',
    processed: 'Procesado',
    processing: 'Procesando',
    protection: 'Protección',
    quantity: 'Cantidad',
    raw: 'Sin procesar',
    readonly: 'Solo lectura',
    reference: 'Referencia',
    refresh: 'Refrescar',
    reset: 'Reiniciar',
    roles: 'Funciones',
    save: 'Guardar',
    search: 'Buscar',
    settings: 'Configuración',
    show: 'Mostrar',
    signedBy: 'Firmado por',
    signedOn: 'Firmado el',
    sortBy: 'Clasificar por',
    status: 'Estado',
    submit: 'Enviar',
    surcharge: 'Recargo',
    test: 'Prueba',
    time: 'Tiempo',
    title: 'Título',
    to: 'hasta',
    total: 'Total',
    type: 'Tipo',
    unknown: 'Desconocido',
    update: 'Actualizar',
    updated: 'Actualizado',
    updatedAt: 'Actualizado en',
    upload: 'Subir',
    uploaded: 'Subido',
    user: 'Usuario',
    username: 'Nombre de usuario',
    users: 'Usuarios',
    website: 'Web',
    weight: 'Peso',
    yes: 'Sí',
    next: 'Siguiente',
    hour: 'hora',
    mile: 'milla',
    or: 'o',
  },
  currencies: {
    gbp: '£',
    eur: '€',
  },
  datetime: {
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miércoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    saturday: 'Sábado',
    sunday: 'Domingo',
    january: 'Enero',
    february: 'Febrero',
    march: 'Marzo',
    april: 'Abril',
    may: 'Mayo',
    june: 'Junio',
    july: 'Julio',
    august: 'Agosto',
    september: 'Septiembre',
    october: 'Octubre',
    november: 'Noviembre',
    december: 'Diciembre',
    today: 'Hoy',
  },
  docs: {
    documentation: 'Documentos',
    faq: 'Preguntas frecuentes',
    frequentlyAskedQuestions: 'Preguntas frecuentes',
    tutorials: 'Tutoriales',
  },
  errors: {
    301: 'No está autorizado a acceder a este recurso.',
    400: 'Lo sentimos, la solicitud no ha tenido éxito.',
    404: 'Lo sentimos, no hemos podido encontrar el recurso que buscaba.',
    500: 'El servidor no pudo procesar la solicitud.',
    error: 'Error',
    nError: '{n} error',
    noResponseFromServer: 'No hay respuesta del servidor',
    noUrl: 'No hay URL',
    sorrySomethingWentWrong: 'Lo siento, algo salió mal',
    uploadFailed: 'Falló al subir',
    uploadFailedWithError: 'Fallo al subir, con error',
  },
  fields: {
    address: 'Dirección',
    addresses: 'Direcciones',
    addressLine1: 'Línea 1 de la dirección',
    addressLine2: 'Línea 2 de la dirección',
    addressLine3: 'Línea 3 de la dirección',
    addressLookup: 'Búsqueda de Dirección',
    city: 'Ciudad',
    country: 'País',
    county: 'Condado/región',
    description: 'Descripción',
    driverName: 'Nombre del conductor',
    driverPhone: 'Número de teléfono del conductor',
    driverRegistration: 'Registro del conductor',
    email: 'Correo electrónico',
    emailAddress: 'Dirección de correo electrónico',
    emailAddresses: 'Direcciones de correo electrónico',
    home: 'Casa',
    mobile: 'Móvil',
    name: 'Nombre',
    number: 'Número',
    phoneNumber: 'Número de teléfono',
    postCode: 'Código postal',
    region: 'Región',
    registrationNumber: 'Número de registro',
    telephoneNumber: 'Número de teléfono',
    telephoneNumbers: 'Números de teléfono',
    title: 'Título',
    titles: {
      dr: 'Dr.',
      lady: 'Señora',
      lord: 'Señor',
      mr: 'Sr.',
      miss: 'Srta.',
      mrs: 'Sra.',
      ms: 'Sra.',
      mx: 'Sx.',
      prof: 'Prof.',
      sir: 'Señor',
    },
    town: 'Población',
    work: 'Trabajo',
  },
  onboarding: {
    documents: {
      public_insurance: 'Certificado de Seguro de Responsabilidad Pública',
      liability: 'Certificado de seguro de responsabilidad del empleador',
      motor_insurance: 'Certificado de seguro de automóvil',
      gdpr: 'Política GDPR',
      small_operator_license: 'Licencia de operador - vehículos pequeños',
      large_operator_license: 'Licencia de operador - vehículos grandes',
      upload: 'Subir documento',
      download: 'Descargar documento',
      file: 'Nombre de archivo',
      document: 'Tipo de documento',
      created: 'Creado el',
      expires: 'Expira el',
    },
    agreement: 'He leído y acepto los términos y condiciones impresos arriba.',
    mustAgree: 'Debe aceptar los términos y condiciones para utilizar la aplicación.',
    welcomeHeader: 'Bienvenido al registro de proveedores de CMAC',
    country: 'Nombre del país',
    legalCompanyName: 'Razón social',
    tradingName: 'Nombre comercial',
    companyRegistrationNumber: 'Número de empresa',
    registeredAddress: 'Domicilio social',
    operatingAddress: 'Dirección de operaciones',
    currency: 'Cierre de sesión',
    logOut: 'Cerrar sesión',
    smallVehicle:
      'Vehículo pequeño (taxi, vehículo para 7 pasajeros,vehículo para personas de movilidad reducida, taxi negro)',
    largeVehicle: 'Vehículo grande (minibús y autocar)',
    fleetSize: 'Tamaño de la flota',
    emailAddress: 'Dirección de correo electrónico',
    mainContactNumber: 'Introduzca su contraseña...',
    primaryEmailAddress: 'Dirección de correo electrónico principal',
    successResult: 'La cuenta se ha creado con éxito, compruebe su bandeja de entrada',
    failResult:
      'No podemos crear su cuenta en este momento, ya existe una cuenta con la información que nos ha proporcionado, póngase en contacto con supplier.relations@cmacgroup.com para obtener más información.',

    progressHeader: 'Gracias por enviar sus datos para ser proveedor de CMAC.',
    progressMainText:
      'Para avanzar en la tramitación de su solicitud, asegúrese de que ha cumplimentado todos los datos de las secciones Datos financieros y Documentos de incorporación de la empresa.',
    progressSubText:
      'Una vez que hayamos recibido esta información, podremos pasar su solicitud a la siguiente fase.',
    progressBarText: 'Progreso en la incorporación',
    progressCompleted:
      'Gracias por enviar todos sus documentos. Uno de nuestro equipo revisará su envío y se comunicará con usted pronto para avanzar al siguiente paso.',
    rejected:
      'Lamentablemente, su solicitud no ha sido exitosa. Comuníquese con nuestro equipo de relaciones con los proveedores para obtener más información.',
  },
  plurals: {
    comments: 'Comentario | Comentarios',
    journeys: 'viaje| Viajes',
    results: 'Resultado | Resultados',
    resultsFound: 'Resultado encontrado | Resultados encontrados',
    rows: 'Fila | Filas',
    suppliers: 'Proveedor | Proveedores',
  },
  ui: {
    anNewVersionOfTheApplicationIsAvailable: 'Una nueva versión de la aplicación está disponible',
    appName: 'Portal de proveedores',
    approve: 'Aprobar',
    approveAllSelectedRows: 'Aprobar todas las filas seleccionadas',
    changeProfilePicture: 'Cambiar foto de perfil',
    chooseX: 'Eligir {x}',
    clear: 'Eliminar',
    clearFilters: 'Eliminar filtros',
    clickToCopyToClipboard: 'Haga clic para copiar en el portapapeles',
    clickToSortByX: 'Haga clic para ordenar por {x}',
    closeDrawer: 'Cerrar recuadro',
    currentlyUnavailable: 'Actualmente no disponible',
    dashboard: 'Panel de información',
    document: 'Documento',
    dragYourXHereToUploadIt: 'Arrastre su {x} aquí para subirlo',
    export: 'Exportar',
    exportToCsv: 'Exportar a CSV',
    file: 'archivo',
    files: 'Archivos',
    fileSizeTooBig: 'Tamaño de archivo demasiado grande',
    footer: 'Pie de página',
    home: 'Inicio',
    homePage: 'Página Principal',
    itemsPerPage: 'Elementos por página',
    navigation: 'Navegación',
    noDetailsToDisplay: 'No hay detalles que mostrar',
    noFileSelected: 'No se ha seleccionado ningún archivo',
    noResultsToShow: 'No hay resultados que mostrar',
    noXProvided: 'No se ha proporcionado {x}',
    orClickToBrowse: 'O haga clic para buscar',
    page: 'Página',
    pageSize: 'Tamaño de página',
    pleaseSeletFromTheFollowing: 'Seleccione una de las siguientes opciones',
    pleaseSelectAnX: 'Seleccione un/una {x}',
    pleaseSelectAX: 'Seleccione un/una {x}',
    pleaseSelectNXOnly: 'Seleccione {n} {x} solamente',
    profilePicture: 'Foto de perfil',
    resultsPerPage: 'Resultados por página',
    saveChanges: 'Guardar cambios',
    selectAll: 'Seleccionar todo',
    selectX: 'Seleccionar {x}',
    selectADifferentX: 'Seleccionar un/una {x} diferente',
    spreadsheet: 'Hoja de cálculo',
    theXHasBeenDeleted: '{x} ha sido eliminado',
    thisWillPutXInDispute: 'Esto pondrá a {x} en disputa',
    updateX: 'Actualizar {x}',
    uploadBeingProcessed: 'Se está procesando la subida',
    uploadStatus: 'Estado de subida',
    uploading: 'Subiendo',
    uploadNewX: 'Subir nuevo/a {x}',
    uploadX: 'Subir {x}',
    userProfile: 'Perfil de usuario',
    version: 'versión',
    xMissing: 'Falta {x}',
    policySignedX: 'Gracias por firmar {x}',
  },
  validations: {
    canOnlyContainLettersAndNumbers: 'Solo puede contener letras y números',
    canOnlyContainLettersNumbersAndTheFollowingCharactersX:
      'Solo puede contener letras, números y los siguientes caracteres: {x}',
    canOnlyContainNumbers: 'Solo puede contener números',
    checkboxConfirm: 'Marque la casilla para confirmar que acepta estas condiciones generales',
    maxNCharacters: 'Máx. {n} caracteres',
    minNCharacters: 'Mín {n} caracteres',
    mustBeAnInteger: 'Debe ser un número entero',
    mustBeAFloat: 'Debe ser un decimal',
    mustBeAValidEmailAddress: 'Debe ser una dirección de correo electrónico válida',
    mustBeAValidTelephoneNumber: 'Debe ser un número de teléfono válido',
    mustBeAWholeNumber: 'Debe ser un número entero',
    mustBeExactlyNCharacters: 'Debe tener exactamente {n} caracteres',
    mustBeGreaterThanN: 'Debe ser mayor que {n}',
    mustBeGreaterThanOrEqualToN: 'Debe ser mayor o igual que {n}',
    mustBeLessThanN: 'Debe ser menor que {n}',
    mustBeLessThanOrEqualToN: 'Debe ser menor o igual que {n}',
    mustBeRoundedToExactlyNDecimalPlaces: 'Debe redondearse exactamente a {n} decimales',
    mustBeRoundedToNDecimalPlaces: 'Debe redondearse a {n} decimales',
    mustHaveASingleDecimalPoint: 'Debe tener un solo punto decimal',
    requiresBooleanValue: 'Requiere valor booleano',
    thisDateMustBeInTheFuture: 'Esta fecha debe ser en el futuro',
    thisDateMustBeInThePast: 'Esta fecha debe ser en el pasado',
    thisExceedsTheLeadMileageLimit: 'Esto excede el límite de kilometraje muerto',
    thisFieldIsRequired: 'Este campo es obligatorio',
    thisFieldMustBeAValidDate: 'Este campo debe ser una fecha válida',
    thisFieldMustContainAX: 'Este campo debe contener {x}',
    thisFieldMustContainAnX: 'Ete campo debe contener {x}',
    mustBeUkBankSortCode: 'El código de clasificación bancaria debe tener el formato 00-00-00',
    mustBeUkBankAccountNumber: 'El número de cuenta bancaria del Reino Unido debe tener 8 dígitos',
    mustBeAtLeastOneChecked: 'Marque uno o ambos de los valores',
  },
  invoiceUpload: {
    invoiceNo: 'N.º de factura',
    reference: 'Referencia',
    uploadDate: 'Fecha de subida',
    invoiceDate: 'Fecha de factura',
    dueDate: 'Fecha de vencimiento',
    total: 'Total',
    currency: 'Divisa',
    status: 'Estado',
    comments: 'Comentarios',
    file: 'Archivo',
    selectJourneys: 'Seleccionar viajes',
    uploadInvoice: 'Subir factura',
    confirm: 'confirmar',
    uploadingAs: 'Actualmente está subiendo como',
    atLeastOneJourney: 'Debe seleccionar al menos un viaje',
    errorUploadingFile: 'Se ha producido un error al subir y analizar el archivo.',
    uploadInFormat: 'Suba su factura en formato PDF.',
    selectFile: 'Seleccione un archivo para subir',
    uploadingAnalyzing: 'Subiendo y analizando',
    invoiceReference: 'Referencia de factura',
    invoice: 'Factura',
    cmacReference: 'Referencia de CMAC',
    yourReference: 'Su referencia',
    journeysSelectionJourney: 'viaje | viajes',
    journeySelectionSelected: 'Seleccionado',
    net: 'Neto',
    tax: 'Impuesto',
    gross: 'Bruto',
    expenses: 'Gastos',
    details: 'Detalles',
    journey: 'Viaje',
    departureDate: 'Fecha de salida',
    cmacRef: 'Referencia de CMAC',
    yourRef: 'Su ref.',
    invoiceAccepted: 'Factura validada y aceptada',
    invoiceDoesNotMatch: 'Por favor revise y vuelva a enviar',
    stillUpload: 'Todavía puede subir esta factura, pero requerirá una investigación. ',
    paymentDelayed: 'El pago se retrasará hasta que esto esté completo.',
    totalNetDoesNotMatch:
      'El importe neto total de la factura no coincide con el precio neto total de todos los viajes seleccionados.',
    expected: 'Previsto',
    totalGross: 'Total bruto',
    totalNet: 'Total neto',
    totalTax: 'Impuesto total',
    notFound: 'No se ha encontrado',
    invoiced: 'Facturado',
    underDispute: 'En disputa',
    notUnique:
      'Ya existe un archivo con este nombre. Por favor, elige un nombre de archivo diferente.',
    invalidCharacters:
      'El nombre del archivo contiene caracteres no válidos. No se permiten los siguientes.',
    invalidFileExtension:
      'Este tipo de archivo no es válido. Se permiten los siguientes tipos de archivos.',
    cancelled: 'Cancelado',
    journeyCost: 'Costo del Viaje (Neto)',
    leadMilesCost: 'Costo de Millas Adicionales (Neto)',
    waitingTimeCost: 'Costo de Tiempo de Espera (Neto)',
    expensesCost: 'Costo de Gastos (Neto)',
    greenFeeCost: 'Costo de Tarifa Ecológica (Neto)',
    totalCost: 'Costo Total (Neto)',
    downloadJourneyData: 'Haz clic aquí para descargar los datos de los viajes seleccionados.',
    paid: 'Pagado',
    excludedJourneys: 'Este proceso excluye viajes que se encuentran actualmente en disputa.',
    rejectedStatus: 'Rechazada',
    createPdfInvoice: 'Crear Factura en PDF',
    createPdf: {
      title: 'Crear Factura en PDF para los viajes seleccionados',
      invoiceNumber: 'Número de Factura',
      invoiceDate: 'Fecha de la Factura',
      chooseAddress: 'Por favor, elija una dirección',
      registerDetails: {
        title: 'Detalles de Registro',
        heading: 'Para crear su factura, necesitamos información adicional.',
        financial: {
          heading: 'Siga los pasos a continuación para añadir sus detalles financieros:',
          step1:
            'Seleccione el icono del menú hamburguesa {x} ubicado en la parte superior derecha de su pantalla',
          step2: "Luego seleccione 'Perfil'.",
          step3:
            "En la sección 'Detalles Financieros', haga clic en el botón de editar {x} e introduzca sus datos.",
        },
        address: {
          heading: 'Siga los pasos a continuación para añadir la dirección de su empresa:',
          step1:
            'Seleccione el icono del menú hamburguesa {x} ubicado en la parte superior derecha de su pantalla.',
          step2: "Luego seleccione 'Perfil'.",
          step3:
            "En la sección 'Direcciones', haga clic en el botón de más {x} e introduzca sus datos.",
        },
      },
    },
    separateInvoicesRequired:
      'Los viajes con vehículos de más de 10 asientos deben facturarse por separado de los viajes con vehículos de menos de 10 asientos.',
    invoiceNumberFieldNameLabel: 'Número de factura Nombre del campo',
    totalNetAmountFieldNameLabel: 'Nombre del campo Importe neto total',
  },
  compliance: {
    introduction:
      'A continuación se presenta un resumen de los documentos clave que deben estar en su lugar y registrados con nosotros. Si tiene alguna pregunta sobre alguno de estos documentos, contáctenos al 03333 207 100',
    noRulesFound: 'No se encontraron reglas de cumplimiento',
  },
  contactItem: {
    generalManager: 'Gerente general',
    reception24Hour: 'Recepción las 24 horas',
    revenueSales: 'Ingresos / Ventas',
    twentyFourHourEmail: 'Correo electrónico las 24 horas',
    notification: 'Notificación',
    other: 'Otro',
  },
}
