
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class UploadDialog extends Vue {
  // props // ***
  @Prop({ type: Boolean })
  loading!: boolean
  @Prop()
  title!: string
  @Prop({ type: Boolean })
  value!: boolean

  //data
  files: Array<File> = []
  docUploadResults: Record<string, unknown> = { isSuccess: true, numberOfRecords: null }

  // computed // ***
  get orgSelected(): boolean {
    return !!this.selectedSupplier?.id
  }
  get selectedSupplier() {
    return this.$store.getters['suppliers/selectedSupplier']
  }
  @Watch('value')
  onValueChanged() {
    this.files = []
  }
  // methods // ***
  uploadFile() {
    let formData = new FormData()
    formData.append('files', this.files[0])
    formData.append('organisationId', this.selectedSupplier.id)
    this.$emit('filesAdded', formData)
  }
  docChange(file: File) {
    this.files = []
    this.files.push(file)
  }
  close() {
    this.$emit('close')
  }
}
