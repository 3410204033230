import FEATURES from '@/config/features'
import { config } from '@/plugins/env'
import api from '@/utilities/api'
import { camelise } from '@/utilities/functions'
import Cookies from 'js-cookie'
import { DateTime } from 'luxon'
import { UserManager, WebStorageStateStore } from 'oidc-client'

const { CLIENT_ID, STS_DOMAIN } = config
const CLIENT_URL = window.location.origin + '/'

const defaultConfig = (): Record<string, any> => ({
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  authority: STS_DOMAIN,
  client_id: CLIENT_ID,
  redirect_uri: `${CLIENT_URL}callback.html`,
  automaticSilentRenew: true,
  silent_redirect_uri: `${CLIENT_URL}silent-renew.html`,
  response_type: 'code',
  scope: 'openid profile email supplier offline_access',
  post_logout_redirect_uri: CLIENT_URL,
  filterProtocolClaims: true,
  monitorSession: false,
})

const defaultApiUser = (): Record<string, any> => ({
  id: null,
  isDisabled: false,
  organisation: {},
  organisations: [],
  roles: [],
  supplierOrganisationAgreements: [],
  supplierPortalConfiguration: '{}',
})

const defaultAuthUser = (): Record<string, any> => ({
  // properties // ***
  access_token: null,
  expires_at: null,
  id_token: null,
  profile: {
    amr: [],
    auth_time: null,
    email: null,
    email_verified: null,
    idp: null,
    name: null,
    preferred_username: null,
    s_hash: null,
    sid: null,
    sub: null,
  },
  refresh_token: null,
  scope: null,
  session_state: null,
  state: null,
  token_type: null,

  // getters // ***
  expired: null,
  expires_in: null,
  scopes: [],
})

const authUserGetterVals = (user: Record<string, any>) => ({
  expired: user.expired,
  expires_in: user.expires_in,
  scopes: user.scopes,
})

const defaultImpersonatableUsers = (): Record<string, any>[] => []

class AuthClient {
  // constructor // ***
  constructor() {
    this.config = defaultConfig()
    this.userManager = new UserManager(this.config)
    this.userManager.events.addUserLoaded((user: any) => {
      this.setAuthUser(user)
    })
    this.userManager.events.addUserUnloaded(() => {
      this.setAuthUser(defaultAuthUser())
    })
  }

  // properties // ***
  authUser: Record<string, any> = defaultAuthUser()
  authApiUser: Record<string, any> = defaultApiUser()
  config: Record<string, any> = defaultConfig()
  impersonatableUsers: Record<string, any>[] = defaultImpersonatableUsers()
  impersonatedUser: Record<string, any> = defaultApiUser()
  userManager: Record<string, any>

  // properties // ***
  get authType(): string {
    if (process.env.NODE_ENV === 'development') {
      return 'development'
    }
    return 'oidc'
  }
  get activeUser(): Record<string, any> {
    return this.impersonating ? this.impersonatedUser : this.authApiUser
  }
  get activeUserIsDisabled(): boolean {
    return !!this.activeUser?.isDisabled
  }
  get activeUserIsPermitUser(): boolean {
    return this.isPermitUser(this.activeUser)
  }
  get authApiUserIsSuperAdmin(): boolean {
    return this.isSuperAdmin(this.authApiUser)
  }
  get isOnBoardingUser(): boolean {
    return this.isOnBoarding(this.authApiUser)
  }
  get activeUserIsSuperAdmin(): boolean {
    return this.isSuperAdmin(this.activeUser)
  }
  get authenticated(): boolean {
    if (!Object.prototype.hasOwnProperty.call(this.authUser, 'expired')) return false
    else if (!this.authUser.expired && this.authUser.access_token) return true
    else return false
  }
  get features(): Record<string, any> {
    if (process.env.NODE_ENV === 'development') return FEATURES
    else return camelise(JSON.parse(this.activeUser.supplierPortalConfiguration)) || {}
  }
  get impersonating(): boolean {
    return !!this.impersonatedUser?.id
  }
  get userHasSignedLatestTermsAndConditions(): boolean {
    return this.activeUser?.supplierOrganisationAgreements.every(
      (x: Record<string, any>) => DateTime.fromISO(x.signedDate).isValid
    )
  }

  // methods // ***
  endImpersonation(): void {
    Cookies.remove('impersonatedUser')
    this.impersonatedUser = defaultApiUser()
  }
  async fetchActiveUser(): Promise<Record<string, any>> {
    const res = await api('supplier/user', { auth: true }, undefined).catch(
      (e: Record<string, any>) => {
        throw new Error(String(e))
      }
    )
    const apiUser = res ? res.data : defaultApiUser()
    const user = { ...apiUser, isSuperAdmin: this.isSuperAdmin(apiUser) }
    if (this.impersonating) this.setImpersonatedUser(user)
    else this.setAuthApiUser(user)

    return this.activeUser
  }
  async fetchAuthUser(): Promise<Record<string, any>> {
    const user: Promise<Record<string, any>> = await this.userManager.getUser()
    const u = user || defaultAuthUser()
    this.setAuthUser({ ...u, ...authUserGetterVals(u) })
    return this.authUser
  }
  async fetchImpersonatableUsers(): Promise<Record<string, any>> {
    if (!this.activeUserIsSuperAdmin) return []
    const res = await api('supplier/users', { auth: true }, undefined)
    const impersonatableUsers = res ? res.data : defaultImpersonatableUsers()
    this.setImpersonatableUsers(impersonatableUsers)
    return this.impersonatableUsers
  }
  async fetchImpersonatedUser(user: Record<string, any>): Promise<Record<string, any>> {
    this.setImpersonatedUser({ ...defaultApiUser(), id: user.id })
    return this.fetchActiveUser()
  }
  isPermitUser(user: Record<string, any>): boolean {
    return !!user?.roles.find((r: Record<string, any>) =>
      ['PermitUser', 'PermitAdmin'].includes(r.name)
    )
  }
  isSuperAdmin(user: Record<string, any>): boolean {
    return !!user?.roles.find((r: Record<string, any>) => r.name === 'CmacAdmin')
  }
  isOnBoarding(user: Record<string, any>): boolean {
    return !!user?.roles.find((r: Record<string, any>) => r.name === 'OnBoarding')
  }
  async logIn(): Promise<void> {
    return await this.userManager.signinRedirect()
  }
  async logOut(): Promise<void> {
    return await this.userManager.signoutRedirect().finally(() => {
      this.authUser = defaultAuthUser()
      this.authApiUser = defaultApiUser()
      this.impersonatedUser = defaultApiUser()
    })
  }
  setAuthApiUser(user: Record<string, any>[]): void {
    this.authApiUser = user || defaultApiUser()
  }
  setAuthUser(user: Record<string, any>): void {
    this.authUser = user || defaultAuthUser()
  }
  setImpersonatableUsers(users: Record<string, any>[]): void {
    this.impersonatableUsers =
      users?.map((u: Record<string, any>) => ({ ...u, id: u.userName })) ||
      defaultImpersonatableUsers()
  }
  setImpersonatedUser(user: Record<string, any>): void {
    this.impersonatedUser = user || defaultApiUser()
  }
}

export default new AuthClient()
