import en from '@/i18n/en'
import fr from '@/i18n/fr'
import cz from '@/i18n/cz'
import de from '@/i18n/de'
import el from '@/i18n/el'
import es from '@/i18n/es'
import it from '@/i18n/it'
import nl from '@/i18n/nl'
import pl from '@/i18n/pl'
import pt from '@/i18n/pt'
import ro from '@/i18n/ro'

export default {
  en,
  fr,
  cz,
  de,
  el,
  es,
  it,
  nl,
  pl,
  pt,
  ro,
}
