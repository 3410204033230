
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class MultipleSupplierSelector extends Vue {
  // props // ***
  @Prop({ type: Boolean })
  hideDetails!: boolean
  @Prop()
  label!: string
  @Prop({ default: () => [], type: Array })
  rules!: ((v: string) => boolean | string)[]

  // computed // ***
  get getRules() {
    const msg = 'At least one supplier must be selected.'
    return [(v: any[]) => v.length > 0 || msg, ...this.rules]
  }
  get suppliers(): Record<string, any> {
    return this.$store.getters['suppliers/suppliers']
  }
  get supplierSelect() {
    return {
      disabled: this.suppliers.length <= 1,
      label: this.label || this.$tc('plurals.suppliers', this.suppliers.length),
      items: this.suppliers.map((s: Record<string, any>, index: number) => ({
        text: s.name,
        value: index,
      })),
    }
  }
  get value() {
    return this.$store.getters['suppliers/activeSuppliers']
  }

  // methods // ***
  getChipText(s: string) {
    const max = 15
    if (s.length >= max + 3) return s.slice(0, max) + '...'
    else return s
  }
  onInput(e: (number | string)[]) {
    // force first entry selected instead of empty array
    const val = !e?.length ? [0] : e
    this.$emit('input', val)
    this.$store.commit('suppliers/setActiveSuppliers', val)
    this.$cookies.set('activeSuppliers', val)
  }
}
