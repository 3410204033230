
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class VersionTracker extends Vue {
  // props // ***
  registration: Record<string, any> | null = null
  updateExists = false
  updating = false

  // created // ***
  created() {
    // Listen for our custom event from the SW registration
    document.addEventListener('app-update-available', this.setAppUpdateAvailable, {
      once: true,
    })

    // Prevent multiple refreshes
    navigator.serviceWorker?.addEventListener('controllerchange', () => {
      // Here the actual reload of the page occurs
      window.location.reload()
    })
  }

  // methods // ***
  setAppUpdateAvailable(event: Record<string, any>) {
    // Store the SW registration so we can send it a message
    // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
    // To alert the user there is an update they need to refresh for
    this.registration = event.detail
    this.updateExists = true
  }

  updateApp() {
    // Called when the user accepts the update
    this.updating = true
    // Make sure we only send a 'skip waiting' message if the SW is waiting
    // send message to SW to skip the waiting and activate the new SW
    if (this.registration?.waiting) this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    window.location.reload()
  }
}
