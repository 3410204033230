
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import mapboxgl from 'mapbox-gl'
import {
  forwardGeocode,
  getBoundsFromCoordinates,
  getCenterFromSetOfCoordinates,
  getPolygonFromCollection,
} from '@/utilities/geo'

import { COORDINATES } from '@/utilities/constants'

const { CENTER, ZOOM } = COORDINATES

@Component
export default class CoverageMap extends Vue {
  // props // ***
  @Prop({ type: Number })
  activeLayer!: number | null
  @Prop({ type: Boolean })
  antialias!: boolean
  @Prop({ required: true, type: Array })
  coverageAreas!: Record<string, any>[]
  @Prop({ type: Boolean })
  fly!: boolean

  // data // ***
  center: number[] = CENTER
  container = 'coverageMap'
  loadedLayers: Record<string, boolean> = {}
  map: Record<string, any> | null = null
  mapLoaded = false
  style = 'mapbox://styles/mapbox/streets-v11'
  zoom = ZOOM

  // computed // ***
  get allCoverageAreaCoordinates(): number[][] {
    const { coverageAreas } = this
    const allCoordinates = coverageAreas.length ? [] : [CENTER]
    coverageAreas.forEach(ca => {
      const coordinates = ca.geoJson?.features?.[0].geometry?.coordinates?.[0]?.[0]
      if (!coordinates || !Array.isArray(coordinates)) return
      else
        coordinates.forEach(c => {
          allCoordinates.push(c)
        })
    })
    return allCoordinates
  }
  get boundsFromCoverageAreas(): Record<string, any> {
    return getBoundsFromCoordinates(this.allCoverageAreaCoordinates)
  }
  get centerOfCoverageAreas(): number[] {
    return getCenterFromSetOfCoordinates(this.allCoverageAreaCoordinates)
  }

  // watch // ***
  @Watch('activeLayer')
  onActiveLayerChanged(n: number | null, o: number | null) {
    if (o) this.updateLayer(o)
    if (n) this.updateLayer(n)
  }
  @Watch('coverageAreas')
  onCoverageAreasChanged() {
    if (this.mapLoaded) this.updateMap()
  }
  @Watch('mapLoaded')
  onMapLoadedChanged(n: boolean) {
    if (n) this.updateMap()
  }

  // mounted // ***
  mounted() {
    this.makeMap()
  }

  // methods // ***
  drawCoverageArea(ca: Record<string, any>) {
    const { map } = this
    const { id, geoJson } = ca || {}
    const { fillLayerId, lineLayerId, sourceId } = this.getAllIds(id)
    if (!map?.getSource(sourceId)) map?.addSource(sourceId, getPolygonFromCollection(geoJson))
    if (map?.getLayer(lineLayerId)) map.removeLayer(lineLayerId)
    if (map?.getLayer(fillLayerId)) map.removeLayer(fillLayerId)
    const isActiveLayer = this.activeLayer === id
    map?.addLayer({
      id: fillLayerId,
      type: 'fill',
      source: sourceId,
      paint: {
        'fill-antialias': true,
        'fill-color': isActiveLayer ? '#e6b181' : '#bf93e4',
        'fill-opacity': 0.5,
        'fill-outline-color': isActiveLayer ? '#d1813f' : '#ad83c4',
      },
    })
    map?.addLayer({
      id: lineLayerId,
      type: 'line',
      source: sourceId,
      layout: { 'line-join': 'round', 'line-cap': 'round' },
      paint: { 'line-color': '#bf93e4', 'line-width': 5, 'line-opacity': 0.4 },
    })
    map?.on('click', fillLayerId, (e: Record<string, any>) => {
      this.onLayerClicked({ id, event: e })
    })
    this.loadedLayers[fillLayerId] = true
    this.loadedLayers[lineLayerId] = true
  }
  drawCoverageAreas() {
    const { coverageAreas } = this
    coverageAreas.forEach((ca: Record<string, any>): void => {
      this.drawCoverageArea(ca)
    })
  }
  async fetchCenter(s: string): Promise<number[] | void> {
    const res = await forwardGeocode(s)
    if (!res) return
    return res.data?.features?.[0]?.center
  }
  getAllIds(id: number): Record<string, string> {
    const fillLayerId = this.getFillLayerId(id)
    const lineLayerId = this.getLineLayerId(id)
    const sourceId = this.getSourceId(id)
    return { fillLayerId, lineLayerId, sourceId }
  }
  getCoverageArea(id: number): Record<string, any> | undefined {
    return this.coverageAreas.find((c: Record<string, any>) => c.id === id)
  }
  getFillLayerId(id: number): string {
    return `coverageAreaFillLayer${id}`
  }
  getLineLayerId(id: number): string {
    return `coverageAreaLineLayer${id}`
  }
  getSourceId(id: number): string {
    return `coverageAreaSource${id}`
  }
  async loadCoverageAreas() {
    const { north, south, east, west } = this.boundsFromCoverageAreas
    const southWest = [west, south]
    const northEast = [east, north]
    this.map = await this.map?.fitBounds([southWest, northEast], { animate: this.fly })
    await this.drawCoverageAreas()
  }
  makeMap() {
    this.mapLoaded = false
    if (this.map) {
      this.center = this.centerOfCoverageAreas
      this.zoom = this.map?.getZoom() || ZOOM
    }
    const { antialias, center, container, style, zoom } = this
    const options = { antialias, center, container, style, zoom }
    this.map = new mapboxgl.Map(options)
    this.map?.on('load', async () => {
      this.mapLoaded = true
    })
  }
  onLayerClicked(e: Record<string, any>) {
    this.$emit('layer-clicked', e)
  }
  setCenter(center: number[] | void) {
    if (center) this.center = center
  }
  unloadAllLayers() {
    Object.keys(this.loadedLayers).forEach(k => {
      if (this.loadedLayers[k]) {
        this.loadedLayers[k] = false
        this.map?.removeLayer(k)
      }
    })
  }
  updateLayer(id: number) {
    const coverageArea = this.getCoverageArea(id)
    if (coverageArea) this.drawCoverageArea(coverageArea)
  }
  updateMap() {
    this.unloadAllLayers()
    this.loadCoverageAreas()
  }
}
