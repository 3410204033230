
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ConfirmationTextarea extends Vue {
  // props // ***
  @Prop({ type: Boolean })
  isValid!: boolean
  @Prop({ type: String })
  label!: string
  @Prop({ type: String })
  message!: string
  @Prop({ type: String })
  messageClass!: string
  @Prop({ type: String })
  placeholder!: string
  @Prop({ type: Array })
  rules!: ((data: any) => string | boolean)[]
  @Prop({ type: Boolean })
  saveOnEnter!: boolean
  @Prop({ type: String })
  type!: string
  @Prop({ type: [String, Number] })
  value!: string | number | null | undefined

  // methods // ***
  onKeydownEnter() {
    if (this.saveOnEnter) this.$emit('save')
  }
}
