import Cookies from 'js-cookie'
import _vue from 'vue'

class Cookie {
  get(name: string | number) {
    const value = Cookies.get(name)
    if (typeof value !== 'string') return
    else return JSON.parse(value)
  }
  remove(name: string | number) {
    return Cookies.remove(name)
  }
  set(name: string | number, value: any) {
    if (typeof value === 'undefined') return this.remove(name)
    else return Cookies.set(name, JSON.stringify(value), { secure: true })
  }
}

const cookie = new Cookie()

export default {
  install(Vue: typeof _vue): any {
    Vue.$cookies = cookie
    Vue.prototype.$cookies = cookie
  },
}
