import i18nMessages from '@/i18n/index'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { updateVuetifyLanguage } from './vuetify'
import { DEFAULT_LOCALE } from '@/utilities/constants'
Vue.use(VueI18n)
const i18n = new VueI18n({ locale: DEFAULT_LOCALE.LANG, messages: i18nMessages })

export default i18n

const loadedLanguages: string[] = []

function setLanguage(lang: string): string {
  if (i18n.locale !== lang) {
    i18n.locale = lang
  }

  updateVuetifyLanguage(lang)

  return lang
}

export async function loadLanguageAsync(lang: string): Promise<string> {
  if (i18n.locale === lang || loadedLanguages.includes(lang)) {
    return await Promise.resolve(setLanguage(lang))
  }

  const messages = i18nMessages[lang as keyof typeof i18nMessages]

  if (messages) {
    i18n.setLocaleMessage(lang, messages)
    loadedLanguages.push(lang)
    return setLanguage(lang)
  } else {
    console.error(`Messages for language ${lang} not found.`)
    return lang
  }
}

// Even though the default language has been loaded
// this performs additonal steps
void loadLanguageAsync(DEFAULT_LOCALE.LANG)
