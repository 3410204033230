
import cloneDeep from 'lodash/cloneDeep'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { EXPENSE_TYPES } from '@/utilities/constants'
import { isGreaterThanOrEqualToN, isRoundedToNDecimalPlaces } from '@/utilities/validations'
const getExpense = (e: Record<string, any>[] = [], id: number): Record<string, any> | null =>
  e.find(i => i.expenseTypeId === id) || null

const { MEET_AND_GREET, PARKING, TOLL, MISCELLANEOUS, GREEN_FEE } = EXPENSE_TYPES

const collectedExpenses = [MEET_AND_GREET, PARKING, TOLL, MISCELLANEOUS, GREEN_FEE]
interface Expense {
  expenseId: number
  expenseType: string
  value: number | null
  description: string | null
  descError?: string | null
}
@Component
export default class ExpenseInput extends Vue {
  // props // ***
  @Prop({ type: Array })
  inputRules!: ((data: any) => string | boolean)[]
  @Prop({ type: String })
  placeholder!: string
  @Prop({ type: Array })
  rules!: ((data: any) => string | boolean)[]
  @Prop({ type: String })
  type!: string
  @Prop({ type: Array })
  value!: Record<string, any>[]

  internalExpenses: Expense[] = [
    {
      expenseId: MEET_AND_GREET,
      expenseType: this.$t('cmac.expenseItem.meetAndGreet') as string,
      value: null,
      description: null,
    },
    {
      expenseId: PARKING,
      expenseType: this.$t('cmac.expenseItem.parking') as string,
      value: null,
      description: null,
    },
    {
      expenseId: TOLL,
      expenseType: this.$t('cmac.expenseItem.toll') as string,
      value: null,
      description: null,
    },
    {
      expenseId: GREEN_FEE,
      expenseType: this.$t('cmac.expenseItem.greenFee') as string,
      value: null,
      description: null,
    },
    {
      expenseId: MISCELLANEOUS,
      expenseType: this.$t('cmac.expenseItem.misc') as string,
      value: null,
      description: null,
    },
  ]
  // methods // ***
  get getExpenses(): Expense[] {
    return this.internalExpenses.map(x => {
      let e = getExpense(this.value, x.expenseId)
      x.value = e?.cost?.net || 0
      x.description = e?.cost?.net ? e?.description : null
      x.descError = null
      return x
    })
  }
  get miscExpense(): Expense {
    return (
      this.internalExpenses.find(x => x.expenseId === MISCELLANEOUS) || {
        expenseId: MISCELLANEOUS,
        expenseType: 'Misc',
        value: null,
        description: null,
      }
    )
  }
  get valueRules() {
    return [isGreaterThanOrEqualToN(0), (v: any) => isRoundedToNDecimalPlaces(v, 2)]
  }
  get isValid() {
    let validVals = this.internalExpenses.every(x =>
      this.valueRules.every(r => r(x.value || 0) === true)
    )
    let miscValid = this.miscExpense.value ? !!this.miscExpense.description : true
    return validVals && miscValid
  }
  getPayload() {
    const payload = cloneDeep(this.value) || []
    collectedExpenses.forEach(x => {
      let e = getExpense(payload, x)
      let ie = this.internalExpenses.find(y => y.expenseId === x) || {
        value: 0,
        description: null,
        expenseType: null,
      }
      if (e) {
        e.cost.net = ie.value
        e.description = ie.description
      } else if (ie.value) {
        payload.push({
          expenseTypeId: x,
          expenseType: ie.expenseType,
          description: ie.description,
          cost: {
            net: ie.value,
          },
        })
      }
    })
    return payload
  }
  onCancel() {
    this.$emit('cancel')
  }
  onInput() {
    if (!this.validate()) return
    this.$emit('input', this.getPayload())
  }
  onSave() {
    this.$emit('save')
  }
  validate() {
    this.miscExpense.descError = null
    if (this.isValid) return true
    if (this.miscExpense.value && !this.miscExpense.description) {
      this.miscExpense.descError = 'A description is required if the value is set'
    }
    return false
  }
}
