export default {
  navbar: {
    userGuide: 'Hilfe & Anleitungen',
  },
  settings: {
    emailTypes: {
      generalManager: 'Geschäftsführer',
      reception: 'Empfang/Rezeption',
      sales: 'Umsatz/Verkauf',
      email24: '24-Stunden-E-Mail',
      notification: 'Benachrichtigung',
      other: 'Sonstiges',
    },
  },
  auth: {
    emailAndPasswordDoNotMatchOurRecords:
      'E-Mail und Passwort stimmen nicht mit unseren Informationen überein.',
    forgottenYourPassword: 'Passwort vergessen?',
    forgottenYourUsername: 'Nutzername vergessen?',
    helpUsFindYourUsername:
      'Bitte geben Sie die im folgenden Formular geforderten Daten an, damit wir Ihren Benutzernamen finden können.',
    helpUsResetYourPassword:
      'Bitte geben Sie uns Ihren Benutzernamen an, damit wir Ihr Passwort zurücksetzen können.',
    login: 'Anmeldung',
    logIn: 'Anmelden',
    logout: 'Abmeldung',
    logOut: 'Abmelden',
    password: 'Passwort',
    passwordReset: 'Password zurücksetzen',
    pleaseEnterThePrimaryEmailAddress:
      'Bitte geben Sie die primäre E-Mail-Adresse für Ihr Konto ein.',
    pleaseEnterYourUsername: 'Bitte geben Sie ihren Nutzernamen ein...',
    pleaseEnterYourPassword: 'Bitte geben Sie Ihr Passwort ein',
    primaryEmailAddress: 'Primäre E-Mail-Adresse',
    unfortunatelyTheSystemCouldNotAuthenticateYou:
      'Das System konnte Sie leider nicht authentifizieren.  Das liegt normalerweise daran, dass Ihre Sitzung abgelaufen ist.  Bitte melden Sie sich erneut an, um fortzufahren.',
    username: 'Nutzername',
    usernameReminder: 'Erinnerung an den Nutzernamen',
    youAreBeingLoggedOut: 'Sie werden abgemeldet.',
    yourSessionHasExpired: 'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.',
  },
  availability: {
    availability: 'Verfügbarkeit.',
    addAvailability: 'Verfügbarkeit hinzufügen',
    addingAvailability: 'Verfügbarkeit wird hinzugefügt',
    accessible: 'Verfügbar',
    double: 'Doppel',
    family: 'Familien',
    single: 'Einzel',
    twin: 'Zweibett',
    room: 'Zimmer',
    rooms: 'Zimmer',
    numberAvailable: 'Anzahl Verfügbar',
    noCmacRate: 'Kein CMAC-Preis',
    rate: 'Preis',
    useCmacAgreedRates: 'Vereinbarte CMAC-Sätze verwenden.',
    fullyBooked: 'Ausgebucht',
    helpText1:
      'Um die Verfügbarkeit einzugeben, wählen Sie ein bestimmtes Datum oder einen Zeitraum aus.',
    helpText2: 'Um ein Datum auszuwählen, klicken Sie in das Feld für dieses Datum.',
    helpText3: 'Um einen Zeitraum auszuwählen, klicken Sie auf ein zweites, anderes Feld.',
    helpText4:
      'Ausgewählte Daten werden als rote Punkte angezeigt.  Um ein ausgewähltes Datum zu löschen, klicken Sie auf das Kästchen für dieses Datum.',
    helpText5:
      'Klicken Sie auf die Schaltfläche "Verfügbarkeit hinzufügen" und geben Sie die Gesamtzahl der verfügbaren Zimmer ein.  Wenn Sie bereits Preise angegeben haben, verwenden wir die vereinbarten CMAC-Preise.  Andernfalls geben Sie bitte den reinen Zimmerpreis für die betreffende Unterkunft ein.',
    helpText6:
      'Sie können mehrere Einträge für dasselbe Datum hinzufügen, wenn Sie Zimmer zu unterschiedlichen Preisen anbieten.',
    publicHeader: 'CMAC Group - Verfügbarkeitsanfrage -',
    publicInstruction1:
      'Bitte geben Sie die Anzahl der verfügbaren Kategorien ein und klicken Sie auf "Senden".',
    publicInstruction2:
      'Sie können diesen Link jederzeit verwenden, um diese Informationen später zu aktualisieren. Er läuft ab am',
    ratesIncludeTax:
      'Bitte vergewissern Sie sich, dass der Preis INKLUSIVE Steuern/Mehrwertsteuer angegeben ist.',
    submittedBy: 'Erstellt von ',
    updatedBy: 'aktualisiert von ',
    specificallyFor: 'Speziell für ',
    parkFlyRoomOnly: 'Nur Sleep & Fly-Zimmer',
    parkFly4Bundle: 'Sleep & Fly-Zimmer + 4-Tage-Parkplatz',
    parkFly8Bundle: 'Sleep & Fly-Zimmer + 8-Tage-Parken',
    parkFly15Bundle: 'Sleep & Fly Zimmer + 15 Tage Parken',
    parkFlyToCloseOut:
      'Um ein Date abzuschließen, schalten Sie bitte die entsprechenden Elemente aus. Um die Buchung wieder zu aktivieren, müssen Sie Ihre Preistabelle erneut hochladen.',
  },
  cmac: {
    actionedBy: 'Veranlasst durch',
    addAddress: 'Adresse hinzufügen',
    addEmailAddress: 'E-Mail-Adresse hinzufügen',
    addTelephoneNumber: 'Telefonnummer hinzufügen',
    addAComment: 'Kommentar hinzufügen',
    addComment: 'Kommentar hinzufügen',
    addingAComment:
      'Das Hinzufügen eines Kommentars bewirkt, dass diese Fahrt angefochten wird.  Bitte nur bei Differenzen verwenden, die nicht in der Fahrstrecke angegeben sind. Bitte geben Sie alle Einzelheiten, z. B. Kilometerstand und Grund für die Beanstandung, an.',
    addExpense: 'Ausgabe hinzufügen',
    agreed: 'Zugestimmt.',
    allOpen: 'Alles offen',
    amend: 'Ändern',
    applies: 'Anwendbar',
    apply: 'Anwenden',
    assignedOrganisation: 'Zugeordnetes Unternehmen',
    availability: 'Verfügbarkeit',
    awaitingResponseFromSupplier: 'Erwarte Antwort vom Lieferanten.',
    billingAddress: 'Rechnungsadresse',
    bulkUpload: 'Bulk-Upload',
    bulkUploadHistory: 'Bulk-Upload-Verlauf',
    cancelled: 'Storniert',
    closed: 'Geschlossen',
    cmac: 'CMAC',
    cmacReference: 'CMAC-Referenz',
    companyDetails: 'Angaben zum Unternehmen',
    companyFinancialDetails: 'Zahlungsdetails',
    companyOnBoardingDocuments: 'Dokumente für die Aufnahme des Unternehmens',
    companyNumber: 'Unternehmensnummer',
    compliance: 'Compliance',
    costConfirmation: 'Kostenübernahme',
    costVatBreakDown: 'Aufschlüsselung der Kosten für die Mehrwertsteuer',
    coverage: 'Deckung',
    coverageAreas: 'Deckungsbereiche',
    currentlyImpersonating: 'Derzeit Impersonated',
    customDates: 'Benutzerdefinierte Daten',
    customerName: 'Kundenname',
    customerOrganisation: 'Kundenunternehmen',
    departureDate: 'Abreisedatum',
    depotAddress: 'Lageradresse',
    dispute: 'Anfechtung',
    disputed: 'Angefochten',
    disputeDetails: 'Details zur Anfechtung',
    disputeEntry: 'Eintrag zur Anfechtung',
    disputeEntries: 'Einträge zur Anfechtung',
    disputeId: 'Nr. der Anfechtung',
    disputes: 'Anfechtungen',
    documentType: 'Art des Dokuments',
    driver: 'Fahrer',
    driverDetails: 'Angaben zum Fahrer',
    editJourney: 'Fahrt bearbeiten',
    editOpenJourneys: 'Offene Fahrten bearbeiten',
    endImpersonation: 'Beendigung der Impersonation',
    endTime: 'Endzeitpunkt',
    expenses: 'Kosten',
    expired: 'Abgelaufen',
    expiryDate: 'Ablaufdatum',
    expiryDateRequired: 'Ablaufdatum erforderlich',
    extras: 'Extras',
    fare: 'Tarif',
    farePriceCalculation: 'Tarifkalkulation',
    fixedPrices: 'Festpreise',
    greenFee: 'Ökologische Gebühr',
    holidayRates: 'Feiertagstarife',
    holidays: 'Feiertage',
    holidayUplift: 'Auftrieb für Feiertage',
    holidayUpliftDescription:
      'Diese benutzerdefinierten Daten gelten für ALLE Preislisteneinträge, es sei denn, sie werden durch ein eintragsspezifisches benutzerdefiniertes Datum überschrieben',
    hotelMarkupRates: 'Hotelaufschläge',
    hotelProfile: 'Hotel-Profil',
    hotelRatesTitle: 'Hoteltarife',
    ifYouNeedToChangeHowWeKnowYouInOurSystemPleaseCallTheSupplierRelationsTeamOn:
      'Wenn Sie Ihre Angaben in unserem System ändern möchten, rufen Sie bitte das Supplier-Relations-Team an.',
    impersonating: 'Impersonating',
    inProgress: 'In Bearbeitung',
    invoiceId: 'Rechnungsnummer',
    invoices: 'Rechnungen',
    job: 'Auftrag | Aufträge',
    jobEntryId: 'Auftrag-Eintrags-Nr',
    jobTypes: 'Auftragsarten',
    journey: 'Fahrt',
    journeys: 'Fahrten',
    journeyChargeMethod: 'Methode der Fahrtkostenabrechnung',
    lastUpdated: 'Letzte Aktualisierung',
    leadMileage: 'Zusatz-Kilometer',
    leadMileageCost: 'Kilometerpreis',
    mileage: 'Kilometerstand',
    mileageRates: 'Kilometertarife',
    minimumPrice: 'Mindestpreis',
    noCustomDates: 'Keine benutzerdefinierten Daten',
    open: 'Offen',
    orAlternativelyEmail: 'Oder alternative E-Mail',
    otherOrganisations: 'Andere Unternehmen',
    passenger: 'Passagier',
    parking: 'Parken',
    parkingExpenses: 'Parkkosten',
    pax: 'Passagier(e)',
    percentageOfOriginalCostPrice: 'Prozentsatz des ursprünglichen Preises',
    permits: {
      currentHeading: 'AKTUELLE ZULASSUNGEN',
      openHeading: 'OFFEN FÜR ANMELDUNGEN',
      title: 'Zulassungen',
      claimed: 'Gebucht',
      status: 'Status',
      expires: 'Läuft ab',
      apply: 'Jetzt anmelden',
      outstandingAmendment: 'Es gibt einen ausstehenden Änderungsantrag für diese Zulassung.',
      noClaimedPermits: 'Keine beanspruchten Zulassungen',
      noOpenApplications: 'Keine offenen Anmeldungen',
      download: 'Herunterladen',
      print: 'Zulassung drucken',
      amend: 'Ändern',
    },
    pleaseAddAComment: 'Bitte fügen Sie einen Kommentar hinzu.',
    pleaseContactXForAssistance: 'Bitte kontaktieren Sie {x} für Hilfe',
    pleaseSelectAXToImpersonate: 'Bitte wählen Sie einen {x} für Impersonate',
    policiesAndTerms: 'Richtlinien und Bedingungen',
    pricingInformation: 'Informationen zur Preisgestaltung',
    processing: 'Verarbeitung',
    profile: 'Profil',
    rate: 'Preis',
    rateCards: 'Preislisten',
    referredToOperations: 'Weiterleitung an Bereich Operations',
    referredToSupplierRelations: 'Bezogen auf die Geschäftsbeziehungen zu den Lieferanten',
    referredToPricingManager: 'An den Preismanager verwiesen',
    regions: 'Regionen',
    registration: 'Registrierung',
    responseReceivedFromSupplier: 'Antwort vom Anbieter erhalten',
    selectDocumentType: 'Dokumenttyp wählen',
    selectUserToImpersonate: 'Nutzer für Impersonate auswählen',
    standDownChargeMethod: 'Art der Zahlung bei Nichtabholung',
    standDownPrice: 'Preis für Nichtabholung',
    startTime: 'Startzeitpunkt',
    statementId: 'Anweis-Nummer',
    stoodDown: 'Verspätung zur Abholung',
    stoodDownWithinXMinutesOfDepartureDate:
      'Innerhalb von {x} Minuten nach dem Buchungs-Zeitpunkt eingetroffen',
    summary: 'Zusammenfassung',
    supplierName: 'Name des Anbieters',
    termsAndConditions: 'Bedingungen und Konditionen',
    thankYouForLoggingInToTheSupplierPortal:
      'Danke, dass Sie sich im Lieferantenportal angemeldet haben',
    tierType: 'Klasse',
    updateFailed: 'Aktualisierung fehlgeschlagen',
    updateSuccessful: 'Aktualisierung erfolgreich',
    userDetails: 'Nutzerdaten',
    hotelDetails: 'Hotel-Details',
    vehicle: 'Fahrzeug',
    vehicleRates: 'Fahrzeugpreise',
    vias: 'Zwischenziele',
    viewAll: 'Alle anzeigen',
    waypoint: 'Wegpunkt | Wegpunkte',
    waitingTime: 'Wartezeit',
    waitingTimeCost: 'Wartezeitkosten',
    waitingTimeFree: 'Kostenlose Wartezeit',
    waitingTimeMinutes: 'Wartezeit in Minuten',
    waitingTimeRate: 'Wartezeitkosten',
    withinXMinutes: 'Innerhalb {x} Minuten',
    vehicles: 'Fahrzeuge',
    vehicleType: 'Fahrzeugtyp',
    yourAccountIsCurrentlyInactive: 'Ihr Konto ist derzeit inaktiv.',
    yourReference: 'Ihre Referenz',
    total: 'Gesamt',
    invoiceuploads: 'Rechnungs-Uploads',
    hotelRates: {
      validBetween: 'Gültig zwischen',
      addNewRate: 'Preise hinzufügen',
      addNewRateText: 'Es gibt einen neuen ausstehenden Tarifantrag für dieses Unternehmen.',
      noRatesTextPrepend:
        'Es gibt keine aktuellen Tarife für Ihr Unternehmen. Bitte kontaktieren Sie uns unter',
      noRatesTextAppend: 'um die vereinbarten Tarife zu übermitteln.',
      hasRatesText:
        'Nachstehend finden Sie die aktuellen Tarife, die wir in unserem System gespeichert haben.  Wenn Sie Änderungen vornehmen möchten, kontaktieren Sie uns bitte unter',
      profile: 'Profil',
      profileStarRating: 'Sterne-Bewertung',
      profileUnkown: 'unbekannt',
      profileYes: 'Ja',
      profileNo: 'Nein',
      profileYesFree: 'Ja, kostenlos',
      profileNumberOfRooms: 'Anzahl der Zimmer',
      profileAccessibleRooms: 'Verfügbare Zimmer',
      profileFamilyRooms: 'Familien-Zimmer',
      profileHotelBus: 'Hotel-Bus',
      profileParking: 'Parkplatz',
      profileSpa: 'Spa',
      profileSwimmingPool: 'Swimming-Pool',
      profileGym: 'Trainingsraum',
      profileRestaurant: 'Restaurant',
      commissionRate: 'Provisionssätze',
      commissionRooms: 'Zimmer',
      commissionFood: 'Mahlzeiten',
      commissionDynamic: 'Dynamisch',
      editText: 'Hotelpreisliste',
      editProfileText: 'Hoteldetails bearbeiten',
      ratesValidDatesText: 'Die Preise gelten zwischen diesen Daten.',
      ratesValidFrom: 'Gültig ab',
      ratesValidTo: 'Gültig bis',
      rates: 'Preise',
      ratesType: 'Typ',
      ratesRoomText: 'Preise pro Zimmer für verspätete Passagiere und Flugpersonal',
      ratesRoomType: 'Zimmertyp',
      ratesMidweekText: 'Nur Zimmer unter der Woche (So-Do)',
      ratesDayLet: 'Kosten für Tagesausflüge',
      ratesWeekendText: 'Nur am Wochenende (Fr-Sa)',
      ratesRoomTwinDouble: 'Zweibett-/Doppelzimmer',
      ratesRoomSingle: 'Einzelzimmer',
      ratesRoomTriple: 'Dreibettzimmer',
      ratesRoomFamily: 'Familienzimmer',
      ratesWeekdayRate: 'Wochentagstarif',
      ratesWeekdayDayRate: 'Wochentags-Tagessatz',
      ratesWeekendRate: 'Wochenendtarif',
      ratesWeekendDayRate: 'Wochenend-Tagestarif',
      ratesFoodText: 'Essen und Getränke (inkl. Tee, Kaffee, Kakao) Preis pro Person',
      ratesContinentalBreakfast: 'Kontinentales Frühstück',
      ratesFullBreakfast: 'Volles Frühstück',
      ratesLunch: 'Mittagessen',
      ratesDinner: 'Abendessen',
      ratesAgreementText:
        'Die Eingabe dieser Tarife stellt eine verbindliche Vereinbarung zwischen der CMAC Group und Ihrem Hotel dar. Sie sollten daher sicherstellen, dass nur eine autorisierte Person das Formular ausfüllt. Das Hotel ist für die Person verantwortlich, die das Formular ausfüllt, und wenn eine nicht autorisierte Person das Formular ausfüllt, hat dies keinen Einfluss auf die CMAC-Gruppe oder ihre Möglichkeit, diese Tarife durchzusetzen.',
      ratesTermsConditionsText:
        'Bitte kreuzen Sie an, um zu bestätigen, dass Sie unser (x)  gelesen haben.',
      ratesTermsConditionsLinkText: 'Bedingungen und Konditionen',
      submittedHeading: 'Eingereicht von',
      viewRatesTermsConditionsText: 'Klicken Sie hier, um unsere anzusehen',
      outstandingRateRequest: 'Ausstehende Preisanfrage',
      submittedByName: 'Name',
      submittedByEmail: 'E-Mail',
      submittedByNameRequired: 'Bitte geben Sie Ihren Namen ein.',
      submittedByEmailRequired: 'Bitte geben Sie Ihre E-Mail-Adresse ein.',
      submittedSuccess:
        'Vielen Dank für das Einreichen Ihrer Raten. Wir freuen uns auf die Zusammenarbeit mit Ihnen.',
      errorSaving:
        'Beim Speichern Ihrer Raten ist ein Fehler aufgetreten. Bitte kontaktieren Sie hotelrelations@cmacgroup.com für Unterstützung.',
    },
    disputesAwaitingAction: 'Streitfälle, die auf Handlung warten',
    editAddress: 'Adresse bearbeiten',
    editEmailAddress: 'E-Mail-Adresse bearbeiten',
    editTelephoneNumber: 'Telefonnummer bearbeiten',
    expenseItem: {
      meetAndGreet: 'Treffen und Begrüßen',
      parking: 'Parken',
      toll: 'Maut',
      greenFee: 'Umweltgebühr',
      misc: 'Sonstiges',
      amount: 'Betrag',
    },
    financialDetails: {
      accountName: 'Kontoname',
      bankAddress: 'Bankadresse',
      ibanNumber: 'IBAN-Nummer',
      currency: 'Währung',
    },
    noOfPax: 'Anz. Pass.',
    pickupDateTime: 'Abholdatum/-zeit',
    psvarStatus: 'Psvar Status',
    supplierRef: 'Lieferanten Ref.',
    vatNumber: 'USt-IDNr.',
    vatTreatment: 'USt-Behandlung',
    vehicleRegistration: 'Kfz-Zulassung',
    cmacRef: 'CMAC-Ref',
    yourRef: 'Ihre Ref',
    noNotificationEmailAddress: 'Sie haben keine Benachrichtigungs-E-Mail-Adresse',
    leadMiles: 'Vorlaufmeilen',
    fareNet: 'Fahrpreis (Netto)',
    leadMilesCost: 'Kosten für Vorlaufmeilen (Netto)',
    totalCost: 'Gesamtkosten (Netto)',
    appliesOnDays: 'Gilt tageweise',
    noVehicleRates: 'Keine Fahrzeugtarife',
    markup: 'Markup',
    addedPrice: 'Aufpreis',
    noHolidayRates: 'Keine Feiertagspreise',
    standDownRates: 'Keine Stand-Down-Tarife',
    noStandDownRates: 'Keine Stand-Down-Tarife',
    stoodDownWithin: 'Ich blieb innerlich stehen',
    minutesOfDepartureDate: 'Protokoll des Abreisedatums',
    ofOriginalCostPrice: 'des ursprünglichen Selbstkostenpreises',
    ofOriginalCostPriceWhicheverIsHigher:
      'des ursprünglichen Selbstkostenpreises, je nachdem, welcher Betrag ',
    waitingTimeRates: 'Wartezeittarife',
    noWaitingTimeRates: 'Keine Wartezeittarife',
    minutesFree: 'Minuten gratis',
    minutes: 'Protokoll',
  },
  common: {
    about: 'Betreff',
    accepted: 'Akzeptiert',
    add: 'Hinzufügen',
    addComment: 'Kommentar hinzuf.',
    addVehicle: 'Fahrzeug hinzufügen',
    actions: 'Aktivitäten',
    addXtoY: '{x} zu {y} hinzufügen',
    agreed: 'Vereinbart',
    agreementType: 'Vereinbarungsart',
    all: 'Alle',
    back: 'Zurück',
    backToTop: 'Zurück zum Anfang',
    cancel: 'Abbrechen',
    close: 'Schließen',
    comment: 'Kommentar',
    comments: 'Kommentare',
    completed: 'Abgeschlossen',
    confirm: 'Bestätigen',
    continue: 'Weiter',
    cost: 'Kosten',
    create: 'Erstellen',
    created: 'erstellt',
    createdAt: 'Erstellt am',
    customer: 'Kunde',
    date: 'Datum',
    dateRange: 'Zeitraum',
    delete: 'Löschen',
    departureDateRange: 'Abfahrtszeitraum',
    description: 'Beschreibung',
    details: 'Details',
    disputed: 'Angefochten',
    distance: 'Entfernung',
    documents: 'Dokumente',
    download: 'Download',
    edit: 'Bearbeiten',
    edited: 'bearbeitet',
    editVehicle: 'Fahrzeug bearb.',
    email: 'E-Mail',
    enabled: 'aktiviert',
    error: 'Fehler',
    example: 'Beispiel',
    expired: 'Abgelaufen',
    expires: 'Läuft ab',
    failed: 'Fehlgeschlagen',
    file: 'Datei',
    filename: 'Dateiname',
    filters: 'Filter',
    from: 'Von',
    full: 'Vollständig',
    half: 'Halb',
    home: 'Startseite',
    hide: 'Ausblenden',
    id: 'ID',
    info: 'Info',
    important: 'Wichtig',
    jobNo: 'Auftrags-Nr.',
    loading: 'lädt',
    map: 'Karte',
    messages: 'Nachrichten',
    miles: 'Kilomenter',
    minutes: 'Minuten',
    mode: 'Modus',
    name: 'Name',
    new: 'Neu',
    no: 'Nein',
    none: 'Keine',
    notes: 'Notizen',
    null: 'Null',
    ok: 'OK',
    open: 'Offen',
    organisation: 'Unternehmen',
    organisations: 'Unternehmen',
    original: 'Original',
    others: 'Andere',
    password: 'Passwort',
    pending: 'Ausstehend',
    price: 'Preis',
    previouslyUploaded: 'Zuvor hochgeladen',
    processed: 'Verarbeitet',
    processing: 'In Verarbeitung',
    protection: 'Schutz',
    quantity: 'Menge',
    raw: 'Roh',
    readonly: 'Schreibgeschützt',
    reference: 'Referenz',
    refresh: 'Aktualisieren',
    reset: 'Zurücksetzen',
    roles: 'Funktionen',
    save: 'Spreichern',
    search: 'Suchen',
    settings: 'Einstellungen',
    show: 'Anzeigen',
    signedBy: 'Unterschrieben von',
    signedOn: 'Unterschrieben am',
    sortBy: 'Sortieren nach',
    status: 'Status',
    submit: 'Absenden',
    surcharge: 'Zuschlag',
    test: 'Test',
    time: 'Zeit',
    title: 'Titel',
    to: 'An',
    total: 'Gesamt',
    type: 'Typ',
    unknown: 'Unbekannt',
    update: 'Aktualisieren',
    updated: 'aktualisiert',
    updatedAt: 'Aktualisiert am',
    upload: 'Hochladen',
    uploaded: 'hochgeladen',
    user: 'Nutzer',
    username: 'Nutzername',
    users: 'Nutzer',
    website: 'Website',
    weight: 'Gewicht',
    yes: 'Ja',
    next: 'nächste',
    hour: 'stunde',
    mile: 'meile',
    or: 'oder',
  },
  currencies: {
    gbp: '£',
    eur: '€',
  },
  datetime: {
    monday: 'Montag',
    tuesday: 'Dienstag',
    wednesday: 'Mittwoch',
    thursday: 'Donnerstag',
    friday: 'Freitag',
    saturday: 'Samstag',
    sunday: 'Sonntag',
    january: 'Januar',
    february: 'Februar',
    march: 'März',
    april: 'April',
    may: 'Mai',
    june: 'Juni',
    july: 'Juli',
    august: 'August',
    september: 'September',
    october: 'Oktober',
    november: 'November',
    december: 'Dezember',
    today: 'heute',
  },
  docs: {
    documentation: 'Dokumentation',
    faq: 'Häufig gestellte Fragen',
    frequentlyAskedQuestions: 'Häufig gestellte Fragen',
    tutorials: 'Anleitungen',
  },
  errors: {
    301: 'Sie sind nicht berechtigt, auf diese Ressource zuzugreifen.',
    400: 'Die Anfrage war leider erfolglos.',
    404: 'Es tut uns leid, wir konnten die von Ihnen gesuchte Ressource nicht finden.',
    500: 'Der Server konnte die Anfrage nicht verarbeiten.',
    error: 'Fehler',
    nError: '{n} Fehler',
    noResponseFromServer: 'Keine Antwort des Servers',
    noUrl: 'Keine Url',
    sorrySomethingWentWrong: 'Entschuldigung, etwas ist schief gelaufen',
    uploadFailed: 'Hochladen fehlgeschlagen',
    uploadFailedWithError: 'Hochladen fehlgeschlagen, mit Fehler',
  },
  fields: {
    address: 'Adresse',
    addresses: 'Adressen',
    addressLine1: 'Adresszeile 1',
    addressLine2: 'Adresszeile 2',
    addressLine3: 'Adresszeile 3',
    addressLookup: 'Adresssuche',
    city: 'Stadt',
    country: 'Land',
    county: 'Landkreis',
    description: 'Beschreibung',
    driverName: 'Fahrername',
    driverPhone: 'Telefonnummer des Fahrers',
    driverRegistration: 'Fahrerregistrierung',
    email: 'E-Mail',
    emailAddress: 'E-Mail-Adresse',
    emailAddresses: 'E-Mail-Adressen',
    home: 'Telefon',
    mobile: 'Mobil',
    name: 'Name',
    number: 'Nummer',
    phoneNumber: 'Telefonnummer',
    postCode: 'Postleitzahl',
    region: 'Region',
    registrationNumber: 'Registriernummer',
    telephoneNumber: 'Telefonnummer',
    telephoneNumbers: 'Telefonnummern',
    title: 'Titel',
    titles: {
      dr: 'Dr.',
      lady: 'Lady',
      lord: 'Lord',
      mr: 'Hr.',
      miss: 'Fr.',
      mrs: 'Fr.',
      ms: 'Fr.',
      mx: '',
      prof: 'Prof.',
      sir: 'Sir',
    },
    town: 'Stadt',
    work: 'Arbeitsplatz',
  },
  onboarding: {
    documents: {
      public_insurance: 'Bescheinigung über eine Haftpflichtversicherung',
      liability: 'Bescheinigung der Arbeitgeberhaftpflichtversicherung',
      motor_insurance: 'Kfz-Versicherungsschein',
      gdpr: 'DSGVO-Richtlinie',
      small_operator_license: 'Führerschein – Kleinfahrzeuge',
      large_operator_license: 'Führerschein – große Fahrzeuge',
      upload: 'Dokument hochladen',
      download: 'Dokument herunterladen',
      file: 'Dateiname',
      document: 'Dokumenttyp',
      created: 'Erstellt am',
      expires: 'Läuft ab',
    },
    agreement:
      'Ich habe die oben abgedruckten Allgemeinen Geschäftsbedingungen gelesen und akzeptiere sie.',
    mustAgree:
      'Um die App nutzen zu können, müssen Sie die Allgemeinen Geschäftsbedingungen akzeptieren.',
    welcomeHeader: 'Willkommen bei der CMAC-Anbieterregistrierung',
    country: 'Land',
    legalCompanyName: 'Juristischer Firmenname',
    tradingName: 'Handelsname',
    companyRegistrationNumber: 'Registrierungsnummer des Unternehmens',
    registeredAddress: 'Registergericht / Gerichtsstand',
    operatingAddress: 'Betriebsanschrift',
    currency: 'Ausloggen',
    logOut: 'Ausloggen',
    smallVehicle: 'Kleinfahrzeug (Taxi, Mehrzweckfahrzeug, Behindertenfahrzeug, Black Cab)',
    largeVehicle: 'Großes Fahrzeug (Kleinbus, Reisebus)',
    fleetSize: 'Flottengröße',
    emailAddress: 'E-Mail-Adresse',
    mainContactNumber: 'Bitte geben Sie Ihr Passwort ein …',
    primaryEmailAddress: 'Primäre E-Mail-Adresse',
    successResult: 'Konto wurde erfolgreich erstellt, bitte prüfen Sie Ihren Posteingang',
    failResult:
      'Wir sind nicht in der Lage, Ihr Konto zu diesem Zeitpunkt zu erstellen, ein Konto mit den von Ihnen angegebenen Informationen existiert bereits, bitte kontaktieren Sie supplier.relations@cmacgroup.com für weitere Informationen.',

    progressHeader: 'Vielen Dank, dass Sie sich als CMAC-Anbieter registrieren lassen möchten.',
    progressMainText:
      'Um mit Ihren Anmeldungen voranzukommen, vergewissern Sie sich bitte, dass alle Teile der nachstehenden Abschnitte "Finanzielle Angaben" und "Unterlagen des Unternehmens" ausgefüllt sind.',
    progressSubText:
      'Sobald wir diese Informationen erhalten haben, können wir Ihre Anmeldung in die nächste Phase überführen.',
    progressBarText: 'Anmeldefortschritt',
    progressCompleted:
      'Vielen Dank für das Einreichen all Ihrer Unterlagen. Eines unserer Teammitglieder wird Ihre Einreichung nun überprüfen und sich in Kürze bei Ihnen melden, um mit dem nächsten Schritt fortzufahren.',
    rejected:
      'Leider wurde Ihre Bewerbung nicht erfolgreich bearbeitet. Bitte kontaktieren Sie unser Team für Lieferantenbeziehungen für weitere Informationen.',
  },
  plurals: {
    comments: 'Kommentar | Kommentare',
    journeys: 'Reise| Reisen',
    results: 'Ergebnis | Ergebnisse',
    resultsFound: 'Gefundenes Ergebnis | Gefundene Ergebnisse',
    rows: 'Zeile | Zeilen',
    suppliers: 'Anbieter | Anbieter',
  },
  ui: {
    anNewVersionOfTheApplicationIsAvailable: 'Eine neue Version der Anwendung ist verfügbar',
    appName: 'Anbieter-Portal',
    approve: 'Akzeptieren',
    approveAllSelectedRows: 'Alle selektierten Zeilen akzeptieren',
    changeProfilePicture: 'Profilbild ändern',
    chooseX: 'Wählen Sie {x}',
    clear: 'Löschen',
    clearFilters: 'Filter löschen',
    clickToCopyToClipboard: 'In die Zwischenablage kopieren',
    clickToSortByX: 'Klicken, um nach {x} zu sortieren',
    closeDrawer: 'Box schließen',
    currentlyUnavailable: 'Zurzeit nicht verfügbar',
    dashboard: 'Dashboard',
    document: 'Dokument',
    dragYourXHereToUploadIt: 'Ziehen Sie Ihre Datei {x} hierher, um sie hochzuladen',
    export: 'Export',
    exportToCsv: 'In CSV exportieren',
    file: 'Datei',
    files: 'Dateien',
    fileSizeTooBig: 'Dateigröße zu groß',
    footer: 'Fußzeile',
    home: 'Home',
    homePage: 'Startseite',
    itemsPerPage: 'Positionen pro Seite',
    navigation: 'Navigation',
    noDetailsToDisplay: 'Keine Details zur Anzeige verfügbar',
    noFileSelected: 'Keine Datei ausgewählt',
    noResultsToShow: 'Keine Ergebnisse zur Anzeige verfügbar',
    noXProvided: 'Kein {x} bereitgestellt',
    orClickToBrowse: 'Oder klicken Sie zum Durchsuchen',
    page: 'Seite',
    pageSize: 'Seitengröße',
    pleaseSeletFromTheFollowing: 'Bitte wählen Sie aus dem Folgenden aus',
    pleaseSelectAnX: 'Bitte wählen Sie ein/e/en {x}',
    pleaseSelectAX: 'Bitte wählen Sie ein/e/en {x}',
    pleaseSelectNXOnly: 'Bitte wählen Sie nur {n} {x}',
    profilePicture: 'Profilbild',
    resultsPerPage: 'Ergebnisse pro Seite',
    saveChanges: 'Änderungen speichern',
    selectAll: 'Alles auswählen',
    selectX: 'Wählen Sie {x}',
    selectADifferentX: 'Wählen Sie ein/e/en andere/n/s {x}',
    spreadsheet: 'Spreadsheet',
    theXHasBeenDeleted: '{x} wurde gelöscht',
    thisWillPutXInDispute: 'Dies wird {x} anfechten',
    updateX: '{x} aktualisieren',
    uploadBeingProcessed: 'Der Upload wird verarbeitet',
    uploadStatus: 'Upload-Status',
    uploading: 'Hochladen',
    uploadNewX: '{x} neu hochladen',
    uploadX: '{x} hochladen',
    userProfile: 'Nutzerprofil',
    version: 'Version',
    xMissing: '{x} fehlt',
    policySignedX: 'Danke, dass Sie {x} unterschrieben haben',
  },
  validations: {
    canOnlyContainLettersAndNumbers: 'Darf nur Buchstaben und Zahlen enthalten',
    canOnlyContainLettersNumbersAndTheFollowingCharactersX:
      'Darf nur Buchstaben, Zahlen und die folgenden Zeichen enthalten: {x}',
    canOnlyContainNumbers: 'Darf nur Zahlen enthalten',
    checkboxConfirm:
      'Bitte kreuzen Sie das Kästchen an, um zu bestätigen, dass Sie diese Bedingungen und Konditionen akzeptieren.',
    maxNCharacters: 'Maximal {n} Zeichen',
    minNCharacters: 'Mindestens {n} Zeichen',
    mustBeAnInteger: 'Muss eine ganze Zahl sein',
    mustBeAFloat: 'Muss eine Dezimalzahl sein',
    mustBeAValidEmailAddress: 'Muss eine gültige E-Mail-Adresse sein',
    mustBeAValidTelephoneNumber: 'Muss eine gültige Telefonnummer sein',
    mustBeAWholeNumber: 'Muss eine ganze Zahl sein',
    mustBeExactlyNCharacters: 'Muss genau {n} Zeichen lang sein',
    mustBeGreaterThanN: 'Muss größer als {n} sein',
    mustBeGreaterThanOrEqualToN: 'Muss größer oder gleich {n} sein',
    mustBeLessThanN: 'Muss kleiner als {n} sein',
    mustBeLessThanOrEqualToN: 'Muss kleiner oder gleich {n} sein',
    mustBeRoundedToExactlyNDecimalPlaces: 'Muss auf genau {n} Dezimalstellen gerundet werden',
    mustBeRoundedToNDecimalPlaces: 'Muss auf {n} Dezimalstellen gerundet werden',
    mustHaveASingleDecimalPoint: 'Muss ein einziges Dezimalkomma haben',
    requiresBooleanValue: 'Erfordert booleschen Wert',
    thisDateMustBeInTheFuture: 'Dieses Datum muss in der Zukunft liegen',
    thisDateMustBeInThePast: 'Dieses Datum muss in der Vergangenheit liegen',
    thisExceedsTheLeadMileageLimit: 'Dies überschreitet den Grenzwert für die Laufleistung.',
    thisFieldIsRequired: 'Dieses Feld ist erforderlich',
    thisFieldMustBeAValidDate: 'In diesem Feld muss ein gültiges Datum stehen',
    thisFieldMustContainAX: 'Dieses Feld muss ein/e/en {x} enthalten',
    thisFieldMustContainAnX: 'Dieses Feld muss ein/e(en {x} enthalten',
    mustBeUkBankSortCode: 'Die Bankleitzahl muss im Format 00-00-00 sein.',
    mustBeUkBankAccountNumber: 'Die britische Bankkontonummer muss 8-stellig sein.',
    mustBeAtLeastOneChecked: 'Überprüfen Sie eine oder beide der Werte.',
  },
  invoiceUpload: {
    invoiceNo: 'Rechnungs-Nr.',
    reference: 'Referenz',
    uploadDate: 'Upload-Datum',
    invoiceDate: 'Rechnungsdatum',
    dueDate: 'Fälligkeitsdatum',
    total: 'Gesamt',
    currency: 'Währung',
    status: 'Status',
    comments: 'Kommentare',
    file: 'Datei',
    selectJourneys: 'Reisen wählen',
    uploadInvoice: 'Rechnung hochladen',
    confirm: 'bestätigen',
    uploadingAs: 'Sie laden gerade hoch als',
    atLeastOneJourney: 'Sie müssen mindestens eine Reise auswählen y',
    errorUploadingFile: 'Beim Hochladen und Analysieren der Datei ist ein Fehler aufgetreten.',
    uploadInFormat: 'Bitte laden Sie Ihre Rechnung im PDF-Format hoch.',
    selectFile: 'Bitte wählen Sie eine Datei zum Hochladen aus',
    uploadingAnalyzing: 'Hochladen und Analysieren',
    invoiceReference: 'Rechnungsreferenz',
    invoice: 'Rechnung',
    cmacReference: 'CMAC-Referenz',
    yourReference: 'Ihre Referenz',
    journeysSelectionJourney: 'Reise | Reisen',
    journeySelectionSelected: 'Ausgewählt',
    net: 'Netto',
    tax: 'Steuer',
    gross: 'Brutto',
    expenses: 'Unkosten',
    details: 'Details',
    journey: 'Reise',
    departureDate: 'Datum der Abreise',
    cmacRef: 'CMAC-Ref',
    yourRef: 'Ihre Ref',
    invoiceAccepted: 'Rechnung geprüft und akzeptiert',
    invoiceDoesNotMatch: 'Bitte überprüfen und erneut einreichen',
    stillUpload: 'Sie können diese Rechnung trotzdem hochladen, aber sie muss geprüft werden.',
    paymentDelayed: 'Die Zahlung wird zurückgestellt, bis dies abgeschlossen ist.',
    totalNetDoesNotMatch:
      'Der Gesamtnettobetrag auf der Rechnung stimmt nicht mit dem Gesamtnettopreis für alle ausgewählten Fahrten überein.',
    expected: 'Voraussichtlich',
    totalGross: 'Gesamt Brutto',
    totalNet: 'Gesamt Netto',
    totalTax: 'Gesamt Steuer',
    notFound: 'Nicht gefunden',
    invoiced: 'In Rechnung gestellt',
    underDispute: 'Angefochten',
    notUnique:
      'Eine Datei mit diesem Namen existiert bereits. Bitte einen anderen Dateinamen wählen.',
    invalidCharacters: 'Der Dateiname enthält ungültige Zeichen. Folgendes ist nicht zulässig.',
    invalidFileExtension: 'Dieser Dateityp ist ungültig. Die folgenden Dateitypen sind zulässig.',
    cancelled: 'Abgebrochen',
    journeyCost: 'Reisekosten (Netto)',
    leadMilesCost: 'Kosten für Vorlaufmeilen (Netto)',
    waitingTimeCost: 'Wartezeitkosten (Netto)',
    expensesCost: 'Kosten für Ausgaben (Netto)',
    greenFeeCost: 'Kosten für die Öko-Abgabe (Netto)',
    totalCost: 'Gesamtkosten (Netto)',
    downloadJourneyData: 'Hier klicken, um die Daten für die ausgewählten Fahrten herunterzuladen.',
    paid: 'Bezahlt',
    excludedJourneys: 'Von diesem Verfahren ausgenommen sind Fahrten, die derzeit umstritten sind.',
    rejectedStatus: 'Abgelehnt',
    createPdfInvoice: 'PDF-Rechnung erstellen',
    createPdf: {
      title: 'PDF-Rechnung für ausgewählte Fahrten erstellen',
      invoiceNumber: 'Rechnungsnummer',
      invoiceDate: 'Rechnungsdatum',
      chooseAddress: 'Bitte Adresse auswählen',
      registerDetails: {
        title: 'Registrierungsdetails',
        heading: 'Um Ihre Rechnung zu erstellen, benötigen wir einige zusätzliche Informationen.',
        financial: {
          heading: 'Befolgen Sie die untenstehenden Schritte, um Ihre Finanzdaten hinzuzufügen:',
          step1: 'Wählen Sie das Hamburger-Menü-Symbol {x} oben rechts auf Ihrem Bildschirm',
          step2: "Wählen Sie dann 'Profil'.",
          step3:
            "Im Abschnitt 'Finanzdaten' klicken Sie auf das Bearbeitungssymbol {x} und geben Sie Ihre Daten ein.",
        },
        address: {
          heading: 'Befolgen Sie die untenstehenden Schritte, um Ihre Firmenadresse hinzuzufügen:',
          step1: 'Wählen Sie das Hamburger-Menü-Symbol {x} oben rechts auf Ihrem Bildschirm.',
          step2: "Wählen Sie dann 'Profil'.",
          step3:
            "Im Abschnitt 'Adressen' klicken Sie auf das Plus-Symbol {x} und geben Sie Ihre Daten ein.",
        },
      },
    },
    separateInvoicesRequired:
      'Fahrten mit Fahrzeugen mit mehr als 10 Sitzen müssen getrennt von Fahrten mit Fahrzeugen mit weniger als 10 Sitzen abgerechnet werden.',
    invoiceNumberFieldNameLabel: 'Name des Feldes „Rechnungsnummer“',
    totalNetAmountFieldNameLabel: 'Name des Feldes „Gesamtnettobetrag“',
  },
  compliance: {
    introduction:
      'Unten finden Sie eine Zusammenfassung der Schlüsseldokumente, die vorhanden sein und bei uns registriert werden müssen. Wenn Sie Fragen zu einem dieser Dokumente haben, kontaktieren Sie uns bitte unter 03333 207 100',
    noRulesFound: 'Keine Compliance-Regeln gefunden',
  },
  contactItem: {
    generalManager: 'Hauptgeschäftsführer',
    reception24Hour: 'Rezeption / Rezeption',
    revenueSales: 'Umsatz / Umsatz',
    twentyFourHourEmail: '24-Stunden-E-Mail',
    notification: 'Benachrichtigung',
    other: 'Andere',
  },
}
