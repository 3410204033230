import CryptoJS from 'crypto-js'
import camelCase from 'lodash/camelCase'
import isInteger from 'lodash/isInteger'
import isNumber from 'lodash/isNumber'
import snakeCase from 'lodash/snakeCase'
import { CASIFY, DEFAULT_CURRENCY_PREFIX } from '@/utilities/constants'
import i18n from '@/plugins/i18n'
import api from '@/utilities/api'

const { ACCEPTED_CASES = [] } = CASIFY || {}

const changeCaseFunctions: Record<string, any> = { camelCase, snakeCase }

/**
 * Adds a unique 'id' property to every element in the array based on the index.
 *
 * @param {Array} a arrayOfObjects
 *
 * @returns {Array}
 */
export const addId = (a: Record<string, any>[]): Record<string, any>[] =>
  a.map((i, n) => ({ ...i, id: n }))

/**
 * Camelise object data properties (recursively).
 *
 * @param {Mixed} obj object to be camelised
 * @param {Boolean} recursive decides if camelisation should be applied recursively
 *
 * @returns {Object}
 */
export function camelise(obj: Record<string, any>, recursive = true): Record<string, any> {
  return casify(obj, 'camel', recursive)
}

export function canCasify(o: Record<string, any>): boolean {
  if (!o) return false
  if (!Array.isArray(o) && typeof o !== 'object') return false
  if (o instanceof Blob) return false
  else return true
}

/**
 * Change the case pattern of object keys (recursively).
 *
 * @param {Object} obj object to be operated on
 * @param {String} targetCase the desired target case
 * @param {Boolean} recursive decides if case change should be applied recursively
 *
 * @returns {Object}
 */
export function casify(
  obj: Record<string, any>,
  targetCase = 'camel',
  recursive = true
): Record<string, any> {
  if (!canCasify(obj)) return obj
  if (!ACCEPTED_CASES.includes(targetCase))
    throw (
      'casify was provided with an invalid case - accepted cases include: ' + String(ACCEPTED_CASES)
    )
  const changeCaseFunction: string = targetCase + 'Case'
  const isArray: boolean = Array.isArray(obj)
  const keys: any = isArray ? obj : Object.keys(obj)
  const changed: any = isArray ? [] : {}
  for (const i in keys) {
    const key = isArray ? i : changeCaseFunctions[changeCaseFunction](keys[i])
    const val = isArray ? obj[i] : obj[keys[i]]
    if (recursive && (Array.isArray(val) || typeof val === 'object')) {
      changed[key] = casify(val, targetCase)
    } else {
      changed[key] = val
    }
  }
  return changed
}

/**
 * Returns true if string is a strict ordered subset of the container string.
 *
 * @param {String} s string to be tested as subset
 * @param {String} container container string to be tested
 * @param {Boolean} caseSensitive if true, casing should be honoured (default:false)
 *
 * @returns {Object}
 */
export function containsString(s: string, container: string, caseSensitive = false): boolean {
  return caseSensitive
    ? container.indexOf(s) !== -1
    : container.toLowerCase().indexOf(s.toLowerCase()) !== -1
}

/**
 * Copy a string to the clipboard.
 *
 * @param {String} s string to copy to the clipboard
 *
 * @returns {Void}
 */
export function copyToClipboard(s: string): void {
  window.navigator.clipboard.writeText(s)
}

/**
 * Handle an ajax file download.
 *
 * @param {String} url target url
 * @param {String} filename filename
 * @param {String} method HTTP method (default: GET)
 *
 * @returns {Void}
 */
export async function downloadAjax(
  url: string,
  fileName: string,
  method = 'GET'
): Promise<Record<string, any>> {
  return api(url, { method: method }, { responseType: 'blob' })
    .then(response => {
      downloadDirectToBrowser(response?.data, fileName)
      return response
    })
    .catch(error => {
      console.error(error)
      return error
    })
}

/**
 * Handle an ajax file download.
 *
 * @param {String} url target url
 * @param {String} filename filename
 * @param {String} method HTTP method (default: GET)
 *
 * @returns {Void}
 */
export async function downloadAjaxPdf(
  url: string,
  filename: string,
  method = 'GET'
): Promise<Record<string, any>> {
  return api(url, { method: method }, { responseType: 'blob' })
    .then(response => {
      console.debug(response)
      downloadDirectToBrowserPdf(response?.data, filename)
      return response
    })
    .catch(error => {
      console.error(error)
      return error
    })
}

/**
 * Handle a file download in the browser from response data.
 *
 * @param {String} data data for download
 * @param {String} filename filename (default: 'unknown.txt')
 *
 * @returns {Void}
 */
export function downloadDirectToBrowser(
  data: string,
  filename: string = i18n.t('common.unknown') + '.txt'
): void {
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(new Blob([data]))
  link.download = filename
  link.click()
}

/**
 * Handle a file download in the browser from response data.
 *
 * @param {String} data data for download
 * @param {String} filename filename (default: 'unknown.pdf')
 *
 * @returns {Void}
 */
export function downloadDirectToBrowserPdf(
  data: string,
  filename = i18n.t('common.unknown') + '.pdf'
): void {
  console.debug('downloadDirectToBrowserPdf:', filename)
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
  link.target = '_blank'
  link.click()
}

/**
 * Simulate a mouse click (mousedown, then mouseup) in the center of a DOM element.
 *
 * @param {Element} el DOM element to target
 *
 * @returns {Void}
 */
export function fakeClick(el: HTMLElement, timeout = 60): void {
  const e: any = new Event('mousedown')
  const offset = el.getBoundingClientRect()
  e.clientX = offset.left + offset.width / 2
  e.clientY = offset.top + offset.height / 2
  el.dispatchEvent(e)
  setTimeout(function () {
    el.dispatchEvent(new Event('mouseup'))
  }, timeout)
}

/**
 * Format a Number as a currency string.
 *
 * @param {Number} n string to be formatted
 * @param {String} prefix currency prefix to be prepended to the string (default: '£')
 *
 * @returns {String}
 */
export function formatCurrency(n: string | number, prefix = DEFAULT_CURRENCY_PREFIX): string {
  const prependPrefix = (v: string) => prefix + v
  const split = Number.parseFloat(String(n)).toFixed(2).toString().split('.')
  const str = split[0]
  if (split.length <= 1) return prependPrefix(str) + '.00'
  else if (split.length > 1 && split[1].length === 1) return `${prependPrefix(str)}.${split[1]}0`
  else if (split.length > 1 && split[1].length >= 2) return `${prependPrefix(str)}.${split[1]}`
  else return prefix + str
}

/**
 * Return an array filled with all the integers between 2 numbers.
 *
 * @param {Number} x1 First number
 * @param {Number} x2 Second number
 * @param {Boolean} inclusive The range should be considered inclusive (default: true)
 * @param {Boolean} ascending The returned array should be in ascending order (default: true)
 *
 * @returns {Array} Array containing the integers between x1 and x2
 */
export function generateIntermediateIntegers(
  x1: number,
  x2: number,
  inclusive = true,
  ascending = true
): number[] {
  if (!isNumber(x1) || !isNumber(x2)) throw 'generateIntermediateIntegers requires a range'
  if (x1 === x2) return inclusive ? [x1, x2] : []
  const min = x1 < x2 ? x1 : x2
  const max = x1 > x2 ? x1 : x2
  const intermediates = []
  const dist = max - min
  for (let i = 1; i < dist; i++) intermediates.push(min + i)
  const res = []
  if (inclusive && isInteger(min)) res.push(min)
  intermediates.forEach(i => res.push(i))
  if (inclusive && isInteger(max)) res.push(max)
  return ascending ? res : res.reverse()
}

/**
 * Hash a string.
 *
 * @param {String} str string to be hashed
 * @param {String} alg string defining algorithm to be used in hashing (optional)
 *
 * @returns {String}
 */
export function hash(str: string, alg = 'SHA-256'): string | undefined {
  if (alg === 'SHA-256') return CryptoJS.SHA256(str).toString()
  else return undefined
}

/**
 * Maps an array of objects to an object with key referencing a shared object property.
 *
 * Important: Key must be a unique field or values will be overwritten
 *
 * @param {Array} arr array to be objectified
 * @param {String} key object property to be used as key
 * @param {Boolean} strict if true, throw error when key is missing
 *
 * @returns {Object}
 */
export function objectify(
  arr: Record<string, any>[],
  key: string,
  strict = false
): Record<string, any> {
  return arr.reduce((a, v) => {
    if (Object.prototype.hasOwnProperty.call(v, key)) a[v[key]] = v
    else if (strict) throw 'cannot objectify with missing keys in strict mode'
    return a
  }, {})
}

export const makeObjectOfBoolsFromArrayOfStrings = (
  arr: string[],
  bool = false
): Record<string, boolean> => makeObjectOfSomethingFromArrayOfStrings(arr, bool)

export const makeObjectOfNullsFromArrayOfStrings = (arr: string[]): Record<string, boolean> =>
  makeObjectOfSomethingFromArrayOfStrings(arr, null)

export const makeObjectOfSomethingFromArrayOfStrings = (
  arr: string[],
  something: any
): Record<string, boolean> =>
  arr.reduce((a: Record<string, boolean>, v: string) => {
    a[v] = something
    return a
  }, {})

/**
 * Returns true if the result of a Math.random() call <= the passed number. (Note: numbers <= 0 always return false, and numbers >= 1 always return true)
 *
 * @param {Number} p propability of being true
 *
 * @returns {Boolean}
 */
export const possiBool = (p: number): boolean => (p <= 0 ? false : Math.random() <= p)

/**
 * Returns a random float within a given range
 *
 * @param {Number} max maximum value to return (required)
 * @param {Number} min minimum value to return (default: 0)
 *
 * @returns {Number} A random float within the given range
 */
export const randomFloat = (max = 1, min = 0): number => Math.random() * (max - min) + min

/**
 * Returns a random integer within a given range
 *
 * @param {Number} max maximum integer value to return (required)
 * @param {Number} min minimum integer value to return (default: 0)
 *
 * @returns {Number} A random integer within the given range
 */
export const randomInt = (max: number, min = 0): number =>
  Math.floor(Math.random() * (max - min)) + min

/**
 * Remove prefix from a string.
 *
 * @param {String} str string to be altered
 * @param {String} pre prefix to be removed from string
 *
 * @returns {String} Altered string
 */
export function removePrefix(str: string, pre: string): string {
  return str.split(pre).slice(1).join('')
}

/**
 * Snakify object data properties (recursively).
 *
 * @param {Object} obj object to be snakified
 * @param {Boolean} recursive decides if snakification should be applied recursively
 *
 * @returns {Object}
 */
export function snakify(obj: Record<string, any>, recursive = true): Record<string, any> {
  return casify(obj, 'snake', recursive)
}

/**
 * Sort array of strings in alphabetical order.
 *
 * @param {Array} arr array to be sorted (required)
 * @param {Boolean} reversed reverse order (default: false)
 * @param {Boolean} ignoreCasing ignore casing (default: true)
 *
 * @returns {Object}
 */
export function sortAlphabetically(arr: string[], reversed = false, ignoreCasing = true): string[] {
  const sorted = arr.sort((a, b) => {
    const testable = ignoreCasing ? [a.toLowerCase(), b.toLowerCase()] : [a, b]
    if (testable[0] === testable[1]) return 0
    else if (testable[0] > testable[1]) return 1
    else if (testable[0] < testable[1]) return -1
    else return 0
  })
  return reversed ? sorted.reverse() : sorted
}

/**
 * Sort array of objects in alphabetical order by a given key.
 *
 * @param {Array} arr array of objects to be sorted (required)
 * @param {String} str the key to sort by (required)
 * @param {Boolean} reversed reverse order (default: false)
 * @param {Boolean} ignoreCasing ignore casing (default: true)
 *
 * @returns {Object}
 */
export function sortAlphabeticallyByKey(
  arr: Record<string, any>[],
  key: string,
  reversed = false,
  ignoreCasing = true
): Record<string, any>[] {
  const sorted = arr.sort((a, b) => {
    const testable = ignoreCasing ? [a[key].toLowerCase(), b[key].toLowerCase()] : [a, b]
    if (testable[0] === testable[1]) return 0
    else if (testable[0] > testable[1]) return 1
    else if (testable[0] < testable[1]) return -1
    else return 0
  })
  return reversed ? sorted.reverse() : sorted
}
