export default {
  navbar: {
    userGuide: 'Nápověda & Průvodci',
  },
  settings: {
    emailTypes: {
      generalManager: 'Generální ředitel',
      reception: 'Recepce/Přední stůl',
      sales: 'Výnos/Prodej',
      email24: '24hodinový email',
      notification: 'Oznámení',
      other: 'Ostatní',
    },
  },
  auth: {
    emailAndPasswordDoNotMatchOurRecords: 'E-mail a heslo neodpovídají našim záznamům',
    forgottenYourPassword: 'Zapomněli jste heslo?',
    forgottenYourUsername: 'Zapomněli jste uživatelské jméno?',
    helpUsFindYourUsername:
      'Uveďte údaje požadované v následujícím formuláři, abychom vám mohli pomoct zjistit uživatelské jméno.',
    helpUsResetYourPassword:
      'Uveďte své uživatelské jméno, abychom vám mohli pomoct obnovit heslo.',
    login: 'Přihlášení',
    logIn: 'Přihlásit se',
    logout: 'Odhlášení',
    logOut: 'Odhlásit se',
    password: 'Heslo',
    passwordReset: 'Obnovení hesla',
    pleaseEnterThePrimaryEmailAddress: 'Zadejte hlavní e-mailovou adresu spojenou s vaším účtem.',
    pleaseEnterYourUsername: 'Zadejte své uživatelské jméno...',
    pleaseEnterYourPassword: 'Zadejte své heslo...',
    primaryEmailAddress: 'Hlavní e-mailová adresa',
    unfortunatelyTheSystemCouldNotAuthenticateYou:
      'Systému se vás bohužel nepodařilo ověřit. Důvodem je obvykle vypršení platnosti relace. Chcete-li pokračovat, přihlaste se znovu.',
    username: 'Uživatelské jméno',
    usernameReminder: 'Připomenutí uživatelského jména',
    youAreBeingLoggedOut: 'Probíhá odhlášení.',
    yourSessionHasExpired: 'Vypršela platnost relace. Přihlaste se znovu.',
  },
  availability: {
    availability: 'Dostupnost',
    addAvailability: 'Přidat dostupnost',
    addingAvailability: 'Přidávání dostupnosti',
    accessible: 'Přístupný',
    double: 'Dvojlůžko',
    family: 'Rodina',
    single: 'Jednolůžko',
    twin: 'Dvě oddělená lůžka',
    room: 'Pokoj',
    rooms: 'Pokoje',
    numberAvailable: 'Počet volných',
    noCmacRate: 'Neuvedena sazba CMAC',
    rate: 'Sazba',
    useCmacAgreedRates: 'Použijte dohodnuté sazby CMAC',
    fullyBooked: 'Plně obsazeno',
    helpText1: 'K zadání dostupnosti zvolte datum nebo datové rozmezí.',
    helpText2: 'Pro výběr data klikněte do políčka pro příslušné datum.',
    helpText3: 'Pro výběr datového rozmezí klikněte do jiného políčka.',
    helpText4:
      'Zvolená data se zobrazí červeně. Ke zrušení výběru zvoleného data klikněte do políčka pro příslušné datum.',
    helpText5:
      'Klikněte na tlačítko Přidat dostupnost a zadejte celkový počet volných pokojů. Pokud jste již v minulosti zadali sazby, použijeme dohodnuté sazby CMAC. Jinak pro tuto dostupnost zadejte sazbu pouze za pokoj.',
    helpText6:
      'Pro stejné datum můžete přidat více záznamů, pokud máte volné pokoje za různé sazby.',
    publicHeader: 'Skupina CMAC – žádost o dostupnost - ',
    publicInstruction1: 'Zadejte počet pro každý typ volného pokoje a klikněte na tlačítko Odeslat',
    publicInstruction2:
      'Pomocí tohoto odkazu se můžete na tuto stránku vrátit a informace aktualizovat. Jejich platnost vyprší',
    ratesIncludeTax: 'Zadaná sazba musí být uvedena VČETNĚ daně / DPH',
    submittedBy: 'Předložené ',
    updatedBy: 'Aktualizováno uživatelem ',
    specificallyFor: 'Specificky pro ',
    parkFlyRoomOnly: 'Pouze pokoj pro spánek a létání',
    parkFly4Bundle: 'Sleep & Fly Room + 4 denní parkování',
    parkFly8Bundle: 'Sleep & Fly Room + 8 denní parkování',
    parkFly15Bundle: 'Sleep & Fly Room + 15 denní parkování',
    parkFlyToCloseOut:
      'Chcete-li uzavřít datum, vypněte příslušné položky. Chcete-li rezervaci znovu aktivovat, budete muset znovu nahrát tabulku s cenami.',
  },
  cmac: {
    actionedBy: 'Údaje zadal(a)',
    addAddress: 'Přidat adresu',
    addEmailAddress: 'Přidat e-mailovou adresu',
    addTelephoneNumber: 'Přidat telefonní číslo',
    addAComment: 'Přidat poznámku',
    addComment: 'Přidat poznámku',
    addExpense: 'Přidat výdaj',
    addingAComment:
      'Doplněním poznámky bude k této cestě vznesen dotaz. Použijte poznámku pouze v případě rozdílů neuvedených v popise cesty. Uveďte úplné informace, např. ujetou vzdálenost a důvod dotazu.',
    agreed: 'Odsouhlaseno',
    allOpen: 'Vše otevřeno',
    amend: 'Upravit',
    applies: 'Platí',
    apply: 'Použít',
    assignedOrganisation: 'Přiřazená organizace',
    availability: 'Dostupnost',
    awaitingResponseFromSupplier: 'Čekání na odpověď od dodavatele',
    billingAddress: 'Fakturační adresa',
    bulkUpload: 'Hromadné nahrání',
    bulkUploadHistory: 'Historie hromadného nahrávání',
    cancelled: 'Zrušeno',
    closed: 'Uzavřeno',
    cmac: 'CMAC',
    cmacReference: 'Zn. CMAC',
    companyDetails: 'Údaje o společnosti',
    companyFinancialDetails: 'Finanční údaje',
    companyOnBoardingDocuments: 'Registrační dokumentace pro společnost',
    companyNumber: 'Číslo společnosti',
    compliance: 'Soulad s předpisy',
    costConfirmation: 'Potvrzení nákladů',
    costVatBreakDown: 'Rozpis nákladů s DPH',
    coverage: 'Pokrytí',
    coverageAreas: 'Oblasti pokrytí',
    currentlyImpersonating: 'Currently impersonating',
    customDates: 'Vlastní data',
    customerName: 'Jméno zákazníka',
    customerOrganisation: 'Organizace zákazníka',
    departureDate: 'Datum odjezdu',
    depotAddress: 'Adresa skladu',
    dispute: 'Dotaz',
    disputed: 'Vznesen dotaz',
    disputeDetails: 'Údaje o dotazu',
    disputeEntry: 'Záznam k dotazu',
    disputeEntries: 'Záznamy k dotazu',
    disputeId: 'ID dotazu',
    disputes: 'Dotaz',
    disputesAwaitingAction: 'Sporů čekajících na vyřízení',
    documentType: 'Typ dokumentu',
    driver: 'Řidič',
    driverDetails: 'Údaje o řidiči',
    editAddress: 'Upravit adresu',
    editEmailAddress: 'Upravit e-mailovou adresu',
    editJourney: 'Upravit cestu',
    editTelephoneNumber: 'Upravit telefonní číslo',
    editOpenJourneys: 'Upravit otevřené cesty',
    endImpersonation: 'End impersonation',
    endTime: 'Čas ukončení',
    expenseItem: {
      meetAndGreet: 'Setkání a pozdrav',
      parking: 'Parkovné',
      toll: 'Mýtné',
      greenFee: 'Ekologická poplatek',
      misc: 'Různé',
      amount: 'Částka',
    },
    expenses: 'Výdaje',
    expired: 'Vypršela platnost',
    expiryDate: 'Datum vypršení platnosti',
    expiryDateRequired: 'Požadováno datum vypršení platnosti',
    extras: 'Dodatečné poplatky',
    fare: 'Jízdné',
    farePriceCalculation: 'Výpočet jízdného',
    financialDetails: {
      accountName: 'Název účtu',
      bankAddress: 'Adresa banky',
      ibanNumber: 'Číslo IBAN',
      currency: 'Měna',
    },
    fixedPrices: 'Pevné ceny',
    greenFee: 'Ekologický poplatek',
    holidayRates: 'Sazby ve svátky',
    holidays: 'Svátky',
    holidayUplift: 'Sváteční povznesení',
    holidayUpliftDescription:
      'Tato vlastní data platí pro VŠECHNY položky ceníku, pokud nejsou přepsány vlastním datem specifickým pro položku',
    hotelMarkupRates: 'Sazby marže hotelu',
    hotelProfile: 'Profil hotelu',
    hotelRatesTitle: 'Hotelové sazby',
    ifYouNeedToChangeHowWeKnowYouInOurSystemPleaseCallTheSupplierRelationsTeamOn:
      'Pokud v našem systému potřebujete změnit údaje, které o vás máme, obraťte se na tým pro vztahy s dodavateli na čísle',
    impersonating: 'Impersonating',
    inProgress: 'V chodu',
    invoiceId: 'ID faktury',
    invoices: 'Faktury',
    job: 'Zakázka | Zakázky',
    jobEntryId: 'ID záznamu o zakázce',
    jobTypes: 'Typy zakázek',
    journey: 'Cesta',
    journeys: 'Cesty',
    journeyChargeMethod: 'Způsob účtování za cestu',
    lastUpdated: 'Poslední aktualizace',
    leadMileage: 'Vzdálenost ujetá bez cestujících',
    leadMileageCost: 'Cena za vzdálenost ujetou bez cestujících',
    mileage: 'Ujeté kilometry',
    mileageRates: 'Sazby za ujeté kilometry',
    minimumPrice: 'Minimální ceny',
    noCustomDates: 'Žádná vlastní data',
    noOfPax: 'Počet cest.',
    open: 'Otevřeno',
    orAlternativelyEmail: 'Nebo případně e-mail',
    otherOrganisations: 'Jiné organizace',
    passenger: 'Cestující',
    parking: 'Parkování',
    parkingExpenses: 'Cena za parkování',
    pax: 'Cestující',
    percentageOfOriginalCostPrice: 'Procento původní ceny',
    permits: {
      currentHeading: 'AKTUÁLNÍ POVOLENÍ',
      openHeading: 'OTEVŘENO PRO ŽÁDOSTI',
      title: 'Povolení',
      claimed: 'Využito',
      status: 'Stav',
      expires: 'Platnost vyprší',
      apply: 'Použít nyní',
      outstandingAmendment: 'U tohoto povolení je nevyřízená žádost o změnu.',
      noClaimedPermits: 'Žádná využitá povolení.',
      noOpenApplications: 'Žádné nevyřízené žádosti.',
      download: 'Stáhnout',
      print: 'Vytisknout povolení',
      amend: 'Upravit',
    },
    pickupDateTime: 'Datum/čas vyzvednutí',
    pleaseAddAComment: 'Přidejte poznámku',
    pleaseContactXForAssistance: 'Potřebujete-li pomoc, pomůže vám {x}',
    pleaseSelectAXToImpersonate: 'Please select a {x} to impersonate',
    policiesAndTerms: 'Zásady a podmínky',
    pricingInformation: 'Cenové informace',
    processing: 'Probíhá zpracování',
    profile: 'Profil',
    psvarStatus: 'Psvar Stav',
    rate: 'Sazba',
    rateCards: 'Ceníky',
    referredToOperations: 'Předáno provoznímu oddělení',
    referredToSupplierRelations: 'Předáno oddělení pro vztahy s dodavateli',
    referredToPricingManager: 'Odkázáno na manažera cen',
    regions: 'Regiony',
    registration: 'SPZ',
    responseReceivedFromSupplier: 'Obdržena odpověď od dodavatele',
    selectDocumentType: 'Zvolte typ dokumentu',
    selectUserToImpersonate: 'Select user to impersonate',
    standDownChargeMethod: 'Způsob účtování při nevyužití služby',
    standDownPrice: 'Cena při nevyužití služby',
    startTime: 'Čas zahájení',
    statementId: 'ID prohlášení',
    stoodDown: 'Služba nevyužita',
    stoodDownWithinXMinutesOfDepartureDate: 'Služba nevyužita do {x} min od data odjezdu',
    summary: 'Shrnutí',
    supplierName: 'Jméno dodavatele',
    supplierRef: 'Dodav. ref.',
    termsAndConditions: 'Podmínky',
    thankYouForLoggingInToTheSupplierPortal:
      'Děkujeme, že jste se přihlásili k portálu pro dodavatele',
    tierType: 'Typ úrovně',
    updateFailed: 'Aktualizace se nezdařila',
    updateSuccessful: 'Aktualizace úspěšně proběhla',
    userDetails: 'Údaje o uživateli',
    hotelDetails: 'Údaje o hotelu',
    vehicle: 'Vozidlo',
    vehicleRates: 'Sazby za vozidlo',
    vatNumber: 'DIČ',
    vatTreatment: 'Zpracování DPH',
    vias: 'Trasy',
    viewAll: 'Zobrazit vše',
    waypoint: 'Souřadnice',
    waitingTime: 'Čekací doba',
    waitingTimeCost: 'Cena za čekací dobu',
    waitingTimeFree: 'Bezplatná čekací doba',
    waitingTimeMinutes: 'Počet minut čekací doby',
    waitingTimeRate: 'Sazba za čekací dobu',
    withinXMinutes: 'Do {x} minut',
    vehicles: 'Vozidla',
    vehicleRegistration: 'Registrace Vozidla',
    vehicleType: 'Typ vozidla',
    yourAccountIsCurrentlyInactive: 'Váš účet je v současné době neaktivní',
    yourReference: 'Vaše značka',
    total: 'Celkem',
    invoiceuploads: 'Nahrané faktury',
    hotelRates: {
      validBetween: 'Platí v rozmezí',
      addNewRate: 'Přidat sazby',
      addNewRateText: 'Pro tuto organizaci je v systému nevyřízená žádost.',
      noRatesTextPrepend:
        'Pro vaši organizaci nejsou v systému žádné aktuální sazby. Spojte se s námi: ',
      noRatesTextAppend: 'Rádi bychom sazby doplnili.',
      hasRatesText:
        'V systému máme v současné době následující sazby. Pokud potřebujete provést změny, spojte se s námi: ',
      profile: 'Profil',
      profileStarRating: 'Hvězdičkové hodnocení',
      profileUnkown: 'Neznámé',
      profileYes: 'Ano',
      profileNo: 'Ne',
      profileYesFree: 'Ano zdarma',
      profileNumberOfRooms: 'Počet pokojů',
      profileAccessibleRooms: 'Přístupné pokoje',
      profileFamilyRooms: 'Rodinné pokoje',
      profileHotelBus: 'Hotelový autobus',
      profileParking: 'Parkování',
      profileSpa: 'Lázně',
      profileSwimmingPool: 'Bazén',
      profileGym: 'Tělocvična',
      profileRestaurant: 'Restaurace',
      commissionRate: 'Provize',
      commissionRooms: 'Pokoje',
      commissionFood: 'Jídlo',
      commissionDynamic: 'Dynamické',
      editText: 'Hotelový ceník',
      editProfileText: 'Upravit údaje o hotelu',
      ratesValidDatesText: 'Sazby platí v tomto datovém rozmezí',
      ratesValidFrom: 'Platnost od',
      ratesValidTo: 'Platnost do',
      rates: 'Sazby',
      ratesType: 'Typ',
      ratesRoomText: 'Sazby za pokoj pro cestující ze zpožděných letů a pro posádky letadel',
      ratesRoomType: 'Typ pokoje',
      ratesMidweekText: 'Pouze pobyt uprostřed týdne (neděle–čtvrtek)',
      ratesDayLet: 'Pobyt bez noclehu',
      ratesWeekendText: 'Pouze víkendový pobyt (pátek-sobota)',
      ratesRoomTwinDouble: 'Dvoulůžkový pokoj',
      ratesRoomSingle: 'Jednolůžkový pokoj',
      ratesRoomTriple: 'Trojlůžkový pokoj',
      ratesRoomFamily: 'Rodina',
      ratesWeekdayRate: 'Sazba pro všední den',
      ratesWeekdayDayRate: 'Sazba za pobyt bez noclehu ve všední den',
      ratesWeekendRate: 'Víkendová sazba',
      ratesWeekendDayRate: ' Sazba za pobyt bez noclehu o víkendu',
      ratesFoodText: 'Jídlo a nápoje (včetně čaje, kávy, ovocné šťávy) Cena za osobu',
      ratesContinentalBreakfast: 'Kontinentální snídaně',
      ratesFullBreakfast: 'Kompletní snídaně',
      ratesLunch: 'Oběd',
      ratesDinner: 'Večeře',
      ratesAgreementText:
        'Zadání těchto sazeb zakládá závaznou dohodu mezi skupinou CMAC Group a vaším hotelem, a proto byste měli zajistit, že formulář vyplní pouze oprávněná osoba. Hotel ponese odpovědnost za osobu, která formulář vyplní, a pokud ho vyplní neoprávněná osoba, nebude to mít dopad na skupinu CMAC ani na její možnost vymáhat tyto sazby.',
      ratesTermsConditionsText:
        'Zaškrtněte toto políčko, abyste potvrdili, že jste si přečetli naše ',
      ratesTermsConditionsLinkText: 'smluvní podmínky',
      viewRatesTermsConditionsText: 'Kliknutím sem zobrazíte naše',
      submittedHeading: 'Údaje zadal(a)',
      outstandingRateRequest: 'Nezaplacená žádost o sazbu',
      submittedByName: 'Jméno',
      submittedByEmail: 'Email',
      submittedByNameRequired: 'Zadejte prosím své jméno.',
      submittedByEmailRequired: 'Zadejte prosím svou e-mailovou adresu.',
      submittedSuccess: 'Děkujeme za odeslání vašich sazeb. Těšíme se na spolupráci.',
      errorSaving:
        'Při ukládání vašich sazeb došlo k chybě. Obraťte se prosím na hotelrelations@cmacgroup.com pro pomoc.',
    },
    cmacRef: 'Zn. CMAC',
    yourRef: 'Vaše značka ',
    noNotificationEmailAddress: 'Nemáte žádnou oznamovací e-mailovou adresu',
    leadMiles: 'Vedené míle',
    fareNet: 'Jízdné (bez DPH)',
    leadMilesCost: 'Náklady na přední míle (bez DPH)',
    totalCost: 'Celkové náklady (bez DPH)',
    appliesOnDays: 'Platí ve dnech',
    noVehicleRates: 'Žádné sazby za vozidla',
    markup: 'Označení',
    addedPrice: 'Přidaná Cena',
    noHolidayRates: 'Žádné prázdninové sazby',
    standDownRates: 'Snížit sazby',
    noStandDownRates: 'Žádné pohotovostní sazby',
    stoodDownWithin: 'Stál uvnitř',
    minutesOfDepartureDate: 'minuty data odjezdu',
    ofOriginalCostPrice: 'původní nákladové ceny',
    ofOriginalCostPriceWhicheverIsHigher: 'původní nákladové ceny, podle toho, která je vyšší',
    waitingTimeRates: 'Sazby za čekací dobu',
    noWaitingTimeRates: 'Žádné sazby za čekací dobu',
    minutesFree: 'minuty zdarma',
    minutes: 'minut',
  },
  common: {
    about: 'O nás',
    accepted: 'Přijato',
    addComment: 'Přidat komentář',
    add: 'Přidat',
    addVehicle: 'Přidat vozidlo',
    actions: 'Kroky',
    addXtoY: 'Přidat {x} až {y}',
    agreed: 'Schváleno',
    agreementType: 'Typ dohody',
    all: 'Vše',
    back: 'Zpět',
    backToTop: 'Zpět na začátek',
    cancel: 'Zrušit',
    close: 'Zavřít',
    comment: 'Poznámka',
    comments: 'Poznámky',
    completed: 'Ukončeno',
    confirm: 'Potvrdit',
    continue: 'Pokračovat',
    cost: 'Náklady',
    create: 'Vytvořit',
    created: 'Vytvořeno',
    createdAt: 'Vytvořeno',
    customer: 'Zákazník',
    date: 'Datum',
    dateRange: 'Datové rozmezí',
    delete: 'Odstranit',
    departureDateRange: 'Datové rozmezí odjezdu',
    description: 'Popis',
    details: 'Další informace',
    disputed: 'Vznesen dotaz',
    distance: 'Vzdálenost',
    documents: 'Dokumenty',
    download: 'Stáhnout',
    edit: 'Upravit',
    edited: 'Upraveno',
    editVehicle: 'Upravit vozidlo',
    email: 'E-mail',
    enabled: 'aktivní',
    error: 'Chyba',
    example: 'Příklad',
    expired: 'Vypršela platnost',
    expires: 'Platnost vyprší',
    failed: 'Neúspěšné',
    file: 'Soubor',
    filename: 'Název souboru',
    filters: 'Filtry',
    from: 'Od',
    full: 'plný',
    half: 'Polovina',
    home: 'Hlavní stránka',
    hide: 'Skrýt',
    id: 'ID',
    info: 'Informace',
    important: 'Důležité',
    jobNo: 'Č. zakázky',
    loading: 'Nahrávání',
    map: 'Mapa',
    messages: 'Zprávy',
    miles: 'Kilometry',
    minutes: 'Minuty',
    mode: 'Režim',
    name: 'Název',
    new: 'Nové',
    no: 'Ne',
    none: 'Žádné',
    notes: 'Poznámky',
    null: 'Nula',
    ok: 'OK',
    open: 'Otevřít',
    organisation: 'Organizace',
    organisations: 'Organizace',
    original: 'Původní',
    others: 'Ostatní',
    password: 'Heslo',
    pending: 'Nevyřízeno',
    price: 'Cena',
    previouslyUploaded: 'Dříve nahráno',
    processed: 'Zpracováno',
    processing: 'Probíhá zpracování',
    protection: 'Ochrana',
    quantity: 'Množství',
    raw: 'Nezpracované',
    readonly: 'Pouze ke čtení',
    reference: 'Značka',
    refresh: 'Aktualizovat',
    reset: 'Obnovit',
    roles: 'Role',
    save: 'Uložit',
    search: 'Hledat',
    settings: 'Nastavení',
    show: 'Zobrazit',
    signedBy: 'Podepsal(a)',
    signedOn: 'Datum podpisu',
    sortBy: 'Seřadit podle',
    status: 'Stav',
    submit: 'Odeslat',
    surcharge: 'Příplatek',
    test: 'Test',
    time: 'Čas',
    title: 'Titul',
    to: 'Komu',
    total: 'Celkem',
    type: 'Typ',
    unknown: 'Neznámé',
    update: 'Aktualizovat',
    updated: 'Aktualizováno',
    updatedAt: 'Aktualizováno',
    upload: 'Nahrát',
    uploaded: 'Nahráno',
    user: 'Uživatel',
    username: 'Uživatelské jméno',
    users: 'Uživatelé',
    website: 'Web',
    weight: 'Váha',
    yes: 'Ano',
    next: 'Další',
    hour: 'hodina',
    mile: 'míle',
    or: 'nebo',
  },
  currencies: {
    gbp: '£',
    eur: '€',
  },
  datetime: {
    monday: 'pondělí',
    tuesday: 'úterý',
    wednesday: 'středa',
    thursday: 'čtvrtek',
    friday: 'pátek',
    saturday: 'sobota',
    sunday: 'neděle',
    january: 'leden',
    february: 'únor',
    march: 'březen',
    april: 'duben',
    may: 'květen',
    june: 'červen',
    july: 'červenec',
    august: 'srpen',
    september: 'září',
    october: 'říjen',
    november: 'listopad',
    december: 'prosinec',
    today: 'dnes',
  },
  docs: {
    documentation: 'Dokumentace',
    faq: 'Často kladené otázky',
    frequentlyAskedQuestions: 'Často kladené otázky',
    tutorials: 'Kurzy',
  },
  errors: {
    301: 'Nemáte oprávnění přistupovat k tomuto zdroji.',
    400: 'Je nám líto, žádost byla neúspěšná.',
    404: 'Bohužel se nám nepodařilo najít hledaný zdroj.',
    500: 'Serveru se nepodařilo zpracovat žádost.',
    error: 'Chyba',
    nError: 'chyba {n}',
    noResponseFromServer: 'Server neodpovídá',
    noUrl: 'Chybí url',
    sorrySomethingWentWrong: 'Omlouváme se, něco se nepodařilo',
    uploadFailed: 'Nahrání se nezdařilo',
    uploadFailedWithError: 'Nahrání se nezdařilo, vyskytla se chyba',
  },
  fields: {
    address: 'Adresa',
    addresses: 'Adresy',
    addressLine1: 'Řádek adresy 1',
    addressLine2: 'Řádek adresy 2',
    addressLine3: 'Řádek adresy 3',
    addressLookup: 'Hledání adresy',
    city: 'Město',
    country: 'Země',
    county: 'Kraj',
    description: 'Popis',
    driverName: 'Jméno řidiče',
    driverPhone: 'Telefonní číslo řidiče',
    driverRegistration: 'Registrace řidiče',
    email: 'E-mail',
    emailAddress: 'E-mailová adresa',
    emailAddresses: 'E-mailové adresy',
    home: 'Bydliště',
    mobile: 'Mobil',
    name: 'Jméno',
    number: 'Číslo',
    phoneNumber: 'Číslo telefonu',
    postCode: 'PSČ',
    region: 'Kraj',
    registrationNumber: 'Registrační číslo',
    telephoneNumber: 'Telefonní číslo',
    telephoneNumbers: 'Telefonní čísla',
    title: 'Oslovení',
    titles: {
      dr: 'dr.',
      lady: 'lady',
      lord: 'lord',
      mr: 'pan',
      miss: 'slečna',
      mrs: 'paní',
      ms: 'paní',
      mx: 'paní',
      prof: 'prof. ',
      sir: 'sir',
    },
    town: 'Město',
    work: 'Zaměstnání',
  },
  onboarding: {
    documents: {
      public_insurance: 'Potvrzení o pojištění odpovědnosti za škodu',
      liability: 'Potvrzení o pojištění odpovědnosti zaměstnavatele',
      motor_insurance: 'Osvědčení o pojištění motorových vozidel',
      gdpr: 'Zásady GDPR',
      small_operator_license: 'Oprávnění k obsluze - malá vozidla',
      large_operator_license: 'Licence provozovatele - velká vozidla',
      upload: 'Nahrát dokument',
      download: 'Stáhnout dokument',
      file: 'název souboru',
      document: 'Typ dokumentu',
      created: 'Vytvořeno dne',
      expires: 'Platnost vyprší dne',
    },
    agreement: 'Přečetl jsem si výše uvedené podmínky a souhlasím s nimi.',
    mustAgree: 'Chcete-li aplikaci používat, musíte přijmout smluvní podmínky.',
    welcomeHeader: 'Vítejte na stránce pro registraci dodavatelů CMAC',
    country: 'Název země',
    legalCompanyName: 'Právní název společnosti',
    tradingName: 'Obchodní název',
    companyRegistrationNumber: 'IČO',
    registeredAddress: 'Sídlo',
    operatingAddress: 'Adresa provozovny',
    currency: 'Odhlášení',
    logOut: 'Odhlásit se',
    smallVehicle:
      'Malé vozidlo (taxi, středně těžké vozidlo, vozidlo přizpůsobené pro invalidní vozík, černé taxi)',
    largeVehicle: 'Velké vozidlo (minibus, autobus)',
    fleetSize: 'Velikost vozového parku',
    emailAddress: 'E-mailová adresa',
    mainContactNumber: 'Zadejte své heslo...',
    primaryEmailAddress: 'Primární e-mailová adresa',
    successResult: 'Účet byl úspěšně vytvořen, podívejte se do schránky příchozí pošty',
    failResult:
      'Váš účet nyní nebylo možné vytvořit. Účet se zadanými údaji již existuje. Zašlete žádost o další informace na adresu supplier.relations@cmacgroup.com.',

    progressHeader:
      'Děkujeme za poskytnutí informací nezbytných k tomu, abyste se stali dodavatelem CMAC.',
    progressMainText:
      'Aby bylo možné zpracovat vaši žádost, vyplňte všechny části v oddílech Finanční údaje a Registrační dokumentace pro společnost.',
    progressSubText:
      'Jakmile tyto informace obdržíme, bude možné přistoupit k další fázi zpracování vaší žádosti.',
    progressBarText: 'Postup registrace',
    progressCompleted:
      'Děkujeme vám za odeslání všech vašich dokumentů. Jeden z našich týmů nyní zkontroluje vaši žádost a brzy vás kontaktuje ohledně dalšího postupu.',
    rejected:
      'Bohužel, vaše žádost nebyla úspěšná. Prosím, kontaktujte naši tým pro vztahy se dodavateli pro další informace.',
  },
  plurals: {
    comments: 'Poznámky | Poznámky',
    journeys: 'Cesta | Cesty',
    results: 'Výsledek | Výsledky',
    resultsFound: 'Nalezený výsledek | Nalezené výsledky',
    rows: 'Řádek | Řádky',
    suppliers: 'Dodavatel | Dodavatelé',
  },
  ui: {
    anNewVersionOfTheApplicationIsAvailable: 'K dispozici je nová verze žádosti',
    appName: 'Portál pro dodavatele',
    approve: 'Schválit',
    approveAllSelectedRows: 'Schválit všechny zvolené řádky',
    changeProfilePicture: 'Změnit profilovou fotografii',
    chooseX: 'Vybrat {x}',
    clear: 'Vynulovat',
    clearFilters: 'Vynulovat filtry',
    clickToCopyToClipboard: 'Kliknutím zkopírovat do schránky',
    clickToSortByX: 'Kliknutím seřadit podle {x}',
    closeDrawer: 'Zavřít zásuvku',
    currentlyUnavailable: 'Aktuálně nedostupné',
    dashboard: 'Ovládací panel',
    document: 'Dokument',
    dragYourXHereToUploadIt: 'Nahrajte položku {x} přetažením sem',
    export: 'Exportovat',
    exportToCsv: 'Exportovat do CSV',
    file: 'soubor',
    files: 'Soubory',
    fileSizeTooBig: 'Soubor je příliš velký',
    footer: 'Zápatí',
    home: 'Hlavní stránka',
    homePage: 'Domovská stránka',
    itemsPerPage: 'Počet položek na stránku',
    navigation: 'Navigace',
    noDetailsToDisplay: 'Nic k zobrazení',
    noFileSelected: 'Nebyl vybrán žádný soubor',
    noResultsToShow: 'Žádné výsledky k zobrazení',
    noXProvided: 'Neposkytnuta položka {x}',
    orClickToBrowse: 'Nebo klikněte a procházejte seznam',
    page: 'Stránka',
    pageSize: 'Velikost stránky',
    pleaseSeletFromTheFollowing: 'Vyberte z následujících položek',
    pleaseSelectAnX: 'Vyberte položku {x}',
    pleaseSelectAX: 'Vyberte položku {x}',
    pleaseSelectNXOnly: 'Vyberte pouze položku {n} {x}',
    profilePicture: 'Profilová fotografie',
    resultsPerPage: 'Počet výsledků na stránku',
    saveChanges: 'Uložit změny',
    selectAll: 'Vybrat vše',
    selectX: 'Vybrat položku {x}',
    selectADifferentX: 'Vyberte jinou položku {x}',
    spreadsheet: 'Tabulka',
    theXHasBeenDeleted: 'Položka {x} byla odstraněna',
    thisWillPutXInDispute: 'Tímto krokem bude k položce {x} vznesen dotaz',
    updateX: 'Aktualizovat položku {x}',
    uploadBeingProcessed: 'Probíhá nahrávání',
    uploadStatus: 'Stav nahrávání',
    uploading: 'Nahrávání',
    uploadNewX: 'Nahrát novou položku {x}',
    uploadX: 'Nahrát položku {x}',
    userProfile: 'Profil uživatele',
    version: 'verze',
    xMissing: 'Chybí {x}',
    policySignedX: 'Děkujeme, že jste podepsali {x}',
  },
  validations: {
    canOnlyContainLettersAndNumbers: 'Může obsahovat pouze písmena a číslice',
    canOnlyContainLettersNumbersAndTheFollowingCharactersX:
      'Může obsahovat pouze písmena, číslice a následující znaky: {x}',
    canOnlyContainNumbers: 'Může obsahovat pouze číslice',
    checkboxConfirm: 'Zaškrtnutím políčka potvrďte svůj souhlas s těmito smluvními podmínkami',
    maxNCharacters: 'Max. počet znaků: {n}',
    minNCharacters: 'Min. počet znaků: {n}',
    mustBeAnInteger: 'Musí být celé číslo',
    mustBeAFloat: 'Musí být desetinné číslo',
    mustBeAValidEmailAddress: 'Musí být platná e-mailová adresa',
    mustBeAValidTelephoneNumber: 'Musí být platné telefonní číslo',
    mustBeAWholeNumber: 'Musí být celé číslo',
    mustBeExactlyNCharacters: 'Musí být dodržen přesný počet znaků {n}',
    mustBeGreaterThanN: 'Musí být větší než {n}',
    mustBeGreaterThanOrEqualToN: 'Musí být větší než nebo rovno {n}',
    mustBeLessThanN: 'Musí být menší než {n}',
    mustBeLessThanOrEqualToN: ' Musí být menší než nebo rovno {n}',
    mustBeRoundedToExactlyNDecimalPlaces:
      'Musí být zaokrouhleno na přesný počet desetinných míst {n}',
    mustBeRoundedToNDecimalPlaces: 'Musí být zaokrouhleno na počet desetinných míst {n}',
    mustHaveASingleDecimalPoint: 'Musí mít jedno desetinné místo',
    requiresBooleanValue: 'Vyžaduje Booleovu hodnotu',
    thisDateMustBeInTheFuture: 'Toto datum musí být v budoucnu',
    thisDateMustBeInThePast: ' Toto datum musí být v minulosti',
    thisExceedsTheLeadMileageLimit:
      'Tato hodnota přesahuje limit vzdálenosti ujeté bez cestujících',
    thisFieldIsRequired: 'Toto pole je požadováno',
    thisFieldMustBeAValidDate: 'V tomto poli musí být platné datum',
    thisFieldMustContainAX: 'Toto pole musí obsahovat položku {x}',
    thisFieldMustContainAnX: 'Toto pole musí obsahovat položku {x}',
    mustBeUkBankSortCode: 'Kód banky musí být ve formátu 00-00-00',
    mustBeUkBankAccountNumber: 'Číslo bankovního účtu ve Velké Británii musí mít 8 číslic',
    mustBeAtLeastOneChecked: 'Zaškrtněte jednu nebo obě hodnoty',
  },
  invoiceUpload: {
    invoiceNo: 'Č. faktury',
    reference: 'Značka',
    uploadDate: 'Datum nahrání',
    invoiceDate: 'Datum vystavení faktury',
    dueDate: 'Datum splatnosti',
    total: 'Celkem',
    currency: 'Měna',
    status: 'Stav',
    comments: 'Poznámky',
    file: 'Soubor',
    selectJourneys: 'Vybrat cesty',
    uploadInvoice: 'Nahrát fakturu',
    confirm: 'Potvrdit',
    uploadingAs: 'Nyní nahráváte jako',
    atLeastOneJourney: 'Musíte vybrat nejméně jednu cestu',
    errorUploadingFile: 'Při nahrávání a analýze souboru nastala chyba.',
    uploadInFormat: 'Nahrajte fakturu ve formátu PDF.',
    selectFile: 'Vyberte soubor, který chcete nahrát',
    uploadingAnalyzing: 'Probíhá nahrávání a analýza',
    invoiceReference: 'Značka faktury',
    invoice: 'Faktura',
    cmacReference: 'Značka CMAC',
    yourReference: 'Vaše značka',
    journeysSelectionJourney: 'cesta | cesty',
    journeySelectionSelected: 'vybráno',
    net: 'Čistá částka',
    tax: 'Daň',
    gross: 'Hrubá částka',
    expenses: 'Výdaje',
    details: 'Podrobnosti',
    journey: 'Cesta',
    departureDate: 'Datum odjezdu',
    cmacRef: 'Zn. CMAC',
    yourRef: 'Vaše značka ',
    invoiceAccepted: 'Faktura ověřena a přijata',
    invoiceDoesNotMatch: 'Zkontrolujte a znovu odešlete',
    stillUpload: 'Můžete tuto fakturu nahrát, ale bude vyžadovat prošetření. ',
    paymentDelayed: 'Platba bude pozdržena, dokud prošetření neproběhne.',
    totalNetDoesNotMatch:
      'Celková čistá částka na faktuře neodpovídá celkové čisté ceně za všechny vybrané jízdy.',
    expected: 'Očekáváno',
    totalGross: 'Celkem hrubá částka',
    totalNet: 'Celkem čistá částka',
    totalTax: 'Daň celkem',
    notFound: 'nenalezeno',
    invoiced: 'Fakturováno',
    underDispute: 'Vznesen dotaz',
    notUnique: 'Soubor se jménem již existuje. Vyberte prosím jiný soubor.',
    invalidCharacters: 'Název souboru obsahuje neplatné znaky. Následující nejsou povoleny.',
    invalidFileExtension: 'Tento typ souboru není platný. Jsou povoleny následující typy souborů.',
    cancelled: 'Zrušeno',
    journeyCost: 'Náklady na cestu (bez DPH)',
    leadMilesCost: 'Náklady na přední míle (bez DPH)',
    waitingTimeCost: 'Náklady na čekání (bez DPH)',
    expensesCost: 'Náklady na výdaje (bez DPH)',
    greenFeeCost: 'Náklady na poplatek za ekologii (bez DPH)',
    totalCost: 'Celkové náklady (bez DPH)',
    downloadJourneyData: 'Klikněte zde pro stažení dat vybraných tras.',
    paid: 'Zaplaceno',
    excludedJourneys: 'Tento proces vylučuje cesty, které jsou aktuálně sporné.',
    rejectedStatus: 'Odmítnuto',
    createPdfInvoice: 'Vytvořit fakturu ve formátu PDF',
    createPdf: {
      title: 'Vytvořit fakturu ve formátu PDF pro vybrané cesty',
      invoiceNumber: 'Číslo faktury',
      invoiceDate: 'Datum faktury',
      chooseAddress: 'Vyberte prosím adresu',
      registerDetails: {
        title: 'Zaregistrovat Detaily',
        heading: 'Pro vytvoření vaší faktury potřebujeme některé další informace.',
        financial: {
          heading: 'Pro přidání vašich finančních údajů postupujte podle následujících kroků:',
          step1: 'Vyberte ikonu hamburger menu {x} v pravém horním rohu obrazovky',
          step2: "Poté vyberte 'Profil'.",
          step3: "V sekci 'Finanční údaje' klikněte na tlačítko úprav {x} a zadejte své údaje.",
        },
        address: {
          heading: 'Pro přidání adresy vaší společnosti postupujte podle následujících kroků:',
          step1: 'Vyberte ikonu hamburger menu {x} v pravém horním rohu obrazovky.',
          step2: "Poté vyberte 'Profil'.",
          step3: "V sekci 'Adresy' klikněte na tlačítko plus {x} a zadejte své údaje.",
        },
      },
    },
    separateInvoicesRequired:
      'Jízdy s vozidly s více než 10 sedadly musí být fakturovány odděleně od jízd s vozidly s méně než 10 sedadly.',
    invoiceNumberFieldNameLabel: 'Název pole Číslo faktury',
    totalNetAmountFieldNameLabel: 'Název pole Celková čistá částka',
  },
  compliance: {
    introduction:
      'Níže je shrnutí klíčových dokumentů, které musí být na místě a zaregistrovány u nás. Pokud máte otázky týkající se některého z těchto dokumentů, kontaktujte nás prosím na 03333 207 100',
    noRulesFound: 'Nebyla nalezena žádná pravidla pro shodu',
  },
  contactItem: {
    generalManager: 'Generální ředitel',
    reception24Hour: 'Recepce / recepce',
    revenueSales: 'Tržby / Prodeje',
    twentyFourHourEmail: '24hodinový e-mail',
    notification: 'Oznámení',
    other: 'jiný',
  },
}
