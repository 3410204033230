export const APP_NAME = 'CMAC Supplier Portal'

export const CASIFY: Record<string, any> = Object.freeze({ ACCEPTED_CASES: ['camel', 'snake'] })

export const CHECKBOX_VALUES: Record<string, any> = Object.freeze({
  CHECKBOX_FALSE: 'check_box_outline_blank',
  CHECKBOX_TRUE: 'check_box',
})

export const CONTACT_ITEM_MODAL_MODES = Object.freeze({ ADD: 1, EDIT: 2 })

export const CONTACT_ITEM_TYPES = Object.freeze({
  ADDRESS: 1,
  EMAIL_ADDRESS: 2,
  EMPTY: 4,
  TELEPHONE_NUMBER: 3,
})

export const COOKIE_KEYS: readonly string[] = Object.freeze([
  'impersonatedUser',
  'impersonatableUsers',
  'activeSupplier',
  'activeSuppliers',
])

export const COORDINATES: Record<string, any> = Object.freeze({
  CENTER: [-4.082705, 52.119272],
  ZOOM: 5,
})

export const COUNTRIES: readonly string[][] = Object.freeze([
  ['Albania', 'AL'],
  ['Algeria', 'DZ'],
  ['Andorra', 'AD'],
  ['Austria', 'AT'],
  ['Belarus', 'BY'],
  ['Belgium', 'BE'],
  ['Bulgaria', 'BG'],
  ['Croatia', 'HR'],
  ['Cyprus', 'CY'],
  ['Czech Republic', 'CZ'],
  ['Denmark', 'DK'],
  ['Dubai', 'AE'],
  ['Egypt', 'EG'],
  ['Finland', 'FI'],
  ['France', 'FR'],
  ['Germany', 'DE'],
  ['Gibraltar', 'GI'],
  ['Greece', 'GR'],
  ['Guernsey', 'GG'],
  ['Iceland', 'IS'],
  ['Ireland', 'IE'],
  ['Isle of Man', 'IM'],
  ['Israel', 'IL'],
  ['Italy', 'IT'],
  ['Jersey', 'JE'],
  ['Latvia', 'LV'],
  ['Luxembourg', 'LU'],
  ['Malta', 'MT'],
  ['Morocco', 'MA'],
  ['Netherlands', 'NL'],
  ['Norway', 'NO'],
  ['Poland', 'PL'],
  ['Portugal', 'PT'],
  ['Romania', 'RO'],
  ['Serbia', 'RS'],
  ['Spain', 'ES'],
  ['Sweden', 'SE'],
  ['Switzerland', 'CH'],
  ['Tunisia', 'TN'],
  ['Turkey', 'TR'],
  ['Ukraine', 'UA'],
  ['United Kingdom', 'GB'],
  ['United States', 'US'],
])

export const CURRENCIES: Record<string, any> = Object.freeze({
  GBP: 0,
  EUR: 1,
})

export const DEFAULT_ADDRESS_PROPERTIES: readonly string[] = Object.freeze([
  'address1',
  'address2',
  'town',
  'region',
  'country',
  'postCode',
])

export const DEFAULT_CURRENCY_PREFIX = '£'

export const DEFAULT_DATE_FORMAT = 'dd/MM/yyyy'

export const DEFAULT_DATE_TIME_FORMAT = 'dd/MM/yyyy HH:mm:ss'

export const DEFAULT_DOCUMENT_EXPIRY_DAYS = 7

export const DEFAULT_DAY_RANGES = Object.freeze({
  BULK_UPLOAD: 14,
  COST_CONFIRMATION: 14,
  DISPUTES: 14,
  JOURNEY: 7,
})

export const DEFAULT_TABLE_OPTIONS: Record<string, any> = Object.freeze({
  page: 1,
  itemsPerPage: 50,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
})

export const DEFAULT_TIME_FORMAT = 'HH:mm:ss'

export const DISPUTE_ENTRY_TYPES: Record<string, any> = Object.freeze({
  NONE: 0,
  COMMENT_TO_SUPPLIER: 1,
  COMMENT_FROM_SUPPLIER: 2,
  REFERRED_TO_SUPPLIER_RELATIONS: 3,
  NOTE: 4,
  FileUploadFromSupplier: 7,
})

export const DISPUTE_STATUSES: Record<string, any> = Object.freeze({
  ALL: null,
  ALL_OPEN: -1,
  NEW: 0,
  AWAITING_RESPONSE_FROM_SUPPLIER: 1,
  CLOSED: 2,
  REFERRED_TO_SUPPLIER_RELATIONS: 3,
  REFERRED_TO_OPERATIONS: 4,
  RESPONSE_RECEIVED_FROM_SUPPLIER: 5,
  REFERRED_TO_PRICING_MANAGER: 6
})

export const DOCUMENT_TYPES = Object.freeze({ UNKNOWN: 0, INVOICE: 1 })

export const EXPENSE_TYPES = Object.freeze({
  MEET_AND_GREET: 1,
  PARKING: 2,
  TOLL: 5,
  WAITING_TIME: 6,
  CLEANING: 19,
  MISCELLANEOUS: 20,
  GREEN_FEE: 138,
})

export const HTTP: Record<string, any> = Object.freeze({
  STATUSES: {
    ERRORS: [
      ...[400, 401, 402, 403, 404, 405, 406, 407, 408, 409, 410, 411, 412, 413, 414, 415, 416, 417],
      419,
      ...[421, 422, 423, 424, 425, 426, 427, 428, 429, 430, 431],
      451,
      ...[500, 501, 502, 503, 504, 505, 506, 507, 508, 509, 510, 511],
    ],
    NON_GLOBAL_ERRORS: [401, 419, 422],
    NON_ERRORS: [
      ...[100, 101, 102, 103],
      ...[200, 201, 202, 203, 204, 205, 206, 207, 208],
      226,
      ...[300, 301, 302, 303, 304, 305, 306, 307, 308],
    ],
  },
})

export const IMPERSONATION_SELECTION_MODES = Object.freeze({
  ORGANISATION: 0,
  USER: 1,
})

export const JOURNEY_STATUSES: Record<string, any> = Object.freeze({
  OPEN: 0,
  AGREED: 1,
  DISPUTED: 2,
  EXPIRED: 3,
  ALL: 4,
  CLOSED: 5,
})

export const ORGANISATION_AGREEMENT_TYPES = Object.freeze({ SUPPLIER_TERMS_AND_CONDITIONS: 0 })

export const PERMIT_FORM_MODES: Record<string, any> = Object.freeze({ APPLY: 1, AMEND: 2 })

export const SNACKBAR_DEFAULTS: Record<string, any> = Object.freeze({
  BUTTON_COLOR: 'indigo',
  CLOSE_BUTTON: true,
  COLOR: 'primary',
  MESSAGE: '',
  RIGHT: true,
  TIMEOUT: 3000,
  TOP: true,
  VERTICAL: false,
  VALUE: false,
})

export const STORE_DEFAULTS: Record<string, Record<string, any>> = Object.freeze({
  CORE: {
    API_ENDPOINTS_LOADING: {},
    AUTH_TYPE: 'oidc',
    CSRF_TOKEN: '',
    ERRORS: {},
    KEEP_ALIVE: [],
    LAYOUT: 'loading-layout',
    ROUTER_VIEWPORT_OFFSET_TOP: 0,
    SHOW_SAVE_CARD_SPACER: false,
    SNACKBAR: {
      BUTTON_COLOR: SNACKBAR_DEFAULTS.BUTTON_COLOR,
      CLOSE_BUTTON: SNACKBAR_DEFAULTS.CLOSE_BUTTON,
      COLOR: SNACKBAR_DEFAULTS.COLOR,
      MESSAGE: SNACKBAR_DEFAULTS.MESSAGE,
      RIGHT: SNACKBAR_DEFAULTS.RIGHT,
      TIMEOUT: SNACKBAR_DEFAULTS.TIMEOUT,
      TOP: SNACKBAR_DEFAULTS.TOP,
      VERTICAL: SNACKBAR_DEFAULTS.VERTICAL,
      VALUE: SNACKBAR_DEFAULTS.VALUE,
    },
    STARTUP_COMPLETED: false,
    TABLES: { itemsPerPage: 50 },
    UPLOAD_PROGRESSES: [],
  },
  SUPPLIERS: {
    ACTIVE_SUPPLIER: 0,
    ACTIVE_SUPPLIERS: [0],
    BULK_UPLOADS: [],
    COVERAGE_AREAS: [],
    CURRENT_JOURNEYS: { journeys: [], pageIndex: 0, returnedRecords: 0, currentRecords: 0 },
    DISPUTES: [],
    DOCUMENTS: [],
    DOCUMENT_TYPES: [],
    DOWNLOAD_RESULTS: [],
    FARE_PRICE_CALCULATIONS: {},
    FIXED_PRICES: [],
    INVOICES: [],
    JOURNEYS: { journeys: [], pageIndex: 0, returnedRecords: 0, currentRecords: 0 },
    RATE_CARDS: {
      HOLIDAY_RATE_CARD_ENTRIES: [],
      HOTEL_RATE_CARDS: [],
      VEHICLE_RATE_CARD_ENTRIES: [],
    },
  },
})

export const TRUTHY: readonly (boolean | number | string)[] = Object.freeze([
  'true',
  'TRUE',
  true,
  1,
])

export const WAYPOINTS: Record<string, any> = Object.freeze({
  WAYPOINT_DESTINATION_ICON: 'place',
  WAYPOINT_ORIGIN_ICON: 'trip_origin',
})

export const AVAILABILITY_TYPES: Record<string, any> = Object.freeze({
  TAXI: 0,
  COACH: 1,
  HOTEL: 2,
})

export const DEFAULT_LOCALE: Record<string, any> = {
  COUNTRY: 'United Kingdom',
  LANG: 'en',
}

export const ALL_LOCALES: Readonly<string[][]> = Object.freeze([
  ['United Kingdom', 'en'],
  ['Spain', 'es'],
  ['Portugal', 'pt'],
  ['France', 'fr'],
  ['Germany', 'de'],
  ['Greece', 'el'],
  ['Czech Republic', 'cz'],
  ['Italy', 'it'],
  ['Netherlands', 'nl'],
  ['Poland', 'pl'],
  ['Romania', 'ro'],
])
