
import { Component, Prop, Vue } from 'vue-property-decorator'
import { config as env } from '@/plugins/env'
import api from '@/utilities/api'

const { VUE_APP_CORE_API_URL } = env

@Component
export default class CmsNotice extends Vue {
  // props // ***
  @Prop({ type: String })
  slug!: string

  // data // ***
  item: string | null = null
  interval: number | undefined = -1

  async mounted() {
    await this.fetchData()
    this.interval = setInterval(() => {
      this.fetchData()
    }, 1000 * 60 * 2)
  }

  beforeDestroy() {
    clearInterval(this.interval)
  }

  // methods // ***
  async fetchData() {
    const url = `/cms/${this.slug}`
    const res = await api(
      url,
      {
        json: true,
        method: 'GET',
        noPrefix: true,
        rootUrl: VUE_APP_CORE_API_URL,
      },
      {}
    )
    if (res.status !== 200 || !res.data) {
      this.item = null
      return
    }
    this.item = res.data
  }
}
