import axios from 'axios'

export const forwardGeocode = async (s: string): Promise<Record<string, any> | void> => {
  const mapboxApiToken = process.env.VUE_APP_MAPBOX_API_KEY
  const pre = 'https://api.mapbox.com/geocoding/v5/mapbox.places'
  const url = `${pre}/${s}.json?access_token=${mapboxApiToken}`
  return await axios.get(url, { withCredentials: false })
}

export const getBoundsFromCoordinates = (coordinates: number[][]): Record<string, any> => {
  let north: number | null = null,
    south: number | null = null,
    east: number | null = null,
    west: number | null = null
  coordinates.forEach((c: number[]) => {
    const lon = c[0]
    const lat = c[1]
    if (north === null || lat >= north) north = lat
    if (south === null || lat <= south) south = lat
    if (east === null || lon >= east) east = lon
    if (west === null || lon <= west) west = lon
  })
  return { north, south, east, west }
}

export const getCenterFromSetOfCoordinates = (coordinates: number[][]): number[] => {
  const { north, south, east, west } = getBoundsFromCoordinates(coordinates)
  const averageLatitude = (north + south) / 2
  const averageLongitude = (east + west) / 2
  return [averageLongitude, averageLatitude]
}

export const getPolygonFromCollection = (geoJson: Record<string, any>): Record<string, any> => ({
  type: 'geojson',
  data: {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'Polygon',
      coordinates: geoJson.features?.[0]?.geometry?.coordinates[0],
    },
  },
})
