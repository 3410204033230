interface RgbObject {
  r: number
  g: number
  b: number
}

export function getFilterGradient(hex: string, alpha = ['0.4', '0.8']): string {
  const { r, g, b } = hexToRgb(hex)
  return `to top left, rgba(${r},${g},${b},${alpha[0]}), rgba(0,0,0,${alpha[1]})`
}

export function hexToRgb(hex: string): RgbObject {
  const rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex) || []
  return { r: parseInt(rgb[1], 16), g: parseInt(rgb[2], 16), b: parseInt(rgb[3], 16) }
}

export function rgbToHex(r: number, g: number, b: number): string {
  const toHex = (c: any): string => {
    const hex = c.toString(16)
    return hex.length == 1 ? '0' + hex : hex
  }
  return '#' + toHex(r) + toHex(g) + toHex(b)
}
