
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ClickableDiv extends Vue {
  // props // ***
  @Prop()
  value!: string | number | undefined | null
  @Prop({ type: Boolean })
  hoverable!: boolean
}
