
import { formatCurrency } from '@/utilities/functions'
import { getCurrency } from '@/utilities/views'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class FareCalculationPopup extends Vue {
  // props // ***
  @Prop({ required: true })
  header!: Record<string, any>
  @Prop({ required: true })
  item!: Record<string, any>
  @Prop({ required: true })
  value!: any

  // computed // ***
  get fare() {
    const { currency, fare } = this.value
    return formatCurrency(fare, getCurrency(currency))
  }
  get farePriceCalculation() {
    return this.farePriceCalculations[this.item.id] || {}
  }
  get farePriceCalculations() {
    return this.$store.getters['suppliers/farePriceCalculations']
  }
  get loading() {
    return this.$store.getters['core/apiEndpointIsLoading']({ method: 'GET', url: this.url })
  }
  get url() {
    return `supplier/price/${this.item.id}`
  }

  // methods // ***
  fetch() {
    this.$store.dispatch('suppliers/fetchFarePriceCalculation', { id: this.item.id, url: this.url })
  }
  onClick() {
    if (!this.loading) this.fetch()
  }
}
