export default {
  navbar: {
    userGuide: 'Ajutor & Ghiduri',
  },
  settings: {
    emailTypes: {
      generalManager: 'Administrerende Direktør',
      reception: 'Resepsjon/Frontdesk',
      sales: 'Inntekt/Salg',
      email24: '24-timers E-post',
      notification: 'Varsel',
      other: 'Annet',
    },
  },
  auth: {
    emailAndPasswordDoNotMatchOurRecords: 'E-mailul și parola nu corespund evidențelor noastre',
    forgottenYourPassword: 'V-ați uitat parola?',
    forgottenYourUsername: 'V-ați uitat numele de utilizator?',
    helpUsFindYourUsername:
      'Vă rugăm să ne furnizați detaliile solicitate prin formularul de mai jos pentru a ne ajuta să vă găsim numele de utilizator',
    helpUsResetYourPassword:
      'Vă rugăm să ne furnizați numele dvs. de utilizator pentru a ne ajuta să vă resetăm parola.',
    login: 'Conectare',
    logIn: 'Conectare',
    logout: 'Deconectare',
    logOut: 'Deconectare',
    password: 'Parolă',
    passwordReset: 'Resetare parolă',
    pleaseEnterThePrimaryEmailAddress:
      'Vă rugăm să introduceți adresa de e-mail principală a contului dvs.',
    pleaseEnterYourUsername: 'Vă rugăm să introduceți numele de utilizator...',
    pleaseEnterYourPassword: 'Vă rugăm să introduceți parola...',
    primaryEmailAddress: 'Adresa de e-mail principală',
    unfortunatelyTheSystemCouldNotAuthenticateYou:
      'Din păcate, sistemul nu v-a putut autentifica. De obicei, acest lucru se întâmplă pentru că v-a expirat sesiunea. Pentru a continua, vă rugăm să vă conectați din nou.',
    username: 'Nume utilizator',
    usernameReminder: 'Memento nume utilizator',
    youAreBeingLoggedOut: 'Sistemul vă deconectează.',
    yourSessionHasExpired: 'Sesiunea dvs. a expirat. Vă rugăm să vă conectați din nou.',
  },
  availability: {
    availability: 'Disponibilitate',
    addAvailability: 'Adăugare disponibilitate',
    addingAvailability: 'Se adaugă disponibilitatea',
    accessible: 'Accesibilă',
    double: 'Dublă',
    family: 'Familie',
    single: 'Single',
    twin: 'Twin',
    room: 'Cameră',
    rooms: 'Camere',
    numberAvailable: 'Nr. disponibile',
    noCmacRate: 'Fără tarif CMAC',
    rate: 'Tarif',
    useCmacAgreedRates: 'Folosiți tarifele convenite cu CMAC',
    fullyBooked: 'Rezervat integral',
    helpText1:
      'Pentru a introduce disponibilitatea, selectați o dată sau un interval calendaristic.',
    helpText2: 'Pentru a selecta o dată, faceți clic în caseta datei respective.',
    helpText3: 'Pentru a selecta un interval, faceți clic într-o a doua căsuță diferită.',
    helpText4:
      'Datele selectate vor fi afișate cu roșu. Pentru a șterge o dată calendaristică selectată, faceți clic pe caseta datei respective.',
    helpText5:
      'Faceți clic pe butonul Adăugare disponibilitate și introduceți numărul total de camere disponibile. Dacă ați transmis anterior tarifele, vom folosi tarifele convenite cu CMAC. În caz contrar, vă rugăm să introduceți tariful exclusiv pentru cameră, aferent disponibilității respective.',
    helpText6:
      'Puteți adăuga înregistrări multiple pentru aceeași dată calendaristică, dacă aveți camere disponibile la tarife diferite.',
    publicHeader: 'Grupul CMAC - Solicitare disponibilitate - ',
    publicInstruction1:
      'Vă rugăm să introduceți numărul din fiecare tip disponibil și să faceți clic pe Transmitere',
    publicInstruction2:
      'Puteți folosi acest link pentru a reveni și actualiza informațiile ulterior. Acesta va expira la',
    ratesIncludeTax: 'Vă rugăm să vă asigurați că tariful INCLUDE taxe/TVA.',
    submittedBy: 'Trimis de ',
    updatedBy: 'Actualizat de ',
    specificallyFor: 'Specjalnie dla ',
    parkFlyRoomOnly: 'Numai în cameră Sleep & Fly',
    parkFly4Bundle: 'Cameră Sleep & Fly + 4 zile de parcare',
    parkFly8Bundle: 'Cameră Sleep & Fly + 8 zile de parcare',
    parkFly15Bundle: 'Cameră Sleep & Fly + 15 zile de parcare',
    parkFlyToCloseOut:
      'Pentru a închide o dată, vă rugăm să dezactivați elementele relevante. Pentru a reactiva rezervarea, va trebui să încărcați din nou foaia de calcul a prețurilor.',
  },
  cmac: {
    actionedBy: 'Acțiune executată de',
    addAddress: 'Adaugă Adresă',
    addEmailAddress: 'Adaugă Adresă de Email',
    addTelephoneNumber: 'Adaugă Număr de Telefon',
    addAComment: 'Adăugați un comentariu',
    addComment: 'Adăugare comentariu',
    addingAComment:
      'Adăugarea unui comentariu va interoga această călătorie. Vă rugăm să îl folosiți doar pentru diferențele care nu figurează în dreptul călătoriei. Vă rugăm să includeți detalii complete, de ex. kilometrajul și motivul interogării.',
    addExpense: 'Adăugați Cheltuială',
    agreed: 'De acord',
    allOpen: 'Toate deschise',
    amend: 'Modificare',
    applies: 'Se aplică',
    apply: 'Aplicare',
    assignedOrganisation: 'Organizație desemnată',
    availability: 'Disponibilitate',
    awaitingResponseFromSupplier: 'Se așteaptă răspunsul furnizorului',
    billingAddress: 'Adresă de Facturare',
    bulkUpload: 'Încărcare în bloc',
    bulkUploadHistory: 'Istoric încărcare în bloc',
    cancelled: 'Anulat',
    closed: 'Închis',
    cmac: 'CMAC',
    cmacReference: 'Referință CMAC',
    companyDetails: 'Datele companiei',
    companyFinancialDetails: 'Detalii financiare',
    companyOnBoardingDocuments: 'Documente de înscriere a companiei',
    companyNumber: 'Cod de înregistrare a companiei',
    compliance: 'Conformitate',
    costConfirmation: 'Confirmarea costurilor',
    costVatBreakDown: 'Defalcare TVA costuri',
    coverage: 'Acoperire',
    coverageAreas: 'Zone acoperite',
    currentlyImpersonating: 'În prezent asumă identitatea lui',
    customDates: 'Date personalizate',
    customerName: 'Numele clientului',
    customerOrganisation: 'Organizația clientului',
    departureDate: 'Data plecării',
    depotAddress: 'Adresă Depozit',
    dispute: 'Interogare',
    disputed: 'Interogat',
    disputeDetails: 'Interogarea detaliilor',
    disputeEntry: 'Interogarea înregistrării',
    disputeEntries: 'Interogarea înregistrărilor',
    disputeId: 'ID interogare',
    disputes: 'Interogări',
    disputesAwaitingAction: 'Litigii Așteptând Acțiune',
    documentType: 'Tip document',
    driver: 'Șofer',
    driverDetails: 'Detalii șofer',
    editAddress: 'Editează Adresă',
    editEmailAddress: 'Editează Adresă de Email',
    editJourney: 'Editează călătoria',
    editTelephoneNumber: 'Editează Număr de Telefon',
    editOpenJourneys: 'Editare călătorii deschise',
    endImpersonation: 'Încheierea asumării identității',
    endTime: 'Momentul încheierii',
    expenseItem: {
      meetAndGreet: 'Întâlnire și salut',
      parking: 'Parcare',
      toll: 'Taxă de drum',
      greenFee: 'Taxă ecologică',
      misc: 'Diverse',
      amount: 'Sumă',
    },
    expenses: 'Cheltuieli',
    expired: 'Expirat',
    expiryDate: 'Data expirării',
    expiryDateRequired: 'Este necesară data expirării',
    extras: 'Suplimente',
    fare: 'Tarif',
    farePriceCalculation: 'Calculul tarifului călătoriei',
    financialDetails: {
      accountName: 'Nume Cont',
      bankAddress: 'Adresă Bancă',
      ibanNumber: 'Număr IBAN',
      currency: 'Monedă',
    },
    fixedPrices: 'Prețuri fixe',
    greenFee: 'Taxă eco',
    holidayRates: 'Tarife sărbători legale',
    holidays: 'Sărbători legale',
    holidayUplift: 'Înălțarea sărbătorilor',
    holidayUpliftDescription:
      'Aceste date personalizate se aplică TUTUROR intrărilor de carduri tarifare, cu excepția cazului în care sunt înlocuite de o dată personalizată specifică intrării',
    hotelMarkupRates: 'Marje de profit ale hotelurilor',
    hotelProfile: 'Profilul hotelului',
    hotelRatesTitle: 'Tarifele hotelului',
    ifYouNeedToChangeHowWeKnowYouInOurSystemPleaseCallTheSupplierRelationsTeamOn:
      'Dacă trebuie să modificați informațiile cu care sunteți cunoscuți în sistemul nostru, vă rugăm să vă adresați echipei de Relații cu furnizorii, la',
    impersonating: 'Se asumă identitatea',
    inProgress: 'În curs',
    invoiceId: 'ID factură',
    invoices: 'Facturi',
    job: 'Job | Joburi',
    jobEntryId: 'ID introducere job',
    jobTypes: 'Tipuri de joburi',
    journey: 'Călătorie',
    journeys: 'Călătorii',
    journeyChargeMethod: 'Modalitatea de taxare a călătoriei',
    lastUpdated: 'Ultima actualizare',
    leadMileage: 'Kilometraj suplimentar fără pasager',
    leadMileageCost: 'Costul kilometrajului suplimentar fără pasager',
    mileage: 'Kilometraj',
    mileageRates: 'Tarife kilometraj',
    minimumPrice: 'Prețul minim',
    noCustomDates: 'Fără date personalizate',
    noOfPax: 'Nr. de Pax',
    open: 'Deschis',
    orAlternativelyEmail: 'Sau, alternativ, e-mail',
    otherOrganisations: 'Alte organizații',
    passenger: 'Pasager',
    parking: 'Parcare',
    parkingExpenses: 'Cheltuieli de parcare',
    pax: 'Pax',
    percentageOfOriginalCostPrice: 'Procentaj din prețul de cost inițial',
    permits: {
      currentHeading: 'AUTORIZAȚII ACTUALE',
      openHeading: 'POT FI SOLICITATE',
      title: 'Autorizații',
      claimed: 'Revendicate',
      status: 'Stare',
      expires: 'Expiră',
      apply: 'Solicitați acum',
      outstandingAmendment:
        'Pentru această autorizație există o cerere de modificare în curs de soluționare.',
      noClaimedPermits: 'Nicio autorizație revendicată.',
      noOpenApplications: 'Nicio cerere deschisă.',
      download: 'Descărcare',
      print: 'Tipărire autorizație',
      amend: 'Modificare',
    },
    pickupDateTime: 'Data/Ora ridicare',
    pleaseAddAComment: 'Vă rugăm să adăugați un comentariu',
    pleaseContactXForAssistance: 'Vă rugăm să contactați {x} pentru asistență',
    pleaseSelectAXToImpersonate: 'Vă rugăm să selectați o/un {x} pentru a-i asuma identitatea',
    policiesAndTerms: 'Politici și termeni',
    pricingInformation: 'Informații legate de preț',
    processing: 'Se procesează',
    profile: 'Profil',
    psvarStatus: 'Status Psvar',
    rate: 'Tarif',
    rateCards: 'Carduri tarife',
    referredToOperations: 'Transmis către operațiuni',
    referredToSupplierRelations: 'Transmis către relații cu furnizorii',
    referredToPricingManager: 'Transmis la managerul de prețuri',
    regions: 'Regiuni',
    registration: 'Înmatriculare',
    responseReceivedFromSupplier: 'Răspuns primit de la furnizor',
    selectDocumentType: 'Selectare tip document',
    selectUserToImpersonate: 'Selectare utilizator căruia i se asumă identitatea',
    standDownChargeMethod: 'Modalitate de taxare dacă clientul renunță',
    standDownPrice: 'Preț de renunțare',
    startTime: 'Momentul începerii',
    statementId: 'ID declarație',
    stoodDown: 'A renunțat',
    stoodDownWithinXMinutesOfDepartureDate:
      'A renunțat în interval de {x} minute de la data plecării',
    summary: 'Rezumat',
    supplierName: 'Denumirea furnizorului',
    supplierRef: 'Ref. furnizor',
    termsAndConditions: 'Termeni și condiții',
    thankYouForLoggingInToTheSupplierPortal:
      'Vă mulțumim pentru autentificarea în portalul pentru furnizori',
    tierType: 'Tipul nivelului',
    updateFailed: 'Actualizare eșuată',
    updateSuccessful: 'Actualizare efectuată cu succes',
    userDetails: 'Detalii utilizator',
    hotelDetails: 'Detalii hotel',
    vatNumber: 'Număr TVA',
    vatTreatment: 'Tratare TVA',
    vehicle: 'Vehicul',
    vehicleRates: 'Tarife vehicul',
    vias: 'Direcții',
    viewAll: 'Vizualizare toate',
    waypoint: 'Punct intermediar | Puncte intermediare',
    waitingTime: 'Timp de așteptare',
    waitingTimeCost: 'Costul timpului de așteptare',
    waitingTimeFree: 'Timp de așteptare gratuit',
    waitingTimeMinutes: 'Timp de așteptare minute',
    waitingTimeRate: 'Tariful timpului de așteptare',
    withinXMinutes: 'În interval de {x} minute',
    vehicles: 'Vehicule',
    vehicleRegistration: 'Inmatricularea Autoturismului',
    vehicleType: 'Tipul vehiculului',
    yourAccountIsCurrentlyInactive: 'În prezent, contul dvs. este inactiv',
    yourReference: 'Referința dvs.',
    total: 'Total',
    invoiceuploads: 'Încărcări facturi',
    hotelRates: {
      validBetween: 'Valabile între',
      addNewRate: 'Adăugare tarife noi',
      addNewRateText: 'Pentru această organizație este în curs o nouă cerere de tarif.',
      noRatesTextPrepend:
        'Nu există tarife actuale pentru organizația dvs. Vă rugăm să ne contactați la',
      noRatesTextAppend: 'pentru a aranja transmiterea tarifelor dvs.',
      hasRatesText:
        'Mai jos sunt tarifele actuale pe care le avem în sistem. Dacă doriți să efectuați modificări, vă rugăm să ne contactați la',
      profile: 'Profil',
      profileStarRating: 'Număr de stele',
      profileUnkown: 'Necunoscut',
      profileYes: 'Da',
      profileNo: 'Nu',
      profileYesFree: 'Da gratuit',
      profileNumberOfRooms: 'Număr de camere',
      profileAccessibleRooms: 'Camere accesibile',
      profileFamilyRooms: 'Camere pentru familie',
      profileHotelBus: 'Autocar de hotel',
      profileParking: 'Parcare',
      profileSpa: 'Spa',
      profileSwimmingPool: 'Piscină',
      profileGym: 'Sală de sport',
      profileRestaurant: 'Restaurant',
      commissionRate: 'Ratele de comision',
      commissionRooms: 'Camere',
      commissionFood: 'Masă',
      commissionDynamic: 'Dinamic',
      editText: 'Card de tarife hoteliere',
      editProfileText: 'Editare detalii hotel',
      ratesValidDatesText: 'Tarifele se aplică între aceste date calendaristice',
      ratesValidFrom: 'Valabile începând din',
      ratesValidTo: 'Valabile până la',
      rates: 'Tarife',
      ratesType: 'Tip',
      ratesRoomText: 'Tarifele pe cameră pentru pasagerii întârziați și echipajul de zbor',
      ratesRoomType: 'Tipul camerei',
      ratesMidweekText: 'Cameră numai în timpul săptămânii(Duminică-Joi)',
      ratesDayLet: 'Închiriere peste zi',
      ratesWeekendText: 'Cameră numai în weekend (Vineri-Sâmbătă)',
      ratesRoomTwinDouble: 'Twin / Dublă',
      ratesRoomSingle: 'Cameră single',
      ratesRoomTriple: 'Triplă',
      ratesRoomFamily: 'Familie',
      ratesWeekdayRate: 'Tariful în timpul săptămânii',
      ratesWeekdayDayRate: 'Tariful peste zi în timpul săptămânii',
      ratesWeekendRate: 'Tariful de weekend',
      ratesWeekendDayRate: 'Tariful peste zi în weekend',
      ratesFoodText: 'Masă și băuturi (incl. ceai, cafea, lichior) Preț pe persoană',
      ratesContinentalBreakfast: 'Mic-dejun continental',
      ratesFullBreakfast: 'Mic-dejun complet',
      ratesLunch: 'Prânz',
      ratesDinner: 'Cină',
      ratesAgreementText:
        'Introducerea acestor tarife va crea un acord obligatoriu între Grupul CMAC și hotelul dvs., prin urmare asigurați-vă că formularul este completat numai de către o persoană autorizată în acest sens. Hotelul își va asuma răspunderea pentru persoana care completează formularul, iar dacă formularul este completat de către o persoană neautorizată, acest lucru nu va afecta Grupul CMAC sau capacitatea sa de a impune tarifele respective.',
      ratesTermsConditionsText: 'Vă rugăm să bifați pentru a confirma că ați citit ',
      ratesTermsConditionsLinkText: 'Termenii și condițiile',
      submittedHeading: 'Transmis de către',
      viewRatesTermsConditionsText: 'Faceți clic aici pentru a vizualiza',
      outstandingRateRequest: 'Cerere de rate neachitate',
      submittedByName: 'Nume',
      submittedByEmail: 'E-mail',
      submittedByNameRequired: 'Vă rugăm să introduceți numele dvs.',
      submittedByEmailRequired: 'Vă rugăm să introduceți adresa dvs. de e-mail.',
      submittedSuccess:
        'Vă mulțumim pentru trimiterea tarifelor dvs. Așteptăm cu nerăbdare să colaborăm cu dvs.',
      errorSaving:
        'A apărut o eroare la salvarea tarifelor dvs. Vă rugăm să contactați hotelrelations@cmacgroup.com pentru asistență.',
    },
    cmacRef: 'Ref. CMAC',
    yourRef: 'Ref. dvs.',
    noNotificationEmailAddress: 'Nu aveți adresă de e-mail de notificare',
    leadMiles: 'Lead Miles',
    fareNet: 'Tarif (Net)',
    leadMilesCost: 'Costul Lead Miles (Net)',
    totalCost: 'Costul Total (Net)',
    appliesOnDays: 'Se aplică în zile',
    noVehicleRates: 'Fără tarife pentru vehicule',
    markup: 'Marcare',
    addedPrice: 'Pret Adaugat',
    noHolidayRates: 'Fără tarife de vacanță',
    standDownRates: 'Tarife Stand Down',
    noStandDownRates: 'Fără tarife de retragere',
    stoodDownWithin: 'S-a oprit înăuntru',
    minutesOfDepartureDate: 'minute de la data plecării',
    ofOriginalCostPrice: 'a prețului de cost inițial',
    ofOriginalCostPriceWhicheverIsHigher:
      'a prețului de cost inițial, oricare dintre acestea este mai mare',
    waitingTimeRates: 'Tarifele timpului de așteptare',
    noWaitingTimeRates: 'Fără tarife de timp de așteptare',
    minutesFree: 'minute libere',
    minutes: 'minute',
  },
  common: {
    about: 'Despre',
    accepted: 'Acceptat',
    addComment: 'Adăug. coment.',
    add: 'Adaugă',
    addVehicle: 'Adăugare vehicul',
    actions: 'Acțiuni',
    addXtoY: 'Adăugare {x} la {y}',
    agreed: 'De acord',
    agreementType: 'Tipul acordului',
    all: 'Toate',
    back: 'Înapoi',
    backToTop: 'Înapoi sus',
    cancel: 'Anulare',
    close: 'Închidere',
    comment: 'Comentariu',
    comments: 'Comentarii',
    completed: 'Finalizat',
    cost: 'Cost',
    confirm: 'Confirmare',
    continue: 'Continuare',
    create: 'Creare',
    created: 'Creat',
    createdAt: 'Creat la',
    customer: 'Client',
    date: 'Data calendaristică',
    dateRange: 'Intervalul calendaristic',
    delete: 'Ștergere',
    departureDateRange: 'Interval calendaristic plecare',
    description: 'Descriere',
    details: 'Detalii',
    disputed: 'Interogat',
    distance: 'Distanță',
    documents: 'Documente',
    download: 'Descărcare',
    edit: 'Editare',
    edited: 'Editat',
    editVehicle: 'Editează vehicul',
    email: 'E-mail',
    enabled: 'activat',
    error: 'Eroare',
    example: 'Exemplu',
    expired: 'Expirat',
    expires: 'Expiră',
    failed: 'Eșuat',
    file: 'Fișier',
    filename: 'Denumire fișier',
    filters: 'Filtre',
    from: 'De la/din',
    full: 'Integral',
    half: 'Jumătate',
    home: 'Acasă',
    hide: 'Ascundere',
    id: 'Id',
    info: 'Info',
    important: 'Important',
    jobNo: 'Job nr.',
    loading: 'Se încarcă',
    map: 'Hartă',
    messages: 'Mesaje',
    miles: 'Kilometri',
    minutes: 'Minute',
    mode: 'Mod',
    name: 'Nume',
    new: 'Nou',
    no: 'Nu',
    none: 'Niciunul',
    notes: 'Observații',
    null: 'Nul',
    ok: 'OK',
    open: 'Deschis',
    organisation: 'Organizație',
    organisations: 'Organizații',
    original: 'Inițial',
    others: 'Altele',
    password: 'Parolă',
    pending: 'În curs',
    price: 'Preț',
    previouslyUploaded: 'Încărcat anterior',
    processed: 'Procesat',
    processing: 'Se procesează',
    protection: 'Protecție',
    quantity: 'Cantitate',
    raw: 'Brut',
    readonly: 'Doar citire',
    reference: 'Referință',
    refresh: 'Reîmprospătare',
    reset: 'Resetare',
    roles: 'Roluri',
    save: 'Salvare',
    search: 'Căutare',
    settings: 'Setări',
    show: 'Afișare',
    signedBy: 'Semnat de',
    signedOn: 'Semnat la data de',
    sortBy: 'Sortare după',
    status: 'Stare',
    submit: 'Transmitere',
    surcharge: 'Suprataxă',
    test: 'Test',
    time: 'Ora',
    title: 'Titlu',
    to: 'Către',
    total: 'Total',
    type: 'Tip',
    unknown: 'Necunoscut',
    update: 'Actualizare',
    updated: 'Actualizat',
    updatedAt: 'Actualizat la',
    upload: 'Încărcare',
    uploaded: 'Încărcat',
    user: 'Utlizator',
    username: 'Nume utilizator',
    users: 'Utilizatori',
    website: 'Pagină web',
    weight: 'Greutate',
    yes: 'Da',
    next: 'Următorul',
    hour: 'ora',
    mile: 'milă',
    or: 'sau',
  },
  currencies: {
    gbp: 'GBP',
    eur: 'EUR',
  },
  datetime: {
    monday: 'Luni',
    tuesday: 'Marți',
    wednesday: 'Miercuri',
    thursday: 'Joi',
    friday: 'Vineri',
    saturday: 'Sâmbătă',
    sunday: 'Duminică',
    january: 'ianuarie',
    february: 'februarie',
    march: 'martie',
    april: 'aprilie',
    may: 'may',
    june: 'iunie',
    july: 'iulie',
    august: 'august',
    september: 'septembrie',
    october: 'octombrie',
    november: 'noiembrie',
    december: 'decembrie',
    today: 'Astăzi',
  },
  docs: {
    documentation: 'Documentație',
    faq: 'FAQ',
    frequentlyAskedQuestions: 'Întrebări frecvente',
    tutorials: 'Tutoriale',
  },
  errors: {
    301: 'Nu sunteți autorizat(ă) să accesați această resursă.',
    400: 'Ne pare rău, solicitarea dvs. a eșuat.',
    404: 'Ne pare rău, nu am găsit resursa pe care o căutați.',
    500: 'Serverul nu a putut procesa solicitarea dvs.',
    error: 'Eroare',
    nError: 'Eroare {n}',
    noResponseFromServer: 'Serverul nu răspunde',
    noUrl: 'Nu există url',
    sorrySomethingWentWrong: 'Ne pare rău, a survenit o eroare',
    uploadFailed: 'Încărcarea a eșuat',
    uploadFailedWithError: 'Încărcarea a eșuat, cu eroare',
  },
  fields: {
    address: 'Adresă',
    addresses: 'Adrese',
    addressLine1: 'Adresă rând 1',
    addressLine2: 'Adresă rând 2',
    addressLine3: 'Adresă rând 3',
    addressLookup: 'Caută Adresă',
    city: 'Oraș',
    country: 'Țară',
    county: 'Județ',
    description: 'Descriere',
    driverName: 'Numele șoferului',
    driverPhone: 'Număr de telefon al șoferului',
    driverRegistration: 'Înregistrare șofer',
    email: 'E-mail',
    emailAddress: 'Adresă de e-mail',
    emailAddresses: 'Adrese de e-mail',
    home: 'Acasă',
    mobile: 'Mobil',
    name: 'Nume',
    number: 'Număr',
    phoneNumber: 'Număr de telefon',
    postCode: 'Cod poștal',
    region: 'Regiune',
    registrationNumber: 'Număr de înmatriculare',
    telephoneNumber: 'Număr de telefon',
    telephoneNumbers: 'Numere de telefon',
    title: 'Titlu',
    titles: {
      dr: 'Dr.',
      lady: 'Lady',
      lord: 'Lord',
      mr: 'Dl',
      miss: 'Dra',
      mrs: 'Dna',
      ms: 'Dna',
      mx: 'Mx',
      prof: 'Prof.',
      sir: 'Sir',
    },
    town: 'Oraș',
    work: 'Serviciu',
  },
  onboarding: {
    documents: {
      public_insurance: 'Certificat de asigurare de răspundere civilă',
      liability: 'Certificat de asigurare de răspundere a angajatorului',
      motor_insurance: 'Certificat de asigurare auto',
      gdpr: 'Politica GDPR',
      small_operator_license: 'Licență de operator - vehicule mici',
      large_operator_license: 'Licență de operator - vehicule mari',
      upload: 'Încărcați documentul',
      download: 'Descărcați documentul',
      file: 'Nume fișier',
      document: 'Tip de document',
      created: 'Creat pe',
      expires: 'Expiră pe',
    },
    agreement: 'Am citit și accept termenii și condițiile tipărite mai sus.',
    mustAgree: 'Trebuie să acceptați termenii și condițiile pentru a utiliza aplicația.',
    welcomeHeader: 'Bine ați venit pe platforma de înscriere a furnizorilor CMAC',
    country: 'Denumirea țării',
    legalCompanyName: 'Denumirea oficială a companiei',
    tradingName: 'Denumirea comercială',
    companyRegistrationNumber: 'Codul de înregistrare a companiei',
    registeredAddress: 'Adresa/sediul legal',
    operatingAddress: 'Adresa punctului de lucru',
    currency: 'Deconectare',
    logOut: 'Deconectare',
    smallVehicle:
      'Vehicul de mici dimensiuni (taxi, microbuz, vehicul cu acces pentru persoanele cu dizabilități)',
    largeVehicle: 'Vehicul de mari dimensiuni (minibus, autocar)',
    fleetSize: 'Dimensiunea flotei',
    emailAddress: 'Adresă de e-mail',
    mainContactNumber: 'Vă rugăm să introduceți parola...',
    primaryEmailAddress: 'Adresa de e-mail principală',
    successResult: 'Contul a fost creat cu succes, vă rugăm să vă verificați inboxul',
    failResult:
      'În acest moment nu putem să vă creăm un cont; există deja un cont cu informațiile furnizate de dvs.; pentru informații suplimentare vă rugăm să contactați supplier.relations@cmacgroup.com.',

    progressHeader:
      'Vă mulțumim că ne-ați transmis informațiile necesare pentru a deveni furnizor CMAC.',
    progressMainText:
      'Pentru a putea da curs cererilor dumneavoastră, vă rugăm să vă asigurați că sunt completate toate rubricile secțiunilor Detalii financiare și Documente de înscriere a companiei, de mai jos.',
    progressSubText: 'Odată ce vom primi aceste informații, vom putea trece la etapa următoare.',
    progressBarText: 'Desfășurarea înscrierii',
    progressCompleted:
      'Vă mulțumim pentru trimiterea tuturor documentelor dumneavoastră. Un membru al echipei noastre va revizui acum solicitarea dumneavoastră și vă va contacta în curând pentru a trece la următorul pas.',
    rejected:
      'Regretăm, dar cererea dumneavoastră nu a avut succes. Vă rugăm să contactați echipa noastră de relații cu furnizorii pentru mai multe informații.',
  },
  plurals: {
    comments: 'Comentariu | Comentarii',
    journeys: 'Călătorie | Călătorii',
    results: 'Rezultat | Rezultate',
    resultsFound: 'Rezultatul a fost găsit | Rezultatele au fost găsite',
    rows: 'Rând | Rânduri',
    suppliers: 'Furnizor | Furnizori',
  },
  ui: {
    anNewVersionOfTheApplicationIsAvailable: 'Este disponibilă o nouă versiune a aplicației',
    appName: 'Portal pentru furnizori',
    approve: 'Aprobare',
    approveAllSelectedRows: 'Aprobarea tuturor rândurilor selectate',
    changeProfilePicture: 'Modificarea fotografiei de profil',
    chooseX: 'Selectare {x}',
    clear: 'Ștergere',
    clearFilters: 'Ștergere filtre',
    clickToCopyToClipboard: 'Clic pentru copierea în clipboard',
    clickToSortByX: 'Clic pentru sortarea după {x}',
    closeDrawer: 'Închidere sertar',
    currentlyUnavailable: 'Momentan indisponibil',
    dashboard: 'Tablou de bord',
    document: 'Document',
    dragYourXHereToUploadIt: 'Trageți {x} dvs. aici pentru a încărca',
    export: 'Exportare',
    exportToCsv: 'Exportare în format CSV',
    file: 'fișier',
    files: 'Fișiere',
    fileSizeTooBig: 'Dimensiunea fișierului este prea mare',
    footer: 'Notă de subsol',
    home: 'Acasă',
    homePage: 'Pagina Principală',
    itemsPerPage: 'Elemente pe pagină',
    navigation: 'Navigare',
    noDetailsToDisplay: 'Nu există detalii de afișat',
    noFileSelected: 'Nu este selectat niciun fișier',
    noResultsToShow: 'Nu există rezultate de afișat',
    noXProvided: 'Nu s-a furnizat {x}',
    orClickToBrowse: 'Sau clic pentru a răsfoi',
    page: 'Pagina',
    pageSize: 'Dimensiunea paginii',
    pleaseSeletFromTheFollowing: 'Vă rugăm să selectați din următoarele',
    pleaseSelectAnX: 'Vă rugăm să selectați un/o {x}',
    pleaseSelectAX: 'Vă rugăm să selectați un/o {x}',
    pleaseSelectNXOnly: 'Vă rugăm să selectați numai {n} {x}',
    profilePicture: 'Fotografie de profil',
    resultsPerPage: 'Rezultate pe pagină',
    saveChanges: 'Salvare modificări',
    selectAll: 'Selectare toate',
    selectX: 'Selectare {x}',
    selectADifferentX: 'Selectare {x} diferit(ă)',
    spreadsheet: 'Foaie de calcul',
    theXHasBeenDeleted: '{x} a fost șters(ștearsă)',
    thisWillPutXInDispute: 'Această acțiune va interoga {x}',
    updateX: 'Actualizare {x}',
    uploadBeingProcessed: 'Documentul încărcat este în curs de procesare',
    uploadStatus: 'Stare încărcare',
    uploading: 'Se încarcă',
    uploadNewX: 'Încărcare {x} nou(ă)',
    uploadX: 'Încărcare {x}',
    userProfile: 'Profil utilizator',
    version: 'versiunea',
    xMissing: 'Lipsește {x}',
    policySignedX: 'Vă mulțumim că ați semnat {x}',
  },
  validations: {
    canOnlyContainLettersAndNumbers: 'Poate conține numai litere și cifre',
    canOnlyContainLettersNumbersAndTheFollowingCharactersX:
      'Poate conține numai litere, cifre și următoarele caractere: {x}',
    canOnlyContainNumbers: 'Poate conține numai cifre',
    checkboxConfirm:
      'Vă rugăm să bifați căsuța pentru a confirma că acceptați termenii și condițiile',
    maxNCharacters: 'Max {n} caractere',
    minNCharacters: 'Min {n} caractere',
    mustBeAnInteger: 'Trebuie să fie un număr întreg',
    mustBeAFloat: 'Trebuie să fie un număr cu zecimale',
    mustBeAValidEmailAddress: 'Trebuie să fie o adresă de e-mail valabilă',
    mustBeAValidTelephoneNumber: 'Trebuie să fie un număr de telefon valabil',
    mustBeAWholeNumber: 'Trebuie să fie un număr complet',
    mustBeExactlyNCharacters: 'Trebuie să aibă exact {n} caractere',
    mustBeGreaterThanN: 'Trebuie să fie mai mare decât {n}',
    mustBeGreaterThanOrEqualToN: 'Trebuie să fie mai mare sau egal cu {n}',
    mustBeLessThanN: 'Trebuie să fie mai mic decât {n}',
    mustBeLessThanOrEqualToN: 'Trebuie să fie mai mic sau egal cu {n}',
    mustBeRoundedToExactlyNDecimalPlaces: 'Trebuie rotunjit la exact {n} zecimale',
    mustBeRoundedToNDecimalPlaces: 'Trebuie rotunjit la {n} zecimale',
    mustHaveASingleDecimalPoint: 'Trebuie să aibă o singură zecimală',
    requiresBooleanValue: 'Necesită o valoare booleană',
    thisDateMustBeInTheFuture: 'Această dată trebuie să fie în viitor',
    thisDateMustBeInThePast: 'Această dată trebuie să fie în trecut',
    thisExceedsTheLeadMileageLimit: 'Depășește limita kilometrajului suplimentar fără pasager',
    thisFieldIsRequired: 'Acest câmp este obligatoriu',
    thisFieldMustBeAValidDate: 'Acest câmp trebuie să fie o dată valabilă',
    thisFieldMustContainAX: 'Acest câmp trebuie să conțină un/o {x}',
    thisFieldMustContainAnX: 'Acest câmp trebuie să conțină un/o {x}',
    mustBeUkBankSortCode: 'Codul de sortare bancară trebuie să fie în formatul 00-00-00',
    mustBeUkBankAccountNumber:
      'Numărul de cont bancar din Marea Britanie trebuie să conțină 8 cifre',
    mustBeAtLeastOneChecked: 'Bifați una sau ambele valori',
  },
  invoiceUpload: {
    invoiceNo: 'Factura nr.',
    reference: 'Referință',
    uploadDate: 'Data încărcării',
    invoiceDate: 'Data facturării',
    dueDate: 'Data scadenței',
    total: 'Total',
    currency: 'Valuta',
    status: 'Stare',
    comments: 'Comentarii',
    file: 'Fișier',
    selectJourneys: 'Selectare călătorii',
    uploadInvoice: 'Încărcare factură',
    confirm: 'Confirmare',
    uploadingAs: 'În prezent încărcați drept',
    atLeastOneJourney: 'Trebuie să selectați cel puțin o călătorie',
    errorUploadingFile: 'A survenit o eroare la încărcarea și analizarea fișierului.',
    uploadInFormat: 'Vă rugăm să încărcați factura în format PDF.',
    selectFile: 'Vă rugăm să selectați un fișier pentru încărcare',
    uploadingAnalyzing: 'Se încarcă și se analizează',
    invoiceReference: 'Referință factură',
    invoice: 'Factura',
    cmacReference: 'Referință CMAC',
    yourReference: 'Referința dvs.',
    journeysSelectionJourney: 'călătorie | călătorii',
    journeySelectionSelected: 'selectat',
    net: 'Net',
    tax: 'Taxe',
    gross: 'Brut',
    expenses: 'Cheltuieli',
    details: 'Detalii',
    journey: 'Călătorie',
    departureDate: 'Data plecării',
    cmacRef: 'Ref. CMAC',
    yourRef: 'Ref. dvs.',
    invoiceAccepted: 'Factură validată și acceptată',
    invoiceDoesNotMatch: 'Vă rugăm să examinați și să trimiteți din nou',
    stillUpload: 'Puteți încărca această factură, dar va necesita investigații. ',
    paymentDelayed: 'Plata va fi amânată până la finalizarea acestora.',
    totalNetDoesNotMatch:
      'Suma totală netă de pe factură nu se potrivește cu prețul net total pentru toate călătoriile selectate.',
    expected: 'Preconizat',
    totalGross: 'Total brut',
    totalNet: 'Total net',
    totalTax: 'Total taxe',
    notFound: 'Nu a fost găsit',
    invoiced: 'Facturat',
    underDispute: 'Interogare în curs',
    notUnique: 'Există deja un fișier cu acest nume. Alegeți un nume de fișier diferit.',
    invalidCharacters: 'Numele fișierului conține caractere nevalide. Următoarele nu sunt permise.',
    invalidFileExtension:
      'Acest tip de fișier nu este valid. Sunt permise următoarele tipuri de fișiere.',
    cancelled: 'Anulat',
    journeyCost: 'Costul Călătoriei (Net)',
    leadMilesCost: 'Costul Lead Miles (Net)',
    waitingTimeCost: 'Costul Timpului de Așteptare (Net)',
    expensesCost: 'Costul Cheltuielilor (Net)',
    greenFeeCost: 'Costul Taxei Verzi (Net)',
    totalCost: 'Costul Total (Net)',
    downloadJourneyData: 'Apăsați aici pentru a descărca datele pentru călătoriile selectate.',
    paid: 'Plătit',
    rejectedStatus: 'Respins',
    excludedJourneys: 'Acest proces exclude călătoriile care sunt în prezent în dispută.',
    createPdfInvoice: 'Creează Factură PDF',
    createPdf: {
      title: 'Creează Factură PDF pentru călătoriile selectate',
      invoiceNumber: 'Număr Factură',
      invoiceDate: 'Data Facturii',
      chooseAddress: 'Vă rugăm să alegeți o adresă',
      registerDetails: {
        title: 'Detalii Înregistrare',
        heading: 'Pentru a crea factura dvs., avem nevoie de câteva informații suplimentare.',
        financial: {
          heading: 'Urmați pașii de mai jos pentru a adăuga detaliile financiare:',
          step1:
            'Selectați pictograma meniului hamburger {x} situată în colțul drept sus al ecranului',
          step2: "Apoi selectați 'Profil'.",
          step3:
            "În secțiunea 'Detalii Financiare', faceți clic pe butonul de editare {x} și introduceți detaliile dvs.",
        },
        address: {
          heading: 'Urmați pașii de mai jos pentru a adăuga adresa companiei dvs.:',
          step1:
            'Selectați pictograma meniului hamburger {x} situată în colțul drept sus al ecranului.',
          step2: "Apoi selectați 'Profil'.",
          step3:
            "În secțiunea 'Adrese', faceți clic pe butonul plus {x} și introduceți detaliile dvs.",
        },
      },
    },
    separateInvoicesRequired:
      'Călătoriile cu vehicule cu mai mult de 10 locuri trebuie facturate separat de călătoriile cu vehicule cu mai puțin de 10 locuri.',
    invoiceNumberFieldNameLabel: 'Numărul facturii Nume câmp',
    totalNetAmountFieldNameLabel: 'Nume câmp pentru suma netă totală',
  },
  compliance: {
    introduction:
      'Mai jos este un rezumat al principalelor documente care trebuie să fie în vigoare și înregistrate la noi. Dacă aveți întrebări legate de oricare dintre aceste documente, vă rugăm să ne contactați la 03333 207 100',
    noRulesFound: 'Nicio regulă de conformitate găsită',
  },
  contactItem: {
    generalManager: 'Director general',
    reception24Hour: 'Recepție 24 de ore',
    revenueSales: 'Venituri/Vânzări',
    twentyFourHourEmail: 'E-mail de 24 de ore',
    notification: 'Notificare',
    other: 'Alte',
  },
}
