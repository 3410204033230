
import { Component, Prop, Vue } from 'vue-property-decorator'
import i18n from '@/plugins/i18n'
import cloneDeep from 'lodash/cloneDeep'
import { getIndexableRecord } from '@/utilities/typescript'
import { DEFAULT_TABLE_OPTIONS } from '@/utilities/constants'

const defaultOptions = cloneDeep(DEFAULT_TABLE_OPTIONS)

@Component
export default class Table extends Vue {
  // props // ***
  @Prop()
  actionHeader!: Record<string, any>
  @Prop({ type: Boolean })
  clickable!: boolean
  @Prop({ type: Array })
  expanded!: Record<string, any>[]
  @Prop({ default: () => [], type: Array })
  filters!: Record<string, any>[]
  @Prop({ type: Boolean })
  fixedHeader!: boolean
  @Prop({ default: () => [] })
  groupBy!: string[]
  @Prop({ default: () => [] })
  groupDesc!: string[]
  @Prop({ required: true })
  headers!: Record<string, any>[]
  @Prop({ type: String })
  height!: string
  @Prop({ type: Boolean })
  hideDefaultFooter!: boolean
  @Prop({ type: Boolean })
  hideDefaultHeader!: boolean
  @Prop()
  items!: Record<string, any>[]
  @Prop()
  itemClass!: string
  @Prop()
  itemsPerPage!: number
  @Prop({ type: Boolean })
  loading!: boolean
  @Prop({ default: i18n.t('common.loading') })
  loadingText!: string
  @Prop({ type: Boolean })
  localPagination!: boolean
  @Prop()
  mobileBreakpoint!: number | string
  @Prop({ type: Boolean })
  multiSort!: boolean
  @Prop({ type: Boolean })
  mustSort!: boolean
  @Prop()
  nullText!: string
  @Prop({ default: 1 })
  page!: number
  @Prop({ type: Boolean })
  raw!: boolean
  @Prop({ type: Boolean })
  showActions!: boolean
  @Prop({ type: Boolean })
  showExpand!: boolean
  @Prop({ type: Boolean })
  showSelect!: boolean
  @Prop({ type: Boolean })
  singleExpand!: boolean
  @Prop()
  sortBy!: string[]
  @Prop()
  sortDesc!: string[]
  @Prop()
  value!: Record<string, any>[]

  // data // ***
  filterValues: Record<string, any> = {}
  showFilters = false

  // computed // ***
  get defaultActionHeader() {
    return {
      align: 'center',
      class: 'px-0',
      cellClass: 'px-0',
      name: 'actions',
      sortable: false,
      value: 'actions',
      width: '1%',
    }
  }
  get defaultItemsPerPage() {
    return this.$store.state.core.tables.itemsPerPage
  }
  get filterRow() {
    return cloneDeep(this.headers).map(h => this.filters.find(f => f.name === h.value))
  }
  get footerProps() {
    const fp = {
      // 'disable-items-per-page': true,
      'disable-pagination': this.loading,
      'items-per-page-text': this.$t('ui.itemsPerPage'),
    }
    return fp
  }
  get getHeaders() {
    const { actionHeader, defaultActionHeader } = this
    const headers = cloneDeep(this.headers)
    if (this.hasActions || this.showActions)
      headers.push({ ...defaultActionHeader, ...actionHeader })
    return headers
  }
  get hasActions() {
    return this.filters.length > 0
  }
  get options() {
    const arr: string[] = [
      'page',
      'sortBy',
      'sortDesc',
      'groupBy',
      'groupDesc',
      'multiSort',
      'mustSort',
    ]
    return arr.reduce(
      (a: any, v: string) => {
        a[v] = getIndexableRecord(this)[v] || defaultOptions[v]
        return a
      },
      { itemsPerPage: this.itemsPerPage || this.defaultItemsPerPage }
    )
  }

  // created // ***
  created() {
    this.resetFilterValues()
  }

  // methods // ***
  clearFiltersClicked() {
    this.resetFilterValues()
    this.onFilterInput()
  }
  onFilterInput() {
    this.$emit('filter:input', this.filterValues)
  }
  onRowClicked(e: any) {
    this.$emit('row-clicked', e)
  }
  onTableCellCancel(e: any) {
    this.$emit('cancel:cell', e)
  }
  onTableCellSave(e: any) {
    this.$emit('save:cell', e)
  }
  onUpdateOptions(e: any) {
    this.$emit('update:options', e)
  }
  resetFilterValues() {
    Vue.set(this, 'filterValues', {})
  }
}
