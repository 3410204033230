
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class EditDialog extends Vue {
  // props // ***
  @Prop({ type: Boolean })
  closeOnClick!: boolean
  @Prop({ type: Boolean })
  closeOnContentClick!: boolean
  @Prop({ type: Boolean })
  value!: boolean
}
