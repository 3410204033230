
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class MaskInput extends Vue {
  // props // ***
  @Prop({ type: Boolean })
  autofocus!: boolean
  @Prop()
  color!: string
  @Prop()
  placeholder!: string
  @Prop()
  prefix!: string
  @Prop({ default: '' })
  mask!: string
  @Prop()
  name!: string
  @Prop()
  value!: number | string

  // data // ***
  local: number | string | null = null

  // watch // ***
  @Watch('local')
  onLocalChanged() {
    if (this.local !== this.value) this.$emit('input', this.local)
  }
  @Watch('value')
  onValueChanged() {
    if (this.local !== this.value) this.local = this.value
  }
}
