
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class CmacScorecard extends Vue {
  // props // ***
  @Prop()
  subtitle!: string
  @Prop()
  text!: string
  @Prop()
  title!: string
  @Prop()
  value!: number | string
  @Prop()
  valueColor!: string
  @Prop()
  width!: string

  // computed // ***
  get valueClass() {
    const colorClass = this.valueColor ? `${this.valueColor}--text` : ''
    return `text-h1 ${colorClass}`
  }

  // methods // ***
  onClick(e: Event) {
    this.$emit('click', e)
  }
}
