<template>
  <v-select :value="value" :items="items" @change="changed" hide-details>
    <template v-slot:item="{ item }">
      <i v-if="item.icon" :class="['mr-2', 'fi', `fi-${item.icon}`]"></i>
    </template>
    <template v-slot:selection="{ item }">
      <i v-if="item.icon" :class="['mr-2', 'fi', `fi-${item.icon}`]"></i>
    </template>
  </v-select>
</template>

<script>
import { ALL_LOCALES, COUNTRIES, DEFAULT_LOCALE } from '@/utilities/constants'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      value: DEFAULT_LOCALE.LANG,
    }
  },
  created() {
    this.value = this.language
  },
  computed: {
    ...mapGetters('core', ['language']),
    items() {
      return ALL_LOCALES.map(locale => {
        return {
          text: locale[0],
          value: locale[1],
          icon: this.getIcon(locale[0]),
        }
      })
    },
  },
  methods: {
    getIcon(country) {
      const foundCountry = COUNTRIES.find(x => x[0] === country)
      return foundCountry ? foundCountry[1].toLowerCase() : undefined
    },
    changed(value) {
      this.$store.dispatch('core/setLanguage', value)
    },
  },
}
</script>
