
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class LoadingSpinner extends Vue {
  // props // ***
  @Prop({ default: 'primary' })
  color!: string
  @Prop({ default: true, type: Boolean })
  loading!: boolean
  @Prop({ default: true, type: Boolean })
  indeterminate!: boolean
  @Prop()
  size!: number | string
}
