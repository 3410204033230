import '@/scss/_index.scss'
import Vue from 'vue'
import Vuetify from 'vuetify'
import en from 'vuetify/es5/locale/en'
import es from 'vuetify/es5/locale/es'
import pt from 'vuetify/es5/locale/pt'
import fr from 'vuetify/es5/locale/fr'
import de from 'vuetify/es5/locale/de'
import el from 'vuetify/es5/locale/el'
import cs from 'vuetify/es5/locale/cs'
import it from 'vuetify/es5/locale/it'
import nl from 'vuetify/es5/locale/nl'
import pl from 'vuetify/es5/locale/pl'
import ro from 'vuetify/es5/locale/ro'

Vue.use(Vuetify)

const themes = {
  light: {
    primary: '#112138',
    secondary: '#6C757D',
    accent: '#1477FC',
    error: '#FD0E33', // Also known as danger
    info: '#19AEBA',
    success: '#1FBF7B',
    warning: '#FFB62C',
  },
  dark: {
    // ...
  },
}

export function updateVuetifyLanguage(lang: string) {
  // The vuetify framework consider czech to be cs hence the mapping.
  const langVuetify = lang === 'cz' ? 'cs' : lang

  if (vuetify.framework.lang.current === langVuetify) {
    return
  }

  const loadedLocales = Object.keys(vuetify.framework.lang.locales)

  if (loadedLocales.includes(langVuetify)) {
    vuetify.framework.lang.current = langVuetify
  } else {
    console.error(
      `The language '${langVuetify}' has not been loaded into the Vuetify locales. Please add.`
    )
  }
}

const vuetify = new Vuetify({
  icons: {
    iconfont: 'md',
  },
  theme: {
    themes,
    options: { customProperties: true },
  },
  lang: {
    locales: { en, es, pt, fr, de, el, cs, it, nl, pl, ro },
  },
})

export default vuetify
