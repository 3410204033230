import authClient from '@/utilities/auth'
import _vue from 'vue'

export default {
  install(Vue: typeof _vue): any {
    Vue.observable(authClient)
    Vue.$auth = authClient
    Vue.prototype.$auth = authClient
  },
}
