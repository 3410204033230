
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class DisabledLayout extends Vue {
  // data // ***
  loggingOut = false

  // computed // ***
  get customerServiceEmail() {
    return `<a href="mailto:supplier.relations@cmacgroup.com"
              >supplier.relations@cmacgroup.com</a>`
  }
  get customerServiceMessage() {
    return this.$t('cmac.pleaseContactXForAssistance', { x: this.customerServiceEmail })
  }

  // methods // ***
  logOut() {
    this.loggingOut = true
    this.$store.dispatch('core/logOut').catch(e => {
      console.error(e)
    })
  }
}
