
import { config } from '@/plugins/env'
import api from '@/utilities/api'
import { Component, Vue } from 'vue-property-decorator'
@Component
export default class DisputeNotification extends Vue {
  // data // ***
  disputeCounts = {}
  count = 0
  polling = -1

  // computed // ***
  get hasOpenDisputes() {
    return this.count > 0
  }
  get activeUser() {
    return this.$auth.activeUser
  }
  // mounted // ***
  mounted() {
    this.getSummary()
    this.pollData()
  }

  beforeDestroy() {
    clearInterval(this.polling)
  }
  // methods // ***
  getSummary() {
    let payload = { organisationIds: this.activeUser.organisations.map((x: any) => x.id) }

    api(`supplier/openDisputes`, { method: 'POST', noApiErrorHandling: true }, { data: payload })
      .then((res: any) => {
        if (res.data) this.count = res.data.find((x: { status: number }) => x.status === 1)?.count
      })
      .catch(e => {
        if (e.response?.data?.errors[0]?.indexOf('User with email address') > -1) {
          clearInterval(this.polling)
          window.location.href = `${config.STS_DOMAIN}/Account/Details`
        }
      })
  }

  pollData() {
    this.polling = setInterval(() => {
      this.getSummary()
    }, 30 * 1000) // every 30 seconds
  }
}
