export function hasSlash(string: string, position = 'start'): boolean {
  if (position === 'start') return hasSlashAtStart(string)
  else if (position === 'end') return hasSlashAtEnd(string)
  else return false
}

export function hasSlashAtEnd(string: string): boolean {
  return !!(string && string[string.length - 1] === '/')
}

export function hasSlashAtStart(string: string): boolean {
  return !!(string && string[0] === '/')
}

export function prefixUrl(url: string, prefix: string): string {
  return stripSlashes(prefix, undefined) + '/' + stripSlashes(url, undefined)
}

export function stripSlashes(string: string, position: string | undefined): string {
  if (position === 'start') return stripSlashesFromStart(string)
  else if (position === 'end') return stripSlashesFromEnd(string)
  else return stripSlashesFromStart(stripSlashesFromEnd(string))
}

export function stripSlashesFromEnd(string: string): string {
  string = stripSlash(string, 'end')
  return hasSlashAtEnd(string) ? stripSlashes(string, 'end') : string
}

export function stripSlashesFromStart(string: string): string {
  string = stripSlash(string, 'start')
  return hasSlashAtStart(string) ? stripSlashes(string, 'start') : string
}

export function stripSlash(string: string, position = 'start'): string {
  if (position === 'start') return stripSlashFromStart(string)
  else if (position === 'end') return stripSlashFromEnd(string)
  else return string
}

export function stripSlashFromEnd(string: string): string {
  return hasSlash(string, 'end') ? string.slice(0, string.length - 1) : string
}

export function stripSlashFromStart(string: string): string {
  return hasSlash(string, 'start') ? string.slice(1, string.length) : string
}

export function objectToQuery(o: Record<string, unknown>): string {
  if (!o) {
    return ''
  }
  return Object.entries(o)
    .filter(([, v]) => v != null)
    .map(([key, val]) => {
      if (Array.isArray(val)) {
        const truthyArray = val.filter(x => {
          if (x === 0) return true
          else return Boolean(x)
        })
        if (truthyArray.length) {
          return `${key}=` + truthyArray.join(`&${key}=`)
        }
      } else if (val instanceof Date && val !== null) {
        return `${key}=${val.toISOString()}`
      } else if (typeof val === 'object' && val !== null) {
        return Object.entries(val)
          .map(([key2, val2]) => {
            if (Array.isArray(val2)) {
              const truthyArray = val2.filter(Boolean)
              if (truthyArray.length) {
                return `${key}.${key2}=` + truthyArray.join(`&${key}.${key2}=`)
              }
            } else {
              return `${key}.${key2}=${
                val2 !== null && val2 !== undefined ? encodeURIComponent(val2) : ''
              }`
            }
          })
          .join('&')
          .replace('&&', '&')
      } else {
        const valToEncode = val as string | number | boolean
        return val !== null && val !== undefined && val !== ''
          ? `${key}=${encodeURIComponent(valToEncode)}`
          : ''
      }
    })
    .join('&')
    .replace('&&', '&')
}
