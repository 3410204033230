
import { Component, Prop, Vue } from 'vue-property-decorator'
import { WAYPOINTS } from '@/utilities/constants'
import { formatAddress } from '@/utilities/views'

const { WAYPOINT_DESTINATION_ICON, WAYPOINT_ORIGIN_ICON } = WAYPOINTS

@Component
export default class Waypoints extends Vue {
  // props // ***
  @Prop({ type: Boolean })
  showAllWaypoints!: boolean
  @Prop({ required: true })
  waypoints!: Record<string, any>[]

  // data // ***
  shortenedAddressConfig: Record<string, number> = { sliceLength: 10, threshold: 25 }
  WAYPOINT_DESTINATION_ICON: string = WAYPOINT_DESTINATION_ICON
  WAYPOINT_ORIGIN_ICON: string = WAYPOINT_ORIGIN_ICON

  // computed // ***
  get formattedAddresses() {
    const { waypoints } = this
    if (!Array.isArray(waypoints) || !waypoints?.length) return []
    const formattedAddresses = []
    formattedAddresses.push(this.getFormattedAddress(waypoints[0].from))
    waypoints.forEach(w => formattedAddresses.push(this.getFormattedAddress(w.to)))
    return formattedAddresses
  }
  get hideTooltip() {
    return false
    // return this.formattedAddresses.length <= 2 && this.showFullAddresses
  }
  get presentedAddresses() {
    const { formattedAddresses, showFullAddresses, shortenedAddresses } = this
    return showFullAddresses ? formattedAddresses : shortenedAddresses
  }
  get presentedVias() {
    const { presentedAddresses } = this
    return presentedAddresses.slice(1, -1)
  }
  get shortenedAddresses() {
    return this.formattedAddresses.map(a => this.getShortenedAddress(a))
  }
  get showFullAddresses() {
    return true
    // TODO: change this to be a prop passed in with default being true, rather than deciding in
    // all cases how this should be handled - possibly maintain the positive by changing prop to
    // 'showShortenedAddress'?
  }
  get tooltipAddresses() {
    return this.formattedAddresses
  }

  // methods // ***
  getFormattedAddress(a: any) {
    return formatAddress(a)
  }
  getShortenedAddress(a: any) {
    const { sliceLength, threshold } = this.shortenedAddressConfig
    if (!a || a.length <= threshold) return a
    return a.slice(0, sliceLength) + '...' + a.slice(a.length - sliceLength)
  }
}
