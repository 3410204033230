
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ConfirmationInput extends Vue {
  // props // ***
  @Prop({ type: Boolean })
  isValid!: boolean
  @Prop({ type: String })
  placeholder!: string
  @Prop({ type: Array })
  rules!: ((data: any) => string | boolean)[]
  @Prop({ type: String })
  type!: string
  @Prop({ type: [String, Number] })
  value!: string | number | null | undefined

  // methods // ***
  onKeydownEnter() {
    this.$emit('save')
  }
}
