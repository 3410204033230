
import { lowerCase, title } from '@/utilities/filters'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Paginator extends Vue {
  // props // ***
  @Prop({ type: String })
  color!: string
  @Prop({ type: Boolean })
  disabled!: boolean
  @Prop({ type: Number, required: true })
  items!: number
  @Prop({ type: Boolean })
  loading!: boolean
  @Prop({ type: Number, required: true })
  page!: number
  @Prop({ type: Number, required: true })
  pageSize!: number
  @Prop({ type: Number, default: 8 })
  totalVisible!: number

  // computed // ***
  get availablePageSizes() {
    return [5, 10, 50, 100, 250]
  }
  get numberOfPages() {
    if (this.items === 0) return 1
    const mod = this.items % this.pageSize
    if (!mod) return this.items / this.pageSize
    else return Math.floor(this.items / this.pageSize) + 1
  }
  get pageSizeSelector() {
    return {
      disabled: !!this.disabled,
      hideDetails: 'true',
      label: title(String(this.$t('ui.pageSize'))),
      items: this.availablePageSizes.map((v: number) => ({
        text: `${String(v)} / ${lowerCase(String(this.$t('ui.page')))}`,
        value: v,
      })),
    }
  }

  // methods // ***
  onSelectInput(e: number) {
    this.$emit('input:size', e)
  }
  onPaginatorInput(e: number) {
    this.$emit('input:page', e)
  }
}
