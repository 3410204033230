import { pascalCase } from '@/utilities/filters'
import api from '@/utilities/api'
import cloneDeep from 'lodash/cloneDeep'
import i18n from '@/plugins/i18n'
import Vue from 'vue'

export const getErrorMessage = function (status: string): string {
  const key = 'errors.' + status
  return i18n.te(key) ? String(i18n.t(key)) : String(i18n.t('common.error'))
}

export const itemById =
  (state: Record<string, any>) =>
  (id: string | number): any =>
    cloneDeep(state.items[id])

export const itemLoading =
  (state: Record<string, any>) =>
  (id: string | number): boolean =>
    !!state.itemsLoading[id]

export const makeDefaultGetters = function (properties: Record<string, any>): Record<string, any> {
  const defaultGetters: Record<string, any> = {}
  properties.forEach((p: any) => {
    defaultGetters[p] = (state: Record<string, any>) => cloneDeep(state[p])
  })
  return defaultGetters
}

export const makeDefaultMutations = function (
  properties: string[],
  defaultState: Record<string, any> = {}
): Record<string, any> {
  const defaultMutations: Record<string, any> = {}
  properties.forEach(p => {
    const m = 'set' + pascalCase(p)
    const r = 're' + m
    defaultMutations[m] = (state: Record<string, any>, value: any) => Vue.set(state, p, value)
    defaultMutations[r] = (state: Record<string, any>) =>
      Vue.set(state, p, cloneDeep(defaultState[p]))
  })
  return defaultMutations
}

export const propertyLoading =
  (state: Record<string, any>) =>
  (property: string): boolean =>
    !!state.propertiesLoading[property]

export async function send(
  a: Record<string, any>,
  payload: Record<string, any>
): Promise<void | Record<string, any>> {
  const collected = { a, payload }
  return await api(collected.payload?.url, collected.payload, undefined)
}

export async function sendAndSet(
  context: Record<string, any>,
  opts: Record<string, any>
): Promise<void> {
  const { commit, dispatch } = context
  const { mutation, payload, reset = true } = opts
  if (reset) commit('re' + mutation)
  const r = await dispatch('send', payload)
  commit(mutation, r?.data)
}

export const setItem = function (state: Record<string, any>, item: Record<string, any>): void {
  if (item && item?.id) Vue.set(state.items, item.id, item)
  else throw { item, msg: 'setItem requires item with valid id' }
}

export const setItemLoading = function (
  state: Record<string, any>,
  opts: Record<string, any>
): void {
  const { id, value } = opts
  if (id) Vue.set(state.itemsLoading, id, value)
  else throw 'setItemLoading requires a valid id'
}

export const setProperties = function (
  context: Record<string, any>,
  properties: string[],
  data: Record<string, any>
): void {
  const { commit } = context
  properties.forEach(p => {
    const mutation = 'set' + pascalCase(p)
    if (Object.prototype.hasOwnProperty.call(data, p)) commit(mutation, data[p])
  })
}

export const setPropertiesLoading = function (
  state: Record<string, any>,
  context: Record<string, any>
): void {
  const { data, value } = context
  Object.keys(data).forEach(p => {
    Vue.set(state.propertiesLoading, p, value)
  })
}

export const setState = (
  state: Record<string, any>,
  values: Record<string, any>,
  full: boolean
): void => {
  const keys = Object.keys(values)
  // unset non-default values if full true
  if (full)
    Object.keys(state)
      .filter(k => !keys.includes(k))
      .forEach(k => {
        Vue.delete(state, k)
      })
  // set default values
  Object.keys(values).forEach(k => {
    Vue.set(state, k, values[k])
  })
}

export const sortByPosition = function (arr: Record<string, any>[]): Record<string, any>[] {
  const newArr = cloneDeep(arr)
  newArr.sort((a, b) => {
    // if no positions, return 0 as not comparable - or if equal return 0
    if (!a.position || !b.position || a.position === b.position) return 0
    // return value based on position
    return a.position - b.position > 0 ? 1 : -1
  })
  return newArr
}
