
import { Component, Prop, Vue } from 'vue-property-decorator'
import { downloadDirectToBrowser } from '@/utilities/functions'

@Component
export default class CsvExporter extends Vue {
  // props // ***
  @Prop()
  buttonText!: string
  @Prop({ required: true })
  headers!: string[]
  @Prop({ required: true })
  items!: Record<string, any>[]

  // computed // ***
  get csvData() {
    const data = [this.headerRow, ...this.lines]
    return data.join('\n')
  }
  get headerRow() {
    return this.headers.join(',')
  }
  get lines() {
    const lines: string[] = []
    this.items.forEach(i => {
      let line: string[] = []
      this.headers.forEach(h => {
        line.push(i[h])
      })
      lines.push(this.addQuotes(line).join(','))
    })
    return lines
  }

  // methods // ***
  addQuotes(a: string[]) {
    return a.map(v => (typeof v === 'string' ? '"' + v + '"' : v))
  }
  onClick() {
    this.fakeDownload(this.csvData)
  }
  fakeDownload(d: string) {
    downloadDirectToBrowser(d, 'csvDownload.csv')
  }
}
