import cloneDeep from 'lodash/cloneDeep'
import { DateTime, Interval } from 'luxon'

const { fromISO } = DateTime
const { fromDateTimes } = Interval

export const parseDate = (d: string): DateTime => fromISO(d)

export const parseDates = (a: string[]): DateTime[] => a.map(d => fromISO(d))

export const forceChronologicalOrder = (dates: DateTime[]): DateTime[] =>
  cloneDeep(dates).sort((a, b) => {
    if (isAfter(a, b)) return 1
    else if (isBefore(a, b)) return -1
    else return 0
  })

export const isAfter = (d1: DateTime, d2: DateTime): boolean =>
  fromDateTimes(d2, d1).isValid && !d2.equals(d1)

export const isBefore = (d1: DateTime, d2: DateTime): boolean =>
  fromDateTimes(d1, d2).isValid && !d1.equals(d2)
