import { CHECKBOX_VALUES } from '@/utilities/constants'
import lodashCamelCase from 'lodash/camelCase'
import lodashLowerCase from 'lodash/lowerCase'
import lodashSnakeCase from 'lodash/snakeCase'
import lodashStartCase from 'lodash/startCase'
import lodashToUpper from 'lodash/toUpper'
import lodashUpperCase from 'lodash/upperCase'
import lodashUpperFirst from 'lodash/upperFirst'
import { DateTime } from 'luxon'

const { CHECKBOX_FALSE, CHECKBOX_TRUE } = CHECKBOX_VALUES

/**
 * Convert a string to camel case.
 *
 * @param {String} s string to be converted
 *
 * @returns {String} converted string
 */
export function camelCase(s: string): string {
  return lodashCamelCase(s)
}

/**
 * Convert a value to checkbox icon
 *
 * @param {Boolean} v value to be converted
 *
 * @returns {String} checkbox icon name
 */
export function checkBoxIcon(v: boolean): string {
  return v ? CHECKBOX_TRUE : CHECKBOX_FALSE
}

/**
 * Add Elipses to a string
 *
 * @param {String} s string to be converted
 *
 * @returns {String} converted string
 */
export function ellipsis(s: string): string {
  return s + '...'
}

/**
 * Return the first letter of each word in a string, as separated by a space.
 *
 * @param {String} s string to be converted
 *
 * @returns {String} converted string
 */
export function initials(s: string): string {
  return s
    .split(' ')
    .map(w => lodashToUpper(w[0]))
    .join(' ')
    .trim()
}

/**
 * Convert a string to lower case.
 *
 * @param {String} s string to be converted
 *
 * @returns {String} converted string
 */
export function lowerCase(s: string): string {
  return lodashLowerCase(s)
}

/**
 * Remove all spaces from a string
 *
 * @param {String} s string to be converted
 *
 * @returns {String} converted string
 */
export function noSpaces(s: string): string {
  return s.split(' ').join('').trim()
}

/**
 * Surround a string with parentheses
 *
 * @param {String} s string to be converted
 *
 * @returns {String} converted string
 */
export function parentheses(s: string): string {
  return '(' + s + ')'
}

/**
 * Convert a string to pascal case.
 *
 * @param {String} s string to be converted
 *
 * @returns {String} converted string
 */
export function pascalCase(s: string): string {
  return lodashUpperFirst(lodashCamelCase(s))
}

/**
 * Convert a string to a sentence.
 *
 * @param {String} s string to be converted
 *
 * @returns {String} converted string
 */
export function sentence(s: string): string {
  return lodashUpperFirst(lodashLowerCase(s))
}

/**
 * Convert a string to snake case.
 *
 * @param {String} s string to be converted
 *
 * @returns {String} converted string
 */
export function snakeCase(s: string): string {
  return lodashSnakeCase(s)
}

/**
 * Convert a string to snake case, then to upper case.
 *
 * @param {String} s string to be converted
 *
 * @returns {String} converted string
 */
export function snakeUp(s: string): string {
  return snakeCase(s)
    .split('_')
    .map(ss => upperCase(ss))
    .join('_')
}

/**
 * Convert a string to a title.
 *
 * @param {String} s string to be converted
 *
 * @returns {String} converted string
 */
export function title(s: string): string {
  return lodashStartCase(s.trim())
    .split(' ')
    .map(v => lodashUpperFirst(lodashLowerCase(v)))
    .map(v => {
      const exceptions = ['a', 'an', 'and', 'but', 'or', 'so', 'the']
      return exceptions.includes(v.toLowerCase()) ? lowerCase(v) : v
    })
    .join(' ')
}

/**
 * Convert a string to upperCase.
 *
 * @param {String} s string to be converted
 *
 * @returns {String} converted string
 */
export function upperCase(s: string): string {
  return lodashUpperCase(s)
}

export function price(value: number, currency: string, locale: string | undefined): string {
  locale = 'en-GB'
  return new Intl.NumberFormat(locale === undefined ? locale : 'en-GB', {
    style: 'currency',
    currency: currency,
  }).format(Number(value || 0))
}

export function shortDateTimeWithSlashes(value: string): string {
  if (value === '') {
    return ''
  }
  return DateTime.fromISO(value, { setZone: true }).toFormat('dd/MM/yyyy HH:mm')
}
export function shortDateWithSlashes(value: string): string {
  if (value === '') {
    return ''
  }
  return DateTime.fromISO(value, { setZone: true }).toFormat('dd/MM/yyyy')
}

const filters: Record<string, any> = {
  camelCase,
  checkBoxIcon,
  ellipsis,
  initials,
  lowerCase,
  noSpaces,
  parentheses,
  pascalCase,
  sentence,
  snakeCase,
  title,
  upperCase,
  price,
  shortDateTimeWithSlashes,
  shortDateWithSlashes,
}

export default filters
