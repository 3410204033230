
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

const defaultAcceptFileTypes = Object.freeze(['image/jpeg', 'image/jpg', 'image/png'])

@Component
export default class PhotoCropperFileInput extends Vue {
  // props // ***
  @Prop({ default: defaultAcceptFileTypes.join(' '), type: String })
  accept!: string
  @Prop({ type: Boolean })
  counter!: boolean
  @Prop({ type: Boolean })
  hideZoomBar!: boolean
  @Prop({ default: 'Image File', type: String })
  label!: string
  @Prop({ type: Array })
  rules!: () => (string | boolean)[]
  @Prop({ type: Boolean })
  showSize!: boolean
  @Prop({ type: Boolean })
  smallChips!: boolean
  @Prop({ type: Number })
  truncateLength!: number
  @Prop({ type: File })
  value!: File
  @Prop({ default: 1.25, type: Number })
  zoomInRatio!: number
  @Prop({ default: 0.75, type: Number })
  zoomOutRatio!: number

  // data // ***
  croppedImage: File | null = null
  cropping = false
  image: {
    file: File | Blob | null
    src: string | ArrayBuffer | null
  } = { file: null, src: '' }
  reader: FileReader = new FileReader()

  @Watch('croppedImage')
  onCroppedImageChanged(v: File | null) {
    this.$emit('input', v)
  }
  // methods // ***
  onChange(e: Record<string, any>) {
    this.setCroppedImage(e)
  }
  onImageSelected(e: File | undefined) {
    if (!e || !this.image.file) return
    this.reader = new FileReader()
    this.reader.addEventListener('load', () => (this.image.src = this.reader.result), false)
    this.reader.readAsDataURL(this.image.file)
    this.cropping = true
  }
  onZoomIn() {
    this.zoom(this.zoomInRatio)
  }
  onZoomOut() {
    this.zoom(this.zoomOutRatio)
  }
  saveImage() {
    this.cropping = false
    this.$emit('save')
  }
  setCroppedImage(e: Record<string, any>) {
    const { canvas } = e
    const image: Record<string, any> = this.image
    const filename = image?.file?.name || 'croppedImage.jpg'
    canvas.toBlob((blob: Blob) => {
      this.croppedImage = new File([blob], filename, undefined)
    }, 'image/jpg')
  }
  zoom(n: number) {
    const refs: Record<string, any> = this.$refs
    refs.cropper?.zoom(n)
  }
}
