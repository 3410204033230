
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SearchRow extends Vue {
  // props // ***
  @Prop({ default: 800, type: Number })
  breakWidth!: number

  // computed // ***
  get breakpoints() {
    return this.$vuetify.breakpoint
  }
  get classes() {
    const { width } = this.breakpoints
    const pass = width >= this.breakWidth
    return {
      actions: pass ? 'd-inline-block' : 'd-block',
      col1: 'text-left flex-grow-1 ' + (pass ? '' : 'py-0'),
      col2: 'text-right flex-grow-0 flex-shrink-1 ' + (pass ? '' : 'py-0'),
      search: pass ? 'd-inline-block mx-4' : 'd-block mt-4',
    }
  }
}
