
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SupplierSelector extends Vue {
  // props // ***
  @Prop({ type: Boolean })
  hideDetails!: boolean
  @Prop()
  label!: string

  // computed // ***
  get supplierSelect() {
    return {
      disabled: this.suppliers.length <= 1,
      label: this.label || this.$tc('plurals.suppliers', this.suppliers.length),
      items: this.suppliers.map((s: Record<string, any>, index: number) => ({
        text: s.name,
        value: index,
      })),
    }
  }
  get supplier(): Record<string, any> {
    return this.suppliers[this.value]
  }
  get suppliers(): Record<string, any> {
    return this.$store.getters['suppliers/suppliers']
  }
  get value() {
    return this.$store.getters['suppliers/activeSupplier']
  }

  // methods // ***
  onInput(e: number | string) {
    this.$store.commit('suppliers/setActiveSupplier', e)
    this.$cookies.set('activeSupplier', e)
    this.$emit('input', e)
    this.$emit('change', e)
  }
}
