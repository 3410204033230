
import { Component, Prop, Vue } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'

@Component
export default class Form extends Vue {
  // props // ***
  @Prop({ type: Boolean })
  disableAutofocus!: boolean
  @Prop({ required: true, type: Array })
  fields!: Record<string, any>[]
  @Prop({ type: String })
  formClass!: string
  @Prop({ type: Boolean })
  hideDividers!: boolean
  @Prop({ type: Boolean })
  lazyValidation!: boolean
  @Prop({ type: Boolean })
  loading!: boolean
  @Prop({ default: false, type: Boolean })
  showSubmit!: boolean
  @Prop({ type: String })
  title!: string

  // data // ***
  values: Record<string, any> = {}

  // computed // ***
  get errorMessages() {
    return !!this.$store.state.core.errors?.[422]
  }
  get showSubmitButton() {
    return this.showSubmit
  }

  // created // ***
  created() {
    this.reset()
  }

  // methods // ***
  getComponent({ component }: Record<string, any>) {
    return component || 'v-text-field'
  }
  onEnter(f: any, e: any) {
    if (this.getComponent(f) === 'v-text-field' || f.submitOnEnter) {
      if (f.validateOnBlur || f.blurOnEnter) e?.target?.blur()
      this.submit()
    }
  }
  onInput(f: any, e: any) {
    this.$emit('input', { field: f, value: e })
    this.setValue(f, e)
    this.resetErrors()
  }
  reset() {
    this.resetErrors()
    this.setInitialValues()
  }
  resetErrors() {
    this.$store.commit('core/resetErrors')
  }
  setInitialValues() {
    this.fields.forEach((f: any) => {
      this.setValue(f, f.value)
    })
  }
  setValue({ name, type }: { name: string; type: string }, value: any) {
    if (value && type === 'number') value = Number(value) // enforces number type
    Vue.set(this.values, name, value)
  }
  shouldAutofocus(index: number, field: Record<string, any>) {
    if (this.disableAutofocus) return false
    return index === 0 && this.getComponent(field) === 'v-text-field'
  }
  submit() {
    if (this.validate() && !this.loading && !this.errorMessages) {
      this.$emit('submit', { fields: this.fields, values: cloneDeep(this.values) })
    }
  }
  validate() {
    const form: any = this.$refs.form
    return form.validate()
  }
}
