
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Page extends Vue {
  // props // ***
  @Prop({ type: Boolean })
  fullHeight!: boolean
  @Prop()
  heading!: string
}
