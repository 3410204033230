
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class LoadingScreen extends Vue {
  // props // ***
  @Prop({ default: 'primary' })
  color!: string
  @Prop({ default: true })
  fullHeight!: boolean
  @Prop({ default: true, type: Boolean })
  loading!: boolean
  @Prop({ default: true, type: Boolean })
  indeterminate!: boolean
  @Prop({ default: 60 })
  size!: number | string

  // computed // ***
  get messageClass(): string {
    return this.color ? this.color + '--text' : ''
  }
}
