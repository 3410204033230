import { loadLanguageAsync } from '@/plugins/i18n'
import routes from '@/routes/index'
import authClient from '@/utilities/auth'
import { authoriseUser } from '@/utilities/boot'
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './vuex'

Vue.use(VueRouter)

const router = new VueRouter({
  base: process.env.BASE_URL,
  linkActiveClass: 'linkActive',
  linkExactActiveClass: 'linkActive',
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((...[to, , next]) => {
  if (to.meta?.public) {
    next()
  } else {
    if (!authClient.authenticated) {
      authoriseUser(router.app).then(() => {
        if (
          !authClient.userHasSignedLatestTermsAndConditions &&
          !authClient.impersonating &&
          authClient.authApiUser.supplierPortalUsageStatus !== 10
        ) {
          if (to.name === 'PoliciesAndTerms') {
            next()
          } else {
            router.push({ name: 'PoliciesAndTerms' })
          }
        } else if (to.path == '/') {
          if (
            authClient.features &&
            authClient.features.homePage?.active &&
            authClient.features.homePage.configuration
          ) {
            router.push(authClient.features.homePage.configuration.route)
          } else {
            router.push('/cost-confirmation')
          }
        }
        next()
      })
    } else {
      if (
        !authClient.userHasSignedLatestTermsAndConditions &&
        !authClient.impersonating &&
        authClient.authApiUser.supplierPortalUsageStatus !== 10
      ) {
        if (to.name === 'PoliciesAndTerms') {
          next()
        } else {
          router.push({ name: 'PoliciesAndTerms' })
        }
      } else if (to.path == '/') {
        if (
          authClient.features &&
          authClient.features.homePage?.active &&
          authClient.features.homePage.configuration
        ) {
          router.push(authClient.features.homePage.configuration.route)
        } else {
          router.push('/cost-confirmation')
        }
      }
      next()
    }
  }
})

router.beforeEach((...[to, , next]) => {
  const lang = store.getters['core/language']
  loadLanguageAsync(lang).then(() => {
    next()
  })
})

export default router
