
import { Component, Watch, Vue } from 'vue-property-decorator'

@Component
export default class Errors extends Vue {
  // computed // ***
  get errors() {
    return this.$store.getters['core/globalErrors']
  }

  // watch // ***
  @Watch('errors')
  onErrorsChanged(e: any, eOld: any) {
    if (this.haveNewErrors(e, eOld)) this.launchErrorSnackbar(e)
  }

  // methods // ***
  concatErrors(e: any) {
    if (['string', 'number'].includes(typeof e)) return String(e)
    else return e?.length ? e.join('\n') : ''
  }
  haveNewErrors(n: any, o: any) {
    return n?.length && n != o
  }
  launchErrorSnackbar(e: any) {
    const params = {
      color: 'error',
      message: this.concatErrors(e),
      timeout: 5000,
    }
    this.$store.dispatch('core/launchSnackbar', params)
  }
}
