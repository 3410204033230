
import { Component, Prop, Vue } from 'vue-property-decorator'
import { COUNTRIES } from '@/utilities/constants'

@Component
export default class CountrySelector extends Vue {
  // props // ***
  @Prop({ default: '', type: String })
  className!: string
  @Prop({ default: '', type: String })
  label!: string
  @Prop({ default: 'GB', type: String })
  value!: string

  // computed // ***
  get countries(): readonly string[][] {
    return COUNTRIES
  }
  get items(): Record<string, string>[] {
    return this.countries.map((c: string[]) => ({ text: c[0], value: c[1] }))
  }

  // methods // ***
  countryIdRecognised(id: string): boolean {
    return !!this.countries.find((v: string[]) => v[1] === id)?.[0]
  }
  getFlagClassname(v = '') {
    const countryId = this.countryIdRecognised(v) ? v : 'GB'
    return `em em-flag-${countryId.toLowerCase()}`
  }
  getFlagClassnameFromId(id: string) {
    return this.getFlagClassname(id)
  }
  getFlagClassnameFromItem(item: Record<string, string>) {
    return this.getFlagClassname(item.value)
  }
  getCountryNameFromId(id: string): string {
    const countryId = this.countryIdRecognised(id) ? id : 'GB'
    return this.countries.find((v: string[]) => v[1] === countryId)?.[0] || ''
  }
  onInput(e: Record<string, string>) {
    this.$emit('input', e.value)
  }
}
