
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class IconWithTooltip extends Vue {
  // props // ***
  @Prop({ default: true, type: Boolean })
  left!: boolean
  @Prop({ type: String })
  icon!: string
  @Prop({ type: String })
  iconColor!: string
  @Prop({ default: false, type: Boolean })
  right!: boolean
  @Prop({ type: String })
  tooltip!: string

  // computed // ***
  get showable() {
    return this.icon || this.$slots.icon
  }
}
