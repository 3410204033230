
import { Component, Prop, Vue } from 'vue-property-decorator'
import TERMS_AND_CONDITIONS_TEXT from '@/components/pages/termsAndConditions/termsAndConditionsText'

@Component
export default class TermsAndConditionsModal extends Vue {
  // props // ***
  @Prop({ default: false, type: Boolean })
  value!: boolean

  // data // ***
  submitAttempted = false
  termsAndConditionsAccepted = false

  // computed // ***
  get loading() {
    return this.$store.getters['core/apiEndpointIsLoading']({
      method: 'POST',
      url: 'supplier/acceptOrganisationAgreement',
    })
  }
  get maxWidth() {
    return '600px'
  }
  get showValidationError() {
    return this.submitAttempted && !this.termsAndConditionsAccepted
  }
  get termsAndConditions() {
    return TERMS_AND_CONDITIONS_TEXT
  }

  // methods // **
  onSubmit() {
    this.submitAttempted = true
    if (this.termsAndConditionsAccepted) this.submit()
  }
  submit() {
    this.$store.dispatch('core/acceptTermsAndConditions')
  }
}
