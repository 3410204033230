import { config as env } from '@/plugins/env'
import store from '@/plugins/vuex'
import auth from '@/utilities/auth'
import { camelise, snakify } from '@/utilities/functions'
import { objectToQuery, prefixUrl } from '@/utilities/urls'
import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const { API_ROOT_URL, CAMELISE_INCOMING, SNAKIFY_OUTGOING } = env

function addApiHeaders(
  headers: Record<string, any>,
  json = false,
  formData = false,
  useBasicApiAuth = false
): Record<string, any> {
  const impersonatingId = auth?.impersonatedUser?.id
  if (impersonatingId) headers['X-Impersonating'] = impersonatingId
  const authType = auth?.authType
  if (useBasicApiAuth) addBasicAuthHeader(headers)
  else if (authType === 'passport') addPassportHeaders(headers)
  else if (authType === 'oidc') addOidcHeaders(headers)
  else if (authType === 'development') addDevelopmentHeaders(headers)
  else throw 'authType not recognised'
  if (json) return addJsonHeaders(headers)
  if (formData) return addFormDataHeaders(headers)
  else return headers
}

function addBearerTokenHeader(headers: Record<string, any>): Record<string, any> {
  const accessToken = auth?.authUser?.access_token
  if (accessToken) headers['Authorization'] = 'Bearer ' + accessToken
  return headers
}

function addBasicAuthHeader(headers: Record<string, any>): Record<string, any> {
  headers['Authorization'] = `Basic ${btoa(
    '809CCF57-D956-4070-B49F-140DE6A8241F:4EF6A3BD-7D4C-4378-8A40-7DAC9AEF0A3A'
  )}`
  return headers
}

function addFormDataHeaders(headers: Record<string, any>): Record<string, any> {
  headers['Content-Type'] = 'multipart/form-data'
  return headers
}

function addJsonHeaders(headers: Record<string, any>): Record<string, any> {
  headers['Content-Type'] = 'application/json'
  headers['Accept'] = 'application/json'
  return headers
}

function addOidcHeaders(headers: Record<string, any>): Record<string, any> {
  return addBearerTokenHeader(headers)
}

function addPassportHeaders(headers: Record<string, any>): Record<string, any> {
  return addBearerTokenHeader(headers)
}

function addDevelopmentHeaders(headers: Record<string, any>): Record<string, any> {
  return headers
}

/**
 * A function for making api requests.
 *
 * @param {String} url Target api URL
 * @param {Object} options Optional parameters (optional)
 * @param {Boolean} options.auth Indicates if url requires authentication
 * @param {Mixed} options.data Request payload
 * @param {Object} options.headers Request headers (default: {})
 * @param {String} options.method Request method (default: 'GET')
 * @param {Boolean} options.noApiErrorHandling Do not auto-handle api errors (default: false)
 * @param {Boolean} options.noPrefix Do not prefix url (default: false)
 * @param {String} options.prefix URL prefix (default: 'api')
 * @param {Object} options.params Query parameters (default: {})
 * @param {String} options.rootUrl API root url (default: API_ROOT_URL)
 * @param {Object} axiosOptions Additional options passed directly through to axios (default: {})
 *
 * @returns {Mixed} The result of the api request
 */
export default async function api(
  url: string,
  options: Record<string, any> | undefined,
  axiosOptions: Record<string, any> | undefined
): Promise<Record<string, any>> {
  return await request(url, options, axiosOptions)
}

const defaultHeaders = () => ({ Accept: '*/*' })

function getBody(data: any, stringify: boolean): string | Record<string, any> {
  if (!data) return ''
  if (typeof data === 'string') return data
  const body = SNAKIFY_OUTGOING === 'true' ? snakify(data) : data
  return stringify ? JSON.stringify(body) : body
}

function getUrl(prefix: string, url: string, rootUrl: string): string {
  return prefixUrl(prefixUrl(url, prefix), rootUrl)
}

function onUndefinedError(e: any): void {
  console.error('api error (undefined error)')
  if (typeof e !== 'undefined') console.error(e)
  // if nothing passed, assume for now 400 bad request
  else store.dispatch('core/onApiError', { status: 400 })
}

async function request(
  url: string,
  opts: Record<string, any> | undefined,
  axiosOptions: Record<string, any> | undefined = {}
): Promise<Record<string, any>> {
  if (!url) throw 'api needs a valid target url'
  const {
    auth,
    data,
    headers = {},
    json = false,
    formData = false,
    method = 'GET',
    noApiErrorHandling = false,
    noPrefix = false,
    prefix = 'api',
    params = {},
    rootUrl = API_ROOT_URL,
    useApiAuth = false,
  } = opts || {}
  store.commit('core/setApiEndpointsLoading', { method, url, value: true })
  const options = {
    data: formData ? data : getBody(data, !auth),
    headers: addApiHeaders({ ...defaultHeaders(), ...headers }, json, formData, useApiAuth),
    method,
    noApiErrorHandling,
    params: SNAKIFY_OUTGOING === 'true' ? snakify(params) : params,
    paramsSerializer: (params: Record<string, unknown>): string => objectToQuery(params),
    url: getUrl(noPrefix ? '' : prefix, url, rootUrl),
    // pass through additional options last to override previous options where necessary
    ...axiosOptions,
    withCredentials: !!auth,
  }
  return axios(options)
    .then(r => {
      const c = CAMELISE_INCOMING === 'true' ? camelise(r) : r
      return c
    })
    .catch(e => {
      const r = CAMELISE_INCOMING === 'true' ? camelise(e) : e
      if (!r?.response?.status) onUndefinedError(r?.response)
      else if (!noApiErrorHandling) {
        store.dispatch('core/onApiError', r?.response)
      }
      throw r
    })
    .finally(() => {
      store.commit('core/setApiEndpointsLoading', { method, url, value: false })
    })
}
