
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class MultiSelect extends Vue {
  @Prop({ type: Boolean })
  dense!: boolean
  @Prop({ type: Boolean })
  disabled!: boolean
  @Prop({ type: Boolean })
  eager!: boolean
  @Prop({ type: Boolean })
  hideDetails!: boolean
  @Prop({ required: true, type: Array })
  items!: ((v: string) => boolean | string)[]
  @Prop()
  label!: string
  @Prop({ default: () => [], type: Array })
  rules!: ((v: string) => boolean | string)[]
  @Prop({ type: Boolean })
  showSelectAll!: boolean
  @Prop()
  value!: any[]

  // data // ***
  allSelected = false

  // methods // ***
  deselectAll() {
    this.allSelected = false
    this.onInput([])
  }
  getChipText(s: string) {
    const max = 15
    if (s.length >= max + 3) return s.slice(0, max) + '...'
    else return s
  }
  onInput(e: Record<string, any>[]) {
    this.$emit('input', e)
  }
  selectAll() {
    this.allSelected = true
    this.onInput(this.items.map((v: Record<string, any>) => v.value))
  }
  onSelectAll() {
    if (this.allSelected) this.deselectAll()
    else this.selectAll()
  }
}
