import { title } from '@/utilities/filters'
import i18n from '@/plugins/i18n'

export default [
  {
    component: (): any => import(/* webpackChunkName: "public" */ '@/pages/Login.vue'),
    meta: { layout: 'empty-layout', title: title(String(i18n.t('auth.login'))), public: true },
    name: 'Login',
    path: '/login',
  },
  {
    component: (): any =>
      import(/* webpackChunkName: "public" */ '@/pages/smartLink/SmartLink.vue'),
    meta: { layout: 'empty-layout', title: title(String(i18n.t('auth.login'))), public: true },
    name: 'SmartLink',
    path: '/smartlink/use/:id',
  },
  {
    component: (): any =>
      import(/* webpackChunkName: "public" */ '@/pages/permits/public/ViewPermit.vue'),
    meta: { title: title(String(i18n.t('cmac.permits'))), public: true },
    name: 'ViewPermit',
    path: '/permits/public/view/:id/:validationCode',
  },
  {
    component: (): any => import(/* webpackChunkName: "public" */ '@/pages/onboarding/sign-up.vue'),
    meta: { layout: 'empty-layout', title: title(String(i18n.t('cmac.permits'))), public: true },
    name: 'onboarding',
    path: '/onboarding',
  },
]
