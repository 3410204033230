
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { forceChronologicalOrder, parseDates } from '@/utilities/time'
import { getFormattedDate } from '@/utilities/org'
import { DateTime } from 'luxon'

@Component
export default class DateRangePicker extends Vue {
  // props // ***
  @Prop({ default: () => [], type: Array })
  additionalRules!: ((value: any) => string | boolean)[]
  @Prop({ type: Boolean })
  allowReverseDates!: boolean
  @Prop({ type: Boolean })
  closeOnInput!: boolean
  @Prop({ type: Boolean })
  dense!: boolean
  @Prop({ type: [Boolean, String] })
  hideDetails!: boolean | string
  @Prop()
  inputClass!: string
  @Prop({ required: false, default: null })
  label!: string
  @Prop({ default: '400px' })
  modalWidth!: string
  @Prop({ type: Boolean })
  showCloseButton!: boolean
  @Prop({ type: Boolean })
  showResetButton!: boolean
  @Prop({ default: () => [], type: Array })
  textFieldRules!: ((value: any) => string | boolean)[]
  @Prop({ required: true, type: Array })
  value!: DateTime[]

  // data // ***
  show = false

  // computed // ***
  get dateRangeText() {
    const { hasLength, valid, value } = this
    if (!valid) return undefined
    else if (hasLength(value, 1)) return getFormattedDate(value[0].toISODate())
    else return value.map(d => getFormattedDate(d.toISODate())).join(' - ')
  }
  get displayLabel() {
    return this.label ? this.label : this.$t('common.dateRange')
  }
  get isoDates() {
    return this.value.map(d => d.toISODate())
  }
  get rules() {
    return [
      (v: any) => this.isArray(v),
      (v: any) => this.hasValidLength(v),
      ...this.additionalRules,
    ]
  }
  get valid() {
    return this.rules.reduce((a, r) => {
      if (!a) return false
      else return r(this.value) === true
    }, true)
  }

  @Watch('show')
  onShowChanged(v: boolean) {
    if (!v) this.$emit('close')
  }

  // methods // ***
  areSameDay(dates: DateTime[]) {
    const { hasLength } = this
    if (hasLength(dates, 2) && dates[0].equals(dates[1])) return true
    else return false
  }
  isArray(v: any) {
    return Array.isArray(v)
  }
  hasLength(v: any, l = 2) {
    return v.length === l
  }
  hasValidLength(v: any) {
    const { hasLength: hl } = this
    return hl(v, 0) || hl(v, 1) || hl(v, 2)
  }
  onClose() {
    this.show = false
  }
  onInput(e: string[]) {
    const dates = parseDates(e)
    // if same date selected return just one
    if (this.areSameDay(dates)) this.$emit('input', dates.slice(0, 1))
    else this.$emit('input', !this.allowReverseDates ? forceChronologicalOrder(dates) : dates)
    if (this.closeOnInput && this.hasLength(dates, 2)) this.show = false
  }
}
