
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class FullScreenDialog extends Vue {
  // props // ***
  @Prop({ type: Boolean })
  activator!: boolean
  @Prop()
  activatorText!: string
  @Prop()
  cardClass!: string
  @Prop()
  toolbarButtons!: Record<string, any>[]
  @Prop()
  title!: string
  @Prop({ type: Boolean })
  value!: boolean

  // data // ***
  dialog = false
  notifications = false
  sound = true
  widgets = false

  // methods // ***
  close() {
    this.$emit('input', false)
  }
  onInput(e: any) {
    this.$emit('input', e)
  }
  onToolbarButtonClicked(e: any) {
    this.$emit('toolbar-button-clicked', e)
  }
}
