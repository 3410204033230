
import { Component, Prop, Vue } from 'vue-property-decorator'
import { getFilterGradient } from '@/utilities/theme'
import ImpersonationNavMenu from '@/components/nav/ImpersonationNavMenu'
import DisputeNotification from '@/components/nav/DisputeNotification'
import LocaleSelector from '@/components/nav/LocaleSelector.vue'

@Component({
  components: {
    'c-impersonation-nav-menu': ImpersonationNavMenu,
    'c-dispute-notification': DisputeNotification,
    'c-locale-selector': LocaleSelector,
  },
})
export default class Navbar extends Vue {
  // props // ***
  @Prop()
  links!: Record<string, any>[]

  // data // ***
  dense = true

  // computed // ***
  get navbarGradient() {
    return getFilterGradient(String(this.$vuetify.theme.themes.light.primary))
  }
  get visible() {
    return this.$auth.impersonating || this.$auth.userHasSignedLatestTermsAndConditions
  }
  get isImpersonating() {
    return this.$auth.impersonating
  }
  get isSupplierUser() {
    return this.$auth.activeUser.roles.findIndex((x: any) => x.name == 'chicken')
  }

  // methods // ***
  onLogoClicked() {
    if (this.visible) {
      this.$router.push('/').catch(e => console.log(e))
    }
  }
}
