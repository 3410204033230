
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class TableActionButton extends Vue {
  // props // ***
  @Prop({ type: String })
  color!: string
  @Prop({ type: Boolean })
  small!: boolean
  @Prop({ type: String })
  text!: string
}
