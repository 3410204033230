
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Modal extends Vue {
  // props // ***
  @Prop({ type: Boolean })
  activator!: boolean
  @Prop()
  activatorText!: string
  @Prop({ type: Boolean })
  fullScreen!: boolean
  @Prop()
  maxWidth!: number | string
  @Prop({ type: Boolean })
  value!: boolean
}
