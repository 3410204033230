
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'

@Component
export default class SaveCard extends Vue {
  // props // ***
  @Prop({ default: true, type: Boolean })
  hideOverlay!: boolean
  @Prop({ type: Boolean })
  hideSaveButton!: boolean
  @Prop({ type: Boolean })
  loading!: boolean
  @Prop({ default: true, type: Boolean })
  persistent!: boolean
  @Prop()
  resetButtonText!: string
  @Prop()
  saveButtonText!: string
  @Prop({ type: Boolean })
  showResetButton!: boolean
  @Prop({ type: Boolean })
  value!: boolean

  // watch // ***
  @Watch('value')
  onValueChanged(to: boolean) {
    this.$store.commit('core/setShowSaveCardSpacer', to)
    this.$nextTick(() => {
      const refs: Record<string, any> = this.$refs
      refs.card.showScroll()
    })
  }

  // methods // ***
  onInput(e: any) {
    this.$emit('input', e)
  }
  onReset(e: any) {
    this.$emit('reset', e)
  }
  onSave(e: any) {
    this.$emit('save', e)
  }
}
