import snakeCase from 'lodash/snakeCase'
import i18n from '@/plugins/i18n'
import { CURRENCIES, DEFAULT_ADDRESS_PROPERTIES, JOURNEY_STATUSES } from '@/utilities/constants'

const { EUR, GBP } = CURRENCIES
const { AGREED, DISPUTED, EXPIRED, OPEN } = JOURNEY_STATUSES

export const formatAddress = (
  address: Record<string, any>,
  properties = DEFAULT_ADDRESS_PROPERTIES
): string => {
  if (!address || typeof address !== 'object') return ''
  let text = ''
  properties.forEach(p => {
    const segment = address[p]
    if (segment?.trim()) text = text += segment + ', '
  })
  if (!text) return ''
  else return text.slice(0, -2) + '.'
}

export const getCurrency = (c: number): string => {
  switch (c) {
    case EUR:
      return String(i18n.t('currencies.eur'))
    case GBP:
      return String(i18n.t('currencies.gbp'))
    default:
      return ''
  }
}

export const getStatus = (s: number): string => {
  switch (s) {
    case AGREED:
      return String(i18n.t('common.agreed'))
    case DISPUTED:
      return String(i18n.t('common.disputed'))
    case EXPIRED:
      return String(i18n.t('common.expired'))
    case OPEN:
      return String(i18n.t('common.open'))
    default:
      return ''
  }
}

export const onSearch = (context: Record<string, any>, e: string): void => {
  context.search = e
  // if (context.options?.page !== 1) context.options.page = 1
  // else context.fetch()
}

export const orderBy = (context: Record<string, any>): string => {
  let ob = snakeCase(context.options?.sortBy[0])
  if (context.options?.sortDesc?.[0]) ob += '_desc'
  return ob
}
