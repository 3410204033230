
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class AddressCard extends Vue {
  // props // ***
  @Prop({ required: true, type: Object })
  address!: Record<string, any>
  @Prop({ default: '400px', type: [String, Number] })
  width!: string | number
  @Prop({ type: Boolean, default: true })
  canDelete!: boolean
  @Prop({ type: Boolean, default: true })
  canEdit!: boolean

  // computed // ***
  get description() {
    return this.address.description
  }
  get formattedAddress() {
    const lines = ['address1', 'address2', 'town', 'region', 'postCode', 'country']
    return lines.reduce((a: string, v: string) => {
      const line = this.address[v]
      if (!line) return a
      else return `${a ? a + '\n' : a}${String(line).trim()}`
    }, '')
  }

  // methods // ***
  onDelete() {
    this.$emit('delete')
  }
  onEdit() {
    this.$emit('edit')
  }
}
