var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"PhotoCropperFileInputContainer"},[_c('v-file-input',{attrs:{"accept":_vm.accept,"counter":_vm.counter,"label":_vm.label,"rules":_vm.rules,"showSize":_vm.showSize,"smallChips":_vm.smallChips,"truncateLength":_vm.truncateLength},on:{"change":_vm.onImageSelected},model:{value:(_vm.image.file),callback:function ($$v) {_vm.$set(_vm.image, "file", $$v)},expression:"image.file"}}),_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.cropping),callback:function ($$v) {_vm.cropping=$$v},expression:"cropping"}},[_c('v-card',[_c('div',{staticClass:"cropperContainer pa-4"},[_c('p',{staticClass:"mb-2"},[_vm._v(" Please position and resize the image so that it is correctly framed before saving. ")]),_c('ul',{staticClass:"mb-4"},[_c('li',[_vm._v("Move the image by clicking and dragging with a mouse or touchscreen.")]),_c('li',[_vm._v(" Resize the image by using the zoom buttons below, or by using a mouse wheel or touchscreen gestures for zooming. "),_c('small',[_vm._v("(Please consult your device's user manual for more information.)")])]),_c('li',[_vm._v(" Adjust the image so that the visible area is correct. (All parts of the image that are greyed out will be cropped/removed when saved.) ")])]),_c('v-cropper',{ref:"cropper",staticClass:"cropper",attrs:{"src":_vm.image.src,"stencil-props":{
            handlers: {},
            movable: true,
            resizable: true,
            scalable: true,
            aspectRatio: 147 / 110,
          },"image-restriction":"stencil"},on:{"change":_vm.onChange}}),_c('div',{staticClass:"cropperActionsContainer d-flex align-center justify-space-between"},[_c('c-btn',{attrs:{"color":"primary mt-2"},on:{"click":_vm.saveImage}},[_vm._v("Save Image")]),(!_vm.hideZoomBar)?_c('div',{staticClass:"zoomBar mt-2"},[_c('c-btn',{attrs:{"icon":"","large":""},on:{"click":_vm.onZoomIn}},[_c('v-icon',[_vm._v("zoom_in")])],1),_c('c-btn',{attrs:{"icon":"","large":""},on:{"click":_vm.onZoomOut}},[_c('v-icon',[_vm._v("zoom_out")])],1)],1):_vm._e()],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }