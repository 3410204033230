
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class LeadMileage extends Vue {
  @Prop({ required: true })
  jobEntryId!: number
  @Prop({ required: true })
  showComponent!: boolean
}
