
import { Component, Prop, Vue } from 'vue-property-decorator'
import NavLink from '@/components/nav/NavLink'
import NavLinkGroup from '@/components/nav/NavLinkGroup'

@Component({ components: { 'c-nav-link': NavLink, 'c-nav-link-group': NavLinkGroup } })
export default class NavLinks extends Vue {
  // props // ***
  @Prop({ required: true })
  items!: Record<string, any>[]

  // methods // ***
  onItemClicked(item: Record<string, any>) {
    this.$emit('item-clicked', item)
  }
}
